import DynamicWidgetsLoader from './../middleware/dynamic-widgets-loader.js';

const widgetTypes = DynamicWidgetsLoader.getWidgetTypes();

const WidgetsConfigurationManager = {
	/**
	 * Generate widget configuration
	 *
	 * @param  {Object} options                          The widget options
	 * @param  {String} options.widgetTypeName           The widget type
	 * @param  {String} [options.widgetId]               If null - new id will be generated
	 * @param  {Object} [options.fieldsValues]           The widget fields configurations
	 * @param  {Object} [options.widgetBase]             If null - a default base will be used
	 *
	 * @return {Object}                                  The Widget configuration
	 */
	generateWidgetConfig: ( { widgetTypeName, widgetId, fieldsValues, widgetBase } ) => {
		let widgetType = widgetTypes[ widgetTypeName ];
		let widgetTypeConfig = widgetType.getWidgetConfiguration();

		if ( !widgetBase ) {
			widgetId = WidgetsConfigurationManager.generateWidgetId( fieldsValues.widgetTitle );
			widgetBase = WidgetsConfigurationManager.getDefaultWidgetOptions( widgetTypeName, widgetTypeConfig.defaultSize );
		}

		return WidgetsConfigurationManager.fillFieldsValuesToConfiguration( widgetId, widgetTypeName, widgetTypeConfig, fieldsValues, widgetBase );
	},

	/**
	 * Generate an id for a widget using the title and a random number
	 *
	 * @param  {String} widgetTitle
	 *
	 * @return {String}
	 */
	generateWidgetId: ( widgetTitle ) => {
		return `${ widgetTitle.replace( /\s+/g, '-' ).toLowerCase() }_${ Math.round( Math.random() * 10000 ) }`;
	},

	/**
	 * Get the default options for a widget. By default it is added to top,left with size of 3
	 *
	 * @param  {String} widgetTypeName
	 * @param  {Array<Number>} defaultSize
	 *
	 * @return {Object}
	 */
	getDefaultWidgetOptions: ( widgetTypeName, defaultSize ) => {
		return {
			type: widgetTypeName,
			position: {
				x: 0,
				y: 0,
				h: defaultSize[ 0 ] ? defaultSize[ 0 ] : 3,
				w: defaultSize[ 1 ] ? defaultSize[ 1 ] : 3,
			}
		};
	},

	/**
	 * Fill in the field values into the widget configuration template
	 *
	 * @param  {String} widgetId
	 * @param  {String} widgetTypeName
	 * @param  {Object} widgetTypeConfiguration
	 * @param  {Object} fieldsValues
	 * @param  {Object} defaultWidget
	 *
	 * @return {Object}
	 */
	fillFieldsValuesToConfiguration: ( widgetId, widgetTypeName, widgetTypeConfiguration, fieldsValues, defaultWidget ) => {
		let widgetInstanceConfiguration = {
			type: defaultWidget.type,
			position: defaultWidget.position
		};

		let widgetInstanceConfigurationOptions = {};
		let { fieldsets } = widgetTypeConfiguration;
		let fieldsetsKeys = Object.keys( fieldsets );

		fieldsetsKeys.forEach( ( fieldsetKey ) => {
			fieldsets[ fieldsetKey ].fields.forEach( ( field ) => {
				let fieldValue = fieldsValues[ field.id ]

				if ( field.isRequired && !fieldValue ) {
					if ( !widgetInstanceConfiguration._errors ) {
						widgetInstanceConfiguration._errors = [];
					}

					widgetInstanceConfiguration._errors.push( `Required field ${ field.id } missing` );
				}

				widgetInstanceConfigurationOptions[ field.id ] = fieldValue;
			} );
		} );

		widgetInstanceConfigurationOptions.widgetId = widgetId;
		widgetInstanceConfiguration.options = widgetInstanceConfigurationOptions;

		return widgetInstanceConfiguration;
	}
}

export default WidgetsConfigurationManager;
