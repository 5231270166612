import React, { Component } from 'react';
import * as moment from 'moment';
import 'flatpickr/dist/themes/light.css';
import Flatpickr from 'react-flatpickr';
import './styles.css';

class RangeDateTimePickers extends Component {
	componentDidMount () {
		let dateFormat = this.getDateFormat(),

			fromPickr = this.fromPickr.flatpickr,
			fromPickrAltInput = fromPickr.altInput,
			startDate = this.props.startDate,
			onStartDateChange = this.props.onStartDateChange,

			toPickr = this.toPickr.flatpickr,
			toPickrAltInput = toPickr.altInput,
			endDate = this.props.endDate,
			onEndDateChange = this.props.onEndDateChange;

		fromPickrAltInput.addEventListener( 'blur', ( event ) => {
			let relatedTarget = event.relatedTarget;

			// do not update value when clicking inside the opened calendar or inside datetime input
			if ( relatedTarget === null || relatedTarget instanceof HTMLInputElement ) {
				let value = fromPickrAltInput.value ? fromPickr.parseDate( fromPickrAltInput.value, dateFormat ) : null,
					newStartDate;

				if ( !value ) {
					newStartDate = value;
				}
				else if ( moment( value ).isValid() ) {
					newStartDate = new Date( value.toString() );
				}
				else {
					newStartDate = new Date( startDate.toString() );
				}

				fromPickr.setDate( newStartDate, false );
				onStartDateChange( newStartDate ? moment( newStartDate ) : newStartDate );
			}
		} );

		toPickrAltInput.addEventListener( 'blur', ( event ) => {
			let relatedTarget = event.relatedTarget;

			// do not update value when clicking inside the opened calendar or inside datetime input
			if ( relatedTarget === null || relatedTarget instanceof HTMLInputElement ) {
				let value = toPickrAltInput.value ? toPickr.parseDate( toPickrAltInput.value, dateFormat ) : null,
					newEndDate;

				if ( !value ) {
					newEndDate = value;
				}
				else if ( moment( value ).isValid() ) {
					newEndDate = new Date( value.toString() );
				}
				else {
					newEndDate = new Date( endDate.toString() );
				}

				toPickr.setDate( newEndDate, false );
				onEndDateChange( newEndDate ? moment( newEndDate ) : newEndDate );
			}
		} );
	}

	shouldComponentUpdate ( nextProps ) {
		if ( this.props.startDate && nextProps.startDate ) {
			if ( this.props.startDate.format() !== nextProps.startDate.format() ) {
				return true;
			}
		}
		else if ( !this.props.startDate && nextProps.startDate ) {
			return true;
		}
		else if ( this.props.startDate && !nextProps.startDate ) {
			return true;
		}


		if ( this.props.endDate && nextProps.endDate ) {
			if ( this.props.endDate.format() !== nextProps.endDate.format() ) {
				return true;
			}
		}
		else if ( !this.props.endDate && nextProps.endDate ) {
			return true;
		}
		else if ( this.props.endDate && !nextProps.endDate ) {
			return true;
		}

		return false;
	}

	getDateFormat () {
		return this.props.dateFormat || 'H:i d/m/Y';
	}

	render () {
		let {
			dateFormat,
			minuteIncrement,
			maxDatesInterval,
			maxDateFromCalendar,
			maxDateToCalendar,
			startDate,
			endDate,
			onStartDateChange,
			onEndDateChange
		} = this.props;

		// restrict from calendar when maxDatesInterval is provided
		let minDateFromCalendar = maxDatesInterval && endDate ? moment( endDate ).subtract( maxDatesInterval.size, maxDatesInterval.unit ).format() : null;
		let minDateToCalendar = startDate ? startDate.clone().format() : null;

		startDate = startDate ? startDate.format() : null;
		endDate = endDate ? endDate.format() : null;
		dateFormat = this.getDateFormat();
		minuteIncrement = minuteIncrement || 15;

		return (
			<>
				<div className="vso-datespicker-container vso-rangepickers-container">
					<Flatpickr
						ref={ ( fromPickr ) => this.fromPickr = fromPickr }
						value={ startDate }
						options={
							{
								enableTime: true,
								time_24hr: true,
								minuteIncrement: minuteIncrement,

								allowInput: true,
								altInput: true,
								altFormat: dateFormat,
								dateFormat: 'Y-m-d H:i',

								minDate: minDateFromCalendar,
								maxDate: maxDateFromCalendar,

								wrap: true
							}
						}
						onChange={ date => {
							let newDate = date.length ? moment( date[ 0 ] ) : null;
							if ( newDate ) {
								onStartDateChange( newDate );
							}
						} }>
						<input type="text" placeholder="Start date" data-input />
						<span className="input-button close-icon" title="clear" data-clear onClick={ () => { onStartDateChange( null ) } }>&nbsp;</span>
					</Flatpickr>
					<Flatpickr
						ref={ ( toPickr ) => this.toPickr = toPickr }
						value={ endDate }
						options={
							{
								enableTime: true,
								time_24hr: true,
								minuteIncrement: minuteIncrement,

								allowInput: true,
								altInput: true,
								altFormat: dateFormat,
								dateFormat: 'Y-m-d H:i',

								minDate: minDateToCalendar,
								maxDate: maxDateToCalendar,

								wrap: true
							}
						}
						onChange={ date => {
							let newDate = date.length ? moment( date[ 0 ] ) : null;
							if ( newDate ) {
								onEndDateChange( newDate );
							}
						} }>
						<input type="text" placeholder="End date" data-input />
						<span className="input-button close-icon" title="clear" data-clear onClick={ () => { onEndDateChange( null ) } }>&nbsp;</span>
					</Flatpickr>
				</div>
				{
					maxDatesInterval ? (
						<div>
							* You can select up to { maxDatesInterval.size } { maxDatesInterval.unit }
						</div>
					) : null
				}
			</>
		);
	}
}

export default RangeDateTimePickers;