/**
 * @param {Number} page  the page index, starting from 0
 * @param {Number} itemsPerPage  number of items on a page
 * @param {Number} totalItems all the items being paginated through
 *
 * @returns {Boolean}
 */
export const hasItemsOnPage = ( page, itemsPerPage, totalItems ) => {
	// Page counts start from 0
	// { Total count } - { The items count that fit in the previous page }
	// Should be greater than 0
	return ( totalItems - page * itemsPerPage ) > 0;
}
