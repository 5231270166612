import React, { Component } from 'react';

import image from './image.png';

import stylable from './../../decorators/stylable.jsx';

import { getPropertyValueByPath } from './../../utils/devicesUtils.js';

import { Link } from 'react-router-dom';

class CurrentValue extends Component {
	static getWidgetConfiguration () {
		return {
			name: 'Current Value',
			image: image,
			description: 'Visualize the current value of a property',
			defaultSize: [ 4, 2 ],
			fieldsets: {
				initial: {
					name: 'Initial',
					fields: [ {
						id: 'widgetSubtitle',
						type: 'textarea',
						label: 'Subtitle'
					} ]
				},
				datasets: {
					name: 'Datasets',
					fields: [ {
						id: 'datasets',
						type: 'datasets',
						label: 'Datasets',
						required: true
					}, {
						id: 'selection',
						type: 'selection',
						label: 'Selection'
					} ]
				}
			}
		};
	}

	static getViewTypes () {
		return [ {
			key: 'split',
			name: 'Split View (Wide)',
		} ]
	}

	componentWillMount () {
		this.prepareWidgetDevices( this.props );
	}

	componentWillReceiveProps ( nextProps ) {
		this.prepareWidgetDevices( nextProps );
	}

	render () {
		const {
			options
		} = this.props;

		let {
			widgetSubtitle,
			widgetSize
		} = options;

		let loadingClass = ( this.isLoading ? 'loading' : '' );

		let blanketColor = options.stylable.blanketColor;

		let deviceValue;
		let devicePropertyUnit;
		let isValueTextLong = false;

		if ( !this.isLoading ) {
			deviceValue = this.getDevicePropertyValue();
			isValueTextLong = ( deviceValue.toString().length > 10 );
			if ( this.selectedDevice ) {
				if ( options.visualization.unit ) {
					devicePropertyUnit = options.visualization.unit;
				}
				else {
					devicePropertyUnit = this.props.api.getDevicePropertyUnit( this.selectedDevice, options.visualization.propertyPath );
				}
			}
		}

		let subtitleMarkup;
		if ( widgetSubtitle ) {
			subtitleMarkup = (
				<p className="widget-subtitle">
					<span className="subtitle-blanket" style={ {
						backgroundColor: blanketColor
					} }>
						{ widgetSubtitle }
					</span>
				</p>
			);
		}



		let widgetInnerContent = (
			<span className={ `current-value-inner-content ${ loadingClass }` }>
				{ deviceValue } <span className="property-unit">{ devicePropertyUnit }</span>
			</span>
		)
		let widgetContent;
		if ( this.selectedDevice ) {
			let linkSrc = options.link;

			if ( linkSrc ) {
				linkSrc = linkSrc.replace( '%id%', getPropertyValueByPath( this.selectedDevice, [ 'snapshot', 'id' ] ) );

				widgetContent = (
					<Link title={ deviceValue } className={ `current-value-text ${ isValueTextLong ? 'long-text' : '' }` } style={ {
						backgroundColor: blanketColor
					} } to={ linkSrc } >
						{ widgetInnerContent }
					</Link>
				);
			}
		}

		// We show it without link if there is no selected device or no link
		if ( !widgetContent ) {
			widgetContent = (
				<span title={ deviceValue } className={ `current-value-text ${ isValueTextLong ? 'long-text' : '' }` } style={ {
					backgroundColor: blanketColor
				} }>
					{ widgetInnerContent }
				</span>
			);
		}

		let widgetCssClasses = 'current-value-widget size-' + ( widgetSize ? widgetSize : 'default' );


		return (
			<div className={ widgetCssClasses }>
				<div className="current-value-text-container">
					{ widgetContent }
					{ subtitleMarkup }
				</div>
			</div>
		);
	}

	prepareWidgetDevices ( newProps ) {
		if ( newProps.provided ) {
			this.selectedDevice = newProps.provided.selectedDevice;
			this.isLoading = newProps.provided.isLoading;
			this.errors = newProps.provided.errors;
		}
	}

	getDevicePropertyValue () {
		let propertyValue;
		let visualizationOptions = this.props.options.visualization;

		if ( this.selectedDevice ) {
			let { propertyPath } = visualizationOptions;

			if ( !propertyPath ) {
				console.warn( 'DEPRECATED: Use "propertyPath" instead of "path" in the visualization config' );
				propertyPath = visualizationOptions.path;
			}

			propertyValue = getPropertyValueByPath( this.selectedDevice, [ 'snapshot', ...propertyPath ] );
		}

		if ( !propertyValue && propertyValue !== 0 ) {
			propertyValue = '-';
		}

		if ( typeof propertyValue === 'object' ) {
			propertyValue = JSON.stringify( propertyValue, null, 2 );
		}

		return propertyValue;
	}
}

export default stylable( CurrentValue );
