import React from 'react';

class GaugeChart extends React.Component {
	isValueOutOfRange () {
		let data = this.props.data,
			value = this.props.value;
		return ( value < data.minValue || value > data.maxValue );
	}

	gaugeChartInlineStyle () {
		let gaugeWidth = this.props.width,
			gaugeHeight = gaugeWidth / 2;
		return { 
			width: gaugeWidth + 'px',
			height: gaugeHeight + 'px'
		};
	}

	mainSemiCircleInlineStyle () {
		let colorOptions = this.props.color,
			value = this.props.value,
			data = this.props.data,
			style = this.gaugeChartInlineStyle();

		style.background = colorOptions.type === 'gradient' && value ? 
			colorOptions.filledValue : colorOptions.unfilledValue;

		if ( value && this.isValueOutOfRange() ) {
			if ( value < data.minValue ) {
				style.background = colorOptions.unfilledValue;
			}
			else if ( value > data.maxValue ) {
				style.background = colorOptions.filledValue;
			}
		}

		return style;
	}

	smallSemiCircleInlineStyle () {
		let gaugeWidth = this.props.width,
			gaugeHeight = gaugeWidth / 2,
			smallGaugeWidth = gaugeHeight,
			smallGaugeHeight = smallGaugeWidth / 2,
			smallGaugеTop = gaugeHeight - smallGaugeHeight,
			smallGaugeMarginLeft = ( gaugeWidth - smallGaugeWidth ) / 2;

		return {
			width: smallGaugeWidth + 'px',
			height: smallGaugeHeight + 'px',
			top: smallGaugеTop + 'px',
			marginLeft: smallGaugeMarginLeft + 'px'
		};
	}

	hiddenSemiCircleInlineStyle () {
		let style = this.gaugeChartInlineStyle(),
			colorOptions = this.props.color;
		style.background = colorOptions.type === 'gradient' ? colorOptions.unfilledValue : colorOptions.filledValue;
		style.top = ( this.props.width / 2 ) + 'px';

		if ( ! this.isValueOutOfRange () ) {
			style.transform = 'rotate(' + this.calculateSemiCirlcleDegreesRotation() + 'deg )';
		}

		return style;
	}

	gaugeDataInlineStyle () {
		let style = this.smallSemiCircleInlineStyle();
		style.top = ( this.props.width / 4 ) + 'px';

		if ( parseInt( style.width, 10 ) < 70 ) {
			style.fontSize = '1em'
		}

		return style;
	}

	gaugeDataInlineStyleEdgeCase () {
		let style = {};
		
		style.width = this.props.width;
		style.height = Math.round( style.width / 3 );

		if ( parseInt( style.width / 2, 10 ) < 100 ) {
			style.fontSize = '0.9em';
		}

		return style;
	}

	gaugeRangeInlineStyle () {
		return { width:  this.props.width }
	}

	calculateSemiCirlcleDegreesRotation () {
		let currentValue = this.props.value,
			minValue = this.props.data.minValue,
			maxValue = this.props.data.maxValue,
			correctedValue = currentValue - minValue,
			range = maxValue - minValue,
			currentValuePercentage = Math.round( ( correctedValue * 100 ) / range ),
			degrees = Math.round( 180 * ( currentValuePercentage ) / 100 ),
			result;

		if ( this.props.color.type === 'gradient' ) {
			result = ( 180 - degrees ) * -1;
		} else {
			result = degrees;
		}

		return result;
	}

	render () {
		let { value, data } = this.props,
			unit = data.unit,
			gaugeData;

		if ( this.isValueOutOfRange()  ) {
			gaugeData = (
				<div className="gauge-data out-of-range" style={ this.gaugeDataInlineStyleEdgeCase() }>
					<p className="value">{ value }{ unit } is out of range</p>
				</div>
			);
		}
		else if ( ! value ) {
			gaugeData = (
				<div className="gauge-data no-data" style={ this.gaugeDataInlineStyleEdgeCase() }>
					<p className="value">{ this.props.isLoading? 'Loading...': 'No data' }</p>
				</div>
			);

		}
		else {
			gaugeData =	( 
				<div className="gauge-data" style={ this.gaugeDataInlineStyle() }>
					<p className="value">{ value }<span className="unit">{ unit }</span></p>
				</div> );		
		}

		return (
			<div className="gauge-chart-container">
				<div className="gauge-chart" style={ this.gaugeChartInlineStyle() }>
					<div className="main-semi-circle" style={ this.mainSemiCircleInlineStyle() }></div>
					<div className="small-semi-circle" style={ this.smallSemiCircleInlineStyle() }></div>
					<div className="hidden-semi-circle" style={ this.hiddenSemiCircleInlineStyle() }></div>
					{ gaugeData }
				</div>
				<div className="gauge-range" style={ this.gaugeRangeInlineStyle() }>
					<p className="min-value">{ data.minValue }<span className="unit">{ unit }</span></p>
					<p className="max-value">{ data.maxValue }<span className="unit">{ unit }</span></p>
				</div>
			</div>
		);
	}
}

GaugeChart.defaultProps = {
	width: 300,
	background: '#0099cc',
	color: {
		type: 'solid',
		filledValue: '#0099cc',
		unfilledValue: '#eee'
	},
	data: {
		minValue: 0,
		maxValue: 100,
		unit: '%'
	}
};

export default GaugeChart;