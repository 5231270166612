import React, { Component } from 'react';
import * as moment from 'moment';
import 'flatpickr/dist/themes/light.css';
import Flatpickr from 'react-flatpickr';
import './styles.css';

class SingleDateTimePicker extends Component {
	componentDidMount () {
		let pickr = this.pickr.flatpickr,
			pickrAltInput = pickr.altInput,
			date = this.props.date,
			dateFormat = this.getDateFormat();


		pickrAltInput.addEventListener( 'blur', ( _ ) => {
			let value = pickrAltInput.value  ? pickr.parseDate( pickrAltInput.value, dateFormat ) : null,
				newDate;

			if ( !value ) {
				newDate = value;
			}
			else if ( moment( value ).isValid() ) {
				newDate = new Date( value.toString() );
			}
			else {
				newDate = new Date( date.toString() );
			}
			pickr.setDate( newDate, true );
		} );
	}

	getDateFormat () {
		return this.props.dateFormat || 'H:i d/m/Y';
	}

	render () {
		let { dateFormat, enableTime, minuteIncrement, placeholder,
			minDate, maxDate,
			date, onChange } = this.props;

		date = date ? date.format(): null;
		dateFormat = this.getDateFormat();
		minuteIncrement =  minuteIncrement || 15;
		enableTime = enableTime || false;

		placeholder = placeholder || 'Select date';

		return (
			<div className="vso-datespicker-container vso-singlepicker-container">
				<Flatpickr
					ref={ ( pickr ) => this.pickr = pickr }
					value={ date }
					options={
						{
							enableTime: enableTime,
							time_24hr: true,
							minuteIncrement: minuteIncrement,

							allowInput: true,
							altInput: true,
							altFormat: dateFormat,
							dateFormat: 'Y-m-d H:i',

							minDate: minDate,
							maxDate: maxDate,
							wrap: true
						}
					}
					onChange={ ( date ) => {
						let newDate = date.length? moment( date[ 0 ] ) : null;
						onChange( newDate );
					} }>
					<input type="text" placeholder={ placeholder } data-input />
					<span className="input-button close-icon" title="clear" data-clear>&nbsp;</span>
				</Flatpickr>
			</div>
		);
	}
}

export default SingleDateTimePicker;