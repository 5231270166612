import {
	FETCH_ALL_FLOWS_STARTED,
	FETCH_ALL_FLOWS_SUCCESS,
	FETCH_ALL_FLOWS_FAIL,
	UPDATE_FLOW_BY_NAME_STARTED,
	UPDATE_FLOW_BY_NAME_SUCCESS,
	UPDATE_FLOW_BY_NAME_FAILED,
	CREATE_FLOW_STARTED,
	CREATE_FLOW_SUCCESS,
	CREATE_FLOW_FAILED,
	INVOKE_FLOW_STARTED,
	INVOKE_FLOW_SUCCESS,
	INVOKE_FLOW_FAILED
} from './../../actions/flowsActions'

import {
	FETCH_DEVICES_SUCCESS,
	FETCH_DEVICE_HISTORY_SUCCESS
} from './../../actions/unitsActions'

export function flowsReducer ( state = {
	isFetching: false,
	error: null,
	flows: {},
	isUpdating: false,
	isCreating: false,
	isInvoking: false,
}, action ) {
	switch ( action.type ) {

		case FETCH_ALL_FLOWS_STARTED:
			return Object.assign( {}, state, {
				isFetching: true
			} )
		case FETCH_ALL_FLOWS_SUCCESS:
			return Object.assign( {}, state, {
				isFetching: false,
				error: null,
				flows: action.allFlows
			} )
		case FETCH_ALL_FLOWS_FAIL:
			return Object.assign( {}, state, {
				isFetching: false,
				error: action.error,
				flows: null
			} )
		case UPDATE_FLOW_BY_NAME_STARTED:
			return Object.assign( {}, state, {
				isUpdating: true
			} )
		case UPDATE_FLOW_BY_NAME_SUCCESS:
			if ( action.oldName === action.flow.name ) {
				return Object.assign( {}, state, {
					isUpdating: false,
					flows: Object.assign( {}, state.flows, {
						[ action.oldName ]: action.flow
					} )
				} )
			}
			else {
				let { [ action.oldName ]: oldFlow, ...otherFlows } = state.flows

				return Object.assign( {}, state, {
					flows: Object.assign( {}, otherFlows, {
						[ action.flow.name ]: action.flow
					} )
				} )
			}
		case UPDATE_FLOW_BY_NAME_FAILED:
			return Object.assign( {}, state, {
				isUpdating: false,
				error: action.error
			} )

		case CREATE_FLOW_STARTED:
			return Object.assign( {}, state, {
				isCreating: true,
			} )
		case CREATE_FLOW_SUCCESS:
			return Object.assign( {}, state, {
				isCreating: false,
				error: null,
				flows: Object.assign( {}, state.flows, {
					[ action.flow.name ]: action.flow
				} )
			} )
		case CREATE_FLOW_FAILED:
			return Object.assign( {}, state, {
				isCreating: false,
				error: action.error
			} )
		case INVOKE_FLOW_STARTED:
			return Object.assign( {}, state, {
				isInvoking: true
			} )
		case INVOKE_FLOW_SUCCESS:
			return Object.assign( {}, state, {
				isInvoking: false,
				error: null,
			} )
		case INVOKE_FLOW_FAILED:
			return Object.assign( {}, state, {
				isInvoking: false,
				error: action.error,
			} )
		case FETCH_DEVICES_SUCCESS: {
			/*
			flowName,
			unitId,
			devices,
			capabilities,
			realtimeUnits
			 */

			let {
				flowName,
				unitId,
				realtimeUnits
			} = action;

			let flow = state.flows[ flowName ];

			if ( !flow ) {
				flow = {
					name: flowName,
					units: []
				}
			}

			flow.units = [ ...new Set( flow.units.concat( [ unitId, ...realtimeUnits ] ) ) ];

			return {
				...state,
				flows: {
					...state.flows,
					[ flowName ]: flow
				}
			}

		}
		case FETCH_DEVICE_HISTORY_SUCCESS: {
			let flowName = action.flowName;
			let flow = state.flows[ flowName ];

			if ( !flow ) {
				flow = {
					name: flowName,
					units: []
				};
			}

			if ( flow.units.indexOf( action.unitId ) === -1 ) {
				flow.units = [ action.unitId, ...flow.units ];
			}

			return {
				...state,
				flows: {
					...state.flows,
					[ flowName ]: flow
				}
			};
		}
		default:
			return state
	}
}
