import React from 'react';

const HeaderMessage = ( props ) => {
	let { message, onClose, onAdd } = props;

	message = message.split( 'ADD' );

	let messageWithButton = (
		<span>
			<span>{ message[ 0 ] }</span>
			<button className="btn add-widget" onClick={ onAdd }>ADD</button>
			<span>{ message[ 1 ] }</span>
		</span>
	);

	return (
		<div className="header-message">
			{ messageWithButton }
			<button className="btn close-header-message" onClick={ onClose }>Done</button>
		</div>
	);
}

export default HeaderMessage;