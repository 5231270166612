import { combineReducers } from 'redux';

import { flowsReducer as flows } from './flowsReducer.js';
import { unitsReducer as units } from './unitsReducer.js';
import { unitsSchemasReducer as unitsSchemas } from './unitsSchemasReducer.js';
import { snapshotsToRealtimeUnitsRelationsReducer as snapshotsToRealtimeUnitsRelations } from './snapshotsToRealtimeUnitsRelationsReducer.js';
import { snapshotsToHistoryUnitsRelationsReducer as snapshotToHistoryUnitsRelations } from './snapshotsToHistoryUnitsRelationsReducer.js';
import { devicesReducer as devices } from './devicesReducer.js';
import { devicesHistoryReducer as devicesHistory } from './devicesHistoryReducer.js';
import { unitsDevicesRelationsReducer as unitsDevicesRelations } from './unitsDevicesRelationsReducer.js';

const entitiesReducer = combineReducers( {
	// dashboards,
	flows,
	units,
	unitsSchemas,
	snapshotsToRealtimeUnitsRelations,
	snapshotToHistoryUnitsRelations,
	devices,
	devicesHistory,
	unitsDevicesRelations
} );

export {
	entitiesReducer
};
