import React from 'react';

const TimeFacet = ({ timeDuration, timeUnit, onClick, selectedFacet }) => {
	let cssClass = 'facet';

	if( selectedFacet && selectedFacet.timeDuration === timeDuration && selectedFacet.timeUnit === timeUnit ) {
		cssClass += ' selected';
	}

	return (
		<button onClick={ () => { onClick({ timeDuration, timeUnit }) } } className={ cssClass } >
			last { timeDuration } { timeUnit }
		</button>
	);
};

export default TimeFacet;