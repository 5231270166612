import {
	FETCH_DEVICES_SUCCESS,
	REALTIME_ROUTED_MESSAGE,
	FETCH_DEVICE_HISTORY_STARTED,
	FETCH_DEVICE_HISTORY_SUCCESS,
	FETCH_DEVICE_LAST_HISTORY_STARTED,
	FETCH_DEVICE_LAST_HISTORY_SUCCESS,
} from './../../actions/unitsActions'

import * as moment from 'moment'

import { getUniqueDeviceId, generateHistoryEntryKeyByRange, generateHistoryEntryKeyByLastCount } from './../../utils/devicesUtils.js';

function nextSnapshot ( id, oldSnapshot, messageProps, timeReceived, timeSet ) {
	let content;

	if ( oldSnapshot.content ) {
		content = Object.assign( {}, oldSnapshot.content, messageProps );
	}
	else {
		content = messageProps;
	}

	return {
		content,
		id,
		timeReceived,
		timeSet
	};
}

export function devicesReducer ( state = {
	isSyncing: false,
	error: null,
	devices: {},
}, action ) {
	switch ( action.type ) {
		case FETCH_DEVICES_SUCCESS: {
			let fetchedDevices = {};
			action.devices.forEach( ( device ) => {
				let {
					period,
					startTime,
					endTime
				} = device.query;

				// let messages = [];
				let snapshot = {};

				if ( device.lastData ) {
					snapshot = device.lastData
				}

				let messages = [ snapshot ];

				let deviceId = getUniqueDeviceId( action.unitId, device.query.id.value );

				fetchedDevices[ deviceId ] = {
					snapshot: snapshot,
					messages: messages,
					history: {},
					communicationInterval: {
						period,
						from: startTime,
						to: endTime
					},
					deviceId
				};
			} );

			return {
				...state,
				devices: {
					...state.devices,
					...fetchedDevices
				}
			};
		}

		case REALTIME_ROUTED_MESSAGE: {
			let { snapshotUnit, message } = action;

			let newDevices = {};

			message.forEach( deviceMessage => {
				let deviceId = getUniqueDeviceId( snapshotUnit, deviceMessage.id );

				let device = state.devices[ deviceId ]

				let messageContent = deviceMessage.content;

				// let { messageType, ...messageProps } = messageContent;
				let messageProps = messageContent;

				let communicationFrom;
				let communicationPeriod;
				let prettyPeriod;
				let communicationTo = deviceMessage.timeReceived;

				let oldSnapshot;
				let messages;

				if ( device ) {
					communicationFrom = device.communicationInterval.from;
					communicationPeriod =
						moment.duration( moment( communicationTo ).diff( communicationFrom ) );
					prettyPeriod =
						communicationPeriod.asHours() + ':'
						+ communicationPeriod.minutes() + ':'
						+ communicationPeriod.seconds();

					oldSnapshot = device.snapshot;

					messages = device.messages;
				}
				else {
					communicationFrom = communicationTo;
					prettyPeriod = '00:00:00';
					oldSnapshot = {};
					messages = [];
				}

				let snapshot = nextSnapshot( deviceMessage.id, oldSnapshot, messageProps, deviceMessage.timeReceived, deviceMessage.timeSet );

				newDevices[ deviceId ] = {
					snapshot,
					messages: [ deviceMessage, ...messages ],
					communicationInterval: {
						from: communicationFrom,
						to: communicationTo,
						period: prettyPeriod
					},
					deviceId
				};
			} );

			let devices;

			// console.log(  )

			if ( state.devices ) {
				devices = {
					...state.devices,
					...newDevices
				};
			}
			else {
				devices = {
					...newDevices
				}
			}

			return {
				...state,
				devices
			}


			// return Object.assign( {}, state, {
			// 	devices: Object.assign( {}, state.devices, {
			// 		[ deviceId ]: Object.assign( {}, device, {
			// 			snapshot,
			// 			messages: [ message, ...messages ],
			// 			communicationInterval: {
			// 				from: communicationFrom,
			// 				to: communicationTo,
			// 				period: prettyPeriod
			// 			},
			// 			deviceId
			// 		} )
			// 	} )
			// } )

		}

		case FETCH_DEVICE_HISTORY_STARTED: {
			let { internalDeviceId } = action;

			return {
				...state,
				devices: {
					...state.devices,
					[ internalDeviceId ]: {
						...state.devices[ internalDeviceId ],
						history: {
							...state.devices[ internalDeviceId ].history,
							isFetching: true
						}
					}
				}
			}


		}
		case FETCH_DEVICE_HISTORY_SUCCESS: {
			let {
				internalDeviceId,
				range,
				history
			} = action;

			let historyEntryKey = generateHistoryEntryKeyByRange( range );

			return Object.assign( {}, state, {
				devices: {
					...state.devices,
					[ internalDeviceId ]: {
						...state.devices[ internalDeviceId ],
						history: {
							...state.devices[ internalDeviceId ].history,
							[ historyEntryKey ]: history,
							isFetching: false
						}
					}
				}
			} )

		}
		case FETCH_DEVICE_LAST_HISTORY_STARTED: {
			let { internalDeviceId } = action;

			return {
				...state,
				devices: {
					...state.devices,
					[ internalDeviceId ]: {
						...state.devices[ internalDeviceId ],
						history: {
							...state.devices[ internalDeviceId ].history,
							isFetching: true
						}
					}
				}
			}


		}
		case FETCH_DEVICE_LAST_HISTORY_SUCCESS: {
			let {
				internalDeviceId,
				count,
				history
			} = action;

			let historyKey = generateHistoryEntryKeyByLastCount( { count } );

			return Object.assign( {}, state, {
				devices: {
					...state.devices,
					[ internalDeviceId ]: {
						...state.devices[ internalDeviceId ],
						history: {
							...state.devices[ internalDeviceId ].history,
							[ historyKey ]: history,
							isFetching: false
						}
					}
				}
			} )

		}
		default: {

			return state
		}
	}
}
