// THE WIDGET IS DEPRICATED
// Please DO NOT use it
// Replaced by StateMonitoringWidget and StateMonitoringWidgetWithValueRules

import React, { Component } from 'react';

import { getPropertyValueByPath, findDeviceExternalId, isNully } from './../../utils/devicesUtils.js';

import DevicesOverviewPopup from './../../popups/DevicesOverviewPopup/DevicesOverviewPopup.jsx';

import stylable from './../../decorators/stylable.jsx';

class TraceMonitoringWidget extends Component {
	constructor ( props ) {
		// Props are super!
		super( props );

		this.state = {
			activeFilters: {}
		};

		this.toggleCriticalFilter = this.toggleCriticalFilter.bind( this );
		this.toggleWarningFilter = this.toggleWarningFilter.bind( this );
		this.toggleOkayFilter = this.toggleOkayFilter.bind( this );

		this.onDeviceClick = this.onDeviceClick.bind( this );
	}

	componentWillMount () {
		this.prepareWidgetDevices( this.props, null );
	}

	componentWillReceiveProps ( nextProps ) {
		this.prepareWidgetDevices( nextProps, this.props );
	}

	render() {
		let {
			options,
		} = this.props;

		let monitoredDevicesRows = [];

		let activeFilters = this.state.activeFilters;
		let areFiltersEmpty = this._areFiltersEmpty( activeFilters );


		/* Coloring is a setting in the widget  */
		let colorCodes = {};
		if( options.colorCodes ) {
			colorCodes = { ...options.colorCodes };
		}

		if( !colorCodes.criticalColor ) {
			colorCodes.criticalColor = '#cb2026';
		}
		if( !colorCodes.warningColor ) {
			colorCodes.warningColor = '#fdba32';
		}
		if( !colorCodes.okayColor ) {
			colorCodes.okayColor = '#669900';
		}


		let isLoadingRelatedData = false;

		if( this.props.provided ) {
			isLoadingRelatedData = this.props.provided.isLoading;
		}

		let monitoringTableCssClass = 'monitoring-widget-table-container';

		if( isLoadingRelatedData ) {
			monitoringTableCssClass += ' loading';
		}

		if( this.widgetDevices ) {

			this.widgetDevices.forEach( ( singleWidgetDevice ) => {
				let { device, oldValueEntry, newValueEntry } = singleWidgetDevice;

				let oldValue = oldValueEntry.value;
				let newValue = newValueEntry.value;


				if( isNully( oldValue ) ) {
					oldValue = '-';
				}

				if( isNully( newValue ) ) {
					newValue = '-';
				}


				let oldValueStyle = {
					'background': colorCodes[ oldValueEntry.state + 'Color' ]
				}
				let newValueStyle = {
					'background': colorCodes[ newValueEntry.state + 'Color' ]
				}

				let hiddenClass = '';
				if( ! areFiltersEmpty && !activeFilters[ newValueEntry.state ] ) {
					hiddenClass = 'hidden';
				}

				let singleMonitoredDeviceRow = (
					<tr onClick={ () => { this.onDeviceClick( device ) } } className={`open-details-popup external-temp ${hiddenClass}`} key={ device.deviceId }>
						<td className="table-cell name-cell">{ findDeviceExternalId( device ) }</td>
						<td className="table-cell">
							<div className="value-in-square old" style={ oldValueStyle }>
								<div>
									{ oldValue }
								</div>
							</div>
						</td>
						<td className="table-cell ">
							<div className="value-in-square new" style={ newValueStyle }>
								<div>
									{ newValue }
								</div>
							</div>
						</td>
					</tr>
				);

				monitoredDevicesRows.push( singleMonitoredDeviceRow );
			});
		}


		let criticalClass = '';
		let warningClass = '';
		let okayClass = '';

		if( areFiltersEmpty ) {
			criticalClass = warningClass = okayClass = ' selected';
		}
		else {
			if( activeFilters.critical ) {
				criticalClass = ' selected with-checkmark';
			}

			if( activeFilters.warning ) {
				warningClass = ' selected with-checkmark';
			}

			if( activeFilters.okay ) {
				okayClass = ' selected with-checkmark';
			}
		}

		return (
			<div className="trace-monitoring-widget">
				<div className="monitoring-filters">
					<div className="monitoring-filters-title"> Filter </div>
					<ul className="monitoring-filters-list">
						<li onClick={ this.toggleCriticalFilter } className={ `monitoring-filter-item ${ criticalClass }` } style={{ 'background': colorCodes.criticalColor }}></li>
						<li onClick={ this.toggleWarningFilter } className={ `monitoring-filter-item ${ warningClass }` } style={{ 'background': colorCodes.warningColor }}></li>
						<li onClick={ this.toggleOkayFilter } className={ `monitoring-filter-item ${ okayClass }` } style={{ 'background': colorCodes.okayColor }}></li>
					</ul>
				</div>
				<div className={ monitoringTableCssClass }>
					<table className="monitoring-widget-table">
						<thead>
							<tr>
								<th className="header-cell name-cell">Track</th>
								<th className="header-cell">Temp old</th>
								<th className="header-cell">Temp new</th>
							</tr>
						</thead>
						<tbody>
							{ monitoredDevicesRows }
						</tbody>
					</table>
				</div>
			</div>
		);
	}

	prepareWidgetDevices ( newProps, oldProps ) {
		let monitoredDevices;

		if( newProps.provided ) {
			monitoredDevices = newProps.provided.devices;
		}

		let criticalRules = newProps.options.criticalRules;
		let warningRules = newProps.options.warningRules;

		let widgetDevices = [];
		let oldWidgetDevices = this.widgetDevices;

		monitoredDevices.forEach( ( device ) => {
			let oldDevice
			if( oldProps && oldProps.provided && oldProps.provided.devices ) {
				oldDevice = oldProps.provided.devices.find( ( oldDevice ) => {
					return ( oldDevice.deviceId === device.deviceId );
				});
			}

			/*
			 * The paths array determine the properties we are looking for
			 * We should select one property to visualize (based on the priority - their order in the Array)
			 *
			 * If we don't break out of the loop after the addition we will end up with
			 * more than 1 entry per device. (Which we currently don't want to)
			 *
			 */
			for( let propertyPath of newProps.options.propertyPaths ) {
				if( !oldDevice || ( oldDevice.snapshot.timeReceived !== device.snapshot.timeReceived ) ) {
					let newPropertyValue = getPropertyValueByPath( device, [ 'snapshot', ...propertyPath ] );

					let oldPropertyValue = getPropertyValueByPath( oldDevice, [ 'snapshot', ...propertyPath ] );

					if( !isNully( newPropertyValue ) ) {
						widgetDevices.push({
							device,
							oldValueEntry: {
								value: oldPropertyValue,
								state: this.getValueState( oldDevice, oldPropertyValue, warningRules, criticalRules )
							},
							newValueEntry: {
								value: newPropertyValue,
								state: this.getValueState( device, newPropertyValue, warningRules, criticalRules )
							}
						});

						break;
					}
				}
				else {
					if( !isNully( oldWidgetDevices ) ) {
						let oldWidgetDeviceItem = oldWidgetDevices.find( ( widgetDevice ) => {
							if( widgetDevice && widgetDevice.device && widgetDevice.device.deviceId ) {
								return widgetDevice.device.deviceId === device.deviceId;
							}

							return false;
						});

						if( oldWidgetDeviceItem ) {
							widgetDevices.push( oldWidgetDeviceItem );
							// We break since we've satisfied the first path
							break;
						}

					}
				}
			}

		});

		this.widgetDevices = widgetDevices;
	}

	_areFiltersEmpty ( activeFilters ) {
		if( !activeFilters.critical
			&& !activeFilters.warning
			&& !activeFilters.okay ) {

			return true;
		}

		return false;
	}

	_getSanitizeFilters ( activeFilters ) {
		if( activeFilters.critical
			&& activeFilters.warning
			&& activeFilters.okay ) {

			return {};
		}

		return activeFilters;
	}

	toggleCriticalFilter () {
		let activeFilters = this.state.activeFilters;
		activeFilters.critical = !activeFilters.critical;

		this.setState({
			activeFilters: this._getSanitizeFilters( activeFilters )
		});
	}

	toggleWarningFilter () {
		let activeFilters = this.state.activeFilters;
		activeFilters.warning = !activeFilters.warning;

		this.setState({
			activeFilters: this._getSanitizeFilters( activeFilters )
		});
	}

	toggleOkayFilter () {
		let activeFilters = this.state.activeFilters;
		activeFilters.okay = !activeFilters.okay;

		this.setState({
			activeFilters: this._getSanitizeFilters( activeFilters )
		});
	}


	checkDeviceRule( device, rules ) {
		if( rules ) {
			for( let singleRule of rules ) {
				let { propertyPaths, values } = singleRule;

				let pathIndex = 0;
				for( let propertyPath of propertyPaths ) {
					let propertyValue = getPropertyValueByPath( device, [ 'snapshot', ...propertyPath ] );

					if( propertyValue === values[ pathIndex ] )  {
						return true;
					}
				}
			}
		}

		return false;
	}

	getValueState( device, propertyValue, warningRules, criticalRules ) {
		if( !isNully( propertyValue ) ) {
			let isCritical = this.checkDeviceRule( device, criticalRules );

			if( isCritical ) {
				return 'critical';
			}
			else {
				let isWarning = this.checkDeviceRule( device, warningRules );

				if( isWarning )  {
					return 'warning';
				}
			}
		}
		else {
			return 'unknown';
		}

		return 'okay';
	}

	onDeviceClick ( device ) {
		this.props.api.openPopup( DevicesOverviewPopup, {
			api: this.props.api,
			deviceOptions: this.props.options.deviceOptions,
			propChartOptions: this.props.options.propChartOptions,
			latPath: this.props.options.latPath,
			lngPath: this.props.options.lngPath,
			devices: [ device ],
			historyUnitType: this.props.options.historyUnitType, // realtime or not
			propertyPaths: {
				displayName: [],
				location: [],
				communicationTime: []
			}
		});
	}
}

export default stylable( TraceMonitoringWidget );
