import api from './../middleware/api/index.js'

/* User login */
export const USER_LOGIN_IN_SUCCESS = 'USER_LOGIN_IN_SUCCESS'
export function userLogInSuccess ( user ) {
	return {
		type: USER_LOGIN_IN_SUCCESS,
		user
	}
}

export const USER_LOGIN_IN_FAILED = 'USER_LOGIN_IN_FAILED'
export function userLogInFail ( error ) {
	return {
		type: USER_LOGIN_IN_FAILED,
		error
	}
}

export const USER_LOGGED_OUT = 'USER_LOGGED_OUT'
export function userLoggedOut () {
	return {
		type: USER_LOGGED_OUT
	}
}

/* User Fetching */
export const FETCH_USER_META_STARTED = 'FETCH_USER_META_STARTED'
export function fetchUserMetaStarted () {
	return {
		type: FETCH_USER_META_STARTED
	}
}

export const FETCH_USER_META_SUCCESS = 'FETCH_USER_META_SUCCESS'
export function fetchUserMetaSuccess ( meta ) {
	return {
		type: FETCH_USER_META_SUCCESS,
		meta
	}
}

export const FETCH_USER_META_FAILED = 'FETCH_USER_META_FAILED'
export function fetchUserMetaFailed ( error ) {
	return {
		type: FETCH_USER_META_FAILED,
		error
	}
}

export function fetchUserMeta ( username, password ) {
	return function ( dispatch ) {
		dispatch( fetchUserMetaStarted() );

		api.setUser( username, password );
		api.cache.flushCache();

		return api.user().meta().get()
			.then( meta => {
				dispatch( fetchUserMetaSuccess( meta ) );

				if ( !meta.dashboards ) {
					dispatch( addUserMeta( 'dashboards', '{}' ) );
				}
			} )
			.catch( error => {
				dispatch( fetchUserMetaFailed( error ) );
			} );
	}
}


/* Update widgets */
export const UPDATE_USER_META_STARTED = 'UPDATE_USER_META_STARTED';
export function updateUserMetaStarted ( { key, value } ) {
	return {
		type: UPDATE_USER_META_STARTED,
		key,
		value
	};
}

export const UPDATE_USER_META_SUCCESS = 'UPDATE_USER_META_SUCCESS';
export function updateUserMetaSuccess ( { key, value } ) {
	return {
		type: UPDATE_USER_META_SUCCESS,
		key,
		value
	};
}

export const UPDATE_USER_META_FAILED = 'UPDATE_USER_META_FAILED';
export function updateUserMetaFailed ( { key, value, error } ) {
	return {
		type: UPDATE_USER_META_FAILED,
		key,
		value,
		error
	};
}

export function updateUserMeta ( key, value ) {
	return function ( dispatch ) {
		dispatch( updateUserMetaStarted( { key, value } ) );

		return api.user().meta().key( key ).update( value )
			.then( () => {
				dispatch( updateUserMetaSuccess( { key, value } ) );
			} )
			.catch( error => {
				dispatch( updateUserMetaFailed( { key, value, error } ) );
			} );
	};
}

/* Update widgets */
export const ADD_USER_META_STARTED = 'ADD_USER_META_STARTED';
export function addUserMetaStarted () {
	return {
		type: ADD_USER_META_STARTED
	};
}

export const ADD_USER_META_SUCCESS = 'ADD_USER_META_SUCCESS';
export function addUserMetaSuccess ( key, value ) {
	return {
		type: ADD_USER_META_SUCCESS,
		key,
		value
	};
}

export const ADD_USER_META_FAILED = 'ADD_USER_META_FAILED';
export function addUserMetaFailed ( key, value, error ) {
	return {
		type: ADD_USER_META_FAILED,
		key,
		value,
		error

	};
}

export function addUserMeta ( key, value ) {
	return function ( dispatch ) {
		dispatch( addUserMetaStarted() );

		return api.user().meta().add( key, value )
			.then( () => {
				dispatch( addUserMetaSuccess( key, value ) );
			} )
			.catch( error => {
				dispatch( addUserMetaFailed( key, value, error ) );
			} );
	};
}
