export function getDashboardStateFromUserState( userState ) {
	let { user, isSyncing, error } = userState;

	let dashboards = {};

	if( user && user.meta && user.meta.dashboards ) {
		dashboards = user.meta.dashboards;
	}

	return {
		dashboards,
		isSyncing,
		error
	}
};
