import React, { Component } from 'react';
import { connect } from 'react-redux';

import * as unitsActions from './../../../../actions/unitsActions.js';
import { findInSchema, isPrimitiveType } from './../../../../utils/jsonSchema.js';

import LevelSelector from './../../../../components/PathSelector/LevelSelector.jsx';

import './PropertyPathSelector.css';

const BASE_PROPERTY_PATH = [ null ]; // const BASE_PROPERTY_PATH = [ 'content', null ]; /* If you want to start from tge content property */

class PropertyPathSelector extends Component {
	constructor ( props ) {
		// Props are super
		super( props );

		this.onSelectedPropertyChange = this.onSelectedPropertyChange.bind( this );
		this.onSubPropertyButtonClick = this.onSubPropertyButtonClick.bind( this );
		this.onOuterPropertyButtonClick = this.onOuterPropertyButtonClick.bind( this );

	}

	getPropertyPath () {
		let { propertyPath } = this.props;

		// Default path for the UI is with selected content
		// (The Dropdown is listing the children of content in the json schema)
		if ( !propertyPath || propertyPath.length < BASE_PROPERTY_PATH.length ) {
			propertyPath = BASE_PROPERTY_PATH
		}

		return propertyPath
	}

	onSelectedPropertyChange ( propertyName ) {
		let propertyPath = this.getPropertyPath();
		let newPath = propertyPath.map( ( pathItem, index, array ) =>
			( index !== array.length - 1 ? pathItem : propertyName ) );

		this.props.onChange( newPath );
	}

	onSubPropertyButtonClick ( propertyName ) {
		let propertyPath = this.getPropertyPath();
		let newPath = [ ...propertyPath ];

		// The last item is set to be the traversed parent property
		newPath[ newPath.length - 1 ] = propertyName;

		// Add a new item (where the selection of the sub-property will be stored)
		newPath.push( null );

		this.props.onChange( newPath );
	}
	onOuterPropertyButtonClick () {
		let propertyPath = this.getPropertyPath();
		let newPath = propertyPath.filter( ( pathItem, index, array ) =>
			( index !== array.length - 1 ) );

		this.props.onChange( newPath );
	}

	render () {
		let { unitsSchemasStore, snapshotUnit } = this.props;
		let { unitsSchemas } = unitsSchemasStore;

		let propertyPath = this.getPropertyPath();
		let selectedProperty = propertyPath[ propertyPath.length - 1 ];

		if ( unitsSchemasStore.isFetching ) {
			return (
				<div className="loading">
					Fetching unit schema...
				</div>
			);
		}

		if ( unitsSchemas[ snapshotUnit ] ) {
			let unitSchema = unitsSchemas[ snapshotUnit ];
			let parentPropertyPath = propertyPath.filter(
				( _, index, array ) => ( index !== array.length - 1 )
			);
			let currentLevelProperties = findInSchema( unitSchema, parentPropertyPath );

			return (
				<LevelSelector
					checkIsItemPrimitive={ isPrimitiveType }
					basePropertyPath={ BASE_PROPERTY_PATH }
					selectedProperty={ selectedProperty }
					parentPropertyPath={ parentPropertyPath }
					currentLevelProperties={ currentLevelProperties }
					onSelectedPropertyChange={ this.onSelectedPropertyChange }
					onSubPropertyButtonClick={ this.onSubPropertyButtonClick }
					onOuterPropertyButtonClick={ this.onOuterPropertyButtonClick }
				/>
			)
		}

		return null;
	}

	componentDidMount () {
		this.prepareData();
	}
	componentDidUpdate () {
		this.prepareData();
	}

	prepareData () {
		let { unitsSchemasStore, flow, snapshotUnit, fetchUnitSchema } = this.props;

		// We send request only if we're not fetching any schema
		// and the one of the selected unit is not yet fetched
		if ( !unitsSchemasStore.isFetching ) {
			let { unitsSchemas } = unitsSchemasStore;

			if ( unitsSchemas ) {
				if ( snapshotUnit ) {
					if ( !unitsSchemas[ snapshotUnit ] ) {
						fetchUnitSchema( flow, snapshotUnit );
					}
				}
			}
		}
	}
}

function mapDispatchToProps ( dispatch ) {
	return {
		fetchUnitSchema: ( flowName, unitId ) => {
			dispatch( unitsActions.fetchUnitSchema( flowName, unitId ) );
		}
	};
}

function mapStateToProps ( state ) {
	return {
		unitsSchemasStore: state.entities.unitsSchemas
	};
}

export default connect( mapStateToProps, mapDispatchToProps )( PropertyPathSelector );
