import React from 'react';
import PropTypes from 'prop-types';

const PathIndicator = ( { currentTraversedPath } ) => {
	return (
		<div>
			{ currentTraversedPath.map( ( pathItem, index ) =>
				( <span className="current-path-item" key={ index }>{ pathItem }</span> ) ) }
		</div>
	)
};

PathIndicator.propTypes = {
	currentTraversedPath: PropTypes.array.isRequired
}

export default PathIndicator;
