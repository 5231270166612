import React from 'react';
import PropTypes from 'prop-types';

import './StepIndicator.css';

const StepIndicator = ( props ) => {
	let { fieldsets, currentStep, onStepChange } = props;

	let fieldsetsKeys = Object.keys( fieldsets );

	return (
		<div className="configuration-steps">
			{ fieldsetsKeys.map( ( fieldsetKey, index ) => {
				let stepTitle = fieldsets[ fieldsetKey ].name;

				if ( !stepTitle ) {
					stepTitle = fieldsetKey;
				}

				return (
					<div
						key={ fieldsetKey }
						className={ `configuration-step ${ fieldsetKey } ${ fieldsetKey === currentStep ? 'current' : '' }` }
						title={ stepTitle }
						onClick={ () => {
							onStepChange( index );
						} }>{ index + 1 }</div>
				);
			} ) }
		</div>
	)
};

StepIndicator.propTypes = {
	fieldsets: PropTypes.object.isRequired,
	currentStep: PropTypes.string.isRequired,
	onStepChange: PropTypes.func.isRequired
}

export default React.memo( StepIndicator );
