// @ts-nocheck
/* @flow */
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
// import App from './App';
// import registerServiceWorker from './registerServiceWorker';

import createHistory from 'history/createHashHistory'
import configureStore from './store/configureStore'

import Root from './containers/root/Root'

const history = createHistory();
const store = configureStore( history );

const rootDomElem = document.getElementById( 'root' );

if ( rootDomElem ) {
	ReactDOM.render(
		<Root store={ store } history={ history } />
		, rootDomElem
	);
}
else {
	throw new Error( 'Root is not present in the DOM' );
}

// registerServiceWorker();
