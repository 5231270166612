import iota from './../middleware/iota'
import { push } from 'connected-react-router'

/* flows Fetching */
export const FETCH_ALL_FLOWS_STARTED = 'FETCH_ALL_FLOWS_STARTED'
export function fetchAllFlowsStarted () {
	return {
		type: FETCH_ALL_FLOWS_STARTED
	}
}

export const FETCH_ALL_FLOWS_SUCCESS = 'FETCH_ALL_FLOWS_SUCCESS'
export function fetchAllFlowsSuccess ( allFlows ) {
	return {
		type: FETCH_ALL_FLOWS_SUCCESS,
		allFlows
	}
}

export const FETCH_ALL_FLOWS_FAIL = 'FETCH_ALL_FLOWS_FAIL'
export function fetchAllFlowsFailed ( error ) {
	return {
		type: FETCH_ALL_FLOWS_FAIL,
		error
	}
}


export function fetchAllFlows () {
	return function ( dispatch ) {
		dispatch( fetchAllFlowsStarted() )

		return iota.flows().get()
			.then( flows => {
				dispatch( fetchAllFlowsSuccess( flows ) )
			} )
			.catch( error => {
				dispatch( fetchAllFlowsFailed( error ) )
			} )
	}
}

/* flows Update */
export const UPDATE_FLOW_BY_NAME_STARTED = 'UPDATE_FLOW_BY_NAME_STARTED'
export function updateFlowByNameStarted () {
	return {
		type: UPDATE_FLOW_BY_NAME_STARTED
	}
}
export const UPDATE_FLOW_BY_NAME_SUCCESS = 'UPDATE_FLOW_BY_NAME_SUCCESS'
export function updateFlowByNameSuccess ( oldName, flow ) {
	return {
		type: UPDATE_FLOW_BY_NAME_SUCCESS,
		flow,
		oldName
	}
}
export const UPDATE_FLOW_BY_NAME_FAILED = 'UPDATE_FLOW_BY_NAME_FAILED'
export function updateFlowByNameFailed ( error ) {
	return {
		type: UPDATE_FLOW_BY_NAME_FAILED,
		error: error
	}
}

export function updateFlowByName ( name, flow ) {
	return ( dispatch ) => {
		dispatch( updateFlowByNameStarted() )

		return iota.flows().name( name ).update( flow )
			.then( flow => {
				dispatch( updateFlowByNameSuccess( name, flow ) )
			} )
			.catch( error => {
				dispatch( updateFlowByNameFailed( error ) )
			} )
	}
}


/* flows Creation */
export const CREATE_FLOW_STARTED = 'CREATE_FLOW_STARTED'
export function createFlowStarted () {
	return {
		type: CREATE_FLOW_STARTED
	}
}
export const CREATE_FLOW_SUCCESS = 'CREATE_FLOW_SUCCESS'
export function createFlowSuccess ( flow ) {
	return {
		type: CREATE_FLOW_SUCCESS,
		flow
	}
}
export const CREATE_FLOW_FAILED = 'CREATE_FLOW_FAILED'
export function createFlowFailed ( error ) {
	return {
		type: CREATE_FLOW_FAILED,
		error
	}
}

export function createFlow ( flow ) {
	return ( dispatch ) => {
		dispatch( createFlowStarted() )

		return iota.flows().add( flow )
			.then( flows => {
				dispatch( createFlowSuccess( flow ) )
				dispatch( push( '/' ) )
			} )
			.catch( error =>
				dispatch( createFlowFailed( error ) )
			)
	}
}

/* Flows Invocation */
export const INVOKE_FLOW_STARTED = 'INVOKE_FLOW_STARTED'
export function invokeFlowStarted () {
	return {
		type: INVOKE_FLOW_STARTED
	}
}
export const INVOKE_FLOW_SUCCESS = 'INVOKE_FLOW_SUCCESS'
export function invokeFlowSuccess ( invokationResult ) {
	return {
		type: INVOKE_FLOW_SUCCESS
	}
}
export const INVOKE_FLOW_FAILED = 'INVOKE_FLOW_FAILED'
export function invokeFlowFailed ( error ) {
	return {
		type: INVOKE_FLOW_FAILED
	}
}

export function invokeFlow ( flow, method, urlQuery, body ) {
	return ( dispatch ) => {
		dispatch( invokeFlowStarted() )

		return iota.flows().invoke( flow.key )[ method ]( { urlQuery, body } )
			.then( invokationResult =>
				dispatch( invokeFlowSuccess( invokationResult ) ) )
			.catch( error =>
				dispatch( invokeFlowFailed( error ) ) )
	}
}
