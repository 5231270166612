import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';

import deleteImage from './../img/delete-white.svg';

import abortImage from './../img/plus.svg';
import confirmImage from './../img/checked_white.svg';

import './DashboardListItem.css';

const ConfirmDashboardDelete = ( { name, onConfirm, onAbort } ) => (
	<div className="dashboard-delete-confirmation-outer ">
		<div className="dashboard-delete-confirmation">
			<span className="dashboard-delete-confirmation__text">Delete { name }?</span>
			<button className="dashboard-delete-confirmation__confirm-button" onClick={ onConfirm }>
				<img
					src={ confirmImage }
					alt="yes"
					title="Confirm delete" />
			</button>
			<button className="dashboard-delete-confirmation__abort-button" onClick={ onAbort }>
				<img
					src={ abortImage }
					alt="no"
					title="Abort delete" />
			</button>
		</div>
	</div>
)

const DashboardListItem = ( props ) => {
	const [ showConfirmDelete, setShowConfirmDelete ] = useState( false );

	return (
		<li style={ props.image ? { backgroundImage: 'url(' + props.image + ')' } : {} }>
			{ showConfirmDelete ? (
				<ConfirmDashboardDelete
					name={ props.name }
					onConfirm={ () => props.onDelete( props.name ) }
					onAbort={ () => setShowConfirmDelete( false ) }
				/>
			) : (
					<button className="delete-dashboard btn" onClick={ () => {
						setShowConfirmDelete( true );
					} } >
						<img src={ deleteImage } alt="Remove" title="Remove this dashboard" />
					</button>
				)
			}
			<Link className="dashboard-link" to={ `/dashboards/${ props.name }` } title={ props.name }>
				<div className="dashboard-link-title"> { props.name } </div>
				<div className="open-dashboard-icon"></div>
			</Link>
		</li>
	);
}

DashboardListItem.propTypes = {
	name: PropTypes.string.isRequired,
	image: PropTypes.string,
	onDelete: PropTypes.func.isRequired
};

export default DashboardListItem;
