import React from 'react';

export default ( props ) => {
	let selectAllCssClass = 'select-checkbox select-all-checkbox';
	let selectPageCssClass = 'select-checkbox select-page-checkbox';

	if( props.allSelected ) {
		selectAllCssClass += ' active selected';
	}

	if( props.pageSelected ) {
		selectPageCssClass += ' active selected';
	}

	return (
		<div className="notifications-list-actions">
			<span className={ selectAllCssClass } onClick={ props.onSelectAllCheckboxClick } >
				<span className="filter-checkbox"></span>
				<span className="select-all-checkbox-text">Select all</span>
			</span>
			<span className={ selectPageCssClass } onClick={ props.onSelectPageCheckboxClick } >
				<span className="filter-checkbox"></span>
				<span className="select-page-checkbox-text">Select page</span>
			</span>
			{ props.isSyncing ?
				<div className="notifications-loading">Loading...</div> : ''
			}
		</div>
	);
};
