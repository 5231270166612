import React, { Component } from 'react';
import request from 'superagent';
import moment from 'moment';

import DateTimeRangePicker from './../../components/DateTimePickers/DateTimeRangePicker.jsx';
import Multiselect from './../../components/ReactWidgets/Multiselect';

import stylable from './../../decorators/stylable.jsx';

import { getDeviceName, getPropertyValueByPath } from './../../utils/devicesUtils';

import './CsvExportWidget.css';

// {
//     "type": "CsvExportWidget",
//     "options": {
//         "widgetTitle": "CSV Export",
//         "widgetId": "csv-export",
//         "requestUrl": "http://lynx/ProRailBarriers/Dev/api/barriers/export?periodStartUtc={{startDateTime}}&periodEndUtc={{endDateTime}}",
//         "csvFileName": "Barriers_Export_"
//         "maxDatesInterval": {
//             "unit": "days",
//             "size": 31
//         },
//         "multiselect": {
//             deviceParam: [ "content", "deviceId"],
//             urlParam: "deviceIds",
//         },
//         "datasets": [
//             {
//                 "flowName": "prorail",
//                 "units": {
//                     "messages": "prorailSnapshot",
//                     "snapshot": "prorailSnapshotMemory"
//                 },
//                 "filters": [
//                     {
//                         "type": "has_snapshot_property",
//                         "options": {
//                            "propertyPath": [
//                                 "content",
//                                 "loLoTemp"
//                             ]
//                        }
//                     }
//                 ]
//             }
//         ]
//     },
//     "position": {
//         "x": 2,
//         "y": 4,
//         "h": 4,
//         "w": 2
//     }
// }

const defaultDropdownItem = { name: 'All' };
const defaultCsvFileName = 'CSV_Export_';
class CsvExportWidget extends Component {
	constructor ( props ) {
		// Props are super
		super( props );

		let { maxDatesInterval } = this.props.options;


		let toDate = moment().toDate();
		let fromDate;

		if ( maxDatesInterval ) {
			fromDate = moment().subtract( maxDatesInterval ).toDate();
		}
		else {
			fromDate = moment().subtract( 1, 'day' ).toDate();
		}

		this.state = {
			selectedDevices: [
				defaultDropdownItem
			],
			isLoadingExportData: false,
			exportMessage: '',
			fromDate,
			toDate
		}

		this.exportCsv = this.exportCsv.bind( this );
		this.fromDateChange = this.fromDateChange.bind( this );
		this.toDateChange = this.toDateChange.bind( this );
	}

	componentWillMount () {
		let { requestUrl } = this.props.options;

		if ( !requestUrl ) {
			this.setState( {
				exportMessage: 'Configuration problem!'
			} );
		}

		this.setupDefaults( this.props );
	}

	onDropdownChange = ( value ) => {
		let dropdownValue = value;

		if ( value.length === 0 ) {
			dropdownValue = [ defaultDropdownItem ];
		}
		else if ( value.length > 1 ) {
			let [ firstItem, ...restOfValue ] = value;

			if ( firstItem === defaultDropdownItem ) {
				dropdownValue = restOfValue;
			}
			else if ( restOfValue.indexOf( defaultDropdownItem ) !== -1 ) {
				dropdownValue = [ defaultDropdownItem ];
			}
		}

		this.setState( {
			selectedDevices: dropdownValue,
		} )
	}

	_dateConfigurationToMoment ( dateConfiguration, isToDate ) {
		let result;

		if ( dateConfiguration ) {
			let { type, offset, value } = dateConfiguration;

			if ( type === 'relative_to_now' ) {
				result = moment().subtract( offset.amount, offset.unit );
			}
			else {
				result = moment( value );
			}
		}
		else {
			if ( isToDate ) {
				result = moment();
			}
			else {
				result = moment().subtract( 3, 'hours' );
			}
		}

		// normalize date
		result.set( 'seconds', 0 );
		result.set( 'milliseconds', 0 );

		return result;
	}

	setupDefaults ( props ) {
		if ( !this.state.areDatesDirty ) {
			let { fromDate, toDate } = this.state;

			if ( !fromDate && !toDate ) {
				let { defaultFromDate, defaultToDate } = props.options

				let newFromDate = this._dateConfigurationToMoment( defaultFromDate );
				let newToDate = this._dateConfigurationToMoment( defaultToDate, true );

				this.setState( {
					fromDate: newFromDate,
					toDate: newToDate
				} );
			}
		}
	}

	fromDateChange ( fromDate ) {
		if ( fromDate && this.state.toDate ) {
			this.setState( {
				fromDate,
				exportMessage: ''
			} )
		}
		else {
			this.setState( { fromDate } )
		}
	}

	toDateChange ( toDate ) {
		if ( this.state.fromDate && toDate ) {
			this.setState( {
				toDate,
				exportMessage: ''
			} )
		}
		else {
			this.setState( { toDate } )
		}
	}

	downloadCsv ( data ) {
		let fileName = this.props.options.csvFileName,
			downloadedTime = moment().format( 'YYYYMMDDHHmmss' ),
			link = document.createElement( 'a' ),
			blob = new Blob( [ data ], { type: 'text/csv;charset=utf-8;' } ),
			url;

		fileName = fileName ? fileName : defaultCsvFileName;
		fileName += downloadedTime + '.csv';

		if ( navigator.msSaveBlob ) { // IE 10+
			navigator.msSaveBlob( blob, fileName );
		}
		else {
			if ( link.download !== undefined ) { // feature detection
				// Browsers that support HTML5 download attribute
				url = URL.createObjectURL( blob );
				link.setAttribute( 'href', url );
				link.setAttribute( 'download', fileName );
				link.style.visibility = 'hidden';
				document.body.appendChild( link );
				link.click();
				document.body.removeChild( link );
			}
		}
	}

	exportCsv () {
		let { requestUrl, multiselect } = this.props.options,
			{ fromDate, toDate } = this.state,
			downloadCsv = this.downloadCsv.bind( this );


		if ( fromDate && toDate ) {
			this.setState( {
				exportMessage: '',
				isLoadingExportData: true
			} );

			requestUrl = requestUrl
				.replace( '{{startDateTime}}', moment( fromDate ).utc().format() )
				.replace( '{{endDateTime}}', moment( toDate ).utc().format() );

			if ( multiselect ) {
				let multiselectUrlParams = '';

				if ( this.state.selectedDevices.length > 1
					|| this.state.selectedDevices[ 0 ] !== defaultDropdownItem ) {
					this.state.selectedDevices.forEach( selectedDevice => {
						multiselectUrlParams += `&${ multiselect.urlParam }=${ getPropertyValueByPath( selectedDevice.device, multiselect.deviceParam ) }`
					} );

					// If there are no get params, we shall add them
					if ( requestUrl.indexOf( '?' ) === -1 ) {
						multiselectUrlParams = multiselectUrlParams.replace( '&', '?' );
					}

					requestUrl += multiselectUrlParams;
				}
			}

			request.get( requestUrl )
				.then( ( data ) => {
					if ( data[ 'statusCode' ] === 204 ) {
						this.setState( {
							exportMessage: 'No data for the chosen period',
							isLoadingExportData: false
						} )
					}
					else if ( data[ 'statusCode' ] === 200 ) {
						downloadCsv( data.text );
						this.setState( {
							isLoadingExportData: false
						} );
					}
				} )
				.catch( ( errors ) => {
					console.error( 'errors', errors )
					this.setState( {
						exportMessage: 'Could not export to CSV',
						isLoadingExportData: false
					} );
				} );

		}
		else {
			this.setState( {
				exportMessage: 'Both dates are required!',
				isLoadingExportData: false
			} );
		}
	}

	render () {
		let {
			fromDate,
			toDate,
			exportMessage,
			selectedDevices
		} = this.state;

		let {
			widgetSubtitle,
			maxDatesInterval,
			multiselect
		} = this.props.options;

		let subtitleMarkup = null;

		let devices = [];
		if ( this.props.provided.devices ) {
			devices = this.props.provided.devices;
		}

		if ( widgetSubtitle ) {
			subtitleMarkup = <p className="widget-subtitle default">{ widgetSubtitle }</p>;
		}

		/*
		<Multiselect
							onChange={ this.onDropdownChange }
							data={ [ defaultDropdownItem, ...mapDevices ] }
							valueField="name"
							textField="name"
							value={ dropdownValue }
							caseSensitive={false}
							minLength={1}
							filter='contains'
							id="locator-sensor-selector" />
		*/

		return (
			<div className="csv-export-widget">
				{ subtitleMarkup }
				<div className="widget-content widget-content-padder">
					<div className="csv-export-widget-container">
						{ multiselect ? (
							<Multiselect
								onChange={ this.onDropdownChange }
								data={
									[
										defaultDropdownItem,
										...devices.map(
											device => ( {
												name: getDeviceName( device, this.props.options.deviceOptions ),
												device: device
											} ) ) ] }
								textField="name"
								valueField="name"
								placeholder="Select Devices to Export"
								value={ selectedDevices }
								caseSensitive={ false }
								minLength={ 1 }
								filter="contains" />
						) : null }
						<DateTimeRangePicker
							start={ fromDate }
							end={ toDate }

							dateFormat='HH:mm dd-MM-yyyy'

							// maxDateFromCalendar={ toDate ? toDate.clone().subtract( 1, 'minutes' ).format() : moment().subtract( 1, 'minutes' ).format() }
							maxDate={ moment().toDate() }

							maxInterval={ maxDatesInterval }

							onChange={ ( { start, end } ) => {
								this.fromDateChange( start );
								this.toDateChange( end );
								// TODO:
							} }
						// onStartDateChange={ ( fromDate ) => this.fromDateChange( fromDate ) }
						// onEndDateChange={ ( toDate ) => this.toDateChange( toDate ) }
						/>
						<div className="csv-export-message">{ exportMessage }</div>
						<button
							className={ `csv-export-btn btn btn-default ${ this.state.isLoadingExportData ? 'btn-loading disabled' : '' }` }
							onClick={ this.exportCsv }>
							{ this.state.isLoadingExportData ? 'Exporting...' : 'Export to CSV' }
						</button>
					</div>
				</div>
			</div>
		);
	}

}

export default stylable( CsvExportWidget );
