export const OPEN_POPUP = 'OPEN_POPUP'
export function openPopup( popupClass, popupProps ) {
	return {
		type: OPEN_POPUP,
		popupClass,
		popupProps
	}
}

export const UPDATE_POPUP = 'UPDATE_POPUP'
export function updatePopup( popupClass, popupProps ) {
	return {
		type: UPDATE_POPUP,
		popupClass,
		popupProps
	}
}

export const CLOSE_POPUP = 'CLOSE_POPUP'
export function closePopup( popupClass ) {
	return {
		type: CLOSE_POPUP,
		popupClass
	}
}
