import React, { Component } from 'react';
import moment from 'moment';

import SingleDateTimePicker from '../../components/DateTimePickers/SingleDateTimePicker';

export default class ActionField extends Component {
	getFieldMarkup () {
		let {
			type,
			initialValue,
			value,
			id,
			onChange
		} = this.props;

		return <input
				type={ type }
				value={ value ? value : ''  }
				onBlur={ ( event ) => {
					if ( event.target.value === '' ) {
						onChange({
							id: id,
							value: initialValue
						});
					}
				} }
				onChange={ ( event ) => { onChange({
					id: id,
					value: event.target.value
				}) } } />;
	}

	getCalendarFieldMarkup () {
		let { onChange, id, value } = this.props;
		return <SingleDateTimePicker 
				date={ value ? moment( value ) : null } 
				onChange={ ( date ) => {
					onChange({
						id: id,
						value: date ? date.format() : ''
					});
					} 
				}
				enableTime={ true }
			/>
	}

	render () {
		let {
			type,
			label,
			validations,
			requiredError,
			numberError
		} = this.props,
			fieldCssClass = 'management-action-field',
			fieldMarkup;

		validations = validations || {};

		if ( type === 'hidden' ) {
			fieldCssClass += ' hidden';
		}

		if ( type === 'calendar' ) {
			fieldMarkup = this.getCalendarFieldMarkup()
		}
		else {
			fieldMarkup = this.getFieldMarkup();
		}


		return (
			<div className={ fieldCssClass }>
				<label>
					{ label } { validations.required ? '*' : '' }
					{ fieldMarkup }
					{ requiredError ? <span className="field-error">Field is required</span> : null }
					{ numberError ? <span className="field-error">Field should be a number</span> : null }
				</label>
			</div>
		);
	}
}