export const ENABLE_DASHBOARD_DND = 'ENABLE_DASHBOARD_DND'
export function enableDashboardDragAndDrop () {
	return {
		type: ENABLE_DASHBOARD_DND
	};
}

export const DISABLE_DASHBOARD_DND = 'DISABLE_DASHBOARD_DND'
export function disableDashboardDragAndDrop () {
	return {
		type: DISABLE_DASHBOARD_DND
	};
}

export const TOGGLE_DASHBOARD_DND = 'TOGGLE_DASHBOARD_DND'
export function toggleDashboardDragAndDrop () {
	return {
		type: TOGGLE_DASHBOARD_DND,
	};
}

export const SHOW_WIDGETS_CONFIGURATOR = 'SHOW_WIDGETS_CONFIGURATOR';
export function showWidgetsConfigurator () {
	return {
		type: SHOW_WIDGETS_CONFIGURATOR
	};
}

export const HIDE_WIDGETS_CONFIGURATOR = 'HIDE_WIDGETS_CONFIGURATOR';
export function hideWidgetsConfigurator () {
	return {
		type: HIDE_WIDGETS_CONFIGURATOR
	};
}
