import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import HeaderBranding from './HeaderBranding.jsx';

import DashboardsConfigurationForm from './../../containers/DashboardsConfigurationForm.jsx';
import HeaderDashboardsMenu from './../../containers/HeaderDashboardsMenu.jsx';
import HeaderNotificationsList from './../../containers/HeaderNotificationsList.jsx';
import LogoutButton from './../../containers/LogoutButton.jsx';

class Header extends PureComponent {
	render () {
		let {
			activeMenu,
			openMenu,
			closeAllMenus,

			headerBackgroundColor,
			onWidgetConfigurationChange,
			headerLogo,
			logOut
		} = this.props;

		return (
			<HeaderBranding
				backgroundColor={ headerBackgroundColor }
				logo={ headerLogo }>
				<HeaderDashboardsMenu
					isOpen={ activeMenu === 'dashboardsMenu' }
					open={ () => openMenu( 'dashboardsMenu' ) }
					close={ closeAllMenus }
					headerBackgroundColor={ headerBackgroundColor } />
				<div className="header-right-menu">
					<DashboardsConfigurationForm
						isOpen={ activeMenu === 'configurationForm' }
						open={ () => openMenu( 'configurationForm' ) }
						close={ closeAllMenus }
						onChange={ onWidgetConfigurationChange } />
					<HeaderNotificationsList
						isOpen={ activeMenu === 'notificationsList' }
						open={ () => openMenu( 'notificationsList' ) }
						close={ closeAllMenus }
					/>
					<LogoutButton logOut={ logOut } />
				</div>
			</HeaderBranding>
		);
	}
}

Header.propTypes = {
	activeMenu: PropTypes.string,

	openMenu: PropTypes.func,
	closeAllMenus: PropTypes.func,

	headerBackgroundColor: PropTypes.string,
	onWidgetConfigurationChange: PropTypes.func,
	headerLogo: PropTypes.string,
	logOut: PropTypes.func
};

export default Header;
