import React, { Component } from 'react';
import { connect } from 'react-redux'

import PopupCanvas from './../components/PopupCanvas.jsx';
import SiteLoading from './../components/SiteLoading.jsx';
import DashboardNotFound from './../components/DashboardNotFound.jsx';

import { getDashboardStateFromUserState } from './../selectors/dashboards.js';

import DashGrid from './DashGrid/DashGrid.jsx';

// UI for widgets configuration
import WidgetsConfigurator from './WidgetsConfigurator/WidgetsConfigurator.jsx';

import WidgetsConfigurationManager from './../utils/WidgetsConfigurationManager.js';

import * as userActions from './../actions/userActions.js';
import * as activeDashboardActions from './../actions/activeDashboardActions.js';

class Dashboard extends Component {
	constructor ( props ) {
		// Props are super
		super( props );

		this.onDashboardLayoutUpdate = this.onDashboardLayoutUpdate.bind( this );
		this.onWidgetEditSave = this.onWidgetEditSave.bind( this );
		this.onCloseWidgetsConfigurator = this.onCloseWidgetsConfigurator.bind( this );

		this.state = {
			dashboardId: null,
			currentDashboard: null
		};
	}

	static getDerivedStateFromProps ( props ) {
		let dashboardId = props.match.params.dashboardId;

		let { isSyncing, dashboards } = getDashboardStateFromUserState( props.userState );

		let currentDashboard = null;

		if ( !dashboardId ) {
			dashboardId = dashboards.order[ 0 ];
		}

		if ( !isSyncing && dashboards ) {
			currentDashboard = dashboards.dashboards[ dashboardId ];
		}

		return {
			dashboardId,
			currentDashboard
		};
	}

	updateDashboardWidgets ( dashboardId, widgets ) {
		let { dashboards } = getDashboardStateFromUserState( this.props.userState );

		let newDashboard = {
			name: dashboardId,
			widgets
		};

		let newDashboards = {
			...dashboards.dashboards,
			[ dashboardId ]: newDashboard
		};

		let newDashboardsMeta = {
			order: dashboards.order,
			mainStyles: dashboards.mainStyles,
			dashboards: newDashboards
		}

		this.props.updateUserMeta( 'dashboards', newDashboardsMeta );
		this.props.closeWidgetForm();
	}

	onDashboardLayoutUpdate ( widgets ) {
		this.updateDashboardWidgets( this.state.dashboardId, widgets );
	}

	render () {
		let { isWidgetsConfiguratorVisible, userState } = this.props;

		let { isSyncing, dashboards } = getDashboardStateFromUserState( userState );

		let dashboardId = this.state.dashboardId;

		if ( isSyncing || !dashboards ) {
			return ( <SiteLoading /> );
		}
		else {
			// if there is only one dashboard, we want to display it directly
			// instead of displaying the dashboards menu page
			// dashboardId = dashboardId ? dashboardId : this.props.dashboards.order[ 0 ];

			let currentDashboard = this.state.currentDashboard;

			if ( currentDashboard ) {
				let widgets = currentDashboard.widgets;

				return (
					<section className="site-container">
						{ isWidgetsConfiguratorVisible ?
							<WidgetsConfigurator
								onClose={ this.onCloseWidgetsConfigurator }
								onSave={ this.onWidgetEditSave } /> : null
						}
						<PopupCanvas />
						<DashGrid
							onDashboardLayoutUpdate={ this.onDashboardLayoutUpdate }
							dashboardId={ dashboardId }
							widgets={ widgets } />
					</section>
				);
			}
			else {
				return ( <DashboardNotFound dashboardId={ dashboardId } /> );
			}
		}
	}
	onCloseWidgetsConfigurator () {
		this.props.closeWidgetForm();
	}

	static findWidgetById ( widgets, widgetId ) {
		return widgets.find( item => {
			if ( item.options.widgetId === widgetId ) {
				return true;
			}

			return false;
		} );
	}

	onWidgetEditSave ( widgetId, widgetTypeName, fieldsValues ) {
		let { dashboardId, currentDashboard } = this.state;
		let widgets = currentDashboard.widgets;
		let previousWidgetVersion;

		if ( widgetId ) {
			previousWidgetVersion = Dashboard.findWidgetById( widgets, widgetId );
		}

		let widgetInstanceConfiguration = WidgetsConfigurationManager.generateWidgetConfig( {
			widgetId,
			widgetTypeName,
			fieldsValues,
			widgetBase: previousWidgetVersion
		} );

		if ( !widgetInstanceConfiguration._errors || !widgetInstanceConfiguration._errors.length ) {
			let updatedWidgets;

			if ( !previousWidgetVersion ) {
				updatedWidgets = [ widgetInstanceConfiguration, ...widgets ];
			}
			else {
				updatedWidgets = widgets.map( widget => {
					if ( widget.options.widgetId === widgetId ) {
						return widgetInstanceConfiguration;
					}

					return widget;
				} );

			}

			this.updateDashboardWidgets( dashboardId, updatedWidgets );
		}
	}
}

function mapStateToProps ( state ) {
	return {
		userState: state.userState,
		isWidgetsConfiguratorVisible: state.activeDashboard.isWidgetsConfiguratorVisible,
	};
}

function mapDispatchToProps ( dispatch ) {
	return {
		updateUserMeta: ( key, value ) => {
			dispatch( userActions.updateUserMeta( key, value ) );
		},
		closeWidgetForm: () => {
			dispatch( activeDashboardActions.hideWidgetsConfigurator() );
		}
	};
}

export default connect( mapStateToProps, mapDispatchToProps )( Dashboard );
