import React from 'react';

import PropTypes from 'prop-types';

import './ConfigurationFieldSelectorList.css';

const ConfigurationFieldSelectorList = ( props ) => (
	<div className={ `configuration-field-selector-list ${ !props.enableEditing ? 'edit-disabled' : '' }` } >
		{ props.items.map( item => (
			<div
				key={ item }
				title={ item }
				className={ `configuration-field-selector-list-item ${ props.value === item ? 'selected' : '' }` }
				onClick={ () => props.onSelect( item ) } >
				{ item }
			</div>
		) ) }
	</div>
);

ConfigurationFieldSelectorList.propTypes = {
	items: PropTypes.array.isRequired,
	value: PropTypes.string,
	onSelect: PropTypes.func.isRequired,
	enableEditing: PropTypes.bool.isRequired
}

export default React.memo( ConfigurationFieldSelectorList );
