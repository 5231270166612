import React, { Component } from 'react';

import Multiselect from '../../components/ReactWidgets/Multiselect';
import DropdownList from '../../components/ReactWidgets/DropdownList';

import RangeDateTimePickers from '../../components/DateTimePickers/RangeDateTimePickers';

import MultipleTimeseriesChart from './../../components/Charts/MultipleTimeseriesChart.jsx';

import * as moment from 'moment';

import stylable from './../../decorators/stylable.jsx';

import {
	findDeviceExternalId,
	getPropertyValueByPath
} from './../../utils/devicesUtils.js';

class ComparisonWidget extends Component {
	constructor ( props ) {
		// props are super!
		super( props );

		this.state = {
			selectedDevices: [],
			devicesHistory: {},
			isHistoryLoading: false,
			selectedProperty: null,
			isPropertyDirty: false,
			areDatesDirty: false,
			layoutRefresh: 0
		}

	}

	componentWillMount () {
		this.prepareWidgetDevices( this.props );

		this.fetchWidgetDataIfNeeded( this.props, this.state, null );

		this.setupDefaults( this.props );

		this.props.api.subscribeForLayoutChange( () => {
			this.setState( { layoutRefresh: this.state.layoutRefresh + 1 } );
		} );
	}

	shouldComponentUpdate ( newProps, newState ) {
		if ( newState.selectedDevices.length !== this.state.selectedDevices.length ||
			newState.fromDate !== this.state.fromDate ||
			newState.toDate !== this.state.toDate ||
			newState.selectedProperty !== this.state.selectedProperty ||
			newProps.provided.devices.length !== this.props.provided.devices.length ||
			newState.devicesHistory !== this.state.devicesHistory ) {

			return true;
		}

		return false;
	}

	componentWillUpdate ( newProps, newState ) {
		this.fetchWidgetDataIfNeeded( newProps, newState, this.state );
	}

	componentWillReceiveProps ( nextProps ) {
		this.prepareWidgetDevices( nextProps );

		this.setupDefaults( nextProps );
	}

	componentWillUnmount () {
		this.props.api.unsubscribeForLayoutChange();
	}

	render () {
		let { options } = this.props;
		let widgetDevices = [];
		let loadingCssClass = '';
		let devicesSelectPlaceholder = 'Select Devices to Compare';

		if ( this.props.provided.isLoading ) {
			loadingCssClass = 'loading';
			devicesSelectPlaceholder = 'Loading Widget Devices...';
		}

		if ( this.monitoredDevices ) {
			this.monitoredDevices.forEach( ( device ) => {
				widgetDevices.push( {
					name: device.snapshot.id,
					fullDevice: device
				} );
			} );
		}

		let {
			selectedDevices,
			selectedProperty,
			toDate
		} = this.state;

		let widgetProperties = [];
		let widgetPropertiesSet;

		if ( selectedDevices.length ) {
			let [ firstDevice, ...restDevices ] = selectedDevices;

			widgetPropertiesSet = new Set( Object.keys( firstDevice.fullDevice.snapshot.content ) );

			restDevices.forEach( ( device ) => {
				let sharedWidgetProperties = new Set();

				for ( let property in device.fullDevice.snapshot.content ) {
					if ( widgetPropertiesSet.has( property ) ) {
						sharedWidgetProperties.add( property );
					}
				}

				widgetPropertiesSet = sharedWidgetProperties;
			} );

			widgetProperties = [ ...widgetPropertiesSet ];
		}


		let valuesColors = options.valuesColors ? options.valuesColors : [];

		let xAxisBindings = {};
		let valuesArray = [];


		let isFetching = false;


		if ( !this.props.provided.isLoading ) {
			for ( let deviceId in this.state.devicesHistory ) {
				let historicalDevice =
					this.props.provided.devices.find( ( device ) => {
						return ( device.deviceId === deviceId )
					} );

				let externalDeviceId = findDeviceExternalId( historicalDevice );

				let singleDeviceHistory = this.state.devicesHistory[ deviceId ];

				if ( singleDeviceHistory.isFetching ) {
					isFetching = true;
				}
				else if ( singleDeviceHistory.history ) {
					let xAxis = [ 'x_' + externalDeviceId ];
					let values = [ externalDeviceId ];

					singleDeviceHistory.history.forEach( ( historyEntry ) => {
						const propertyValue = getPropertyValueByPath( historyEntry, [ 'content', selectedProperty ] );

						if ( propertyValue !== undefined && propertyValue !== null ) {
							xAxis.push( moment( getPropertyValueByPath( historyEntry, [ 'timeSet' ] ) ).format( 'YYYY-MM-DD HH:mm:ss' ) );
							values.push( propertyValue );
						}
					} );

					xAxisBindings[ externalDeviceId ] = 'x_' + externalDeviceId;
					valuesArray.push( xAxis );
					valuesArray.push( values );
				}

			}
		}

		let textColor = options.stylable.textColor;

		let multiChart;
		if ( this.monitoredDevices
			&& selectedDevices.length
			&& selectedProperty
			&& this.state.fromDate
			&& this.state.toDate ) {

			if ( this.state.isHistoryLoading ) {
				multiChart = (
					<div className="no-data-selected">Loading chart data...</div>
				)
			}
			else {
				multiChart = (
					<MultipleTimeseriesChart
						xAxisBindings={ xAxisBindings }
						valuesArray={ valuesArray }
						isFetching={ isFetching }
						valuesColors={ valuesColors }
						forceRefreshCounter={ this.state.layoutRefresh }

						showXAxis={ true }
						showYAxis={ true }
						showXGridLines={ false }
						showYGridLines={ true }
					/>
				);
			}
		}
		else {
			multiChart = (
				<div className="no-data-selected">Select devices, properties and dates to see the chart.</div>
			)
		}

		let multiselect;
		if ( widgetDevices.length !== 1 ) {
			multiselect = (
				<Multiselect
					onChange={ ( selectedDevices ) => {
						this.setState( { selectedDevices, devicesHistory: {}, isHistoryLoading: true } );

						this._saveWidgetState( {
							selectedProperty: this.state.selectedProperty,
							selectedDevices
						} );
					} }
					data={ widgetDevices }
					textField="name"
					valueField="name"
					placeholder={ devicesSelectPlaceholder }
					value={ selectedDevices }
					caseSensitive={ false }
					minLength={ 1 }
					textColor={ textColor }
					filter="contains" />
			);
		}


		return (
			<div className="comparison-widget" >
				<div className={ `widget-settings-selection ${ loadingCssClass }` }>
					{ multiselect }
					<DropdownList
						onChange={ ( selectedProperty ) => {
							this.setState( { selectedProperty, devicesHistory: {}, isPropertyDirty: true, isHistoryLoading: true } )

							this._saveWidgetState( {
								selectedDevices: this.state.selectedDevices,
								selectedProperty
							} );
						} }
						data={ widgetProperties }
						// textField="name"
						placeholder="Select Property"
						value={ selectedProperty }
						caseSensitive={ false }
						minLength={ 1 }
						filter="contains" />

					<div className="calendars">
						<RangeDateTimePickers
							dateFormat='H:i d/m/Y'


							maxDateFromCalendar={ toDate ? toDate.clone().subtract( 1, 'minutes' ).format() : moment().subtract( 1, 'minutes' ).format() }
							maxDateToCalendar={ moment().format() }

							startDate={ this.state.fromDate }
							endDate={ this.state.toDate }

							onStartDateChange={ ( fromDate ) => this.setState( { fromDate, devicesHistory: {}, areDatesDirty: true, isHistoryLoading: true } ) }
							onEndDateChange={ ( toDate ) => this.setState( { toDate, devicesHistory: {}, areDatesDirty: true, isHistoryLoading: true } ) } />
					</div>
				</div>
				<div className="chart-container">
					{ multiChart }
				</div>
			</div>
		);
	}

	_saveWidgetState ( { selectedDevices, selectedProperty } ) {
		let selectedDevicesNames = selectedDevices.map( device => device.name );

		this.props.api.saveWidgetState( {
			selectedDevicesNames,
			selectedProperty
		} );
	}

	_dateConfigurationToMoment ( dateConfiguration, isToDate ) {
		let result;

		if ( dateConfiguration ) {
			let { type, offset, value } = dateConfiguration;

			if ( type === 'relative_to_now' ) {
				result = moment().subtract( offset.amount, offset.unit );
			}
			else {
				result = moment( value );
			}
		}
		else {
			if ( isToDate ) {
				result = moment();
			}
			else {
				result = moment().subtract( 3, 'hours' );
			}
		}

		return result;
	}

	setupDefaults ( props ) {
		let selectedDevice,
			savedWidgetState = props.api.getWidgetState(),
			selectedDevicesNames = savedWidgetState ? savedWidgetState.selectedDevicesNames : null,
			selectedProperty = savedWidgetState ? savedWidgetState.selectedProperty : null,
			selectedDevices = [],
			widgetDevices = this.monitoredDevices;

		if ( selectedDevicesNames && widgetDevices ) {
			for ( let i in selectedDevicesNames ) {
				for ( let j in widgetDevices ) {
					if ( widgetDevices[ j ] ) {
						if ( widgetDevices[ j ].snapshot.id === selectedDevicesNames[ i ] ) {
							selectedDevices.push( {
								name: selectedDevicesNames[ i ],
								fullDevice: widgetDevices[ j ]
							} );

							break;
						}
					}
				}
			}

			this.setState( {
				selectedDevices,
				selectedProperty
			} );
		}

		if ( widgetDevices.length === 1 ) {
			let firstDevice = widgetDevices[ 0 ];
			selectedDevice = {
				name: firstDevice.snapshot.id,
				fullDevice: firstDevice
			};


			this.setState( {
				selectedDevices: [ selectedDevice ]
			} );
		}

		if ( !this.state.areDatesDirty ) {
			let { fromDate, toDate } = this.state;

			if ( !fromDate && !toDate ) {
				let { defaultFromDate, defaultToDate } = props.options

				let newFromDate = this._dateConfigurationToMoment( defaultFromDate );

				if ( newFromDate ) {
					this.setState( {
						fromDate: newFromDate
					} );
				}

				let newToDate = this._dateConfigurationToMoment( defaultToDate, true );

				if ( newToDate ) {
					this.setState( {
						toDate: newToDate
					} );
				}
			}
		}
	}

	prepareWidgetDevices ( newProps ) {
		let monitoredDevices;

		if ( newProps.provided.devices ) {
			monitoredDevices = newProps.provided.devices;
		}

		this.monitoredDevices = monitoredDevices;
	}

	areDatasetsLoaded ( newProps ) {
		if ( newProps.provided.devices && newProps.provided.devices.length ) {
			return true;
		}

		return false;
	}

	fetchWidgetDataIfNeeded ( newProps, newState, oldState ) {
		let { selectedDevices, selectedProperty, fromDate, toDate, devicesHistory } = newState;



		if ( this.areDatasetsLoaded( newProps ) ) {
			if ( selectedProperty && selectedDevices.length && fromDate && toDate ) {

				if ( oldState ) {
					if ( selectedProperty === oldState.selectedProperty
						&& selectedDevices === oldState.selectedDevices
						&& fromDate === oldState.fromDate
						&& toDate === oldState.toDate ) {

						return;
					}
				}

				const fromDateFormatted = fromDate.format();
				const toDateFormatted = toDate.format();

				let fullSelectedDevices = selectedDevices.map( device => device.fullDevice );

				let historyPromise = newProps.api.fetchDevicesHistory( fullSelectedDevices, fromDateFormatted, toDateFormatted );

				if ( historyPromise ) {
					historyPromise.then( ( history ) => {
						devicesHistory = {};

						fullSelectedDevices.forEach( ( device, index ) => {
							devicesHistory[ device.deviceId ] = {
								history: history[ index ]
							}
						} );

						this.setState( {
							devicesHistory,
							isHistoryLoading: false
						} );
					} );
				}
			}
		}
	}
}

export default stylable( ComparisonWidget );
