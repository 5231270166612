import React from 'react';
import MultiselectWidget from 'react-widgets/lib/Multiselect';
import 'react-widgets/dist/css/react-widgets.css';
import './styles.css';

const Multiselect = ( props ) => {
	return <MultiselectWidget { ...props } />;
}

Multiselect.propTypes = MultiselectWidget.propTypes;

export default Multiselect;