import React from 'react';
import PropTypes from 'prop-types';

import PathIndicator from './PathIndicator.jsx';

import './LevelSelector.css';


const LevelSelector = ( { basePropertyPath, checkIsItemPrimitive, selectedProperty, parentPropertyPath, currentLevelProperties, onSelectedPropertyChange, onSubPropertyButtonClick, onOuterPropertyButtonClick } ) => {
	let currentTraversedPath = parentPropertyPath;

	return (
		<label>
			Filter by:
			<PathIndicator currentTraversedPath={ currentTraversedPath } />
			<div className="property-path-level-selector">
				{
					( parentPropertyPath.length >= basePropertyPath.length ) ?
						<div onClick={ onOuterPropertyButtonClick }>{ `< Select Outer Level` }</div>
						: ''
				}
				{ Object.keys( currentLevelProperties ).map( propertyName => (
					<div
						key={ propertyName }
						className={ `property-path-item-selector ${ selectedProperty === propertyName ? 'active' : '' }` } >
						<div onClick={ () => { onSelectedPropertyChange( propertyName ) } }>
							{ propertyName }
						</div>
						{
							// If the item is not primitive, we show the icon to traverse to the inner level (and select a sub-property)
							!checkIsItemPrimitive( currentLevelProperties, propertyName ) ?
								<div onClick={ ( e ) => {
									onSubPropertyButtonClick( propertyName )
								} }>{ `>` }</div>
								: ''
						}
					</div>
				) ) }
			</div>
		</label>
	);
};

LevelSelector.propTypes = {
	selectedProperty: PropTypes.string,
	basePropertyPath: PropTypes.array.isRequired,
	checkIsItemPrimitive: PropTypes.func.isRequired,
	parentPropertyPath: PropTypes.array,
	currentLevelProperties: PropTypes.object,
	onSelectedPropertyChange: PropTypes.func.isRequired,
	onSubPropertyButtonClick: PropTypes.func.isRequired,
	onOuterPropertyButtonClick: PropTypes.func.isRequired
};

export default LevelSelector;
