import React, { useState } from 'react';

import './DatasetConfigurationHelp.css';

const DatasetConfigurationHelp = () => {
	const [ isInfoPopupOpen, setIsInfoPopupOpen ] = useState( false );
	return (
		<div className="dataset-configuration-info">
			<span className="icon" onClick={ () => setIsInfoPopupOpen( !isInfoPopupOpen ) }>i</span>
			{
				isInfoPopupOpen ?
					(
						<div className="popup">
							Define what data to use and how to filter it. <br /><a href={ `https://up2team.visualstudio.com/Iota/_wiki/wikis/Iota.wiki?wikiVersion=GBwikiMaster&pagePath=%2Fiota.visualizer&pageId=56&anchor=iota-datasets-configuration-notation` }>See in wiki</a>
						</div>
					)
					: null
			}
		</div>
	)
}

export default DatasetConfigurationHelp;
