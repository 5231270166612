import React, { Component } from 'react';
import request from 'superagent';

import stylable from './../../decorators/stylable.jsx';

import { getPropertyValueByPath } from './../../utils/devicesUtils.js';


const weatherApiUrl = 'https://api.weatherbit.io/v2.0/current';

class CurrentWeather extends Component {
	constructor( props ) {
		super( props );

		this.state = {
			isLoading: false,
			weatherData: null,
			errors: null
		}
	}

	componentWillReceiveProps ( nextProps ) {
		this.prepareWeatherData( nextProps );
	}

	// https://api.weatherbit.io/v2.0/current?key=0ab1c187600040f690647355f9f1ac11&city=Sofia&country=BG
	prepareWeatherData( newProps ) {
		if( newProps.options ) {
			let {
				city,
				country,
				apiKey
			} = newProps.options;

			if( this.city !== city
				|| this.country !== country
				|| this.apiKey !== apiKey ) {

				this.fetchWeatherData( city, country, apiKey );

				this.city = city;
				this.country = country;
				this.apiKey = apiKey;
			}
		}

	}

	fetchWeatherData( city, country, apiKey ) {
		request.get( `${ weatherApiUrl }?key=${ apiKey }&city=${ city }&country=${ country }` )
			.then( ( response ) => {
				let weatherData = response.body;
				this.setState({
					isLoading: false,
					weatherData
				})
			})
			.catch( ( errors ) => {
				this.setState({
					isLoading: false,
					errors
				})
			} );

		this.setState({
			isLoading: true,
			weatherData: null,
			errors: null
		});
	}

	render() {
		const { options } = this.props;

		let { widgetSubtitle } = options;

		let loadingClass = ( this.state.isLoading? 'loading' : '' );

		let blanketColor = options.stylable.blanketColor;

		let weatherData = this.state.weatherData;
		let currentTemperature;

		if( !this.state.isLoading ) {
			currentTemperature = getPropertyValueByPath( weatherData, [ 'data', 0, 'temp' ] );
		}

		let subtitleMarkup = '';
		if( widgetSubtitle ) {
			subtitleMarkup = (
				<p className="widget-subtitle">
					<span className="subtitle-blanket" style={{
						backgroundColor: blanketColor
					}}>
						{ widgetSubtitle }
					</span>
				</p>
			);
		}

		const widgetInnerContent = (
			<span className={ `current-value-inner-content ${loadingClass}`}>
				{ currentTemperature } <span className="property-unit">°C</span>
			</span>
		 )

		const widgetContent = (
				<span title={ currentTemperature } className="current-value-text" style={{
					backgroundColor: blanketColor
				}}>
					{ widgetInnerContent }
				</span>
			);


		return (
			<div className="current-value-widget">
				<div className="current-value-text-container">
					{ widgetContent }
					{ subtitleMarkup }
				</div>
			</div>
		);
	}

}

export default stylable( CurrentWeather );
