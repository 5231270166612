import React, { Component } from 'react';

import DropdownList from '../../components/ReactWidgets/DropdownList';

import request from 'superagent';

import stylable from './../../decorators/stylable.jsx';

import {
	findDeviceExternalId
} from './../../utils/devicesUtils.js';

import ManagementAction from './ManagementAction.jsx';

/*
Example config:
{
    "type": "DeviceManagement",
    "options": {
        "stylable": {
            "color": "#ffffff",
            "backgroundImage": "https://api.maplese.com/ImageViewer.ashx?name=fd9d2150-18aa-45f2-97fd-828f522a9491.svg&image=marker"
        },
        "widgetTitle": "Manage Devices",
        "widgetId": "device-management-prorailSnapshot",
        "actions": [{
            "title": "Battery",
            "description": "",
            "actionText": "Reset battery",
            "id": "battery",
            "targetMethod": "post",
            "targetUrl": "http://localhost:5050/api/flows/clientId/key1?LrnDevEui={{deviceId}}&MessageType=resetbattery"
        }, {
            "title": "Downlink location",
            "description": "Request the device location",
            "actionText": "Where are you",
            "id": "gps-downlink",
            "fields": [
                {
                    "type": "hidden",
                    "defaultValue": "2bff",
                    "label": "Downlink Payload",
                    "key": "Payload",
                    "id": "payload"
                }
            ],
            "targetMethod": "post",
            "targetUrl": "http://localhost:5050/api/flows/clientId/key1?LrnDevEui={{deviceId}}&MessageType=Downlink"
        }, {
        	"title": "Device Meta",
            "description": "Update the device meta here",
            "actionText": "Update meta",
            "id": "update-device-meta",
			"fields": [
                {
                    "type": "text",
                    "defaultValue": [
                        "content",
                        "meta",
                        "correctionTimeOffset"
                    ],
                    "label": "Red Light Correction Time",
                    "id": "correctionTimeOffset"
                }, {
                    "id": "geolocation",
                    "type": "text",
                    "label": "geolocation",
                    "defaultValue": [
                        "content",
                        "meta",
                        "geolocation"
                    ]
                }, {
                    "id": "barrierID",
                    "type": "text",
                    "label": "barrierID",
                    "defaultValue": [
                        "content",
                        "meta",
                        "barrierID"
                    ]
                }, {
                    "id": "city",
                    "type": "text",
                    "label": "city",
                    "defaultValue": [
                        "content",
                        "meta",
                        "city"
                    ]
                }, {
                    "id": "street",
                    "type": "text",
                    "label": "street",
                    "defaultValue": [
                        "content",
                        "meta",
                        "street"
                    ]
                }, {
                    "id": "description",
                    "type": "text",
                    "label": "description",
                    "defaultValue": [
                        "content",
                        "meta",
                        "description"
                    ]
                }, {
                    "id": "startDateTime",
                    "type": "text",
                    "label": "startDateTime",
                    "defaultValue": [
                        "content",
                        "meta",
                        "startDateTime"
                    ]
                }
            ],
            "targetMethod": "post",
            "targetUrl": "http://localhost:5050/api/flows/clientId/key1?LrnDevEui={{deviceId}}&MessageType=meta"
        }],
        "datasets": [{
            "flowName": "prorail",
            "units": {
                "messages": "prorailSnapshot",
                "snapshot": "prorailSnapshot"
            }
        }]
    },
    "position": {
        "x": 3,
        "y": 0,
        "h": 14,
        "w": 4
    }
}

 */

class DeviceManagement extends Component {
	constructor ( props ) {
		// Props are super
		super( props );

		this.state = {
			selectedDevice: null,
			activeRequests: {}
		}

		this.widgetDevices = [];

		this.invokeAction = this.invokeAction.bind( this );
	}

	componentWillReceiveProps( newProps ) {
		this.prepareDevices( newProps );
	}

	prepareDevices( newProps ) {
		let widgetDevices = [],
			{ selectedDevice } = this.state;

		if( newProps.provided ) {
			widgetDevices = newProps.provided.devices.map( device => {
				return { name: findDeviceExternalId( device ) };
			});

			this.widgetDevices = widgetDevices;

			if( ! selectedDevice ) {
				selectedDevice = widgetDevices[ 0 ];

				this.setState({ selectedDevice });
			}
		}
	}

	invokeAction({ id, targetUrl, targetMethod, values }) {
		let selectedDevice = this.state.selectedDevice;
		let deviceId = selectedDevice.name;
		let processedUrl = targetUrl.replace( /{{deviceId}}/, deviceId );

		let req = request[ targetMethod.toLowerCase() ]( processedUrl );

		if( values && Object.keys( values ).length ) {
			req.send( values );
		}

		this.setState({
			activeRequests: {
				...this.state.activeRequests,
				[ id ]: {
					isSending: true,
					error: null
				}
			}
		});

		req
			.then( response => {
				this.setState({
					activeRequests: {
						...this.state.activeRequests,
						[ id ]: {
							isSending: false,
							error: null
						}
					}
				});
			})
			.catch( error => {
				this.setState({
					activeRequests: {
						...this.state.activeRequests,
						[ id ]: {
							isSending: false,
							error: error
						}
					}
				});
			});
	}

	render() {
		let selectedDevice = this.state.selectedDevice;
		let widgetSubtitle = this.props.options.widgetSubtitle;
		let widgetDevices = this.widgetDevices;

		let isLoadingRelatedData = false;
		let haveEncounteredDataError = false;

		if( this.props.provided ) {
			isLoadingRelatedData = this.props.provided.isLoading;

			if( this.props.provided.errors && this.props.provided.errors.length ) {
				haveEncounteredDataError = true;
			}
		}

		let widgetSettingsSelectionCssClass = 'widget-settings-selection';

		if( isLoadingRelatedData ) {
			widgetSettingsSelectionCssClass += ' loading';
		}

		if( haveEncounteredDataError ) {
			widgetSettingsSelectionCssClass += ' error';
		}

		let widgetHeader = (
			<div className={ widgetSettingsSelectionCssClass }>
				<DropdownList
					onChange={ ( selectedDevice ) => {
						this.setState({
							selectedDevice,
							activeRequests: {}
						})
					}}
					data={ widgetDevices }
					textField="name"
					placeholder="Select Device"
					value={ selectedDevice }
					caseSensitive={false}
					minLength={1}
					filter="contains"
				 />
			</div>
		);

		let deviceActionsSection;

		if( isLoadingRelatedData ) {
			deviceActionsSection = ( <div className="widget-content-padder">Loading devices...</div> );
		}
		else if ( !selectedDevice ) {
			deviceActionsSection = '';
		}
		else {
			let fullDevice = this.props.provided.devices.find( device => device.snapshot.id === selectedDevice.name );
			let actionsConfig = this.props.options.actions;
			let deviceActionsList = actionsConfig.map(
				actionConfig => <ManagementAction actionStatus={ this.state.activeRequests[ actionConfig.id ] } key={ actionConfig.id } selectedDevice={ fullDevice } invokeAction={ this.invokeAction } { ...actionConfig } />
			);

			deviceActionsSection = (
				<div className="device-management-actions">
					{ deviceActionsList }
				</div>
			);
		}

		let subtitleMarkup = '';
		if ( widgetSubtitle ) {
			subtitleMarkup = <p className="widget-subtitle default">{ widgetSubtitle }</p>;
		}

		let widgetContent = (
			<div className="widget-content">
				{ deviceActionsSection }
			</div>
		);

		return (
			<div className="device-management-widget" onClick={ this.openPopup } >
				{ subtitleMarkup }
				{ widgetHeader }
				{ widgetContent }
			</div>
		);
	}
}

export default stylable( DeviceManagement );
