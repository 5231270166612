const defaultConfig = {
	iotaUrl: 'http://localhost:5050',
	username: 'iota',
	password: 'iota2017!$',
	tileLayer: {
		url: '//server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}',
		attribution:
			'{attribution.Esri} &mdash; Esri, DeLorme, NAVTEQ, TomTom, Intermap, iPC, USGS, FAO, NPS, NRCAN, GeoBase, Kadaster NL, Ordnance Survey, Esri Japan, METI, Esri China (Hong Kong), and the GIS User Community'
	}
}

let config;

if ( window && window[ "config" ] ) {
	config = window[ "config" ];
}
else {
	config = defaultConfig;
}

export default config;
