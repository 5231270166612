import React from 'react';

const NoWidgetsConfiguredLayout = ( props ) => {
	return (
		<div className="no-widgets-configured-grid">
			There are no widgets added. Configure it to start seeing stuff.
		</div>
	);
}

export default NoWidgetsConfiguredLayout;