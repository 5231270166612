import React, { Component } from 'react';

import { Map, TileLayer } from 'react-leaflet';
import './../../../node_modules/leaflet/dist/leaflet.css';

import Control from 'react-leaflet-control';
import HeatmapLayer from 'react-leaflet-heatmap-layer';

import config from './../../config.js';

import Multiselect from '../../components/ReactWidgets/Multiselect';
import DropdownList from '../../components/ReactWidgets/DropdownList';

import FullscreenHeatMapPopup from './../../popups/FullscreenHeatMapPopup/FullscreenHeatMapPopup.jsx';

import { getPropertyValueByPath, getDeviceName } from './../../utils/devicesUtils.js';

import stylable from './../../decorators/stylable.jsx';


// {
// "type": "HeatmapWidget",
// "options": {
//     "stylable": {
//         "color": "#23282D"
//     },
//     "widgetTitle": "Heatmap",
//     "widgetId": "heatmap-locator-widget",
//     "dropdownProperties": [
//         {
//             "name": "fCntUp",
//             "displayName": "Count up",
//             "propertyPath": [
//                 "content",
//                 "fCntUp"
//             ],
//             "radius": 40,
//             "gradient": {
//                 "0.4": {
//                     "color": "green",
//                     "label": "ok"
//                 },
//                 "0.8": {
//                     "color": "yellow",
//                     "label": "warning"
//                 },
//                 "1.0": {
//                     "color": "red",
//                     "label": "danger"
//                 }
//             }
//         },
//         {
//             "name": "batteryLifeCount",
//             "displayName": "Battery life",
//             "propertyPath": [
//                 "content",
//                 "batteryLifeCount"
//             ],
//             "radius": 30,
//             "gradient": {
//                 "0.3": {
//                     "color": "blue",
//                     "label": "ok"
//                 },
//                 "0.6": {
//                     "color": "pink",
//                     "label": "warning"
//                 },
//                 "1.0": {
//                     "color": "red",
//                     "label": "danger"
//                 }
//             }
//         }
//     ],
//     "locationPropertyPath": {
//         "lat": [
//             "content",
//             "lat"
//         ],
//         "lng": [
//             "content",
//             "lng"
//         ]
//     },
//     "datasets": [
//         {
//             "flowName": "prorail",
//             "units": {
//                 "messages": "prorailSnapshot",
//                 "snapshot": "prorailSnapshotMemory",
//                 "history": "prorailSnapshot"
//             },
//             "filters": [
//                 {
//                     "type": "has_snapshot_property",
//                     "options": {
//                         "propertyPath": [
//                             "content",
//                             "lat"
//                         ]
//                     }
//                 }
//             ]
//         },
//         {
//             "flowName": "prorail",
//             "units": {
//                 "messages": "prorailSnapshot",
//                 "snapshot": "prorailSnapshotMemory",
//                 "history": "prorailSnapshot"
//             },
//             "filters": [
//                 {
//                     "type": "has_snapshot_property",
//                     "options": {
//                         "propertyPath": [
//                             "content",
//                             "temp"
//                         ]
//                     }
//                 }
//             ]
//         }
//     ]
// },
// "position": {
//     "x": 0,
//     "y": 27,
//     "h": 11,
//     "w": 4
// }
// },


const defaultDropdownItem = { name: 'All' };
const defaultMapCenter = [ 52.52730392656781, 10.458902895450592 ];
const defaultMapZoom = 3;

class HeatMap extends Component {
	constructor ( props ) {
		// props are super!
		super( props );

		let selectedPropertyConfig = this.props.options.dropdownProperties[ 0 ];

		this.state = {
			devicesDropdownValue: [ defaultDropdownItem ],
			selectedPropertyConfig,
			viewport: {},
			fullscreenPopupOpen: false
		}

		this.mapDevices = [];
		// storing current map viewport for the fullscreen popup
		this.currentViewport = {};

		this.onDevicesDropdownChange = this.onDevicesDropdownChange.bind( this );
		this.onFullscreenPopupClose = this.onFullscreenPopupClose.bind( this );
	}

	componentWillReceiveProps( newProps ) {
		this.prepareDevices( newProps );
	}

	componentWillUpdate( newProps, newState ) {
		let updatePopup = newProps.api.updatePopup;

		if( ! updatePopup || ! newState.fullscreenPopupOpen ) {
			return;
		}

		updatePopup( FullscreenHeatMapPopup, this.getMapFullscreenPopupOptions() );
	}

	// update selected devices after realtime updates
	prepareDevices ( newProps ) {
		let { devicesDropdownValue, selectedPropertyConfig } = this.state,
			{ options, provided } = newProps,
			newState = {},
			mapDevices = [];

		if( !provided.isLoading
			&& provided.devices ) {
			const latPath = options.locationPropertyPath.lat;
			const lngPath = options.locationPropertyPath.lng;

			provided.devices.forEach( ( device ) => {
				const deviceName = getDeviceName( device, options.deviceOptions );
				const lat = getPropertyValueByPath( device, [ 'snapshot', ...latPath ] );
				const lng = getPropertyValueByPath( device, [ 'snapshot', ...lngPath ] );

				if( lat && lng ) {
					mapDevices.push({
						name: deviceName,
						lat,
						lng,
						fullDevice: device
					});
				}
			});
		}

		this.mapDevices = mapDevices;

		devicesDropdownValue.forEach( selectedDevice => {
			mapDevices.forEach( device => {
				if( device.name === selectedDevice.name ) {
					selectedDevice.lat = device.lat;
					selectedDevice.lng = device.lng;
				}
			});
		});

		newState.devicesDropdownValue = devicesDropdownValue;

		if( !selectedPropertyConfig ) {
			selectedPropertyConfig = options.dropdownProperties[ 0 ]

			newState.selectedPropertyConfig = selectedPropertyConfig;
		}

		this.setState( newState );
	}

	getFullscreenControl() {
		return (
			<Control position="topright" className="leaflet-bar">
				{ /* eslint-disable-next-line */ }
				<a className="map-fullscreen-icon" onClick={ () => {
					this.props.api.openPopup( FullscreenHeatMapPopup, this.getMapFullscreenPopupOptions() );

					this.setState( { fullscreenPopupOpen: true } );
				} }>
				</a>
			</Control>
			);
	}

	onFullscreenPopupClose() {
		this.setState( { fullscreenPopupOpen: false } );
	}

	getMapFullscreenPopupOptions() {
		return {
			selectedDevices: this.getSelectedDevices(),
			selectedPropertyConfig: this.state.selectedPropertyConfig,
			viewport: this.currentViewport,
			options: this.props.options,
			onClose: this.onFullscreenPopupClose
		}
	}

	onDevicesDropdownChange ( value ) {
		let devicesDropdownValue = value;

		if( value.length === 0 ) {
			devicesDropdownValue = [ defaultDropdownItem ];
		}
		else if( value.length > 1 ) {
			let [ firstItem, ...restOfValue ] = value;

			if( firstItem === defaultDropdownItem ) {
				devicesDropdownValue = restOfValue;
			}
			else if( restOfValue.indexOf( defaultDropdownItem ) !== -1 ) {
				devicesDropdownValue = [ defaultDropdownItem ];
			}

		}

		this.setState({
			devicesDropdownValue
		})
	}

	getDevicePropertyValue( device ) {
		let { selectedPropertyConfig } = this.state;
		let { propertyPath } = selectedPropertyConfig;
		let propertyValue = getPropertyValueByPath( device, [ 'fullDevice', 'snapshot', ...propertyPath ] );

		return propertyValue;
	}

	getSelectedDevices() {
		const { devicesDropdownValue } = this.state;

		if( devicesDropdownValue.length === 1 && devicesDropdownValue[ 0 ] === defaultDropdownItem ) {
			return this.mapDevices;
		}

		return devicesDropdownValue;
	}

	getInitialViewport () {
		let initialMapViewPortDefinition = {};

		if( this.props.options.mapBounds ) {
			initialMapViewPortDefinition.bounds = this.props.options.mapBounds;
		}
		else if( this.props.options.mapCenter && this.props.options.mapZoom ) {
			initialMapViewPortDefinition.center = this.props.options.mapCenter;
			initialMapViewPortDefinition.zoom = this.props.options.mapZoom;
		}

		if( Object.keys( initialMapViewPortDefinition ).length === 0 ) {
			initialMapViewPortDefinition.center = defaultMapCenter;
			initialMapViewPortDefinition.zoom = defaultMapZoom;
		}

		return initialMapViewPortDefinition;
	}

	render() {
		const { options } = this.props;
		const { devicesDropdownValue, selectedPropertyConfig, viewport } = this.state;
		let mapDevices = this.mapDevices;

		const selectedDevices = this.getSelectedDevices();

		let widgetProperties = [],
			{ dropdownProperties } = options;

		if( selectedDevices.length ) {
			dropdownProperties.forEach( ( propertyConfig ) => {
				let isSharedProperty = false; //check if all devices has that property

				selectedDevices.forEach( ( device ) => {
					let deviceContent = device.fullDevice.snapshot.content;

					for( let property in deviceContent ) {
						if( property === propertyConfig.name ) {
							isSharedProperty = true;
						}
					};
				});

				if( isSharedProperty ) {
					widgetProperties.push( propertyConfig.displayName );
				}
			});
		}

		let gradient = {};
		if ( selectedPropertyConfig && selectedPropertyConfig.gradient ) {
			for( let key in selectedPropertyConfig.gradient ) {
				gradient[ key ] = selectedPropertyConfig.gradient[ key ].color;
			}
		}
		else {
			gradient = { 0.4: 'green', 0.8: 'yellow', 1.0: 'red' };
		}

		let heatmapPoints = [];

		if( selectedPropertyConfig ) {
			selectedDevices.forEach( ( selectedDevice, index ) => {
				let filteredDevice = mapDevices.filter( mapDevice => mapDevice.name === selectedDevice.name )[ 0 ],
					devicePropertyValue = this.getDevicePropertyValue( filteredDevice );

					if( devicePropertyValue ) {
						heatmapPoints.push( [ filteredDevice.lat, filteredDevice.lng, devicePropertyValue ] );
					}
			});
		}


		// TESTING

		// heatmapPoints = [
		// 	[-37.8869090667, 175.3657417333, "10000"],
		// 	[-37.8894207167, 175.4015351167, "20"],
		// 	[-37.8927369333, 175.4087452333, "30"],
		// 	[-37.90585105, 175.4453463833, "100"],
		// 	[-37.9064188833, 175.4441556833, "100"],
		// 	[-37.90584715, 175.4463564333, "100"],
		// 	[-37.9033391333, 175.4244005667, "100"],
		// 	[-37.9061991333, 175.4492620333, "100000000"],
		// 	[-37.9058955167, 175.4445613167, "100000000"]
		// ];

		let initialMapViewPortDefinition = this.getInitialViewport();

		if( Object.keys( this.currentViewport ).length === 0 ) {
			this.currentViewport = initialMapViewPortDefinition;
		}

		return (
			<div className="locator-widget" >
				<div>
					<div className="widget-settings-selection">
						<Multiselect
							onChange={ this.onDevicesDropdownChange }
							data={ [ defaultDropdownItem, ...mapDevices ] }
							textField="name"
							valueField="name"
							value={ devicesDropdownValue }
							caseSensitive={false}
							minLength={1}
							filter='contains'
							id="locator-sensor-selector" />

							{
								dropdownProperties.length === 1 ? '' :
								<DropdownList
								onChange={ ( selectedProperty ) => {
									let selectedPropertyConfig = dropdownProperties.filter( property => {
										return property.displayName === selectedProperty;
									});

									selectedPropertyConfig = selectedPropertyConfig ? selectedPropertyConfig[ 0 ] : null;

									this.setState({ selectedPropertyConfig });
								} }
								data={ widgetProperties }
								textField="name"
								valueField="name"
								placeholder="Select Property"
								value={ selectedPropertyConfig.displayName }
								caseSensitive={ false }
								minLength={ 1 }
								filter="contains" />
							}
					</div>
				</div>
				<div className="map-container">
					<Map { ...initialMapViewPortDefinition } viewport={ viewport } animate={ true }
						onViewportChanged={ newViewport => {
							setTimeout( () => {
								this.currentViewport = newViewport;
							}, 300 );
						} } >
						{ selectedPropertyConfig ?
							<HeatmapLayer
								fitBoundsOnLoad
								fitBoundsOnUpdate={ false }
								points={ heatmapPoints }
								gradient={ gradient }
								radius={ selectedPropertyConfig.radius ? parseFloat( selectedPropertyConfig.radius ) : 40  }
								longitudeExtractor={ m => m[ 1 ] }
								latitudeExtractor={ m => m[ 0 ] }
								intensityExtractor={ m => parseFloat( m[ 2 ] ) } /> : '' }

						<TileLayer url={ config.tileLayer.url } />

						{ this.getFullscreenControl() }
					</Map>
				</div>
			</div>
		);
	}
}

export default stylable( HeatMap );
