import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { updateUserMeta } from './../actions/userActions';
import { push } from 'connected-react-router';
import { getDashboardStateFromUserState } from './../selectors/dashboards.js';

const NewDashboard = () => {
	const [ name, setName ] = useState( '' );

	const dispatch = useDispatch();
	const userState = useSelector( state => state.userState );
	const [ error, setError ] = useState( null );
	const [ isLoading, setIsLoading ] = useState( false );


	let dashboardsState = getDashboardStateFromUserState( userState );

	let dashboards = {};
	let order = [];
	if ( dashboardsState && dashboardsState.dashboards && dashboardsState.dashboards.dashboards ) {
		dashboards = dashboardsState.dashboards.dashboards;
		order = dashboardsState.dashboards.order;
	}

	useEffect( () => {
		if ( isLoading && dashboards && dashboards[ name ] ) {
			setIsLoading( false );
			dispatch( push( `/dashboards/${ name }` ) )
		}
	}, [ isLoading, dashboards ] );

	const createDashboard = () => {
		dispatch( updateUserMeta( 'dashboards', {
			dashboards: {
				...dashboards,
				[ name ]: {
					name,
					widgets: []
				},
			},
			order: [ ...order, name ]
		} ) );
		setError( null );
		setIsLoading( true );
	}

	return (
		<div className="site-container centered">
			<h3>
				New Dashboard
		</h3>
			<div className="new-dashboard-form clearfix">
				<label>
					Dashboard Name <br />
					<input
						autoFocus
						value={ name }
						onChange={ ( e ) => setName( e.target.value ) } />
				</label>
				<br />
				<button
					disabled={ isLoading }
					onClick={ () => {
						if ( dashboards && dashboards[ name ] ) {
							setError( `Dashboard with name ${ name } already exists` );
						}
						else {
							createDashboard();
						}
					} } className="btn">
					{ isLoading ? 'Creating' : 'Create' }
				</button>
				{
					error ? (
						<div>{ error }</div>
					) : ''
				}
			</div>
		</div>
	)
}

export default NewDashboard;