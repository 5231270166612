import React, { Component } from 'react';

import * as moment from 'moment';

import RangeDateTimePickers from '../../components/DateTimePickers/RangeDateTimePickers';

import TimeFacet from './../../components/TimeFacet.jsx';

import TimeseriesList from './TimeseriesList.jsx';

import { getPropertyValueByPath } from './../../utils/devicesUtils.js';

class StringPropertyHistoryOverview extends Component {
	constructor ( props ) {
		super( props );

		let now = moment();
		let yesterday = moment().subtract( 1, 'days' )

		this.state = {
			toDate: now,
			fromDate: yesterday,
			selectedFacet: null,
			isFetching: true
		};

		this.onToDateChange = this.onToDateChange.bind( this );
		this.onFromDateChange = this.onFromDateChange.bind( this );

		this.onTimeFacetClicked = this.onTimeFacetClicked.bind( this );
	}

	componentWillMount () {
		this.fetchDeviceHistory( this.props, this.state );
	}

	shouldComponentUpdate ( newProps, newState ) {
		if ( !newState.fromDate || !newState.toDate ) {
			return false;
		}

		return true;
	}

	componentWillUpdate ( newProps, newState ) {
		if ( newState.selectedFacet !== this.state.selectedFacet ||
			newState.fromDate !== this.state.fromDate ||
			newState.toDate !== this.state.toDate ) {
			this.fetchDeviceHistory( newProps, newState );
		}
	}

	onToDateChange ( toDate ) {
		this.setState( {
			toDate
		} );
	}
	onFromDateChange ( fromDate ) {
		this.setState( {
			fromDate
		} );
	}

	onTimeFacetClicked ( selectedFacet ) {
		let { timeDuration, timeUnit } = selectedFacet;

		let toDate = moment();
		let fromDate = moment().subtract( timeDuration, timeUnit );

		this.setState( {
			toDate,
			fromDate,
			selectedFacet
		} )
	}

	fetchDeviceHistory ( props, state ) {
		const fromDateFormatted = state.fromDate.format();
		const toDateFormatted = state.toDate.format();

		let device = props.selectedDevice;
		let historyPromise = props.api.fetchDevicesHistory( [ device ], fromDateFormatted, toDateFormatted );

		if ( historyPromise ) {
			historyPromise.then( ( history ) => {
				this.setState( {
					deviceHistory: history[ 0 ],
					isFetching: false
				} );
			} );
		}
	}

	render () {
		const { selectedDevice, selectedPropertyPath } = this.props;
		const { fromDate, toDate, deviceHistory, isFetching } = this.state;

		let xAxis = [];
		let values = [];
		let valuesLabel;

		if ( selectedPropertyPath && selectedDevice && fromDate && toDate ) {
			if ( deviceHistory ) {

				deviceHistory.forEach( ( historyEntry ) => {
					const [ /* skip the snapshot */, ...historyPath ] = selectedPropertyPath;
					const propertyValue = getPropertyValueByPath( historyEntry, historyPath );

					if ( propertyValue ) {
						xAxis.push( moment( getPropertyValueByPath( historyEntry, [ 'timeSet' ] ) ).format( 'YYYY-MM-DD HH:mm:ss' ) );
						values.push( propertyValue );
					}

				} );

				if ( xAxis.length ) {
					valuesLabel = selectedPropertyPath[ selectedPropertyPath.length - 1 ];
				}
			}
		}

		if ( selectedPropertyPath && selectedDevice ) {
			let chartHeader = (
				<p className="chart-hint" >
					{ selectedPropertyPath } history
					<span className="icon expand-icon" onClick={ this.props.toggleChartContainer }></span>
				</p>
			);

			let chartContainer = (
				<div className="popup-property-history-chart-container">
					<div className="popup-property-history-inner-container chart-initialized">
						<div className="property-chart-helper">
							<div className="datetime-container">
								<div className="calendars-container">
									<div className="calendars">
										<RangeDateTimePickers
											dateFormat='H:i d/m/Y'

											maxDateFromCalendar={ toDate ? toDate.clone().subtract( 1, 'minutes' ).format() : moment().subtract( 1, 'minutes' ).format() }
											maxDateToCalendar={ moment().format() }

											startDate={ this.state.fromDate }
											endDate={ this.state.toDate }

											onStartDateChange={ this.onFromDateChange }
											onEndDateChange={ this.onToDateChange } />
									</div>
								</div>
								<div className="period-facets-container">
									<TimeFacet onClick={ this.onTimeFacetClicked } timeDuration="1" timeUnit="hour" selectedFacet={ this.state.selectedFacet } />
									<TimeFacet onClick={ this.onTimeFacetClicked } timeDuration="12" timeUnit="hours" selectedFacet={ this.state.selectedFacet } />
									<TimeFacet onClick={ this.onTimeFacetClicked } timeDuration="1" timeUnit="days" selectedFacet={ this.state.selectedFacet } />
									<TimeFacet onClick={ this.onTimeFacetClicked } timeDuration="5" timeUnit="days" selectedFacet={ this.state.selectedFacet } />
								</div>
							</div>
							<div className="chart-error-messages"></div>
							<TimeseriesList
								isFetching={ isFetching }
								xAxis={ xAxis }
								values={ values }
								valuesLabel={ valuesLabel }
							/>
						</div>
					</div>
				</div>
			);

			return (
				<div className="sow-popup-property-history-container">
					{ chartHeader }
					{ chartContainer }
				</div>
			);
		}

		return (
			<div className="sow-popup-property-history-container empty"></div>
		);
	}
}

export default StringPropertyHistoryOverview;

