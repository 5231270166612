import { capitalizeFirstLetter } from './generalUtils.js';

export function findInSchema ( schema, path ) {
	if ( !schema ) {
		return null;
	}

	let schemaItem = {};


	if ( schema.allOf ) {
		schemaItem = {
			...schemaItem,
			...loadAllOf( schema.allOf, schema ),
		}
	}

	if ( schema.oneOf ) {
		schemaItem = {
			...schemaItem,
			...loadOneOf( schema.oneOf, schema ),
		}
	}

	if ( schema.properties ) {
		schemaItem = {
			...schemaItem,
			...schema.properties,
		}
	}

	path.forEach( ( pathItem ) => {
		let nextSchemaItem = schemaItem[ pathItem ];
		let mergedItem = {};
		let capitalizedNewPath;

		if ( !nextSchemaItem ) {
			capitalizedNewPath = capitalizeFirstLetter( pathItem );
			nextSchemaItem = schemaItem[ capitalizedNewPath ];
		}

		if ( nextSchemaItem ) {
			if ( nextSchemaItem.oneOf ) {
				nextSchemaItem.oneOf.forEach( ( oneOfItem ) => {
					if ( oneOfItem.type !== "null" ) {
						let oneOfSchema = oneOfItem;

						if ( oneOfSchema.$ref ) {
							oneOfSchema = load$Ref( oneOfSchema.$ref, schema );
						}

						mergedItem = { ...mergedItem, ...oneOfSchema };
					}
				} );
			} else if ( nextSchemaItem.allOf ) {
				nextSchemaItem.allOf.forEach( ( allOfItem ) => {
					let allOfSchema = allOfItem;

					if ( allOfSchema.$ref ) {
						allOfSchema = load$Ref( allOfSchema.$ref, schema );
					}

					mergedItem = { ...mergedItem, ...allOfSchema };
				} );
			}
			else {
				mergedItem = nextSchemaItem;
			}

			schemaItem = mergedItem;
		}
	} );

	return schemaItem;
}

function loadAllOf ( allOfDefinition, schema ) {
	let mergedItem = {};

	allOfDefinition.forEach( ( allOfItem ) => {
		let allOfSchema = allOfItem;

		if ( allOfSchema.$ref ) {
			allOfSchema = load$Ref( allOfSchema.$ref, schema );
		}

		mergedItem = { ...mergedItem, ...allOfSchema };
	} );

	return mergedItem;
}


function loadOneOf ( oneOfDefinition, schema ) {
	let mergedItem = {};

	oneOfDefinition.forEach( ( oneOfItem ) => {
		if ( oneOfItem.type !== "null" ) {
			let oneOfSchema = oneOfItem;

			if ( oneOfSchema.$ref ) {
				oneOfSchema = load$Ref( oneOfSchema.$ref, schema );
			}

			mergedItem = { ...mergedItem, ...oneOfSchema };
		}
	} );

	return mergedItem;
}

export function isPrimitiveType ( schemaLevel, schemaProp ) {
	// No refs - if there ware refs the schemaProp would be schema.oneOf/allOf
	let subSchema = schemaLevel[ schemaProp ];

	if ( !subSchema ) {
		subSchema = schemaLevel[ capitalizeFirstLetter( schemaProp ) ]
	}

	if ( subSchema.type ) {
		return true;
	}

	return false;
}

function load$Ref ( $refKey, schema ) {
	let referencedSchemaPart = schema;
	let refKeyParts = $refKey.split( '/' );

	let [ firstRef, ...refParts ] = refKeyParts;

	if ( firstRef !== '#' ) {
		throw new Error( 'non-root refs are not supported' );
	}

	refParts.forEach( ( refPart ) => {
		referencedSchemaPart = referencedSchemaPart[ refPart ];
	} )

	if ( referencedSchemaPart.properties ) {
		return referencedSchemaPart.properties;
	}

	if ( referencedSchemaPart.allOf ) {
		return loadAllOf( referencedSchemaPart.allOf, schema )
	}

	if ( referencedSchemaPart.oneOf ) {
		return loadOneOf( referencedSchemaPart.oneOf, schema )
	}

	throw new Error( 'The desired $ref is not found' );
}