import React from 'react';

export default function NotificationsFilters( props ) {
	const { typeFilter, viewStateFilter, toggleTypeFilter, toggleViewStateFilter } = props;

	return (
		<div className="notifications-list-filters">
			<div className="type-filters">
				<div className="filters-title"><span className="icon"></span>Filter</div>
				<div className="container">
					<div
						className={ 'filter-token filter-communication' + ( typeFilter.COMMUNICATION ? ' active' : '' ) }
						onClick={ () => toggleTypeFilter( 'COMMUNICATION' ) }
						title="Network" >
						<div className="filter-token-title">Network</div>
					</div>
					<div
						className={ 'filter-token filter-limits' + ( typeFilter.BOUND ? ' active' : '' ) }
						onClick={ () => toggleTypeFilter( 'BOUND' ) }
						title="Limits" >
						<div className="filter-token-title">Limits</div>
					</div>
					<div
						className={ 'filter-token filter-battery' + ( typeFilter.BATTERY ? ' active' : '' ) }
						onClick={ () => toggleTypeFilter( 'BATTERY' ) }
						title="Battery" >
						<div className="filter-token-title">Battery</div>
					</div>
					<div
						className={ 'filter-token filter-other' + ( typeFilter.OTHER ? ' active' : '' ) }
						onClick={ () => toggleTypeFilter( 'OTHER' ) }
						title="Other">
						<div className="filter-token-title">Other</div>
					</div>
				</div>

			</div>
			<div className="general-filters">
				<div className="filters-title"><span className="icon"></span>View</div>
				<div className="view-state-filters">
					<div className={ 'filter-all' + ( viewStateFilter === 'ALL' ? ' active' : '' ) } onClick={ () => toggleViewStateFilter( 'ALL' ) }>
						<span className="filter-checkbox"></span>
						<span className="filter-token">All</span>
					</div>
					<div className={ 'filter-new' + ( viewStateFilter === 'NEW' ? ' active' : '' ) } onClick={ () => toggleViewStateFilter( 'NEW' ) }>
						<span className="filter-checkbox"></span>
						<span className="filter-token">Unseen</span>
					</div>
				</div>
			</div>
		</div>
	);
}
