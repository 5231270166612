import React, { Component } from 'react';

import { connect } from 'react-redux';

import DynamicWidgetsLoader from './../../../middleware/dynamic-widgets-loader.js';

import * as flowsActions from './../../../actions/flowsActions.js';

import TextField from './../fields/TextField.jsx';
import TextareaField from './../fields/TextareaField.jsx';
import DatasetsSelectorFieldSet from './../fields/datasets/DatasetsSelectorFieldSet.jsx';
import StylableConfiguration from './../fields/stylable/StylableConfiguration.jsx';

import StepIndicator from './StepIndicator.jsx';

import './WidgetConfigurationForm.css';

// Icons
import pencilIcon from './../../../img/fsi-pencil.svg';
import brushIcon from './../../../img/fsi-brush.svg';
import dataIcon from './../../../img/fsi-db.svg';

class WidgetConfigurationForm extends Component {
	static getFieldUi ( value, field, onChange, providedData, errors ) {

		switch ( field.type ) {
			case 'textarea':
				return (
					<TextareaField
						key={ field.id }
						value={ value }
						field={ field }
						onChange={ onChange }
						errors={ errors } />
				);
			case 'datasets': {
				return (
					<DatasetsSelectorFieldSet
						key={ field.id }
						value={ value }
						field={ field }
						onChange={ onChange }
						flows={ providedData.flows }
						errors={ errors } />
				);
			}
			case 'stylable': {
				return (
					<StylableConfiguration
						key={ field.id }
						value={ value }
						field={ field }
						onChange={ onChange }
						errors={ errors } />
				);
			}
			case 'text':
			default: {
				return (
					<TextField
						key={ field.id }
						value={ value }
						field={ field }
						onChange={ onChange }
						errors={ errors } />
				);
			}
		}
	}

	static getFieldConfigurationStructure ( field, value ) {
		switch ( field.type ) {
			case 'datasets': {
				return DatasetsSelectorFieldSet.getFieldConfigurationStructure( field, value );
			}
			case 'stylable': {
				return StylableConfiguration.getFieldConfigurationStructure( field, value );
			}
			default: {
				// All textfields / textareas are handled by default

				let fieldValue = {
					[ field.id ]: value
				}

				if ( field.required && !value ) {
					fieldValue._errors = {
						[ field.id ]: [ {
							field: field.id,
							type: 'REQUIRED_EMPTY',
							message: `${ field.label } is required`
						} ]
					};
				}

				return fieldValue;
			}
		}
	}

	constructor ( props ) {
		// Props are super
		super( props );

		this.state = {
			fieldsValues: {},
			errors: null,
			currentStep: 0
		}

		this.widgetTypes = DynamicWidgetsLoader.getWidgetTypes();

		this.onFieldChange = this.onFieldChange.bind( this );
		this.onSubmitConfiguration = this.onSubmitConfiguration.bind( this );

		this.onBack = this.onBack.bind( this );
	}

	componentDidMount () {
		this.props.fetchAllFlows();
	}

	onBack () {
		if ( this.state.currentStep ) {
			this.setState( { currentStep: this.state.currentStep - 1 } )
		}
		else {
			this.props.onCancel();
		}
	}

	render () {
		let { errors } = this.state;

		let widgetConfigurationDefinition = this.getCurrentWidgetConfiguration();
		let { fieldsets, modules } = widgetConfigurationDefinition;
		let fieldsetsKeys = Object.keys( fieldsets );
		let currentFieldsetKey = fieldsetsKeys[ this.state.currentStep ];

		let isFirstStep = this.state.currentStep === 0;
		let isLastStep = fieldsetsKeys.length === this.state.currentStep + 1;

		let widgetConfigurationOutput = [];

		widgetConfigurationDefinition.fieldsets[ currentFieldsetKey ].fields.forEach( ( field ) => {
			let value = this.getFieldValue( field );
			let fieldErrors;

			if ( errors && errors[ field.id ] ) {
				fieldErrors = errors[ field.id ];
			}

			widgetConfigurationOutput.push( WidgetConfigurationForm.getFieldUi( value, field, this.onFieldChange, { flows: this.props.flows }, fieldErrors ) )
		} );

		return (
			<div className="widget-configuration-form">
				<button className="btn-nostyle" onClick={ this.props.onCancel } >{ `< back to widgets` }</button>
				<h3>
					{ widgetConfigurationDefinition.name }
				</h3>
				<StepIndicator
					onStepChange={ ( newStep ) => this.setState( {
						currentStep: newStep
					} ) }
					fieldsets={ fieldsets }
					modules={ modules }
					currentStep={ currentFieldsetKey } />
				<div>
					{ widgetConfigurationOutput }
				</div>
				<div>
					<button className="btn-nostyle prev-step-button" onClick={ this.onBack } >
						{ isFirstStep ? '< cancel' : '< back' }
					</button>
					{ !isLastStep ?
						<button
							className="btn-nostyle next-step-button"
							onClick={ () => this.setState( {
								currentStep: this.state.currentStep + 1
							} ) } >
							next >
						</button> : null
					}
				</div>
				<div>
					<button
						className="btn add-widget-submit-button"
						onClick={ this.onSubmitConfiguration } >
						Add Widget
					</button>
				</div>
			</div>
		);
	}

	getFieldValue ( field ) {
		let value = this.state.fieldsValues[ field.id ];

		if ( !value ) {
			if ( field.defaultValue ) {
				value = field.defaultValue;
			}
			else {
				value = '';
			}
		}

		return value;
	}

	getCurrentWidgetConfiguration () {
		let widgetType = this.widgetTypes[ this.props.widgetConfigurationForm.widgetType ];
		return widgetType.getWidgetConfiguration();
	}

	onFieldChange ( fieldId, value ) {
		this.setState( {
			...this.state,
			fieldsValues: {
				...this.state.fieldsValues,
				[ fieldId ]: value
			}
		} )
	}

	onSubmitConfiguration () {
		let widgetId = this.props.widgetId;
		let widgetConfigurationDefinition = this.getCurrentWidgetConfiguration();
		let { fieldsets } = widgetConfigurationDefinition;
		let fieldsetsKeys = Object.keys( fieldsets );
		let hasErrors = false;


		let fieldsetsConfigurations = fieldsetsKeys.map( ( fieldsetKey ) => {
			return fieldsets[ fieldsetKey ].fields.reduce(
				( fieldsAccumulator, field ) => {
					let currentFieldValues = WidgetConfigurationForm.getFieldConfigurationStructure( field, this.state.fieldsValues[ field.id ] );

					if ( currentFieldValues._errors ) {
						hasErrors = true;
					}

					// Merge the errors
					if ( fieldsAccumulator._errors ) {
						if ( currentFieldValues._errors ) {
							currentFieldValues._errors = {
								...fieldsAccumulator._errors,
								...currentFieldValues._errors
							}
						}
						else {
							currentFieldValues._errors = fieldsAccumulator._errors;
						}
					}

					return {
						...fieldsAccumulator,
						...currentFieldValues
					}
				}, {} );
		} );

		// TODO: Check for errors and merge the configurations
		// let widgetFieldsValues =


		if ( hasErrors ) {
			let errors = fieldsetsConfigurations.reduce( ( acc, widgetFieldsValues ) => {
				if ( widgetFieldsValues._errors ) {
					acc.concat( widgetFieldsValues._errors );
				}
				return acc;
			}, [] );

			this.setState( {
				errors
			} )
		}
		else {
			let widgetFieldsetsValues = fieldsetsConfigurations.reduce( ( acc, widgetFieldsValues ) => {
				return {
					...acc,
					...widgetFieldsValues
				};
			}, {} );
			this.props.onSave( widgetId, this.props.widgetConfigurationForm.widgetType, widgetFieldsetsValues );
		}
	}
}


function mapDispatchToProps ( dispatch ) {
	return {
		fetchAllFlows: () => {
			dispatch( flowsActions.fetchAllFlows() );
		}
	};
}

function mapStateToProps ( state ) {
	return {
		flows: state.entities.flows
	};
}

export default connect( mapStateToProps, mapDispatchToProps )( WidgetConfigurationForm );

export const fieldsetsIcons = {
	initial: pencilIcon,
	datasets: dataIcon,
	stylable: brushIcon
};
