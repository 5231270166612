import React, { Component } from 'react';

import { connect } from 'react-redux'


class DevicesOverviewHeader extends Component {
	render () {

		let innerHeaderMarkup;

		if ( !this.props.selectedDeviceName ) {
			innerHeaderMarkup = (
				<div>
					<h3 className="no-sensor-selected-header popup-header-title">Select a sensor to see it's overview</h3>
				</div>
			);
		}
		else {
			innerHeaderMarkup = (
				<div>
					<h3 className="popup-header-title">Properties</h3>
					<h4 className="popup-current-sensor-name">{ this.props.selectedDeviceName }</h4>
				</div>
			);
		}

		return (
			<div className="sow-popup-overview-header">
				{ innerHeaderMarkup }
			</div>
		);
	}
}


function mapStateToProps ( state, ownProps ) {
	return {
	};
}

function mapDispatchToProps ( dispatch ) {
	return {
	};
}

export default connect( mapStateToProps, mapDispatchToProps )( DevicesOverviewHeader );
