import React, { Component } from 'react';

import * as moment from 'moment';


import RangeDateTimePickers from '../../components/DateTimePickers/RangeDateTimePickers';

import TimeFacet from './../../components/TimeFacet.jsx';

import TimeseriesChart from './../../components/Charts/TimeseriesChart.jsx';

import { getPropertyValueByPath } from './../../utils/devicesUtils.js';

class DevicesOverviewHistoryChart extends Component {
	constructor ( props ) {
		super( props );

		let now = moment();
		let yesterday = moment().subtract( 1, 'days' )

		this.state = {
			toDate: now,
			fromDate: yesterday,
			selectedFacet: null,
			isFetching: true
		};

		this.onToDateChange = this.onToDateChange.bind( this );
		this.onFromDateChange = this.onFromDateChange.bind( this );

		this.onTimeFacetClicked = this.onTimeFacetClicked.bind( this );
	}

	componentWillMount () {
		this.fetchDeviceHistory( this.props, this.state );
	}

	componentWillUpdate ( newProps, newState ) {
		if ( newState.selectedFacet !== this.state.selectedFacet ||
			newState.fromDate !== this.state.fromDate ||
			newState.toDate !== this.state.toDate ) {
			this.fetchDeviceHistory( newProps, newState );
		}
	}

	onToDateChange ( toDate ) {
		this.setState( {
			toDate,
			selectedFacet: null,
			isFetching: toDate ? true : false,
			deviceHistory: null
		} );
	}
	onFromDateChange ( fromDate ) {
		this.setState( {
			fromDate,
			selectedFacet: null,
			isFetching: fromDate ? true : false,
			deviceHistory: null
		} );
	}

	onTimeFacetClicked ( selectedFacet ) {
		let { timeDuration, timeUnit } = selectedFacet;

		let toDate = moment();
		let fromDate = moment().subtract( timeDuration, timeUnit );

		this.setState( {
			toDate,
			fromDate,
			selectedFacet,
			isFetching: true,
			deviceHistory: null
		} )
	}

	fetchDeviceHistory ( props, state ) {
		const fromDate = state.fromDate;
		const toDate = state.toDate;

		if ( fromDate && toDate ) {
			let device = props.selectedDevice;
			let historyPromise = props.api.fetchDevicesHistory( [ device ], fromDate.format(), toDate.format() );

			if ( historyPromise ) {
				historyPromise.then( ( history ) => {
					this.setState( {
						deviceHistory: history[ 0 ],
						isFetching: false
					} );
				} );
			}
		}
	}

	render () {
		const {
			selectedDevice,
			selectedPropertyPath,
			chartOptions,
			propertyValueUnit,
			readablePropertyName
		} = this.props;

		const {
			fromDate,
			toDate,
			deviceHistory,
			isFetching
		} = this.state;

		// let chartProps;
		let xAxis = [];
		let values = [];
		let valuesLabel;

		if ( selectedPropertyPath && selectedDevice && fromDate && toDate ) {
			if ( deviceHistory ) {
				deviceHistory.forEach( ( historyEntry ) => {
					const [ /* skip the snapshot */, ...historyPath ] = selectedPropertyPath;
					const propertyValue = getPropertyValueByPath( historyEntry, historyPath );

					if ( propertyValue !== null && propertyValue !== undefined ) {
						xAxis.push( moment( getPropertyValueByPath( historyEntry, [ 'timeSet' ] ) ).format( 'YYYY-MM-DD HH:mm:ss' ) );
						values.push( propertyValue );
					}

				} );

				if ( xAxis.length ) {
					valuesLabel = readablePropertyName;
				}
			}
		}

		if ( selectedPropertyPath && selectedDevice ) {
			let chartHeader = (
				<p className="chart-hint">
					{ readablePropertyName } history
					<span className="icon expand-icon" onClick={ this.props.toggleChartContainer }></span>
				</p>
			);

			let chartContainer = (
				<div className="popup-property-history-chart-container">
					<div className="popup-property-history-inner-container chart-initialized">
						<div className="property-chart-helper">
							<div className="datetime-container">
								<div className="calendars-container">
									<div className="calendars">
										<RangeDateTimePickers
											dateFormat='H:i d/m/Y'

											maxDatesInterval={ chartOptions ? chartOptions.maxDatesInterval : null }

											maxDateFromCalendar={ toDate ? toDate.clone().subtract( 1, 'minutes' ).format() : moment().subtract( 1, 'minutes' ).format() }
											maxDateToCalendar={ moment().format() }

											startDate={ this.state.fromDate }
											endDate={ this.state.toDate }

											onStartDateChange={ this.onFromDateChange }
											onEndDateChange={ this.onToDateChange } />
									</div>
								</div>
								<div className="period-facets-container">
									<TimeFacet onClick={ this.onTimeFacetClicked } timeDuration="1" timeUnit="hour" selectedFacet={ this.state.selectedFacet } />
									<TimeFacet onClick={ this.onTimeFacetClicked } timeDuration="12" timeUnit="hours" selectedFacet={ this.state.selectedFacet } />
									<TimeFacet onClick={ this.onTimeFacetClicked } timeDuration="1" timeUnit="days" selectedFacet={ this.state.selectedFacet } />
									<TimeFacet onClick={ this.onTimeFacetClicked } timeDuration="5" timeUnit="days" selectedFacet={ this.state.selectedFacet } />
								</div>
							</div>
							<div className="chart-error-messages"></div>
							<TimeseriesChart
								key={ this.props.selectedPropertyPath ? this.props.selectedPropertyPath.join() : moment().unix() }
								isFetching={ isFetching }
								xAxis={ xAxis }
								values={ values }
								valuesLabel={ valuesLabel }
								valuesUnit={ propertyValueUnit }
								chartOptions={ chartOptions }
								legend={ { position: 'inset' } }
							/>
						</div>
					</div>
				</div>
			);

			return (
				<div className="sow-popup-property-history-container">
					{ chartHeader }
					{ chartContainer }
				</div>
			);
		}


		return (
			<div className="sow-popup-property-history-container empty"></div>
		);
	}
}

export default DevicesOverviewHistoryChart;
