import React, { PureComponent } from 'react';

import DevicesOverviewPopup from './../../popups/DevicesOverviewPopup/DevicesOverviewPopup.jsx';

import image from './image.png';

import stylable from './../../decorators/stylable.jsx';

class SensorOverview extends PureComponent {
	static getWidgetConfiguration () {
		return {
			name: 'Sensors Overview',
			image: image,
			description: 'Use this widget to visualize devices counts',
			defaultSize: [ 4, 2 ],
			fieldsets: {
				initial: {
					name: 'Initial',
					fields: [ {
						id: 'widgetSubtitle',
						type: 'textarea',
						label: 'Subtitle'
					} ]
				},
				datasets: {
					name: 'Datasets',
					fields: [ {
						id: 'datasets',
						type: 'datasets',
						label: 'Datasets',
						required: true
					} ]
				}
			}
		};
	}

	static getViewTypes () {
		return [ {
			key: 'split',
			name: 'Split View (Wide)',
		} ]
	}

	static getStylingVariants () {
		return [ {
			key: 'no_items',
			name: 'No Items'
		} ];
	}

	static calculateStylingVariant ( props ) {
		if ( !props.provided.devices || !props.provided.devices.length ) {
			return 'no_items';
		}

		return 'default';
	}

	constructor ( props ) {
		super( props );

		this.openPopup = this.openPopup.bind( this );
	}

	render () {
		const {
			options
		} = this.props;

		let {
			widgetSubtitle
		} = options;

		let blanketColor = options.stylable.blanketColor;

		let subtitleMarkup = null;
		if ( widgetSubtitle ) {
			subtitleMarkup = (
				<p className="widget-subtitle">
					<span className="subtitle-blanket" style={ {
						backgroundColor: blanketColor
					} }>
						{ widgetSubtitle }
					</span>
				</p>
			);
		}

		let isLoadingRelatedData = false;
		let haveEncounteredDataError = false;
		let filteredDevices;

		if ( this.props.provided ) {
			filteredDevices = this.props.provided.devices;
			isLoadingRelatedData = this.props.provided.isLoading;

			if ( this.props.provided.errors && this.props.provided.errors.length ) {
				haveEncounteredDataError = true;
			}
		}

		let devicesCount = filteredDevices.length;
		let widgetCssClasses = 'sensor-overview-widget';
		let devicesCountCssClasses = 'devices-count';

		if ( isLoadingRelatedData ) {
			devicesCountCssClasses += ' loading';
			devicesCount = '';
		}

		if ( haveEncounteredDataError ) {
			widgetCssClasses += ' error';
		}

		return (
			<div className={ widgetCssClasses } onClick={ this.openPopup } >
				<div className="sensors-overview-counts-container widget-content">
					<span className={ devicesCountCssClasses }>
						{ devicesCount }
					</span>
					{ subtitleMarkup }
				</div>
			</div>
		);
	}

	openPopup () {
		this.props.api.openPopup( DevicesOverviewPopup, {
			api: this.props.api,
			deviceOptions: this.props.options.deviceOptions,
			propChartOptions: this.props.options.propChartOptions,
			latPath: this.props.options.latPath,
			lngPath: this.props.options.lngPath,
			devices: this.props.provided.devices,
			historyUnitType: this.props.options.historyUnitType,
			propertyPaths: {
				displayName: [],
				location: [],
				communicationTime: []
			}
		} );
	}

}

export default stylable( SensorOverview );
