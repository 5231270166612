// {
// 	"type": "GaugeWidget",
// 	"options": {
// 		"widgetTitle": "Current external temperature of device Test_1",
// 		"widgetId": "gauge-per-device",
// 		"datasets": [
// 				{
// 					"flowName": "prorail",
// 					"units": {
// 						"messages": "prorailMessagesMemoryRepository",
// 						"snapshot": "prorailSnapshot"
// 					},
// 					"filters": [
// 					{
// 						"type": "has_snapshot_property",
// 						"options": {
// 							"propertyPath": [
// 								"content",
// 								"extTemp"
// 							]
// 						}
// 					}
// 				]
// 			}
// 		],
//		"gaugeChart": {
//			"width": 400,
//			"color": {
//				"type": "gradient", // "solid"
// 				"filledValue": "linear-gradient( to right, #1abc9c 0%, #f1c40f 50%, #c0392b 100%)",
//				"unfilledValue": "#eee"
//			},
//			"data": {
//				"minValue": 44,
//				"maxValue": 49,
//				"unit": "°C"
//			}
//		},
// 		"selection": [
// 			{
// 				"type": "first_with_value",
// 				"path": [
// 					"id"
// 				],
// 				"value": "Test_1"
// 			}
// 		],
// 		"visualization": {
// 			"type": "current",
// 			"propertyPath": [
// 				"content",
// 				"extTemp"
// 			]
// 		}
//	},
// 	"position": {
// 		"x": 8,
// 		"y": 0,
// 		"h": 5,
// 		"w": 4
// 	}
// }

import React, { Component } from 'react';

import stylable from './../../decorators/stylable.jsx';

import { getPropertyValueByPath } from './../../utils/devicesUtils.js';

import GaugeChart from './../../components/Charts/GaugeChart.jsx';

class GaugeWidget extends Component {
	componentWillMount () {
		this.prepareWidgetDevices( this.props );
	}

	componentWillReceiveProps ( nextProps ) {
		this.prepareWidgetDevices( nextProps );
	}

	render() {
		const { options } = this.props;
		let deviceValue;

		if( !this.isLoading ) {
			deviceValue = this.getDevicePropertyValue();
		}

		return (
			<div className="gauge-widget">
				<div className="widget-content">
					<GaugeChart
						isLoading={ this.isLoading }
						value={ deviceValue }
						data={ options.gaugeChart.data }
						width={ options.gaugeChart.width }
						color={ options.gaugeChart.color } />
				</div>
			</div>
		);
	}

	prepareWidgetDevices( newProps ) {
		if( newProps.provided ) {
			this.selectedDevice = newProps.provided.selectedDevice;
			this.isLoading = newProps.provided.isLoading;
			this.errors = newProps.provided.errors;
		}
	}

	getDevicePropertyValue() {
		let propertyValue;

		if( this.selectedDevice ) {
			propertyValue = getPropertyValueByPath( this.selectedDevice, [ 'snapshot', ...this.props.options.visualization.propertyPath ] );
		}

		if( !propertyValue && propertyValue !== 0 ) {
			propertyValue = undefined;
		}

		return propertyValue;
	}
}

export default stylable( GaugeWidget );
