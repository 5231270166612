// Libs
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import timedGridRerender from './../../decorators/timed-grid-rerender.jsx'

// Actions
import * as unitsActions from './../../actions/unitsActions.js';
import * as userActions from './../../actions/userActions.js';
import * as dashboardActions from './../../actions/popupActions.js';

import { getFilteredDevices } from './../../utils/datasets/filters.js';

import selectDevice from './../../utils/datasets/selection.js';

import { getMetaFromUserState } from './../../selectors/meta.js';
import { getDashboardStateFromUserState } from './../../selectors/dashboards.js';

// Widgets Loader
import DynamicWidgetsLoader from './../../middleware/dynamic-widgets-loader.js';

// Styling
import './../../css/react-grid-layout.styles.css';
import './../../css/react-resizable.styles.css';
// UI Components
import NoWidgetsConfiguredLayout from './../../components/NoWidgetsConfiguredLayout.jsx';
import WidgetWrap from '../../components/WidgetWrap/WidgetWrap.jsx';

import DashGridWrap from './../../components/DashGridWrap.jsx';

import { getWidgetsApi } from './widgetsProvidedApi.js';

import FetchWidgetsDevicesDebouncer from './FetchWidgetsDevicesDebouncer.js';

// Layout change subscribers
let dashboardLayoutSubscribers = {};

class DashGrid extends Component {
	constructor ( props ) {
		super( props );

		this.state = {
			focusedWidgetId: null
		}

		this.debouncer = new FetchWidgetsDevicesDebouncer( {
			fetchDevices: props.fetchDevices
		} );


		this.onDashboardRearrange = this.onDashboardRearrange.bind( this );
		this.onWidgetDelete = this.onWidgetDelete.bind( this );
		this.onWidgetEdit = this.onWidgetEdit.bind( this );

		this.widgetTypes = DynamicWidgetsLoader.getWidgetTypes();

		this.getMeta = this.getMeta.bind( this );
		this.saveMeta = this.saveMeta.bind( this );
	}

	componentWillMount () {
		this.debouncer.prepareWidgetsDevices( null, this.props );

	}
	componentWillReceiveProps ( nextProps ) {
		this.debouncer.prepareWidgetsDevices( this.props, nextProps );
	}

	// CANDO: Add a timer to handle the debounce API
	// It is needed if the debounced methods are called later, after the widgets are added.
	// On the other hand this won't be needed if it's only used in the DashGrid

	componentDidMount () {
		this.debouncer.processDebouncedApi();
	}

	componentDidUpdate () {
		this.debouncer.processDebouncedApi();
	}

	/**
	 * Get the updated widget configuration from the change in the layout
	 * @param  {Object} widget     The widget configuration
	 * @param  {Object} layoutItem The layout as provided from the ReactGrid
	 * @return {Object}            The updated widget configuration
	 */
	_getUpdatedWidget ( widget, layoutItem ) {
		let { x, y, w, h } = layoutItem;

		if ( x !== widget.position.x
			|| y !== widget.position.y
			|| w !== widget.position.w
			|| h !== widget.position.h ) {

			return {
				...widget,
				position: {
					x,
					y,
					h,
					w
				}
			}
		}

		return widget;
	}

	getMeta ( key ) {
		let { meta } = getMetaFromUserState( this.props.userState );

		if ( meta && meta[ key ] ) {
			return meta[ key ];
		}
	}

	saveMeta ( key, value ) {
		let { meta } = getMetaFromUserState( this.props.userState );
		let oldWidgetState;

		if ( meta && meta[ key ] ) {
			oldWidgetState = meta[ key ];
		}

		if ( oldWidgetState ) {
			this.props.updateUserMeta( key, value );
		}
		else {
			this.props.addUserMeta( key, JSON.stringify( value ) );
		}
	}

	onWidgetDelete ( deletedWidgetId ) {
		let widgets = this.props.widgets;

		let updatedWidgets = widgets.filter(
			( widget, index ) => ( deletedWidgetId !== widget.options.widgetId ) );

		this.props.onDashboardLayoutUpdate( updatedWidgets );
	}

	onWidgetEdit () {
		console.error( 'Not Implemented' );
		// TODO: Open the edit mode and pre-fill the widget data
	}

	/**
	 * Handle the Dashboard Rearrangement Event
	 * -> update each of the widget configuration with the new layout
	 * @param  {Object} layout The updated layout
	 */
	onDashboardRearrange ( layout ) {
		let widgets = this.props.widgets;

		let updatedWidgets = widgets.map( ( widget, index ) => {
			// The grid layout returns the layout in the same order as provided initially
			if ( layout[ index ].i === widget.options.widgetId ) {
				this._notifyDashboardSubscribers( widget.options.widgetId );

				return this._getUpdatedWidget( widget, layout[ index ] );
			}
			else {
				// However it is not in the docs so it might not always be the case...
				// So we have added an additional verification
				for ( let layoutItem of layout ) {
					if ( layoutItem.i === widget.options.widgetId ) {
						return this._getUpdatedWidget( widget, layoutItem );
					}
				}
			}
			// If the dashboard was rearranged but we miss a widget
			console.error( 'A widget went missing' + widget.options.widgetId );

			return null;
		} );

		this.props.onDashboardLayoutUpdate( updatedWidgets );
	}

	/**
	 * Build the grid layout from the widgets configuration
	 * @param  {Array} widgets      The widgets configuration
	 * @param  {Number} columnsCount The number of columns to be displayed
	 * @return {Object}              The layout in the ReactGrid format
	 */
	buildLayout ( widgets, columnsCount = 12 ) {
		let layout = widgets.map( ( widget ) => {
			let x = Math.ceil( ( widget.position.x / 12 ) * columnsCount );
			let w = Math.ceil( ( widget.position.w / 12 ) * columnsCount );

			if ( columnsCount === 6 ) {
				if ( w <= 2 ) {
					w = Math.ceil( columnsCount / 2 );
					if ( x !== 0 ) {
						x = columnsCount / 2;
					}
					// TODO: make the rearrangement smarter
				}
				else {
					w = columnsCount;
				}
			}
			else if ( columnsCount <= 3 ) {
				w = columnsCount;
			}

			return {
				i: widget.options.widgetId,
				x,
				y: widget.position.y,
				w,
				h: widget.position.h,
			}
		} );

		return layout;
	}



	/**
	 * Get the API provided to the widgets
	 * @return {Object}  The methods that can be called from the Widget
	 */
	getWidgetsApi () {
		let {
			unitsDevices,
			flows,
			unitsSchemas,
			snapshotsToHistoryUnits,
			openPopup,
			updatePopup
		} = this.props;

		let {
			getMeta,
			saveMeta,
		} = this;

		return getWidgetsApi( {
			flows,
			unitsDevices,
			unitsSchemas,
			snapshotsToHistoryUnits,
			getMeta,
			saveMeta,

			openPopup,
			updatePopup,
			dashboardLayoutSubscribers,

		} )
	}

	_notifyDashboardSubscribers ( widgetId ) {
		if ( dashboardLayoutSubscribers[ widgetId ] ) {
			dashboardLayoutSubscribers[ widgetId ]();
		}
	}

	/**
	 * Gets the ProvideData method
	 * -> The ProvideData directly loads data to the widget based on its configuration
	 * -> The data is available in this.props.provided
	 * @return {function( Object ): Object} The ProvideData function to be invoked: provide( widget )
	 */
	getProvideData () {
		let unitsDevices = this.props.unitsDevices;
		let allDevices = this.props.allDevices;

		/**
		 * ProvideData based on the specific widget configuration
		 * @param  {Object} widget  The widget configuration
		 * @return {Object}         The provided data for the widget
		 */
		const provide = ( widget ) => {
			let isLoading = false;
			let errors = [];
			let devices = [];
			let selectedDevice;

			let { options } = widget;
			let { datasets, selection } = options;

			// Set the loading & errors
			if ( datasets ) {
				for ( let datasetItem of datasets ) {
					let snapshotUnit = datasetItem.units.snapshot;

					if ( unitsDevices[ snapshotUnit ] ) {
						if ( unitsDevices[ snapshotUnit ].isSyncing ) {
							isLoading = true;
						}
						else if ( unitsDevices[ snapshotUnit ].error ) {
							errors.push( unitsDevices[ snapshotUnit ].error );
						}
					}
				}

				devices = getFilteredDevices( datasets, unitsDevices, allDevices )

				if ( devices && devices.length ) {
					if ( selection ) {
						selectedDevice = selectDevice( selection, devices );
					}
				}
			}

			// Prepare the result
			let result = {
				isLoading,
				errors
			};

			if ( selection ) {
				result.selectedDevice = selectedDevice;
			}
			else {
				result.devices = devices;
			}

			return result;
		};

		return provide;
	}

	/**
	 * Build the widgets markup
	 * @param  {Object} widgets              The widgets configuration
	 * @return {Array}                       The widgets markup
	 */
	buildMarkup ( widgets ) {
		let widgetTypes = this.widgetTypes;

		let widgetsApi = this.getWidgetsApi();
		let provide = this.getProvideData();

		let widgetIds = [];

		let { dashboards } = getDashboardStateFromUserState( this.props.userState );

		let defaultStyles = dashboards.mainStyles;
		let isDndEnabled = this.props.isDndEnabled;

		let markup = widgets.map( ( widget ) => {
			let widgetId = widget.options.widgetId;

			widgetIds.push( widgetId );

			return React.createElement( WidgetWrap, {
				key: widgetId,
				widgetId,
				isDndEnabled,
				onDelete: this.onWidgetDelete,
				onEdit: this.onWidgetEdit,
				isFocused: this.state.focusedWidgetId === widgetId,
				onClick: () => this.onWidgetClick( widgetId )
			},
				React.createElement( widgetTypes[ widget.type ], {
					options: widget.options,
					api: widgetsApi( widgetId ),
					provided: provide( widget ),
					defaultStyles: defaultStyles
				} )
			);
		} );

		if ( widgetIds.length !== [ ...new Set( widgetIds ) ].length ) {
			console.warn( 'One ore more duplicated widget ids: ' );
			console.warn( widgetIds );
		}

		return markup;
	}

	onWidgetClick = ( widgetId ) => {
		this.setState( {
			focusedWidgetId: widgetId
		} )
	}

	render () {
		let widgets = this.props.widgets;

		if ( widgets && widgets instanceof Array ) {
			let markup = this.buildMarkup( widgets );

			let layouts = {
				md: this.buildLayout( widgets ),
				sm: this.buildLayout( widgets, 6 ),
				xs: this.buildLayout( widgets, 3 )
			};

			return (
				<DashGridWrap
					isDndEnabled={ this.props.isDndEnabled }
					onDashboardRearrange={ this.onDashboardRearrange }
					layouts={ layouts } >
					{ markup }
				</DashGridWrap>
			);
		}
		else {
			return (
				<NoWidgetsConfiguredLayout />
			);
		}
	}
}

DashGrid.propTypes = {
	onDashboardLayoutUpdate: PropTypes.func,
	dashboardId: PropTypes.string,
	widgets: PropTypes.array,
	// Redux
	isDndEnabled: PropTypes.bool,
	unitsDevices: PropTypes.object,
	allDevices: PropTypes.object,
	flows: PropTypes.object,
	userState: PropTypes.object,
	snapshotsToHistoryUnits: PropTypes.object,
	unitsSchemas: PropTypes.object
};

function mapStateToProps ( state ) {
	return {
		isDndEnabled: state.activeDashboard.isDndEnabled,
		unitsDevices: state.entities.unitsDevicesRelations.unitsDevices,
		allDevices: state.entities.devices.devices,
		flows: state.entities.flows.flows,
		userState: state.userState,
		snapshotsToHistoryUnits: state.entities.snapshotToHistoryUnitsRelations.snapshotsToHistoryUnits,
		unitsSchemas: state.entities.unitsSchemas.unitsSchemas
	};
}

function mapDispatchToProps ( dispatch ) {
	return {
		fetchDevices: ( flowName, snapshotUnitId, messagesUnitsIds, capabilities, historyUnit ) => {
			dispatch( unitsActions.fetchDevices( flowName, snapshotUnitId, messagesUnitsIds, capabilities, historyUnit ) );
		},
		openPopup: ( PopupComponent, popupProps ) => {
			dispatch( dashboardActions.openPopup( PopupComponent, popupProps ) );
		},
		updatePopup: ( PopupComponent, popupProps ) => {
			dispatch( dashboardActions.updatePopup( PopupComponent, popupProps ) );
		},
		updateUserMeta: ( key, value ) => {
			dispatch( userActions.updateUserMeta( key, value ) );
		},
		addUserMeta: ( key, value ) => {
			dispatch( userActions.addUserMeta( key, value ) );
		}
	};
}

export default connect( mapStateToProps, mapDispatchToProps )( timedGridRerender( DashGrid ) );
export const theComponent = DashGrid;
