export function getMetaFromUserState( userState ) {
	let { user, isSyncing, error } = userState;

	let meta = {};

	if( user && user.meta ) {
		meta = user.meta;
	}

	return {
		meta,
		isSyncing,
		error
	}
};
