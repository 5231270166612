import React, { Component } from 'react';

import { getPropertyValueByPath } from './../../utils/devicesUtils.js';

class DevicesOverviewPropertiesList extends Component {
	constructor ( props ) {
		super( props );

		this.onPropertyClicked = this.onPropertyClicked.bind( this );

		this.state = {
			expandedProperty: null
		}

	}

	render () {
		const selectedDevice = this.props.selectedDevice;

		if ( !selectedDevice ) {
			return (
				<div className="sow-popup-properties-container empty">
				</div>
			);
		}

		const propertiesBasePath = this.props.propertiesBasePath;

		const properties = getPropertyValueByPath( selectedDevice, propertiesBasePath );

		let tableInnerMarkup = [];

		for ( let propName in properties ) {
			if ( properties[ propName ] !== null ) {
				if ( typeof properties[ propName ] === 'object' ) {
					let rowClassName = 'property-table-row complex-property-row';
					let propertyValue = properties[ propName ];

					if ( this.props.selectedPropertyName === propName ) {
						rowClassName += ' active';
					}

					if ( propertyValue instanceof Array ) {
						propertyValue = '[...]';
					}
					else {
						propertyValue = '{...}';
					}

					tableInnerMarkup.push(
						(
							<tr
								className={ rowClassName }
								key={ propName }
								onClick={ () => { this.onPropertyClicked( propName ) } } >

								<td className="popup-property-name">{ propName }</td>
								<td className="popup-property-value">{ propertyValue }</td>
							</tr>
						)
					);
				}
				else {
					let rowClassName = 'property-table-row';

					if ( this.props.selectedPropertyName === propName ) {
						rowClassName += ' active';
					}

					let propertyValueUnit = this.props.getUnitForProperty( propName );
					let readablePropertyName = this.props.getReadableNameForProperty( propName );

					tableInnerMarkup.push(
						(
							<tr className={ rowClassName } key={ propName } onClick={ () => { this.onPropertyClicked( propName ) } }>
								<td className="popup-property-name" title={ propName } >{ readablePropertyName }</td>
								<td className="popup-property-value">{ properties[ propName ].toString() } { propertyValueUnit ? propertyValueUnit : '' }</td>
							</tr>
						)
					);
				}
			}
		}

		return (
			<div className="sow-popup-properties-container">
				<table className="popup-property-table">
					<tbody>
						{ tableInnerMarkup }
					</tbody>
				</table>
			</div>
		)
	}

	onPropertyClicked ( propertyName ) {
		this.props.onPropertyClicked( propertyName );
	}
}

export default DevicesOverviewPropertiesList;
