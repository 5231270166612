import React, { Component } from 'react';

import { calculateContrastedColor } from '../utils/devicesUtils.js';

// TODO: figure out how to list all the possible styling variants
export default function stylable ( ProvidedComponent ) {
	class StylableWrap extends Component {
		static getWidgetConfiguration () {
			if ( typeof ProvidedComponent.getWidgetConfiguration === 'function' ) {
				let widgetConfiguration = ProvidedComponent.getWidgetConfiguration();

				if ( widgetConfiguration ) {
					let { fieldsets } = widgetConfiguration;

					if ( !fieldsets ) {
						fieldsets = {};
					}

					if ( !fieldsets.initial ) {
						fieldsets.initial = {
							name: 'Initial',
							fields: []
						};
					}

					fieldsets.initial.fields.unshift( {
						id: 'widgetTitle',
						type: 'text',
						label: 'Title',
						required: true
					} );

					fieldsets.stylable = {
						name: 'Styling',
						fields: [ {
							id: 'stylable',
							type: 'stylable',
							label: 'Styling'
						} ]
					}

					if ( typeof ProvidedComponent.getStylingVariants === 'function' ) {
						fieldsets.stylable.fields[ 0 ].variants = ProvidedComponent.getStylingVariants();
					}

					if ( typeof ProvidedComponent.getViewTypes === 'function' ) {
						fieldsets.stylable.fields[ 0 ].viewTypes = ProvidedComponent.getViewTypes();
					}

					return {
						...widgetConfiguration,
						fieldsets
					}
				}
			}

			return null;
		}

		render () {
			let { options, ...restProps } = this.props;
			let { stylable, ...restOptions } = options;
			let defaultStyles = this.props.defaultStyles;
			let defaultWidgetsBackgroundColor;

			if ( defaultStyles ) {
				defaultWidgetsBackgroundColor = this.props.defaultStyles.defaultWidgetsBackgroundColor;
			}

			// set in main dashboard styles

			if ( !stylable ) {
				stylable = {};
			}

			let { color, backgroundImage, icon } = stylable;

			if ( !color ) {
				color = defaultWidgetsBackgroundColor ? defaultWidgetsBackgroundColor : '#ffffff';
			}

			let variant;

			if ( typeof ProvidedComponent.calculateStylingVariant === 'function' ) {
				variant = ProvidedComponent.calculateStylingVariant( this.props );
			}

			if ( variant ) {
				if ( stylable.variants && stylable.variants[ variant ] ) {
					let selectedVariant = stylable.variants[ variant ];

					if ( selectedVariant.color ) {
						color = selectedVariant.color;
					}

					if ( selectedVariant.backgroundImage ) {
						console.warn( 'Background image is deprecated in favour of icon' );
						backgroundImage = selectedVariant.backgroundImage;
					}

					if ( selectedVariant.icon ) {
						icon = selectedVariant.icon;
					}
				}
			}

			let textColor = calculateContrastedColor( color );
			let blanketColor = this._getRgbaFromHexColor( color, 0.5 );
			let textColorClass = ( textColor === '#ffffff' ? 'white-text' : 'black-text' );

			if ( icon ) {
				let processedIcon = `/icons/${ icon }_${ ( textColor === '#ffffff' ) ? 'white' : 'black' }.svg`;
				backgroundImage = processedIcon;
			}

			let stylableAfterProps = {
				color,
				backgroundImage,
				textColor,
				blanketColor,
				textColorClass
			}

			let widgetTitleMarkup = null;

			if ( options.widgetTitle ) {
				widgetTitleMarkup = <h3 className="widget-title">
					{ options.widgetTitle }
				</h3>
			}

			let { widgetView } = stylable;

			if ( !widgetView && restOptions.widgetView ) {
				widgetView = restOptions.widgetView;
				console.warn( 'Widget View in the options is deprecated. Move it to the stylable configurations' );
			}

			let widgetCssClasses = 'styled-widget';
			widgetCssClasses += ' ' + textColorClass;
			widgetCssClasses += ' ' + ( widgetView ? widgetView : 'default' ) + '-view';
			widgetCssClasses += ' ' + ( widgetTitleMarkup ? '' : 'no-heading' );
			return (
				<div
					style={ {
						background: color,
						color: textColor,
					} }
					className={ widgetCssClasses } >
					<div className="widget-header">
						{ widgetTitleMarkup }
					</div>

					<ProvidedComponent { ...restProps } options={ { ...restOptions, stylable: stylableAfterProps } } />

					{ backgroundImage ?
						<div className="widget-background-icon" style={ {
							backgroundImage: 'url(' + backgroundImage + ')'
						} } >
						</div> : '' }
				</div>
			);
		}

		_getRgbaFromHexColor ( hexCode, opacity ) {
			let _getRgbFromHex = function ( hexCode = '#ffffff' ) {
				hexCode = hexCode.replace( '#', '' );

				return {
					r: parseInt( hexCode.substr( 0, 2 ), 16 ),
					g: parseInt( hexCode.substr( 2, 2 ), 16 ),
					b: parseInt( hexCode.substr( 4, 2 ), 16 )
				};
			};

			let rgb = _getRgbFromHex( hexCode );

			return 'rgba( ' + rgb.r + ', ' + rgb.g + ', ' + rgb.b + ', ' + opacity + ' )';
		}
	}

	return StylableWrap;
}
