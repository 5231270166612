import React, { PureComponent } from 'react';

import { connect } from 'react-redux';

import memoize from 'memoize-one';

import * as notificationsActions from './../actions/notificationsActions.js';

import HeaderNotificationListItem from './../components/Notifications/HeaderNotificationListItem.jsx';
import NotificationsFilters from './../components/Notifications/NotificationsFilters.jsx';
import NotificationsSelectionInfoBox from './../components/Notifications/NotificationsSelectionInfoBox.jsx';
import HeaderNotificationsListContainer from './../components/Notifications/HeaderNotificationsListContainer.jsx';
import SelectionActions from './../components/Notifications/SelectionActions.jsx';

import NoNotificationsMessage from './../components/Notifications/NoNotificationsMessage.jsx';

import notificationIcon from './../img/notification-white.svg';

import { hasItemsOnPage } from './../utils/paging.js';

// How many notifications at once
const NOTIFICATIONS_PER_PAGE = 8;

const getNewNotifications = memoize( ( notifications = [] ) => {
	return notifications.filter( notification => !notification.isSeen );
} );

const filterNotificationsByType = memoize( ( notifications, typeFilter ) => {
	if ( typeFilter.COMMUNICATION
		&& typeFilter.BOUND
		&& typeFilter.BATTERY
		&& typeFilter.OTHER ) {
		return notifications;
	}

	return notifications.filter( notification =>
		typeFilter[ notification.notificationType.toUpperCase() ] === true );
} );

class HeaderNotificationsList extends PureComponent {

	constructor ( props ) {
		super( props )

		let typeFilter = this.getInitialTypeFilterState();

		this.state = {
			page: 0,
			viewStateFilter: 'ALL',
			typeFilter,
			selectedNotifications: {},
			expandedNotifications: {},
			selectAll: false,
			selectPage: false,
			confirmBulkDelete: false
		};

		this.onNotificationItemClick = this.onNotificationItemClick.bind( this );
		this.onNotificationItemSelect = this.onNotificationItemSelect.bind( this );
		this.onDeleteNotificationClick = this.onDeleteNotificationClick.bind( this );
		this.onToggleNotificationVisibility = this.onToggleNotificationVisibility.bind( this );

		this.toggleTypeFilter = this.toggleTypeFilter.bind( this );

		this.selectAllNotificationsOnPage = this.selectAllNotificationsOnPage.bind( this );
		this.selectAllFilteredNotifications = this.selectAllFilteredNotifications.bind( this );

		this.onSelectPageCheckboxClick = this.onSelectPageCheckboxClick.bind( this );
		this.onSelectAllCheckboxClick = this.onSelectAllCheckboxClick.bind( this );

		this.onDeleteSelectedClick = this.onDeleteSelectedClick.bind( this );
		this.onMarkSelectedSeenClick = this.onMarkSelectedSeenClick.bind( this );

		this.onAbortBulkDeleteClick = this.onAbortBulkDeleteClick.bind( this );
		this.onConfirmBulkDeleteClick = this.onConfirmBulkDeleteClick.bind( this );
	}

	componentDidMount () {
		this.props.fetchNotifications();
	}

	getInitialTypeFilterState () {
		return {
			COMMUNICATION: true,
			BOUND: true,
			BATTERY: true,
			OTHER: true
		};
	}

	onNotificationItemClick ( notificationId ) {
		const shouldExpand = !this.state.expandedNotifications[ notificationId ];

		this.setState( {
			expandedNotifications: {
				...this.state.expandedNotifications,
				[ notificationId ]: shouldExpand
			}
		} );
	}

	onNotificationItemSelect ( notificationId ) {
		const shouldSelect = !this.state.selectedNotifications[ notificationId ];

		let updatedState = {
			selectedNotifications: {
				...this.state.selectedNotifications,
				[ notificationId ]: shouldSelect
			}
		}

		if ( !shouldSelect ) {
			updatedState.selectAll = false;
			updatedState.selectPage = false;
		}

		this.setState( updatedState );
	}

	onDeleteNotificationClick ( notification ) {
		this.props.deleteNotifications( [ notification.notificationId ] );

		// Move a page back ( if there is no page after )
		let { page } = this.state;
		let filteredNotifications = this.getFilteredNotifications();
		let hasItemsOnCurrentPage = hasItemsOnPage( page, NOTIFICATIONS_PER_PAGE, ( filteredNotifications.length - 1 ) )
		if ( !hasItemsOnCurrentPage && page !== 0 ) {
			page = page - 1;
		}

		// Unselect the deleted item
		if ( this.state.selectedNotifications[ notification.notificationId ] ) {
			this.setState( {
				page,
				selectedNotifications: {
					...this.state.selectedNotifications,
					[ notification.notificationId ]: false
				}
			} )
		}
		else {
			this.setState( {
				page
			} )
		}
	}

	onDeleteSelectedClick () {
		this.setState( {
			confirmBulkDelete: true
		} );
	}

	onConfirmBulkDeleteClick () {

		let selectedNotificationsIds = this.getSelectedNotificationsIds();

		if ( selectedNotificationsIds.length === this.props.notifications.notifications.length ) {
			// Delete all older than now:
			this.props.deleteNotificationsBefore( new Date() );
		}
		else {
			this.props.deleteNotifications( this.getSelectedNotificationsIds() )
		}

		// Move a page back ( if there is no page after )
		let { page } = this.state;
		let filteredNotifications = this.getFilteredNotifications();
		let hasItemsOnCurrentPage = hasItemsOnPage( page, NOTIFICATIONS_PER_PAGE, ( filteredNotifications.length - selectedNotificationsIds.length ) )
		if ( !hasItemsOnCurrentPage && page !== 0 ) {
			page = page - 1;
		}

		this.setState( {
			page,
			selectedNotifications: {},
			selectAll: false,
			selectPage: false,
			confirmBulkDelete: false
		} );
	}

	onAbortBulkDeleteClick () {
		this.setState( {
			confirmBulkDelete: false
		} )
	}

	onMarkSelectedSeenClick () {
		let selectedNotificationsIds = this.getSelectedNotificationsIds();

		this.props.markNotificationsAsSeen( selectedNotificationsIds );

		this.setState( {
			selectedNotifications: {},
			selectAll: false,
			selectPage: false,
		} )
	}

	getSelectedNotificationsIds () {
		let selectedNotificationsIds = [];

		for ( let notificationId in this.state.selectedNotifications ) {
			if ( this.state.selectedNotifications[ notificationId ] ) {
				selectedNotificationsIds.push( notificationId );
			}
		}

		return selectedNotificationsIds;
	}

	onToggleNotificationVisibility () {
		if ( this.props.isOpen ) {
			this.props.close();
		}
		else {
			this.props.open();
		}
	}

	markCurrentNotificationsAsSeen () {
		let filteredNotifications = this.props.notifications.notifications;

		if ( this.state.viewStateFilter === 'NEW' ) {
			filteredNotifications = getNewNotifications( filteredNotifications );
		}

		filteredNotifications = filterNotificationsByType( filteredNotifications, this.state.typeFilter );

		let currentPageNotifications = this.getNotificationsForPage( filteredNotifications, this.state.page );

		let unseenNotifications = currentPageNotifications.filter( notification => !notification.isSeen );

		if ( unseenNotifications.length ) {
			let unseenNotificationsIds = unseenNotifications.map( notification => notification.notificationId );
			this.props.markNotificationsAsSeen( unseenNotificationsIds );
		}
	}

	getNotificationsForPage ( notifications, page ) {
		let pageNotifications = [],
			// From the current page with notifications we calculate the start/end index
			pageStartIndex = page * NOTIFICATIONS_PER_PAGE,
			nextPageStartIndex = pageStartIndex + NOTIFICATIONS_PER_PAGE,
			filteredNotifications = notifications;

		if ( !filteredNotifications ) {
			filteredNotifications = [];
		}

		for ( let notificationIndex = pageStartIndex; notificationIndex < nextPageStartIndex;
			notificationIndex++ ) {

			// If we've reached the end of the notifications
			if ( notificationIndex === filteredNotifications.length ) {
				break;
			}

			pageNotifications.push( filteredNotifications[ notificationIndex ] );
		}

		return pageNotifications;
	}

	goToPage ( page ) {
		this.setState( {
			page
		} )
	}

	getSelectedNotificationsCount () {
		let selectedFiltered = Object.keys( this.state.selectedNotifications )
			.filter( key =>
				this.state.selectedNotifications[ key ] );

		return selectedFiltered.length;
	}

	onSelectPageCheckboxClick () {
		if ( this.state.selectPage ) {
			this.setState( {
				selectPage: false,
				selectAll: false,
				selectedNotifications: {}
			} );
		}
		else {
			this.selectAllNotificationsOnPage();
		}
	}

	selectAllNotificationsOnPage () {
		let filteredNotifications = this.props.notifications.notifications;

		if ( this.state.viewStateFilter === 'NEW' ) {
			filteredNotifications = getNewNotifications( filteredNotifications );
		}

		filteredNotifications = filterNotificationsByType( filteredNotifications, this.state.typeFilter );

		let currentPageNotifications = this.getNotificationsForPage( filteredNotifications, this.state.page );
		let selectedNotifications = {};

		currentPageNotifications.forEach( notification => {
			selectedNotifications[ notification.notificationId ] = true;
		} );

		this.setState( {
			selectedNotifications,
			selectPage: true,
			selectAll: false
		} );
	}

	onSelectAllCheckboxClick () {
		if ( this.state.selectAll ) {
			this.setState( {
				selectPage: false,
				selectAll: false,
				selectedNotifications: {}
			} );
		}
		else {
			this.selectAllFilteredNotifications();
		}
	}

	selectAllFilteredNotifications () {
		let filteredNotifications = this.getFilteredNotifications();

		let selectedNotifications = {};

		filteredNotifications.forEach( notification => {
			selectedNotifications[ notification.notificationId ] = true;
		} );

		this.setState( {
			selectedNotifications,
			selectAll: true,
			selectPage: false
		} )
	}

	/**
	 * Toggle type filter by its name
	 *
	 * @param {String} currentTypeFilter
	 */
	toggleTypeFilter ( currentTypeFilter ) {
		let newTypeFilterState;

		if ( this.state.typeFilter.COMMUNICATION
			&& this.state.typeFilter.BOUND
			&& this.state.typeFilter.BATTERY
			&& this.state.typeFilter.OTHER ) {
			newTypeFilterState = {
				[ currentTypeFilter ]: true
			};
		}
		else {
			newTypeFilterState = {
				...this.state.typeFilter,
				[ currentTypeFilter ]: !this.state.typeFilter[ currentTypeFilter ]
			}
		}

		if ( !newTypeFilterState.COMMUNICATION
			&& !newTypeFilterState.BOUND
			&& !newTypeFilterState.BATTERY
			&& !newTypeFilterState.OTHER ) {
			newTypeFilterState = this.getInitialTypeFilterState();
		}

		this.setState( {
			typeFilter: newTypeFilterState,
			page: 0
		} )
	}

	getFilteredNotifications () {
		let notificationsStore = this.props.notifications;
		let filteredNotifications = [];

		if ( notificationsStore ) {
			filteredNotifications = notificationsStore.notifications;

			if ( this.state.viewStateFilter === 'NEW' ) {
				filteredNotifications = getNewNotifications( filteredNotifications );;
			}

			filteredNotifications = filterNotificationsByType( filteredNotifications, this.state.typeFilter );
		}

		return filteredNotifications;
	}

	render () {
		let notificationsState = this.props.notifications,
			newNotificationsCount = 0,
			notificationsList = [],
			isVisible = this.props.isOpen,
			notificationsButtonClass = 'btn-nostyle',
			filteredNotifications,
			previousLink,
			previousLinkCssClass = 'btn previous-notifications',
			previousLinkClickHandler,
			nextLink,
			nextLinkCssClass = 'btn next-notifications',
			nextLinkClickHandler,
			notificationId,
			selected,
			expanded;


		// TODO: replace with getFilteredNotifications()
		if ( notificationsState.notifications ) {
			// If filter is NEW, we show unseen notifications only
			// if it is ALL, we show all
			filteredNotifications = this.props.notifications.notifications;

			let newNotifications = getNewNotifications( filteredNotifications );
			if ( this.state.viewStateFilter === 'NEW' ) {
				filteredNotifications = newNotifications;
			}

			filteredNotifications = filterNotificationsByType( filteredNotifications, this.state.typeFilter );

			newNotificationsCount = newNotifications.length;

			let pageNotifications = this.getNotificationsForPage( filteredNotifications, this.state.page );

			// If the page is not the initial one, show the back link
			if ( !this.state.page ) {
				previousLinkCssClass += ' disabled';
			}
			else {
				previousLinkClickHandler = ( () => this.goToPage( this.state.page - 1 ) );
			}

			previousLink = ( <button type="button" className={ previousLinkCssClass } onClick={ previousLinkClickHandler } ></button> );

			// If the page is not the last one, show the next link
			if ( ( this.state.page + 1 ) * NOTIFICATIONS_PER_PAGE >= filteredNotifications.length ) {
				nextLinkCssClass += ' disabled';
			}
			else {
				nextLinkClickHandler = ( () => this.goToPage( this.state.page + 1 ) );
			}
			nextLink = ( <button type="button" className={ nextLinkCssClass } onClick={ nextLinkClickHandler } ></button> );


			if ( pageNotifications.length ) {
				notificationsList = pageNotifications.map( notification => {
					notificationId = notification.notificationId;
					selected = ( this.state.selectedNotifications[ notificationId ] ? true : false );
					expanded = ( this.state.expandedNotifications[ notificationId ] ? true : false );

					return (
						<HeaderNotificationListItem
							key={ notificationId }
							selected={ selected }
							expanded={ expanded }
							notification={ notification }
							onNotificationItemClick={ this.onNotificationItemClick }
							onNotificationItemSelect={ this.onNotificationItemSelect }
							onDeleteNotificationClick={ this.onDeleteNotificationClick } />
					);
				} );

			}
			else {
				notificationsList = ( [ <NoNotificationsMessage key="-42" /> ] );
			}
		}

		if ( isVisible ) {
			notificationsButtonClass += ' active';
		}

		let newNotificationsIndicator;

		if ( newNotificationsCount ) {
			newNotificationsIndicator = (
				<span className="notifications-count">{ newNotificationsCount }</span>
			);
		}

		return (
			<HeaderNotificationsListContainer
				onToggleNotificationVisibility={ this.onToggleNotificationVisibility }
				notificationsButtonClass={ notificationsButtonClass }
				notificationIcon={ notificationIcon }
				imageDescription={ 'See Notifications' }
				newNotificationsIndicator={ newNotificationsIndicator }
				isVisible={ isVisible }
				isSyncing={ notificationsState.isSyncing }>

				<div className="notifications-list-actions-filters">
					<NotificationsFilters
						toggleViewStateFilter={ ( newViewStateFilter ) => this.setState( {
							viewStateFilter: newViewStateFilter,
							selectPage: false,
							selectAll: false,
							selectedNotifications: {},
							page: 0
						} ) }
						toggleTypeFilter={ this.toggleTypeFilter }
						viewStateFilter={ this.state.viewStateFilter }
						typeFilter={ this.state.typeFilter } />
					<NotificationsSelectionInfoBox
						selectedNotificationsCount={ this.getSelectedNotificationsCount() }
						onMarkSelectedAsSeen={ this.onMarkSelectedSeenClick }
						onDeleteSelected={ this.onDeleteSelectedClick }
						showConfirmDelete={ this.state.confirmBulkDelete }
						onConfirmDelete={ this.onConfirmBulkDeleteClick }
						onAbortDelete={ this.onAbortBulkDeleteClick }
					/>
					<div className="notifications-list-tools">
						<SelectionActions
							allSelected={ this.state.selectAll }
							pageSelected={ this.state.selectPage }
							onSelectAllCheckboxClick={ this.onSelectAllCheckboxClick }
							onSelectPageCheckboxClick={ this.onSelectPageCheckboxClick }
							isSyncing={ notificationsState.isSyncing } />
						<div className="notifications-pagination">
							{ previousLink }
							{ nextLink }
						</div>
					</div>
					<div className="notifications-list">
						{ notificationsList }
					</div>
				</div>
			</HeaderNotificationsListContainer>
		);
	}
}

function mapStateToProps ( state, ownProps ) {
	return {
		notifications: state.notifications,
	};
}


function mapDispatchToProps ( dispatch ) {
	return {
		fetchNotifications: () => {
			dispatch( notificationsActions.fetchNotifications() );
		},
		markNotificationsAsSeen: ( notificationsIds ) => {
			dispatch( notificationsActions.markNotificationsAsSeen( notificationsIds ) )
		},
		deleteNotifications: ( notificationsIds ) => {
			dispatch( notificationsActions.deleteNotifications( notificationsIds ) );
		},
		deleteNotificationsBefore: ( timeSet ) => {
			dispatch( notificationsActions.deleteNotificationsBefore( timeSet ) );
		}
	}
}

export default connect( mapStateToProps, mapDispatchToProps )( HeaderNotificationsList );
