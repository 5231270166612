import {
	OPEN_POPUP,
	UPDATE_POPUP,
	CLOSE_POPUP,
} from './../actions/popupActions';

export function popupReducer ( state = {
	popup: null,
	popupProps: null
}, action ) {
	switch ( action.type ) {
		case OPEN_POPUP:
			return {
				...state,
				popup: action.popupClass,
				popupProps: action.popupProps
			}
		case UPDATE_POPUP:
			return {
				...state,
				popup: action.popupClass,
				popupProps: action.popupProps
			}
		case CLOSE_POPUP:
			return {
				...state,
				popup: null,
				popupProps: null
			}
		default:
			return state
	}
}
