// {
// 	"type": "BinaryTimelineWidget",
// 	"options": {
// 		"widgetTitle": "Timeline",
// 		"widgetId": "timeline-widget",
// 		"propertyPath": [
// 			"content",
// 			"loLoTemp"
// 		],
// 		"timelineElements": [
//            {
//                "value": 20, // or true, or open, or up
//                "condition": "<", // optional
//                "label": "low temp", //optional
//                "color": "#d32d41"
//            },
//            {
//                "value": 20, // or false, or closed, or down
//                "condition": ">=", // optional
//                "label": "high temp", //optional
//                "color": "#4a85b0"
//            }
//        ],
// 		"maxDatesInterval": {
// 			"unit": "days",
// 			"size": 7
// 		},
// 		"axisSeparatorsCount": 10,
// 		"datasets": [
// 			{
// 				"flowName": "prorail",
// 				"units": {
// 					"messages": "prorailMessageMemoryRepository",
// 					"snapshot": "prorailSnapshot"
// 				},
// 				"filters": [
// 					{
// 						"type": "has_snapshot_property",
// 						"propertyPath": [
// 							"content",
// 							"loLoTemp"
// 						]
// 					}
// 				]
// 			}
// 		]
// 	},
// 	"position": {
// 		"x": 0,
// 		"y": 0,
// 		"h": 10,
// 		"w": 6
// 	}
// }

import React, { Component } from 'react';

import Multiselect from '../../components/ReactWidgets/Multiselect';

import * as moment from 'moment';

import stylable from './../../decorators/stylable.jsx';
import { calculateContrastedColor, isValueSatisfyingCondition, isNully } from '../../utils/devicesUtils.js';

import RangeDateTimePickers from '../../components/DateTimePickers/RangeDateTimePickers';

import {
	getPropertyValueByPath
} from './../../utils/devicesUtils.js';

class BinaryTimelineWidget extends Component {
	constructor ( props ) {
		// props are super!
		super( props );

		this.state = {
			selectedDevices: [],
			devicesHistory: {},
			isHistoryLoading: false
		}

		this.defaultDateTimeFormat = 'DD-MM-YYYY HH:mm';
	}

	componentWillMount () {
		this.prepareWidgetDevices( this.props );
		this.setupDefaults( this.props );

		this.fetchWidgetHistoryDataIfNeeded( this.props, this.state, null );
	}

	shouldComponentUpdate ( newProps, newState ) {
		if ( newState.selectedDevices.length !== this.state.selectedDevices.length ||
			newState.fromDate !== this.state.fromDate ||
			newState.toDate !== this.state.toDate ||
			newProps.provided.devices.length !== this.props.provided.devices.length ||
			newState.devicesHistory !== this.state.devicesHistory ) {

			return true;
		}

		return false;
	}

	componentWillUpdate ( newProps, newState ) {
		this.fetchWidgetHistoryDataIfNeeded( newProps, newState, this.state );
	}

	componentWillReceiveProps ( nextProps ) {
		this.prepareWidgetDevices( nextProps );
		this.setupDefaults( nextProps );
	}

	_dateConfigurationToMoment ( dateConfiguration, isToDate ) {
		let result;

		if ( dateConfiguration ) {
			let { type, offset, value } = dateConfiguration;

			if ( type === 'relative_to_now' ) {
				result = moment().subtract( offset.amount, offset.unit );
			}
			else {
				result = moment( value );
			}
		}
		else {
			if ( isToDate ) {
				result = moment();
			}
			else {
				result = moment().subtract( 3, 'hours' );
			}
		}

		return result;
	}

	setupDefaults ( props ) {
		let savedWidgetState = this.props.api.getWidgetState(),
			selectedDevicesNames = savedWidgetState ? savedWidgetState.selectedDevicesNames : null,
			selectedDevices = [],
			widgetDevices = this.widgetDevices;

		if ( selectedDevicesNames && widgetDevices ) {
			for ( let i in selectedDevicesNames ) {
				for ( let j in widgetDevices ) {
					if ( widgetDevices[ j ].name === selectedDevicesNames[ i ] ) {
						selectedDevices.push( widgetDevices[ j ] );

						break;
					}
				}
			}

			this.setState( {
				selectedDevices
			} );
		}

		if ( !this.state.areDatesDirty ) {
			let { fromDate, toDate } = this.state;

			if ( !fromDate && !toDate ) {
				let { defaultFromDate, defaultToDate } = props.options

				let newFromDate = this._dateConfigurationToMoment( defaultFromDate );

				if ( newFromDate ) {
					this.setState( {
						fromDate: newFromDate
					} );
				}

				let newToDate = this._dateConfigurationToMoment( defaultToDate, true );

				if ( newToDate ) {
					this.setState( {
						toDate: newToDate
					} );
				}
			}
		}
	}

	prepareWidgetDevices ( newProps ) {
		let monitoredDevices;

		if ( newProps.provided.devices ) {
			monitoredDevices = newProps.provided.devices;
		}

		let widgetDevices = [];

		if ( monitoredDevices ) {
			monitoredDevices.forEach( ( device ) => {
				widgetDevices.push( {
					name: device.snapshot.id,
					fullDevice: device
				} );
			} );
		}

		this.widgetDevices = widgetDevices;
	}

	_prepareHistoryData ( history ) {
		if ( !history ) {
			return [];
		}

		let historyEl;
		let value;

		let timelineData = [];
		let timelineEl = {};

		for ( let i in history ) {
			historyEl = history[ i ];

			value = getPropertyValueByPath( historyEl, this.props.options.propertyPath );

			// if there is no such property
			value = ( typeof value !== 'undefined' ) ? value : 'no data';

			// eslint-disable-next-line
			if ( i == 0 ) {
				// no data state
				timelineEl.fromDate = moment( this.state.fromDate );
				timelineEl.toDate = moment( historyEl.timeSet );
				timelineEl.value = 'no data';
				timelineData.push( timelineEl );
				timelineEl = {};

				timelineEl.fromDate = moment( historyEl.timeSet );
				timelineEl.value = value;

				// eslint-disable-next-line
				if ( i == history.length - 1 ) {
					timelineEl.toDate = moment( this.state.toDate );

					timelineData.push( timelineEl );
					timelineEl = {};
				}
			}
			else {
				// eslint-disable-next-line
				if ( i == history.length - 1 ) {
					timelineEl.toDate = moment( this.state.toDate );

					timelineData.push( timelineEl );
					timelineEl = {};
				}
				else {
					// eslint-disable-next-line
					if ( timelineEl.value != value ) {
						timelineEl.toDate = moment( historyEl.timeSet );

						timelineData.push( timelineEl );
						timelineEl = {};

						timelineEl.fromDate = moment( historyEl.timeSet );
						timelineEl.value = value;
					}
				}
			}
		}

		return timelineData;
	}

	renderDeviceTimelineHistory ( historyData, index ) {
		let data = this._prepareHistoryData( historyData.history );
		let markup = [];
		// let elements = this._prepareTimelineElementsConfiguration( this.props.options.timelineElements );

		for ( let i in data ) {
			markup.push( this.renderSingleTimeLineElement( data[ i ], i ) );
		}

		if ( markup.length ) {
			markup.push( this.getTimelineHistoryAxis() );
		}
		else {
			markup = 'No data for the selected period!';
		}

		return (
			<div key={ index } className="timeline-history">
				<h3 className="timeline-history-title">{ historyData.name }</h3>
				{ markup }
			</div>
		);
	}

	// get the hozrizontal axis with time intervals
	getTimelineHistoryAxis () {
		let fromDate = this.state.fromDate,
			toDate = this.state.toDate;

		let { axisSeparatorsCount } = this.props.options;

		let interval = moment.duration( toDate.diff( fromDate ) ) / axisSeparatorsCount;

		let dateTimeFormat;

		let i = 0;
		let nextDate = moment( fromDate );
		let previousDate = moment( { ...nextDate } ).subtract( 1, 'day' );
		let datesMarkup = [];
		let elementStyle;

		while ( i <= axisSeparatorsCount ) {
			elementStyle = {
				left: ( 100 / axisSeparatorsCount ) * i + '%',
				width: ( 100 / axisSeparatorsCount ) + '%'
			}

			// display the date if it's changed, otherwise the time is enough
			// eslint-disable-next-line
			dateTimeFormat = ( i == axisSeparatorsCount || previousDate.get( 'date' ) != nextDate.get( 'date' ) ) ? 'DD-MM HH:mm' : 'HH:mm';

			datesMarkup.push( <div key={ i } className="timeline-history-axis-date-container" style={ elementStyle }>
				<div className="timeline-history-axis-date"> { nextDate.format( dateTimeFormat ) } </div>
			</div> );

			previousDate = moment( { ...nextDate } );
			nextDate = nextDate.add( interval );

			i++;
		}

		return (
			<div key={ Math.random() } className="timeline-history-axis"> { datesMarkup } </div>
		);
	}

	getTimelineElementColorAndLabel ( timelineElValue ) {
		let elementsConfig = this.props.options.timelineElements;

		for ( let i in elementsConfig ) {
			let { condition, value, color, label } = elementsConfig[ i ];

			if ( isValueSatisfyingCondition( condition ? condition : '==', timelineElValue, value ) ) {
				return { color, label };
			}
		}

		return {
			color: '#efefef',
			label: undefined
		};
	}

	renderSingleTimeLineElement ( data, index ) {
		let elementInterval = moment.duration( data.toDate.diff( data.fromDate ) );
		let selectedDatesInterval = moment.duration( this.state.toDate.diff( this.state.fromDate ) );

		let { color, label } = this.getTimelineElementColorAndLabel( data.value );

		let elementWidth = ( elementInterval / selectedDatesInterval ) * 100;
		let elementLeft = ( data.fromDate.diff( this.state.fromDate ) / selectedDatesInterval ) * 100;

		let tooltipWidth = 22;
		let tooltipSidePosition = ( 100 - ( elementLeft + tooltipWidth ) ) > 0 ? { left: elementLeft + '%' } :
			{ right: ( 100 - ( elementWidth + elementLeft ) ) + '%' };

		let elementStyle = {
			background: color,
			width: elementWidth + '%'
		};
		let tooltipStyle = {
			background: color,
			color: calculateContrastedColor( color ),
			width: tooltipWidth + '%',
			...tooltipSidePosition
		};
		// if no label defined, use property value
		let tooltipLabel = !isNully( label ) ? label : ( isNully( data.value ) ? '' : data.value.toString() );

		return (
			<div key={ index } className="timeline-element" style={ elementStyle }>
				<div className="timeline-tooltip" style={ tooltipStyle }>
					<div> { tooltipLabel } </div>
					<div className="timelane-dates">from { data.fromDate.format( this.defaultDateTimeFormat ) }</div>
					<div className="timelane-dates">to { data.toDate.format( this.defaultDateTimeFormat ) }</div>
				</div>
			</div>
		);
	}

	render () {
		let { options } = this.props;

		let {
			selectedDevices,
			toDate
		} = this.state;


		let timelineMarkup = [];

		if ( this.state.fromDate && this.state.toDate ) {
			for ( let deviceId in this.state.devicesHistory ) {
				if ( !this.state.devicesHistory[ deviceId ].isFetching ) {
					timelineMarkup.push( this.renderDeviceTimelineHistory( this.state.devicesHistory[ deviceId ], deviceId ) );
				}
			}
		}

		let textColor = options.stylable.textColor;

		let multiselect;

		if ( this.widgetDevices.length !== 1 ) {
			multiselect = (
				<Multiselect
					onChange={ ( selectedDevices ) => this.onSelectedDevicesChange( selectedDevices ) }
					data={ this.widgetDevices }
					textField="name"
					valueField="name"
					placeholder="Select Devices to Compare"
					value={ selectedDevices }
					caseSensitive={ false }
					minLength={ 1 }
					textColor={ textColor }
					filter="contains" />
			);
		}

		return (
			<div className="timeline-widget" >
				<div className="widget-settings-selection">
					{ multiselect }

					<RangeDateTimePickers
						dateFormat='H:i d/m/Y'

						maxDatesInterval={ options.maxDatesInterval ? options.maxDatesInterval : null }

						maxDateFromCalendar={ toDate ? toDate.clone().subtract( 1, 'minutes' ).format() : moment().subtract( 1, 'minutes' ).format() }
						maxDateToCalendar={ moment().format() }

						startDate={ this.state.fromDate }
						endDate={ this.state.toDate }

						onStartDateChange={ ( fromDate ) => this.setState( { fromDate, devicesHistory: {}, areDatesDirty: true } ) }
						onEndDateChange={ ( toDate ) => this.setState( { toDate, devicesHistory: {}, areDatesDirty: true } ) } />
				</div>
				<div className="timelines-container">
					{ timelineMarkup }
				</div>
			</div>
		);
	}

	areDatasetsLoaded ( newProps ) {
		if ( newProps.provided.devices && newProps.provided.devices.length ) {
			return true;
		}

		return false;
	}

	onSelectedDevicesChange ( selectedDevices ) {
		let selectedDevicesNames = selectedDevices.map( device => {
			return device.name;
		} );

		this.setState( { selectedDevices, devicesHistory: {} } );

		this.props.api.saveWidgetState( { selectedDevicesNames } );
	}

	fetchWidgetHistoryDataIfNeeded ( newProps, newState, oldState ) {
		let { selectedDevices, fromDate, toDate, devicesHistory } = newState;

		if ( this.areDatasetsLoaded( newProps ) ) {
			if ( selectedDevices.length && fromDate && toDate ) {

				if ( oldState ) {
					if ( selectedDevices === oldState.selectedDevices
						&& fromDate === oldState.fromDate
						&& toDate === oldState.toDate ) {

						return;
					}
				}

				const fromDateFormatted = fromDate.format();
				const toDateFormatted = toDate.format();

				let fullSelectedDevices = selectedDevices.map( device => device.fullDevice );

				let historyPromise = newProps.api.fetchDevicesHistory( fullSelectedDevices, fromDateFormatted, toDateFormatted );

				if ( historyPromise ) {
					historyPromise.then( ( history ) => {
						devicesHistory = {};

						fullSelectedDevices.forEach( ( device, index ) => {
							devicesHistory[ device.deviceId ] = {
								history: history[ index ],
								name: device.snapshot.id
							}
						} );

						this.setState( {
							devicesHistory,
							isHistoryLoading: false
						} );
					} );
				}
			}
		}
	}
}

export default stylable( BinaryTimelineWidget );
