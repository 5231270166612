// {
// 	"type": "PieChartWidget",
// 	"options": {
// 		"widgetTitle": "Current external temperature of device Test_1",
// 		"widgetId": "gauge-per-device",
//		"chartType": "pie", // can be missed, will display pie by default
// 		"comparisonProperties": {
// 			"keyValue": true,
// 			"condition": "==="
// 		},
//		"pieChartColumns": {
//			"keyColumn": "low t°",
//			"data2": "no low t°"
//		},
// 		"datasets": [
// 				{
// 					"flowName": "prorail",
// 					"units": {
// 						"messages": "prorailMessagesMemoryRepository",
// 						"snapshot": "prorailSnapshot"
// 					},
// 					"filters": [
// 					{
// 						"type": "has_snapshot_property",
// 						"options": {
// 							"propertyPath": [
// 								"content",
// 								"loLoTemp"
// 							]
// 						}
// 					}
// 				]
// 			}
// 		],
// 		"visualization": {
// 			"propertyPath": [
// 				"content",
// 				"loLoTemp"
// 			]
// 		}
//	},
// 	"position": {
// 		"x": 4,
// 		"y": 6,
// 		"h": 6,
// 		"w": 4
// 	}
// }

import React, { Component } from 'react';

import stylable from './../../decorators/stylable.jsx';

import { isValueSatisfyingCondition, getPropertyValueByPath } from './../../utils/devicesUtils.js';

import CircleChart from './../../components/Charts/CircleChart.jsx';

class PieChartWidget extends Component {
	componentWillMount () {
		this.prepareWidgetDevices( this.props );
	}

	componentWillReceiveProps ( nextProps ) {
		this.prepareWidgetDevices( nextProps );
	}

	_generateDataForChart () {
		let pieChartColumns = this.props.options.pieChartColumns,
			filteredDevices = this.filteredDevices,
			valuesArray = [];

		for ( let key in filteredDevices ) {
			valuesArray.push( [ pieChartColumns[ key ] , filteredDevices[ key ].length ] );
		}

		return {
			valuesArray
		};
	}

	render() {
		const { options } = this.props;
		let valuesColors = [],
			chartData = this._generateDataForChart();

		return (
			<div className="pie-chart-widget">
				<div className="widget-content">
					<CircleChart
						chartType= { options.chartType }
						valuesArray={ chartData.valuesArray }
						isFetching={ this.isLoading }
						valuesColors={ valuesColors } />
				</div>
			</div>
		);
	}

	prepareWidgetDevices( newProps ) {
		let monitoredDevices,
			widgetDevices = [],
			filteredDevices = {
				keyColumn: [],
				data2: []
			},
			options = this.props.options,
			comparisonProperties = options.comparisonProperties;

		if ( newProps.provided ) {
			this.isLoading = newProps.provided.isLoading;
			this.errors = newProps.provided.errors;
		}

		if ( newProps.provided.devices ) {
			monitoredDevices = newProps.provided.devices;
		}

		if ( monitoredDevices ) {
			monitoredDevices.forEach( device => {
				let currentValue = getPropertyValueByPath( device, [ 'snapshot', ...options.visualization.propertyPath ] );

				widgetDevices.push({
					name: device.snapshot.id,
					fullDevice: device
				});

				if ( isValueSatisfyingCondition( comparisonProperties.condition, currentValue, comparisonProperties.keyValue ) ) {
					filteredDevices.keyColumn.push( {
						name: device.snapshot.id,
						fullDevice: device
					} );
				}
				else {
					filteredDevices.data2.push( {
						name: device.snapshot.id,
						fullDevice: device
					} );
				}
			} );


		}

		this.widgetDevices = widgetDevices;
		this.filteredDevices = filteredDevices;
	}
}

export default stylable( PieChartWidget );