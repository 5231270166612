import React, { Component } from 'react';

class TextField extends Component {
	constructor ( props ) {
		// Props are super
		super( props );

		this.onFieldChange = this.onFieldChange.bind( this );
	}

	render () {
		let {
			value,
			field,
			errors
		} = this.props;

		let errorsMessages = [];

		if ( errors && errors.length ) {
			errorsMessages = errors.map( ( error, index ) => ( ( <div key={ index } className="validation-error">{ error.message }</div> ) ) )
		}

		return (
			<label className="text-field-label">
				{ field.label }
				{ field.required ? '*' : '' }
				{ errorsMessages }
				<input type="text" value={ value } onChange={ this.onFieldChange } />
			</label>
		);
	}

	onFieldChange ( event ) {
		this.props.onChange( this.props.field.id, event.target.value );
	}
}

export default TextField;
