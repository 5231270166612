// Iota Middleware (Api)
import iota from './../../middleware/iota.js';

import { findInSchema } from './../../utils/jsonSchema.js';

import {
	findDeviceSnapshotUnit,
	// findDeviceHistoryUnits,
	findDeviceHistoryUnit,
	findUnitFlow,
	findDeviceExternalId
} from './../../utils/devicesUtils.js';

const WIDGET_STATE_PREFIX = '__widgetState__';

/**
 * Fetch the device history from the iota API
 * @param  {Object} options                    The options
 * @param  {String} options.flowName           The name of the flow
 * @param  {Array} options.historyUnits        The names of the history units
 * @param  {String} options.externalDeviceId   The ID of the device (in the iota unit)
 * @param  {Date} options.fromDate             From date
 * @param  {Date} options.toDate               To date
 * @return {Promise?}                          The promise of the request
 */
function fetchHistory ( { flowName, historyUnits, externalDeviceId, fromDate, toDate } ) {
	if ( historyUnits.length ) {
		let historyUnit = historyUnits[ 0 ];

		return iota.flows().name( flowName ).unit( historyUnit ).devices().id( externalDeviceId ).history( {
			start: fromDate,
			end: toDate
		} );
	}
	return null;
}

/**
 * Fetch the device history from the iota API
 * @param  {Object} options                    The options
 * @param  {String} options.flowName           The name of the flow
 * @param  {Array} options.historyUnits        The names of the history units
 * @param  {String} options.externalDeviceId   The ID of the device (in the iota unit)
 * @param  {Date} options.count                How many values to get
 * @return {Promise?}                          The promise of the request
 */
function fetchLastHistory ( { flowName, historyUnits, externalDeviceId, count } ) {
	if ( historyUnits.length ) {
		let historyUnit = historyUnits[ 0 ];

		return iota.flows().name( flowName ).unit( historyUnit ).devices().id( externalDeviceId ).last( {
			count
		} );
	}
	return null;
}

export function getWidgetsApi ( {
	flows,
	unitsDevices,
	unitsSchemas,
	snapshotsToHistoryUnits,
	getMeta,
	saveMeta,

	openPopup,
	updatePopup,
	dashboardLayoutSubscribers
} ) {
	let defineWidgetApi = ( widgetId ) => {
		let fetchDeviceHistory = ( device, fromDate, toDate ) => {
			let snapshotUnit = findDeviceSnapshotUnit( device, unitsDevices );
			let historyUnit = findDeviceHistoryUnit( device, unitsDevices, snapshotsToHistoryUnits );
			let flowName = findUnitFlow( snapshotUnit, flows );

			let externalDeviceId = findDeviceExternalId( device );

			let historyPromise = fetchHistory( {
				flowName,
				historyUnits: [ historyUnit ],
				externalDeviceId,
				fromDate,
				toDate
			} )

			return historyPromise
		};

		let fetchDeviceLastHistory = ( device, count ) => {
			let snapshotUnit = findDeviceSnapshotUnit( device, unitsDevices );
			let historyUnit = findDeviceHistoryUnit( device, unitsDevices, snapshotsToHistoryUnits );

			let flowName = findUnitFlow( snapshotUnit, flows );

			let externalDeviceId = findDeviceExternalId( device );

			let historyPromise = fetchLastHistory( {
				flowName,
				historyUnits: [ historyUnit ],
				externalDeviceId,
				count
			} )

			return historyPromise
		};

		return {
			getWidgetState: () => {
				let widgetState;
				let parsedState;
				let widgetStateKey = WIDGET_STATE_PREFIX + widgetId;

				widgetState = getMeta( widgetStateKey );

				if ( widgetState ) {
					try {
						parsedState = JSON.parse( widgetState );
						widgetState = parsedState;
					}
					catch ( e ) {
						console.warn( 'Widget state is not a valid JSON' );
					}
				}

				return widgetState;
			},
			saveWidgetState: ( widgetState ) => {
				let widgetStateKey = WIDGET_STATE_PREFIX + widgetId;

				saveMeta( widgetStateKey, widgetState );
			},
			fetchDevicesHistory: ( devices, fromDate, toDate ) => {
				let devicesHistoryPromises = [];

				devices.forEach( ( device ) => {
					devicesHistoryPromises.push(
						fetchDeviceHistory( device, fromDate, toDate )
					);
				} );

				return Promise.all( devicesHistoryPromises );
			},
			fetchDevicesLastHistory: ( devices, count ) => {
				let devicesHistoryPromises = [];

				devices.forEach( ( device ) => {
					devicesHistoryPromises.push(
						fetchDeviceLastHistory( device, count )
					);
				} );

				return Promise.all( devicesHistoryPromises );
			},
			openPopup,
			updatePopup,
			subscribeForLayoutChange: ( callback ) => {
				if ( typeof callback === 'function' ) {

					if ( dashboardLayoutSubscribers[ widgetId ] ) {
						window && window[ 'debug' ] && console.log( 'Overwriting layout subscriber for widget ' + widgetId );
					}

					dashboardLayoutSubscribers[ widgetId ] = callback;
				}
			},
			unsubscribeForLayoutChange: () => {
				dashboardLayoutSubscribers[ widgetId ] = null;
			},
			getDevicePropertyReadableName: ( selectedDevice, propertyPath ) => {
				let snapshotUnit = findDeviceSnapshotUnit( selectedDevice, unitsDevices );

				let propertySchemaItem = findInSchema( unitsSchemas[ snapshotUnit ], propertyPath );

				if ( propertySchemaItem && propertySchemaItem.title ) {
					return propertySchemaItem.title
				}

				return propertyPath[ propertyPath.length - 1 ];
			},
			getDevicePropertyUnit: ( selectedDevice, propertyPath ) => {
				let snapshotUnit = findDeviceSnapshotUnit( selectedDevice, unitsDevices );

				let propertySchemaItem = findInSchema( unitsSchemas[ snapshotUnit ], propertyPath );

				if ( propertySchemaItem && propertySchemaItem.description ) {
					return propertySchemaItem.description
				}

				return '';
			}
		}
	}

	return defineWidgetApi;
}
