import React from 'react';

import BulkNotificationsDeleteConfirmBox from './BulkNotificationsDeleteConfirmBox.jsx';

export default function NotificationsSelectionInfoBox ( props ) {
	const {
		selectedNotificationsCount,
		onMarkSelectedAsSeen,
		onDeleteSelected,
		showConfirmDelete,
		onConfirmDelete,
		onAbortDelete
	} = props;

	let deleteActions;

	if( showConfirmDelete ) {
		deleteActions = (
			<BulkNotificationsDeleteConfirmBox
				bulkCount={ selectedNotificationsCount }
				onConfirm={ onConfirmDelete }
				onAbort={ onAbortDelete }
				/>
		);
	}
	else {
		deleteActions = (
			<span className="delete-selected-action" onClick={ onDeleteSelected }>Delete selected</span>
		);
	}

	let selectionInfoCssClass = 'selection-info';

	if( !selectedNotificationsCount ) {
		selectionInfoCssClass += ' no-selection';
	} else if( showConfirmDelete ) {
		selectionInfoCssClass += ' bulk-delete-confirm';
	}


	return ( <div className={ selectionInfoCssClass }>
			{ selectedNotificationsCount } items selected.
			<span className="available-actions-container">
				<span className="mark-seen-selected-action" onClick={ onMarkSelectedAsSeen }>Mark as seen</span>
				{ deleteActions }
			</span>
		</div> );
};
