import { connect } from 'mqtt';

export default class IotaMqtt {
	constructor ( baseUrl ) {
		let _this = this;

		this._subscribers = {};

		let baseSocketUri;

		if( baseUrl.indexOf( 'https://' ) !== -1 ) {
			baseSocketUri = baseUrl.replace( 'https://', 'wss://' );
		}
		else {
			baseSocketUri = baseUrl.replace( 'http://', 'ws://' );
		}

		this._baseUrl = baseSocketUri + '/ws';

		let client = connect( baseSocketUri + '/mqtt', {
			clientId: "iota$mqtt$clientId" + this.generateGuid()
		 });


		window.onbeforeunload = () => {
			client.end();
		};

		client.on( 'message', ( topic, message ) => {
			_this.notify( topic, message, 'onmessage' );
		} );

		this._client = client;
	}

	generateGuid() {
		return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace( /[xy]/g, function( c ) {
			var r = Math.random() * 16 | 0,
				// eslint-disable-next-line
				v = c === 'x' ? r : ( r & 0x3 | 0x8 );
			return v.toString( 16 );
		});
	}

	subscribe({ notificationId, onmessage, onerror, onclose }) {
		let _this = this;

		let client = this._client;

		if( !client.connected ) {
			// Wait to connect and resubscribe
			client.on( 'connect', () => {
				if( notificationId ) {
					_this.subscribe({ notificationId, onmessage, onerror, onclose });
				}
				else {
					console.warn( 'NotificationId was not provided' );
				}
			});

			return;
		}

		client.subscribe( notificationId, { qos: 0 }, ( err, granted ) => {
			if( err ) {
				console.error( err );
				_this.notify( notificationId, err, 'onerror' )

			}
		});


		if( !this._subscribers[ notificationId ] ) {
			this._subscribers[ notificationId ] = [];
		}

		this._subscribers[ notificationId ].push({ onmessage, onerror, onclose });
	}

	notify( unitId, message, handler ) {
		if( this._subscribers[ unitId ] && this._subscribers[ unitId ].length ) {
			this._subscribers[ unitId ].forEach( function ( listener ) {
				if( typeof listener[ handler ] === 'function' ) {
					listener[ handler ]( message );
				}
			});
		}
	}

}
