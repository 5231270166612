import React from 'react';
import { DivIcon } from 'leaflet';
import { Tooltip } from 'react-leaflet';

export function getUniqueDeviceId ( unitId, deviceId ) {
	return '_' + unitId + '_' + deviceId;
}

export function getRangeString ( range ) {
	return range.from + '_' + range.to;
}

export function getUniqueDeviceHistoryId ( unitId, deviceId, range ) {
	return getUniqueDeviceId( unitId, deviceId ) + '_' + getRangeString( range );
}

export function getUniqueFlowUnitId ( flowName, unitId ) {
	return flowName + '_' + unitId;
}

export function getUniqueFlowSnapshotNotifyId ( flowName, snapshotUnit, messagesUnit ) {
	return flowName + '_' + snapshotUnit + '_' + messagesUnit;
}


export function getPropertyValueByPath ( deviceMessage, propertyPathArray ) {
	let value = deviceMessage;

	propertyPathArray.forEach( ( propertyPathEntry ) => {
		if ( value ) {
			value = value[ propertyPathEntry ];
		}
	} );

	return value;
}

export function getDefaultMarkerSvg ( color ) {
	let fillColor = color ? color : '#010101';

	return `<svg version="1.1" xmlns="http://www.w3.org/2000/svg"
			xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:a="http://ns.adobe.com/AdobeSVGViewerExtensions/3.0/"
			x="0px" y="0px" width="65px" height="65px" viewBox="0 0 198.43 198.43" enable-background="new 0 0 198.43 198.43"
			xml:space="preserve" class="draggable">
		<g>
			<path class="marker-bnd" fill="${ fillColor }" fill-opacity="1" d="M177.272,88.999c0-43.016-34.871-77.887-77.887-77.887c-43.016,0-77.887,34.871-77.887,77.887
				c0,34.326,22.215,63.444,53.045,73.816l24.842,24.842l24.842-24.842C155.056,152.443,177.272,123.325,177.272,88.999z"/>
			<path class="filled" fill-rule="evenodd" clip-rule="evenodd" fill="#fff" d="M71.018,69.831c-0.159-1.088-0.242-2.048-0.242-2.862c0-14.548,11.836-26.385,26.385-26.385
				c13.858,0,25.246,10.741,26.298,24.333h-9.143c-1.361-8.4-8.643-14.841-17.421-14.841c-9.742,0-17.668,7.926-17.668,17.67
				c0,9.082,6.891,16.579,15.717,17.554v7.396c0,3.763,3.062,6.825,6.825,6.825h39.09c3.763,0,6.825-3.062,6.825-6.825V71.742
				c0-3.763-3.062-6.825-6.825-6.825h-12.136c-1.062-16.49-14.808-29.583-31.561-29.583c-17.444,0-31.635,14.192-31.635,31.635
				c0,16.397,28.048,78.848,29.242,81.498l2.393,5.314l2.393-5.314c0.685-1.52,10.208-22.724,18.204-43.622h-5.602
				c-4.887,12.637-10.495,25.895-14.995,36.096C87.607,119.287,73.063,83.856,71.018,69.831 M94.943,71.742v8.253
				c-5.921-0.941-10.467-6.067-10.467-12.249c0-6.849,5.571-12.42,12.418-12.42c5.874,0,10.797,4.103,12.082,9.591h-7.208
				C98.005,64.917,94.943,67.979,94.943,71.742z M109.073,70.167c-0.915,4.61-4.393,8.298-8.88,9.538v-7.963
				c0-0.854,0.721-1.575,1.575-1.575H109.073z M142.434,92.696c0,0.854-0.721,1.575-1.575,1.575h-39.09
				c-0.854,0-1.575-0.721-1.575-1.575v-7.603c7.389-1.402,13.148-7.416,14.185-14.927h8.867c-0.703,4.515-2.623,11.113-5.232,18.723
				h5.511c2.489-7.432,4.353-14.046,5.01-18.723h12.324c0.854,0,1.575,0.721,1.575,1.575V92.696z"/>
		</g>
		<text transform="matrix(1 0 0 1 7.1079 13.5215)" opacity="0" font-family="'MyriadPro-Regular'" font-size="12">a</text>
	</svg>`;
}

export function getEmptyMarkerSvg ( color, iconSrc ) {
	let fillColor = color ? color : '#010101';

	return `<svg version="1.1" xmlns="http://www.w3.org/2000/svg"
			xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:a="http://ns.adobe.com/AdobeSVGViewerExtensions/3.0/"
			x="0px" y="0px" width="65px" height="65px" viewBox="0 0 198.43 198.43" enable-background="new 0 0 198.43 198.43"
			xml:space="preserve" class="draggable">
		<g>
			<path class="marker-bnd" fill="${ fillColor }" fill-opacity="1" d="M177.272,88.999c0-43.016-34.871-77.887-77.887-77.887c-43.016,0-77.887,34.871-77.887,77.887
				c0,34.326,22.215,63.444,53.045,73.816l24.842,24.842l24.842-24.842C155.056,152.443,177.272,123.325,177.272,88.999z"/>
				<image xlink:href="${ iconSrc }" x="50px" y="40px" height="100px" width="100px"/>
		</g>
		<text transform="matrix(1 0 0 1 7.1079 13.5215)" opacity="0" font-family="'MyriadPro-Regular'" font-size="12">a</text>
	</svg>`;
}

export function getDefaultMarkerTooltip ( value, color ) {
	return <Tooltip direction="top" offset={ [ 0, -50 ] } >
		<div className="property-value-tooltip" style={ { color } }>
			{ value ? value : '' }
		</div>
	</Tooltip>;
}

export function getDefaultClusterOptions ( disableClusteringAtZoom ) {
	return {
		disableClusteringAtZoom,
		iconCreateFunction: function ( cluster ) {
			var clusterChildrenCount = cluster.getChildCount(),
				clusterChildrenCountClassName = '',
				sortedColors = [];

			if ( clusterChildrenCount > 99 ) {
				if ( clusterChildrenCount > 999 ) {
					clusterChildrenCountClassName = ' bigger-cluster';
				}
				else {
					clusterChildrenCountClassName = ' big-cluster';
				}
			}

			return new DivIcon( {
				html: `<div class="cluster-content">${ clusterChildrenCount }</div class="cluster-content">
						<div class="cluster-colors-container">
							${ ( sortedColors[ 0 ] ? '<div class="cluster-color-circle" style="background-color:' + sortedColors[ 0 ][ 0 ] + '" ></div>' : '' ) }
							${( sortedColors[ 1 ] ? '<div class="cluster-color-circle" style="background-color:' + sortedColors[ 1 ][ 0 ] + '" ></div>' : '' ) }
							${( sortedColors[ 2 ] ? '<div class="cluster-color-circle" style="background-color:' + sortedColors[ 2 ][ 0 ] + '" ></div>' : '' ) }
						'</div>`,
				className: 'my-div-cluster' + clusterChildrenCountClassName
			} );
		}
	}
}

const findNeedleKeyInOneToMany = ( needle, oneToMany, path ) => {
	for ( let oneToManyKey in oneToMany ) {
		let needleArray;
		if ( path ) {
			needleArray = getPropertyValueByPath( oneToMany[ oneToManyKey ], path );
		}
		else {
			needleArray = oneToMany[ oneToManyKey ];
		}

		if ( needleArray
			&& needleArray.indexOf
			&& needleArray.indexOf( needle ) !== -1 ) {
			return oneToManyKey
		}
	}

	return null;
};

export function findDeviceSnapshotUnit ( device, unitDevicesRelations ) {
	let { deviceId } = device;

	return findNeedleKeyInOneToMany( deviceId, unitDevicesRelations, [ 'devices' ] );
}

export function findDeviceHistoryUnits ( device, unitDevicesRelations, snapshotToRealtimeUnits, type ) {
	console.warn( 'Deprecated: findDeviceHistoryUnits will be dropped in next versions' );

	let snapshotUnit = findDeviceSnapshotUnit( device, unitDevicesRelations );
	let historyUnits;

	if ( type === 'realtime' ) {
		historyUnits = snapshotToRealtimeUnits[ snapshotUnit ]
	}
	else {
		historyUnits = [ snapshotUnit ];
	}

	return historyUnits;
};

export function findDeviceHistoryUnit ( device, unitDevicesRelations, snapshotsToHistoryUnits ) {
	let snapshotUnit = findDeviceSnapshotUnit( device, unitDevicesRelations );

	let historyUnit = snapshotsToHistoryUnits[ snapshotUnit ];

	if ( !historyUnit ) {
		historyUnit = snapshotUnit;
	}

	return historyUnit;
}

export function findUnitFlow ( unitId, flows ) {
	return findNeedleKeyInOneToMany( unitId, flows, [ 'units' ] );
}

export function findDeviceExternalId ( device ) {
	if ( device && device.snapshot && device.snapshot.id ) {
		return device.snapshot.id
	}

	if ( device && device.messages && device.messages.id ) {
		return device.messages.id
	}

	return null;
}


export function generateHistoryEntryKeyByRange ( { from, to } ) {
	return new Date( from ).getTime() + '_' + new Date( to ).getTime();
}

export function generateHistoryEntryKeyByLastCount ( { count } ) {
	return 'last_' + count;
}


// black or white
export function calculateContrastedColor ( baseColor ) {
	let getColorBrightness = function ( hexCode ) {
		if ( !hexCode ) {
			return 0;
		}

		// strip off any leading #
		hexCode = hexCode.replace( '#', '' );

		let c_r = parseInt( hexCode.substr( 0, 2 ), 16 );
		let c_g = parseInt( hexCode.substr( 2, 2 ), 16 );
		let c_b = parseInt( hexCode.substr( 4, 2 ), 16 );

		return ( ( c_r * 299 ) + ( c_g * 587 ) + ( c_b * 114 ) ) / 1000;
	};

	let getOptimalColor = function ( hexCode ) {
		return getColorBrightness( hexCode ) > 230 ? '#000000' : '#ffffff';
	};

	return getOptimalColor( baseColor );
}

export function isNully ( value ) {
	if ( value === undefined || value === null ) {
		return true;
	}

	return false;
}

export function isValueSatisfyingCondition ( condition, value, keyValue ) {
	var result;

	switch ( condition ) {
		case '===':
		case '==': result = value === keyValue; break;

		case '!==':
		case '!=': result = value !== keyValue; break;

		case '>=': result = value >= keyValue; break;
		case '>': result = value > keyValue; break;

		case '<=': result = value <= keyValue; break;
		case '<': result = value < keyValue; break;

		default: result = false;
	}

	return result;
}

export function getDeviceName ( device, deviceOptions ) {
	let defaultName = device.snapshot.id,
		name;

	if ( deviceOptions && deviceOptions.namePropertyPath ) {
		name = getPropertyValueByPath( device.snapshot, deviceOptions.namePropertyPath );
	}

	return name || defaultName;
}
