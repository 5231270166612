import React, { Component } from 'react';

import { connect } from 'react-redux';

import * as userActions from './../actions/userActions.js';

import { getDashboardStateFromUserState } from './../selectors/dashboards.js';

import DashboardListItem from './../components/DashboardListItem.jsx';

class Home extends Component {
	render () {
		let { dashboards, isSyncing, error } = getDashboardStateFromUserState( this.props.userState );

		let {
			order,
			mainStyles
		} = dashboards;

		let content;

		let loadingContent = (
			<div className="loading">Loading...</div>
		);

		let errorContent = (
			<div className="error">Something went wrong</div>
		);

		let emptyContent = (
			<div className="no-items">There are no dashboards. <span>Add a new one using the { '</>' } icon in the header. </span></div>
		);

		if ( isSyncing ) {
			content = loadingContent;
		}
		else if ( error ) {
			content = errorContent;
		}
		else if ( !order || order.length === 0 ) {
			content = emptyContent;
		}
		else {
			let dashboardsMenuImages = ( mainStyles && mainStyles.dashboardsMenu ) ? mainStyles.dashboardsMenu : {};

			let dashboardsItems = order.map( ( dashboardName ) => (
				<DashboardListItem
					key={ dashboardName }
					name={ dashboardName }
					image={ dashboardsMenuImages[ dashboardName ] }
					onDelete={ this.onDelete } />
			) );

			content = (
				<div className="dashboards-list">
					<ul>
						{ dashboardsItems }
					</ul>
				</div>
			);
		}

		return (
			<section className="site-container">
				{ content }
			</section>
		);
	}
	onDelete = ( dashboardName ) => {
		let { dashboards } = getDashboardStateFromUserState( this.props.userState );

		dashboards.dashboards[ dashboardName ] = undefined;
		dashboards.order = dashboards.order.filter( name => name !== dashboardName );

		this.props.updateUserMeta( 'dashboards', dashboards );
	}
}

function mapStateToProps ( state ) {
	return {
		userState: state.userState
	};
}

function mapDispatchToProps ( dispatch ) {
	return {
		updateUserMeta: ( key, value ) => {
			dispatch( userActions.updateUserMeta( key, value ) );
		}
	};
}

export default connect( mapStateToProps, mapDispatchToProps )( Home );
