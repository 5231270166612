import React from 'react';

export default ( props ) => (
	<div className="header-notifications-container">
		<button onClick={ () => { props.onToggleNotificationVisibility() } } className={ props.notificationsButtonClass }>
			<img className="icon notification-icon" src={ props.notificationIcon } alt={ props.imageDescription } title={ props.imageDescription } />
			{ props.newNotificationsIndicator }
		</button>
		<div className="header-notifications-inner-container">
			<div className={ 'header-notifications-list-container' + ( props.isVisible? '' : ' hidden' )
				+ ( props.isSyncing ? ' disabled' : '' ) }>
				{ props.children }
			</div>
		</div>
	</div>
);
