import React, { useState } from 'react';
import PropTypes from 'prop-types';

import deleteImage from './../../img/delete-white.svg';
import editImage from './../../img/fsi-pencil_white.svg';

import './WidgetWrap.css';

const WidgetWrap = ( props ) => {
	const {
		onMouseDown,
		onMouseUp,
		onTouchStart,
		onTouchEnd,

		widgetId,

		onDelete,
		onEdit,

		onClick,

		isFocused
	} = props;

	const [ isDeleting, setIsDeleting ] = useState( false );
	const [ isEditing, setIsEditing ] = useState( false );

	const propsToPassToDragHandle = {
		onMouseDown,
		onMouseUp,
		onTouchStart,
		onTouchEnd
	};

	let classNames = props.className;
	classNames += props.isDndEnabled ? ' resize-active' : ' resize-inactive';
	classNames += isDeleting ? ' is-deleting' : '';
	classNames += isEditing ? ' is-editing' : '';
	classNames += isFocused ? ' focused' : '';

	return (
		<div style={ { ...props.style } } className={ `widget-wrapper ${ classNames }` } onClick={ onClick } >
			<button className="delete-widget btn" onClick={ () => {
				setIsDeleting( true );
				onDelete( widgetId );
			} } >
				<img src={ deleteImage } alt="Remove" title="Remove this widget" />
			</button>
			<button className="edit-widget btn" onClick={ () => {
				setIsEditing( true );
				onEdit( widgetId );
			} } >
				<img src={ editImage } alt="Remove" title="Remove this widget" />
			</button>
			<span className="drag-handle" { ...propsToPassToDragHandle } ></span>
			{ props.children }
		</div>
	);
}

WidgetWrap.propTypes = {
	onMouseDown: PropTypes.func,
	onMouseUp: PropTypes.func,
	onTouchStart: PropTypes.func,
	onTouchEnd: PropTypes.func,

	widgetId: PropTypes.string,
	onDelete: PropTypes.func,
	onEdit: PropTypes.func
}

export default WidgetWrap;
