import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';

import { popupReducer as popupState } from './popupReducer.js';
import { userReducer as userState } from './userReducer.js';

import { activeDashboardReducer as activeDashboard } from './activeDashboardReducer.js'

import { notificationsReducer as notifications } from './notificationsReducer.js';

import { entitiesReducer as entities } from './entities/index.js';

export default ( history ) => combineReducers( {
	router: connectRouter( history ),
	activeDashboard,
	popupState,
	userState,
	entities,
	notifications,
} );
