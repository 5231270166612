import {
	FETCH_DEVICES_SUCCESS,
} from './../../actions/unitsActions'

export function snapshotsToHistoryUnitsRelationsReducer ( state = {
	snapshotsToHistoryUnits: {}
}, action ) {
	switch ( action.type ) {
		case FETCH_DEVICES_SUCCESS: {
			let snapshotUnitName = action.unitId;
			let historyUnitName = action.historyUnit;

			let currentHistoryUnitRelation = state.snapshotsToHistoryUnits[ snapshotUnitName ];

			if ( historyUnitName ) {
				if ( currentHistoryUnitRelation && historyUnitName && currentHistoryUnitRelation !== historyUnitName ) {
					console.warn(
						`Possible snapshot to history configuration mistake: \n old: ${ currentHistoryUnitRelation }; new: ${ historyUnitName }. \n Old value overridden.`
					);
				}

				return Object.assign( {}, state, {
					snapshotsToHistoryUnits: {
						...state.snapshotsToHistoryUnits,
						[ snapshotUnitName ]: historyUnitName
					}
				} );
			}

			return state;
		}
		default:
			return state;
	}
}
