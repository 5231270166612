import {
	// UNIT_REALTIME_ENABLED,
	REALTIME_ROUTE_ENABLED,
	FETCH_DEVICE_HISTORY_STARTED,
	FETCH_DEVICE_HISTORY_SUCCESS,
	FETCH_DEVICE_HISTORY_FAIL,
} from './../../actions/unitsActions'

export function unitsReducer ( state = {
	isFetching: false,
	error: null,
	units: {},
	realtimeUnits: [],
	snapshotUnits: [],
	isUpdating: false,
}, action ) {
	switch ( action.type ) {
		// case UNIT_REALTIME_ENABLED:
		// 	if( state.realtimeUnits.indexOf( action.unitId ) === -1  ) {
		// 		return Object.assign( {}, state, {
		// 			realtimeUnits: [ action.unitId, ...state.realtimeUnits ],
		// 		});
		// 	}

		// 	return state;

		case REALTIME_ROUTE_ENABLED:
			if ( state.realtimeUnits.indexOf( action.realtimeUnitId ) === -1 ) {
				return Object.assign( {}, state, {
					realtimeUnits: [ action.realtimeUnit, ...state.realtimeUnits ],
					snapshotUnits: [ action.snapshotUnitId ]
				} );
			}

			return state;

		case FETCH_DEVICE_HISTORY_STARTED:
			return state;
		case FETCH_DEVICE_HISTORY_SUCCESS:
			return state;
		case FETCH_DEVICE_HISTORY_FAIL:
			return state;
		default:
			return state;
	}
}
