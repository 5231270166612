import {
	FETCH_UNIT_SCHEMA_STARTED,
	FETCH_UNIT_SCHEMA_SUCCESS,
	FETCH_UNIT_SCHEMA_FAILED,
} from './../../actions/unitsActions'

export function unitsSchemasReducer ( state = {
	isFetching: false,
	error: null,
	unitsSchemas: {},
}, action ) {
	switch ( action.type ) {
		case FETCH_UNIT_SCHEMA_SUCCESS: {
			return {
				...state,
				isFetching: false,
				unitsSchemas: {
					...state.unitsSchemas,
					[ action.unitId ]: action.schema
				}
			};
		}
		case FETCH_UNIT_SCHEMA_STARTED: {
			return {
				...state,
				error: null,
				isFetching: true
			}
		}
		case FETCH_UNIT_SCHEMA_FAILED: {
			return {
				...state,
				error: action.error,
				isFetching: false,
				unitsSchemas: {
					...state.unitsSchemas,
					[ action.unitId ]: null
				}
			}
		}
		default: {
			return state;
		}
	}
}
