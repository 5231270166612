import React, { Component } from 'react';

import * as moment from 'moment';

import C3Chart from 'react-c3js';
import 'c3/c3.css';

const defaultColors = [ '#0099cc', '#cc0000', '#ffbb33', '#99cc00' ],
	barsMiddlePositionString = 'set_bars_middle_position';

let setBarsMiddlePositionKeys = ( periodsCount ) => {
		let result = [ barsMiddlePositionString ],
			i = 0;

		for ( ; i < periodsCount; i++  ) {
			result.push( i + 0.5 );
		}
		
		return result;
	},
	setIndexedValues = ( periodsCount ) => {
		let result = [],
			i = 0;

		for ( ; i < periodsCount; i++  ) {
			result.push( i );
		}
		
		return result;
	};

class MultipleIndexedBarChart extends Component {
	render () {
		let { devices, xTickLabels, valuesArray, isFetching, valuesColors } = this.props,
			chartLoading = (
				<div className="chart-data-loading">Loading data...</div>
			),
			chartNoData = (
				<div className="no-chart-data">No data for the selected period</div>
			),
			chartColors = {},
			chartCanvasInnerContainer,
			middlePositionKeys = setBarsMiddlePositionKeys( xTickLabels.length ),
			indexedValues = setIndexedValues( xTickLabels.length );

		if( isFetching ) {
			chartCanvasInnerContainer = chartLoading;
		}
		else if( !valuesArray.length ) {
			chartCanvasInnerContainer = chartNoData;
		}
		else {
			devices.forEach( ( device, index ) => {
				if ( valuesColors[ device ] ) {
					chartColors[ device ] = valuesColors[ index ];
				}
				else {
					chartColors[ device ] = defaultColors[ index % 4 ];
				}
			} );
			valuesArray.unshift( middlePositionKeys );

			let chartProps = {
				data: {
					//unload: true,
					x: barsMiddlePositionString,
					columns: valuesArray,
					colors: chartColors,
					type: 'bar'
				},
				axis: {
					x: {
						tick: {
							values: indexedValues,
							format: function ( x ) {								
								// avoid barsMiddlePositionKeys
								if ( x % 1 === 0 ) {
									if ( xTickLabels[ x ].split( ' ' ).length > 1 ) {
										return moment( xTickLabels[ x ] ).format( 'DD-MM-YY HH:mm' );
									}
										return moment( xTickLabels[ x ] ).format( 'DD-MM-YYYY' );
								}
								return x;
							},
							rotate: -50
						}
					},
					y: {
						tick: {
							format: function ( y ) {
								
								var milliseconds = y * 1000,
									tempTime = moment.duration( milliseconds ),
									days = tempTime.get( 'days' ),
									hours = tempTime.get( 'hours' ),
									minutes = tempTime.get( 'minutes' ),
									seconds = tempTime.get( 'seconds' ),
									tick;
								seconds = seconds ? seconds + 'sec' : '';
								if ( !days && !hours && !minutes ) {
									tick = seconds;
								}
								else if ( !days && !hours ) {
									tick = minutes + 'min ' + seconds;
								}
								else {
									days = days ? days + 'd ' : '';
									hours =  hours ? hours + 'h ' : '';
									minutes =  minutes ? minutes + 'min' : '';

									tick = days + hours + minutes;
								}
								return tick;
							}
						}
					}
				},
				tooltip: {
					contents: function ( d, defaultTitleFormat, defaultValueFormat, color ) { 
						var $$ = this,
							text, i, bgcolor, milliseconds, tempTime, days, hours, minutes, seconds;

						for (i = 0; i < d.length; i++) {
							if (! (d[i] && (d[i].value || d[i].value === 0))) { continue; }

							if (! text) {
								text = "<table class='" + $$.CLASS.tooltip + "'>";
							}

							
							milliseconds = d[i].value * 1000;
							tempTime = moment.duration( milliseconds );
							days = tempTime.get( 'days' );
							days = days ? days + 'day ' : '';

							hours = tempTime.get( 'hours' );
							hours =  hours ? hours + 'h ' : '';

							minutes = tempTime.get( 'minutes' ) + 'min';
							seconds = tempTime.get( 'seconds' ) + 'sec';

							bgcolor = $$.levelColor ? $$.levelColor(d[i].value) : color(d[i].id);

							text += '<tr class="' + $$.CLASS.tooltipName + '-' + d[i].id + '">';
							text += '<td class="value" style="background-color:' + bgcolor + ';color: #fff;text-align:left">' + days + hours + minutes + ' ' + seconds + '</td>';
							text += '</tr>';
						}
						return text + "</table>";
					}
				},
				point: {
					show: false
				},
				grid: {
					x: {
						show: false
					},
					y: {
						show: true
					}
				},
				padding: {
					bottom: 60,
					left: 80,
					right: 50
				}
			}

			chartCanvasInnerContainer = ( <C3Chart className="c3-multiple-ts-charts" { ...chartProps } /> );
		}

		return (
			<div className="chart-canvas-container">
				{ chartCanvasInnerContainer }
			</div>
		);
	}
}

export default MultipleIndexedBarChart;