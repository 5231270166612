import React from 'react';

import './ColorIndicator.css';

export default ( props ) => {
	return (
		<div onClick={ props.onClick } className="stylable-color-indicator" style={ {
			backgroundColor: props.color
		} }></div>
	)
};