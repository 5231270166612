import React from 'react';

export default ( { index, onClick, isSelected } ) => {
	return (
		<button
			className={ `dataset-tab ${ isSelected ? 'active' : '' }` }
			onClick={ onClick }>
			{ index + 1 }
		</button>
	)
}