import React, { Component } from 'react';

import { getPropertyValueByPath, findDeviceExternalId, isNully } from './../../utils/devicesUtils.js';

export default class MonitoredDeviceItem extends Component {
	constructor ( props ) {
		// Props are super
		super( props );

		let isLoading = false;
		let valuesState = this.getValuesState( props, null, null );

		if( valuesState ) {
			let { oldValueEntry, newValueEntry } = valuesState;

			if( isNully( oldValueEntry.value ) ) {
				props.api.fetchDevicesLastHistory( [ props.device ], 2 ).then ( ( history ) => {
					this.updateOldValueEntry( history[ 0 ] );
				});

				isLoading = true;
			}

			this.state = {
				isLoading,
				oldValueEntry,
				newValueEntry
			};

			this.isItemMounted = false;

		}
	}

	componentWillMount () {
		this.isItemMounted = true;
	}
	componentWillUnmount () {
		this.isItemMounted = false;
	}

	componentWillReceiveProps ( nextProps,  ) {
		let isLoading = false;
		let valuesState = this.getValuesState( nextProps, this.props, this.state );

		if( valuesState ) {
			let { oldValueEntry, newValueEntry } = valuesState;

			if( isNully( oldValueEntry.value ) ) {
				nextProps.api.fetchDevicesLastHistory( [ nextProps.device ], 2 ).then ( ( history ) => {
					this.updateOldValueEntry( history[ 0 ] );
				});

				isLoading = true;
			}

			this.setState({
				isLoading,
				oldValueEntry,
				newValueEntry
			});

		}
	}

	render () {
		let {
			areFiltersEmpty,
			activeFilters,

			device,

			colorCodes,
			onDeviceClick
		} = this.props;

		let {
			newValueEntry,
			oldValueEntry,
			isLoading
		} = this.state;

		let hiddenClass = '';
		if( ! areFiltersEmpty && !activeFilters[ newValueEntry.status ] ) {
			hiddenClass = 'hidden';
		}

		let displayName = findDeviceExternalId( device );


		let oldValue = oldValueEntry.value;
		let newValue = newValueEntry.value;


		if( isNully( oldValue ) ) {
			oldValue = '-';
		}

		if( isNully( newValue ) ) {
			newValue = '-';
		}

		let oldValueStyle = {
			'background': colorCodes[ oldValueEntry.status + 'Color' ]
		}
		let newValueStyle = {
			'background': colorCodes[ newValueEntry.status + 'Color' ]
		}

		let oldValueLoadingCssClass = '';
		if( isLoading ) {
			oldValueLoadingCssClass = 'loading';
			oldValue = '';
		}

		return (
			<div onClick={ onDeviceClick } className={`open-details-popup monitored-device-item ${ hiddenClass }`}>
				<div className="table-cell name-cell">{ displayName }</div>
				<div className="table-cell">
					<div className="value-in-square old" style={ oldValueStyle }>
						<div className={  oldValueLoadingCssClass } >
							{ oldValue }
						</div>
					</div>
				</div>
				<div className="table-cell ">
					<div className="value-in-square new" style={ newValueStyle }>
						<div>
							{ newValue }
						</div>
					</div>
				</div>
			</div>
		);
	}


	// prepareDeviceData( newProps, oldProps ) {
	// }

	getValuesState( nextProps, oldProps, oldState ) {
		let {
			device,
			warningRules,
			criticalRules
		} = nextProps;

		let oldDevice;


		if( oldProps ) {
			oldDevice = oldProps.device;
		}

		/*
		 * The paths array determine the properties we are looking for
		 * We should select one property to visualize (based on the priority - their order in the Array)
		 *
		 * If we don't break out of the loop after the addition we will end up with
		 * more than 1 entry per device. (Which we currently don't want to)
		 *
		 */
		for( let propertyPath of nextProps.propertyPaths ) {
			let newPropertyValue = getPropertyValueByPath( device, [ 'snapshot', ...propertyPath ] );
			let oldPropertyValue;

			if( !isNully( newPropertyValue ) ) {
				if( oldDevice ) {
					oldPropertyValue = getPropertyValueByPath( oldDevice, [ 'snapshot', ...propertyPath ] );
				}

				if( !oldDevice || ( oldDevice.snapshot.timeReceived !== device.snapshot.timeReceived ) ) {
					return {
						oldValueEntry: {
							value: oldPropertyValue,
							status: this.props.getValueStatus( oldDevice, oldPropertyValue, warningRules, criticalRules )
						},
						newValueEntry: {
							value: newPropertyValue,
							status: this.props.getValueStatus( device, newPropertyValue, warningRules, criticalRules )
						}
					}
				}
				else {
					return this.state;
				}


			}
		}

		return null;
	}

	updateOldValueEntry( history ) {
		if( !this.isItemMounted ) {
			return false;
		}

		if( history.length && history.length > 1 ) {
			this.setState( ( prevState, props ) => {
				if( isNully( prevState.oldValueEntry.value ) ) {
					for( let propertyPath of props.propertyPaths ) {

						let oldPropertyValue = getPropertyValueByPath( history[ 1 ], [ ...propertyPath ] );

						return {
							...prevState,
							oldValueEntry: {
								value: oldPropertyValue,
								status: this.props.getValueStatus( history[ 1 ], oldPropertyValue, props.warningRules, props.criticalRules )
							},
							isLoading: false
						}
					}
				}

				return {
					...prevState,
					isLoading: false
				};
			});
		}
		else {
			this.setState({
				isLoading: false
			});
		}
	}
}
