import * as moment from 'moment';
import { isValueSatisfyingCondition } from '../devicesUtils';

export function applyAccumulationFilter ( filter, timeSetCollection, valuesCollection, keyValue, condition ) {
	let accumulationObject = {};

	if ( filter && typeof timePeriodFilters[ filter ] === 'function' ) {
		if ( timeSetCollection.length && valuesCollection.length ) {
			accumulationObject = timePeriodFilters[ filter ]( timeSetCollection, valuesCollection, keyValue, condition );
		}
	}

	return accumulationObject;
};

function accumulateTimes ( timeSets, values, keyValue, condition, defineTimePeriodKeyFormat, handleSplitTimeCase ) {
	var result = {},
		prevUniqueValue,
		prevUniqueValueTime,
		currentTime,
		currentValue,
		areValuesBoolean = typeof values[ 0 ] === 'boolean' ? true : false;

	timeSets.forEach( ( timeSet, index ) => {
		var duration = 0,
			timeFormated,
			prevUniqueValueTimeFormated;

		currentTime = moment( timeSet );
		timeFormated = defineTimePeriodKeyFormat( currentTime );
		currentValue = values[ index ];

		if ( index === 0 ) {
			prevUniqueValue = values[ index ];
			prevUniqueValueTime = currentTime;
		}

		if ( result.hasOwnProperty( timeFormated ) ) {

			if ( areValuesBoolean && prevUniqueValue === currentValue ) {
				// values are boolean so if prev and next values are equal
				// we have to skip recording the values
			}
			else {
				if ( isValueSatisfyingCondition( condition, prevUniqueValue, keyValue ) ) {
					// get duration of key value
					duration = currentTime.diff( prevUniqueValueTime, 'seconds' );
					result[ timeFormated ] += duration;
				}

				prevUniqueValue = currentValue;
				prevUniqueValueTime = currentTime;
			}

		}
		else {
			result[ timeFormated ] = 0;

			if ( areValuesBoolean && prevUniqueValue === currentValue ) {
				// values are boolean so if prev and next values are equal
				// we have to skip recording the values
			}
			else {
				if ( isValueSatisfyingCondition( condition, prevUniqueValue, keyValue ) ) {
					prevUniqueValueTimeFormated = defineTimePeriodKeyFormat( prevUniqueValueTime );
					// edge case split time when key value duration is in different days
					if ( prevUniqueValueTimeFormated !== timeFormated ) {
						// adds times and durations to result
						handleSplitTimeCase( prevUniqueValueTime, currentTime, result );
					}
				}

				prevUniqueValue = currentValue;
				prevUniqueValueTime = currentTime;
			}
		}
	} );

	return result;
}

var timePeriodFilters = {
	per_day: function ( timeSets, values, keyValue, condition ) {
		var timePeriodKeyFormat = 'YYYY-MM-DD',
			defineTimePeriodKeyFormat = function ( date ) {
				return date.format( timePeriodKeyFormat );
			},
			handleSplitTimeCase = function ( prevUniqueValueTime, currentTime, result ) {
				var prevUniqueValueTimeFormated = defineTimePeriodKeyFormat( prevUniqueValueTime ),
					timeFormated = defineTimePeriodKeyFormat( currentTime ),

					endOfPeriod = moment( prevUniqueValueTimeFormated + ' 23:59:59', 'YYYY-MM-DD HH:mm:ss' ),
					startOfPeriod;

				endOfPeriod.add( 1, 'seconds' );
				result[ prevUniqueValueTimeFormated ] += endOfPeriod.diff( prevUniqueValueTime, 'seconds' );

				while ( endOfPeriod.clone().add( 24, 'hours' ).isBefore( currentTime ) ) {
					startOfPeriod = endOfPeriod.clone();
					endOfPeriod = endOfPeriod.add( 24, 'hours' );
					result[ defineTimePeriodKeyFormat( startOfPeriod ) ] = endOfPeriod.diff( startOfPeriod, 'seconds' );
				}

				startOfPeriod = moment( timeFormated + ' 00:00:00', 'YYYY-MM-DD HH:mm:ss' );
				result[ timeFormated ] += currentTime.diff( startOfPeriod, 'seconds' );

				return result;
			};

		return accumulateTimes( timeSets, values, keyValue, condition, defineTimePeriodKeyFormat, handleSplitTimeCase );
	},
	per_hour: function ( timeSets, values, keyValue, condition ) {
		var timePeriodKeyFormat = 'YYYY-MM-DD HH',
			defineTimePeriodKeyFormat = function ( date ) {
				return date.format( timePeriodKeyFormat );
			},
			handleSplitTimeCase = function ( prevUniqueValueTime, currentTime, result ) {
				var prevUniqueValueTimeFormated = defineTimePeriodKeyFormat( prevUniqueValueTime ),
					timeFormated = defineTimePeriodKeyFormat( currentTime ),

					endOfPeriod = moment( prevUniqueValueTimeFormated + ':59:59', 'YYYY-MM-DD HH:mm:ss' ),
					startOfPeriod;

				endOfPeriod.add( 1, 'seconds' );
				result[ prevUniqueValueTimeFormated ] += endOfPeriod.diff( prevUniqueValueTime, 'seconds' );

				while ( endOfPeriod.clone().add( 1, 'hours' ).isBefore( currentTime ) ) {
					startOfPeriod = endOfPeriod.clone();
					endOfPeriod = endOfPeriod.add( 1, 'hours' );
					result[ defineTimePeriodKeyFormat( startOfPeriod ) ] = endOfPeriod.diff( startOfPeriod, 'seconds' );
				}

				startOfPeriod = moment( timeFormated + ':00:00', 'YYYY-MM-DD HH:mm:ss' );
				result[ timeFormated ] += currentTime.diff( startOfPeriod, 'seconds' );

				return result;
			}

		return accumulateTimes( timeSets, values, keyValue, condition, defineTimePeriodKeyFormat, handleSplitTimeCase );
	},
	per_10_minutes: function ( timeSets, values, keyValue, condition ) {
		var defineTimePeriodKeyFormat = function ( date, minutes ) {
			var formatedDate = date.format( 'YYYY-MM-DD HH' ),
				tens = parseInt( ( date.get( 'minute' ) / 10 ).toString(), 10 );

			minutes = minutes || 0;

			return formatedDate + ':' + tens + minutes;
		},
			handleSplitTimeCase = function ( prevUniqueValueTime, currentTime, result ) {
				var prevUniqueValueTimeFormated = defineTimePeriodKeyFormat( prevUniqueValueTime ),
					timeFormated = defineTimePeriodKeyFormat( currentTime ),

					endOfPeriod = moment( defineTimePeriodKeyFormat( prevUniqueValueTime, 9 ) + ':59', 'YYYY-MM-DD HH:mm:ss' ),
					startOfPeriod;

				endOfPeriod.add( 1, 'seconds' );
				result[ prevUniqueValueTimeFormated ] += endOfPeriod.diff( prevUniqueValueTime, 'seconds' );

				while ( endOfPeriod.clone().add( 10, 'minutes' ).isBefore( currentTime ) ) {
					startOfPeriod = endOfPeriod.clone();
					endOfPeriod = endOfPeriod.add( 10, 'minutes' );
					result[ defineTimePeriodKeyFormat( startOfPeriod ) ] = endOfPeriod.diff( startOfPeriod, 'seconds' );
				}

				startOfPeriod = moment( timeFormated + ':00', 'YYYY-MM-DD HH:mm:ss' );
				result[ timeFormated ] += currentTime.diff( startOfPeriod, 'seconds' );

				return result;
			}

		return accumulateTimes( timeSets, values, keyValue, condition, defineTimePeriodKeyFormat, handleSplitTimeCase );

	},
	per_minute: function ( timeSets, values, keyValue, condition ) {
		var timePeriodKeyFormat = 'YYYY-MM-DD HH:mm',
			defineTimePeriodKeyFormat = function ( date ) {
				return date.format( timePeriodKeyFormat );
			},
			handleSplitTimeCase = function ( prevUniqueValueTime, currentTime, result ) {
				var prevUniqueValueTimeFormated = defineTimePeriodKeyFormat( prevUniqueValueTime ),
					timeFormated = defineTimePeriodKeyFormat( currentTime ),

					endOfPeriod = moment( defineTimePeriodKeyFormat( prevUniqueValueTime ) + ':59', 'YYYY-MM-DD HH:mm:ss' ),
					startOfPeriod;

				endOfPeriod.add( 1, 'seconds' );
				result[ prevUniqueValueTimeFormated ] += endOfPeriod.diff( prevUniqueValueTime, 'seconds' );

				while ( endOfPeriod.clone().add( 1, 'minutes' ).isBefore( currentTime ) ) {
					startOfPeriod = endOfPeriod.clone();
					endOfPeriod = endOfPeriod.add( 1, 'minutes' );
					result[ defineTimePeriodKeyFormat( startOfPeriod ) ] = endOfPeriod.diff( startOfPeriod, 'seconds' );
				}

				startOfPeriod = moment( timeFormated + ':00', 'YYYY-MM-DD HH:mm:ss' );
				result[ timeFormated ] += currentTime.diff( startOfPeriod, 'seconds' );

				return result;
			}

		return accumulateTimes( timeSets, values, keyValue, condition, defineTimePeriodKeyFormat, handleSplitTimeCase );

	}
};

export function addMissingPeriodsToResult ( filter, fromDate, toDate, accumulationResult ) {
	if ( filter && typeof fullAccumulationPeriods[ filter ] === 'function' ) {
		fullAccumulationPeriods[ filter ]( accumulationResult, fromDate, toDate );
	}
}

var fullAccumulationPeriods = {
	per_day: function ( accumulationResult, fromDate, toDate ) {
		var timePeriodKeyFormat = 'YYYY-MM-DD',
			defineTimePeriodKeyFormat = function ( date ) {
				return date.format( timePeriodKeyFormat );
			},
			timePeriodKey;

		while ( fromDate.clone().add( 1, 'days' ).isBefore( toDate ) ) {
			timePeriodKey = defineTimePeriodKeyFormat( fromDate );
			if ( !accumulationResult.hasOwnProperty( timePeriodKey ) ) {
				accumulationResult[ timePeriodKey ] = 0;
			}
			fromDate.add( 1, 'days' );
		}
	},
	per_hour: function ( accumulationResult, fromDate, toDate ) {
		var timePeriodKeyFormat = 'YYYY-MM-DD HH',
			defineTimePeriodKeyFormat = function ( date ) {
				return date.format( timePeriodKeyFormat );
			},
			timePeriodKey;

		while ( fromDate.clone().add( 1, 'hours' ).isBefore( toDate ) ) {
			timePeriodKey = defineTimePeriodKeyFormat( fromDate );
			if ( !accumulationResult.hasOwnProperty( timePeriodKey ) ) {
				accumulationResult[ timePeriodKey ] = 0;
			}
			fromDate.add( 1, 'hours' );
		}
	},
	per_10_minutes: function ( accumulationResult, fromDate, toDate ) {
		var defineTimePeriodKeyFormat = function ( date, minutes ) {
			var formatedDate = date.format( 'YYYY-MM-DD HH' ),
				tens = parseInt( ( date.get( 'minute' ) / 10 ).toString(), 10 );
			minutes = minutes || 0;

			return formatedDate + ':' + tens + minutes;
		},
			timePeriodKey;

		while ( fromDate.clone().add( 10, 'minutes' ).isBefore( toDate ) ) {
			timePeriodKey = defineTimePeriodKeyFormat( fromDate );
			if ( !accumulationResult.hasOwnProperty( timePeriodKey ) ) {
				accumulationResult[ timePeriodKey ] = 0;
			}
			fromDate.add( 10, 'minutes' );
		}
	},
	per_minute: function ( accumulationResult, fromDate, toDate ) {
		var timePeriodKeyFormat = 'YYYY-MM-DD HH:mm',
			defineTimePeriodKeyFormat = function ( date ) {
				return date.format( timePeriodKeyFormat );
			},
			timePeriodKey;

		while ( fromDate.clone().add( 1, 'minutes' ).isBefore( toDate ) ) {
			timePeriodKey = defineTimePeriodKeyFormat( fromDate );
			if ( !accumulationResult.hasOwnProperty( timePeriodKey ) ) {
				accumulationResult[ timePeriodKey ] = 0;
			}
			fromDate.add( 1, 'minutes' );
		}
	}
}