import React, { Component } from 'react';

class TextareaField extends Component {
	constructor ( props ) {
		// Props are super
		super( props );

		this.onFieldChange = this.onFieldChange.bind( this );
	}

	render () {
		let {
			value,
			field
		} = this.props

		return (
			<label className="textarea-field-label">
				{ field.label }
				<textarea value={ value } onChange={ this.onFieldChange } />
			</label>

		)
	}

	onFieldChange ( event ) {
		this.props.onChange( this.props.field.id, event.target.value );
	}
}

export default TextareaField;