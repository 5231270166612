import React, { useState } from 'react';

import WidgetsPalette from './WidgetsPalette/WidgetsPalette.jsx';
import WidgetConfigurationForm from './WidgetConfigurationForm/WidgetConfigurationForm.jsx';

export default ( props ) => {
	const { onClose, onSave } = props;

	let [ isWidgetsPaletteVisible, setIsWidgetsPaletteVisible ] = useState( true );
	let [ widgetConfigurationForm, setWidgetConfigurationForm ] = useState( null );

	return (
		<div>
			{
				isWidgetsPaletteVisible ?
					<WidgetsPalette
						onClose={ onClose }
						onWidgetTypeSelected={ ( widgetType ) => {
							setWidgetConfigurationForm( {
								prefilledFields: null,
								widgetType
							} );
							setIsWidgetsPaletteVisible( false );
						} } /> : ''
			}
			{
				widgetConfigurationForm ?
					<WidgetConfigurationForm
						widgetConfigurationForm={ widgetConfigurationForm }
						onSave={ onSave }
						onCancel={ () => {
							setWidgetConfigurationForm( null );
							setIsWidgetsPaletteVisible( true );
						} } /> : ''
			}
		</div>
	);
};
