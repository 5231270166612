import React from 'react';

import arrangeImage from './../../img/rearrange-white.svg';


const DashboardEditButton = ( props ) => {
	const { isActive, onClick } = props;

	let dragAndDropButtonClass = 'btn-nostyle toggle-drag-and-drop';
	let rearrangeIconDescription = 'Modify Dashboard Layout';


	if ( isActive ) {
		rearrangeIconDescription = 'Save Dashboard Layout';
		dragAndDropButtonClass += ' active';
	}

	return (
		<button
			className={ dragAndDropButtonClass }
			onClick={ onClick } >
			<img
				className="icon"
				src={ arrangeImage }
				alt={ rearrangeIconDescription }
				title={ rearrangeIconDescription } />
		</button>
	);
}

export default DashboardEditButton;
