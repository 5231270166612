import React, { Component } from 'react';

import ActionField from './ActionField.jsx';

import { getPropertyValueByPath } from './../../utils/devicesUtils.js';


export default class ManagementAction extends Component {
	constructor ( props ) {
		// Props are super
		super( props );

		this.state = {
			fieldsValues: {},
			errors: {
				required: [],
				number: []
			}
		}

		this.onActionFieldChange = this.onActionFieldChange.bind( this );
		this.invokeActionClicked = this.invokeActionClicked.bind( this );
	}

	componentWillReceiveProps ( nextProps ) {
		if ( getPropertyValueByPath( nextProps.selectedDevice, [ 'snapshot', 'id' ] ) !== getPropertyValueByPath( this.props.selectedDevice, [ 'snapshot', 'id' ] ) ) {
			this.setState( {
				fieldsValues: {},
				errors: {
					required: [],
					number: []
				}
			} );
		}
	}

	onActionFieldChange ({ id, value }) {
		this.setState({
			fieldsValues: {
				...this.state.fieldsValues,
				[ id ]: value
			},
			errors: this.validate( id, value )
		});
	}

	validate ( id, value ) {
		let currentField = this.props.fields.filter( field => field.id === id )[ 0 ],
			fieldValidations = currentField.validations,
			errors = this.state.errors,
			requiredErrors = [ ...errors.required ],
			requiredIndex = requiredErrors.indexOf( id ),
			numberErrors = [ ...errors.number ],
			numberIndex = numberErrors.indexOf( id );

		if ( fieldValidations ) {
			if ( fieldValidations.required ) {
				if ( value === '' ) {
					requiredErrors.push( id );
				}
				else if ( requiredIndex > -1 ) {
					requiredErrors = [
						...requiredErrors.slice( 0, requiredIndex ),
						...requiredErrors.slice( requiredIndex + 1 )
					];
				}
			}

			if ( fieldValidations.valueType === 'number' ) {
				if ( isNaN( value * 1 ) ) {
					if ( numberIndex === -1 ) {
						numberErrors.push( id );
					}
				}
				else if ( numberIndex > -1 ) {
					numberErrors = [
						...numberErrors.slice( 0, numberIndex ),
						...numberErrors.slice( numberIndex + 1 )
					];
				}
			}
		}

		return { required: requiredErrors, number: numberErrors };
	}

	invokeActionClicked () {
		let {
			targetUrl,
			targetMethod,
			id,
			selectedDevice,
			fields
		} = this.props,
			explicitValues = {},
			explicitValuesKeys = [],
			errorCollection = [],
			requiredErrors = this.state.errors.required,
			numberErrors = this.state.errors.number;

		if( fields ) {
			fields.forEach( ({ id, defaultValue }) => {
				let explicitValue = this.state.fieldsValues[ id ];

				if( !explicitValue ) {
					if ( defaultValue instanceof Array ) {
						explicitValue = getPropertyValueByPath( selectedDevice, [ "snapshot", ...defaultValue ] );
					}
					else {
						explicitValue = defaultValue;
					}
				}
				if ( explicitValue !== undefined ) {
					explicitValues[ id ] = explicitValue;
				}
			});

			if ( requiredErrors.length === 0 && numberErrors.length === 0 ) {
				explicitValuesKeys = Object.keys( explicitValues );
				if ( explicitValuesKeys.length === fields.length) {
					this.props.invokeAction({
						id,
						targetUrl,
						targetMethod,
						values: explicitValues
					});
				}
				else {
					fields.forEach( field => {
						if ( explicitValuesKeys.indexOf( field.id ) === -1 ) {
							errorCollection.push( field.id );
						}
					} );
					this.setState( {
						errors: {
							required: errorCollection,
							number: this.state.errors.number
						}
					} );
				}
			}
		}
		else {
			this.props.invokeAction({
				id,
				targetUrl,
				targetMethod,
				values: explicitValues
			});
		}
	}

	render () {
		let {
			title,
			description,
			actionText,
			actionStatus,
			selectedDevice,
			fields
		} = this.props,
			getInitialValue = ( defaultValue ) => {
				if ( defaultValue && defaultValue instanceof Array ) {
					defaultValue = getPropertyValueByPath( selectedDevice, [ "snapshot", ...defaultValue ] );
				}
				return defaultValue;
			};

		let actionFieldsList = [];

		if( fields ) {
			actionFieldsList = fields.map( field => {
				let { id, defaultValue } = field;
				let value = this.state.fieldsValues[ id ],
					initialValue = getInitialValue( defaultValue ),
					requiredErrors = this.state.errors.required,
					numberErrors = this.state.errors.number;

				if( typeof value === 'undefined' ) {
					value = initialValue;
				}

				return <ActionField
					key={ id }
					numberError={ numberErrors.indexOf( id ) > -1 }
					requiredError={ requiredErrors.indexOf( id ) > -1 }
					initialValue={ initialValue }
					onChange={ this.onActionFieldChange }
					value={ value } { ...field } />
			});
		}

		let invokeActionCssClass = 'invoke-action';

		if( actionStatus ) {
			if( actionStatus.isSending ) {
				invokeActionCssClass += ' loading';
			}
			else if( actionStatus.error ) {
				invokeActionCssClass += ' error';
			}
			else {
				invokeActionCssClass += ' success';
			}
		}

		return (
			<div className="management-action">
				<div className="widget-content-padder">
					<h3 className="action-title">
						{ title }
					</h3>
					<div className="action-description">
						{ description }
					</div>
					<div className="action-fields-list">
						{ actionFieldsList }
					</div>
					<div className={ invokeActionCssClass }>
						<button
							className="btn invoke-action-button"
							onClick={ this.invokeActionClicked } >
							{ actionText }
						</button>
					</div>
				</div>
			</div>
		);
	}
}