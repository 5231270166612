import React, { Component } from 'react';

import { DivIcon, LatLng } from 'leaflet';
import { Map, Marker, TileLayer } from 'react-leaflet';
import Control from 'react-leaflet-control';

import './../../../node_modules/leaflet/dist/leaflet.css';

import config from './../../config.js';

import { getDefaultMarkerSvg, getPropertyValueByPath } from './../../utils/devicesUtils.js';

class DevicesOverviewLocator extends Component {
	componentDidUpdate () {
		// Checks if the map container size changed and updates the map if so
		let map = this.refs.map;
		if ( map ) {
			map[ 'leafletElement' ].invalidateSize()
		}
	}

	getFullscreenControl () {
		return (
			<Control position="topright" className="leaflet-bar">
				{ /* eslint-disable-next-line */ }
				<a className="map-fullscreen-icon" onClick={ this.props.toggleLocatorContainer } > </a>
			</Control>
		);
	}

	render () {
		const selectedDevice = this.props.selectedDevice;


		if ( selectedDevice ) {
			let latitude;
			let longitude;

			if ( this.props.latPath ) {
				latitude = parseFloat( getPropertyValueByPath( selectedDevice, this.props.latPath ) );
			}

			if ( this.props.lngPath ) {
				longitude = parseFloat( getPropertyValueByPath( selectedDevice, this.props.lngPath ) );
			}

			let position;

			if ( !isNaN( latitude ) && !isNaN( longitude ) ) {
				position = new LatLng( latitude, longitude );
			}

			if ( position ) {
				const markerWidth = 65;
				const markerHeight = 65;


				let defaultMarker = getDefaultMarkerSvg();
				// let markerColor = this.props.color;

				let deviceIcon = new DivIcon( {
					iconSize: [ markerWidth, markerHeight ],
					html: defaultMarker,
					iconAnchor: [ markerWidth / 2, markerHeight ],
					// color: markerColor,
					className: 'my-div-icon'
				} );

				return (
					<div className="sow-popup-location-container">
						{ /* scrollWheelZoom="center" it will zoom to the center of the view regardless of where the mouse was. */ }
						<Map center={ position } zoom={ 13 } scrollWheelZoom="center" ref="map">
							<TileLayer
								url={ config.tileLayer.url } />
							<Marker position={ position } icon={ deviceIcon } />

							{ this.getFullscreenControl() }
						</Map>
					</div>
				)
			}
		}

		return (
			<div className="sow-popup-location-container empty">
			</div>
		);
	}
}

export default DevicesOverviewLocator;
