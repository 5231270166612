import React, { Component } from 'react';

class PaletteWidgetItem extends Component {
	constructor ( props ) {
		// Props are super
		super( props );

		this.onAddWidgetClick = this.onAddWidgetClick.bind( this );
	}

	render () {
		return (
			<div className="widget-in-palette" onClick={ this.onAddWidgetClick }>
				<span className="widget-in-palette-link">
					<div className="widget-in-palette-description">{ this.props.description }</div>
					<div className="widget-in-palette-img"><img src={ this.props.image } alt={ `${this.props.name} preview` } /></div>
					<div className="widget-in-palette-name">{ this.props.name }</div>
				</span>
			</div>
		);
	}

	onAddWidgetClick () {
		this.props.onAddWidgetClick( this.props.type );
	}
}

export default PaletteWidgetItem;
