import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { getDashboardStateFromUserState } from './../selectors/dashboards.js';

class HeaderDashboardsMenu extends Component {
	constructor ( props ) {
		super( props );

		this.toggleHeaderMenu = this.toggleHeaderMenu.bind( this );
		this.closeHeaderMenu = this.closeHeaderMenu.bind( this );
	}

	toggleHeaderMenu () {
		if ( this.props.isOpen ) {
			this.props.close();
		}
		else {
			this.props.open();
		}
	}

	closeHeaderMenu () {
		this.props.close();
	}

	render () {
		let { userState, router, headerBackgroundColor, isOpen } = this.props;



		let { dashboards, isSyncing, error } = getDashboardStateFromUserState( userState );

		let { order } = dashboards;
		let isVisible = isOpen;

		let routingPath = router.location.pathname.split( '/' );
		let currentDashboard = routingPath.length ? routingPath[ routingPath.length - 1 ] : '';

		let itemsMarkup;

		let loadingItemMarkup = (
			<li className="loading">
				<span className="header-menu-item ">
					Loading...
				</span>
			</li>
		);

		let noDashboardsConfiguredMarkup = (
			<li className="no-items">
				<NavLink
					className="header-menu-item"
					to={ `/new-dashboard` }
					onClick={ this.closeHeaderMenu } >
					Add new dashboard
				</NavLink>
			</li>
		)

		let errorItemMarkup = (
			<li className="header-menu-item error">Something went wrong</li>
		);


		if ( isSyncing ) {
			itemsMarkup = loadingItemMarkup;
		}
		else if ( !order ) {
			itemsMarkup = noDashboardsConfiguredMarkup;
		}
		else if ( error ) {
			itemsMarkup = errorItemMarkup;
		}
		else if ( order.length === 0 || order.length === 1 ) {
			currentDashboard = order.length ? order[ 0 ] : '';

			return (
				<div className="header-menu">
					<div className="current-dashboard" >
						<span> { currentDashboard } </span>
					</div>
				</div>
			)
		}
		else {
			itemsMarkup = order.map( ( dashboardName ) => {
				if ( dashboardName !== currentDashboard ) {
					return (
						<li key={ dashboardName } >
							<NavLink
								className="header-menu-item"
								to={ `/dashboards/${ dashboardName }` }
								onClick={ this.closeHeaderMenu }
							>
								{ dashboardName }
							</NavLink>
						</li>
					);
				}
				return null;
			} );
		}

		return (
			<div className="header-menu">
				<div className={ "toggle-header-menu" + ( isVisible ? '' : ' closed' ) }
					onClick={ this.toggleHeaderMenu }>
					<span> { currentDashboard } </span>
				</div>
				<ul className={ "header-menu-list" + ( isVisible ? '' : ' hidden' ) }
					style={ headerBackgroundColor ? { backgroundColor: headerBackgroundColor } : {} }>
					{ itemsMarkup }
				</ul>
			</div>
		);
	}
}

function mapStateToProps ( state, ownProps ) {
	return {
		userState: state.userState,
		router: state.router,
	};
}

export default connect( mapStateToProps, null )( HeaderDashboardsMenu );
