import React, { Component } from 'react';

import stylable from './../../decorators/stylable.jsx';

import image from './image.png';

class SampleWidget extends Component {

	static getWidgetConfiguration () {
		return {
			name: 'Text',
			image: image,
			description: 'Use this widget to show static text like intro, help or contact info',
			defaultSize: [ 4, 4 ],
			fieldsets: {
				initial: {
					name: 'Initial',
					fields: [ {
						id: 'widgetSubtitle',
						type: 'textarea',
						label: 'Subtitle'
					} ]
				}
			}
		};
	}

	render () {
		let blanketColor = this.props.options.stylable.blanketColor;

		let subtitle = this.props.options.widgetSubtitle;

		/* Support newlines in the content (subtitle) of the widget */
		if ( subtitle && subtitle.indexOf( '\n' ) !== -1 ) {
			subtitle = subtitle.split( '\n' ).map( ( line, index ) => {
				return ( <div key={ index }>{ line }</div> )
			} );
		}

		return (
			<div className="sample-widget">
				<div className="sample-widget-text-container">
					<div className="widget-subtitle">
						<span className="subtitle-blanket" style={ {
							backgroundColor: blanketColor
						} }>
							{ subtitle }
						</span>
					</div>
				</div>
			</div>
		);
	}

}

export default stylable( SampleWidget );
