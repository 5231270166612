import React from 'react';
import PropTypes from 'prop-types';

import './IconPicker.css';

const IconPicker = ( props ) => {
	let {
		selectedIcon,
		onChange,
		icons
	} = props;

	return (
		<div className="stylable-icon-picker">
			<div className="icon-picker-overlay">
				<div
					className={ `icon-picker-single-icon no-icon-picker ${ !selectedIcon ? 'selected' : '' }` }
					onClick={ () => {
						onChange( null );
					} } title="No Icon" >
					<div className="icon-picker-single-icon-inner">
						No Icon
								</div>
				</div>
				{
					icons.map( ( icon ) => {
						let singleIconStyle = {
							backgroundImage: `url( "/icons/${ icon.pathBase }_black.svg" )`
						}
						let isCurrentIconSelected = ( selectedIcon === icon.pathBase );
						return (
							<div
								key={ icon.pathBase }
								className={ `icon-picker-single-icon ${ isCurrentIconSelected ? 'selected' : '' }` }
								onClick={ () => {
									onChange( icon );
								} } title={ icon.name } >
								<div className="icon-picker-single-icon-inner" style={ singleIconStyle }>
								</div>
							</div>
						);
					} )
				}
			</div>
		</div>
	)
};

IconPicker.propTypes = {
	onChange: PropTypes.func.isRequired,
	selectedIcon: PropTypes.string,
	icons: PropTypes.array.isRequired
}

export default IconPicker;