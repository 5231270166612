import React, { Component } from 'react';

import { Map, TileLayer } from 'react-leaflet';
import './../../../node_modules/leaflet/dist/leaflet.css';

import Control from 'react-leaflet-control';
// import HeatmapLayer from 'react-leaflet-heatmap-layer';

import config from './../../config.js';

import Multiselect from '../../components/ReactWidgets/Multiselect';
import DropdownList from '../../components/ReactWidgets/DropdownList';

import { getPropertyValueByPath, getDeviceName } from './../../utils/devicesUtils.js';

import stylable from './../../decorators/stylable.jsx';

import LeafletHexbin from './LeafletHexbin.jsx';

import './style.css';


/* TMP */


// {
// "type": "HexbinWidget",
// "options": {
//     "stylable": {
//         "color": "#23282D"
//     },
//     "useAverageValues": false,
//     "widgetTitle": "Heatmap",
//     "widgetId": "heatmap-locator-widget",
//     "dropdownProperties": [
//         {
//             "name": "fCntUp",
//             "displayName": "Count up",
//             "propertyPath": [
//                 "content",
//                 "fCntUp"
//             ],
//             "colorRanges": [
//                 {
//                     "color": "#48ae55",
//                     "maxValue": 350,
//                     "label": "ok"
//                 },
//                 {
//                     "color": "#ffc04d",
//                     "minValue": 350.001,
//                     "maxValue": 1000,
//                     "label": "warning"
//                 },
//                 {
//                     "color": "#f63232",
//                     "minValue": 1000.001,
//                     "label": "danger"
//                 }
//             ]
//         },
//         {
//             "name": "batteryLifeCount",
//             "displayName": "Battery life",
//             "propertyPath": [
//                 "content",
//                 "batteryLifeCount"
//             ],
//             "colorRanges": [
//                 {
//                     "color": "#48ae55",
//                     "maxValue": 350,
//                     "label": "ok"
//                 },
//                 {
//                     "color": "#ffc04d",
//                     "minValue": 350.001,
//                     "maxValue": 1000,
//                     "label": "warning"
//                 },
//                 {
//                     "color": "#f63232",
//                     "minValue": 1000.001,
//                     "label": "danger"
//                 }
//             ]
//         }
//     ],
//     "locationPropertyPath": {
//         "lat": [
//             "content",
//             "lat"
//         ],
//         "lng": [
//             "content",
//             "lng"
//         ]
//     },
//     "datasets": [
//         {
//             "flowName": "prorail",
//             "units": {
//                 "messages": "prorailSnapshot",
//                 "snapshot": "prorailSnapshotMemory",
//                 "history": "prorailSnapshot"
//             },
//             "filters": [
//                 {
//                     "type": "has_snapshot_property",
//                     "options": {
//                         "propertyPath": [
//                             "content",
//                             "lat"
//                         ]
//                     }
//                 }
//             ]
//         },
//         {
//             "flowName": "prorail",
//             "units": {
//                 "messages": "prorailSnapshot",
//                 "snapshot": "prorailSnapshotMemory",
//                 "history": "prorailSnapshot"
//             },
//             "filters": [
//                 {
//                     "type": "has_snapshot_property",
//                     "options": {
//                         "propertyPath": [
//                             "content",
//                             "temp"
//                         ]
//                     }
//                 }
//             ]
//         }
//     ]
// },
// "position": {
//     "x": 0,
//     "y": 27,
//     "h": 11,
//     "w": 4
// }
// },


const defaultDropdownItem = { name: 'All' };
const defaultMapCenter = [ 52.52730392656781, 10.458902895450592 ];
const defaultMapZoom = 3;


const calculateAvgColorValue = function ( d ) {
	let sum = d.reduce( ( acc, i ) => {
		if( i && i.o && i.o[ 2 ] ) {
			return acc + i.o[ 2 ]
		}

		return acc;
	}, 0 );

	let cv = sum/d.length

	return cv;
}

const calculateMeanColorValue = function ( d ) {
	// We are going to use the mean for the color
	// Mean is better than the avg since it removes possible errors in the dataset

	let sortedD = d.sort( ( l, r ) => {
		if( l && l.o && l.o[ 2 ] ) {
			if( r && r.o && r.o[ 2 ] ) {
				return l.o[ 2 ] - r.o[ 2 ]
			}
			else {
				return 1;
			}
		}
		else {
			return -1;
		}
	});

	let dLen = d.length;

	let cv;

	if( dLen % 2 ) {
		cv = sortedD[ parseInt( dLen/2 - 0.5, 10 ) ].o[ 2 ];
	}
	else {
		cv = ( sortedD[ parseInt( dLen/2, 10 ) ].o[ 2 ] + sortedD[ parseInt( dLen/2 - 1, 10 ) ].o[ 2 ] ) / 2;
	}

	return cv;
}

class HexbinWidget extends Component {
	constructor ( props ) {
		// props are super!
		super( props );

		let selectedPropertyConfig = this.props.options.dropdownProperties[ 0 ];

		this.state = {
			devicesDropdownValue: [ defaultDropdownItem ],
			selectedPropertyConfig
		}

		this.mapDevices = [];

		this.onDevicesDropdownChange = this.onDevicesDropdownChange.bind( this );
	}

	componentWillReceiveProps( newProps ) {
		this.prepareDevices( newProps );
	}

	// update selected devices after realtime updates
	prepareDevices ( newProps ) {
		let { devicesDropdownValue, selectedPropertyConfig } = this.state,
			{ options, provided } = newProps,
			newState = {},
			mapDevices = [];

		if( !provided.isLoading
			&& provided.devices ) {
			const latPath = options.locationPropertyPath.lat;
			const lngPath = options.locationPropertyPath.lng;

			provided.devices.forEach( ( device ) => {
				const deviceName = getDeviceName( device, options.deviceOptions );
				const lat = getPropertyValueByPath( device, [ 'snapshot', ...latPath ] );
				const lng = getPropertyValueByPath( device, [ 'snapshot', ...lngPath ] );

				if( lat && lng ) {
					mapDevices.push({
						name: deviceName,
						lat,
						lng,
						fullDevice: device
					});
				}
			});
		}

		this.mapDevices = mapDevices;

		devicesDropdownValue.forEach( selectedDevice => {
			mapDevices.forEach( device => {
				if( device.name === selectedDevice.name ) {
					selectedDevice.lat = device.lat;
					selectedDevice.lng = device.lng;
				}
			});
		});

		newState.devicesDropdownValue = devicesDropdownValue;

		if( !selectedPropertyConfig ) {
			selectedPropertyConfig = options.dropdownProperties[ 0 ]

			newState.selectedPropertyConfig = selectedPropertyConfig;
		}

		this.setState( newState );
	}

	getLegend() {
		let { selectedPropertyConfig } = this.state;

		if( ! selectedPropertyConfig  ) {
			return '';
		}

		let colorRanges = selectedPropertyConfig.colorRanges;
		let legendMarkup = [];

		colorRanges.forEach( ( range, i ) => {
			legendMarkup.push( <li key={ i }>
				<div className="legend-color" style={{ backgroundColor: range.color, opacity: 0.8 }}></div>
				{ range.label }
			</li>)
		});

		return (
			<Control position="bottomright">
				<ul className="map-legend"> { legendMarkup } </ul>
			</Control>
		);
	}

	onDevicesDropdownChange ( value ) {
		let devicesDropdownValue = value;

		if( value.length === 0 ) {
			devicesDropdownValue = [ defaultDropdownItem ];
		}
		else if( value.length > 1 ) {
			let [ firstItem, ...restOfValue ] = value;

			if( firstItem === defaultDropdownItem ) {
				devicesDropdownValue = restOfValue;
			}
			else if( restOfValue.indexOf( defaultDropdownItem ) !== -1 ) {
				devicesDropdownValue = [ defaultDropdownItem ];
			}

		}

		this.setState({
			devicesDropdownValue
		})
	}

	getDevicePropetyValue( device ) {
		let { selectedPropertyConfig } = this.state;
		let { propertyPath } = selectedPropertyConfig;
		let propertyValue = getPropertyValueByPath( device, [ 'fullDevice', 'snapshot', ...propertyPath ] );

		return propertyValue;
	}

	render() {
		const { options } = this.props;
		const { devicesDropdownValue, selectedPropertyConfig } = this.state;
		let mapDevices = this.mapDevices;

		let selectedDevices;

		if( devicesDropdownValue.length === 1 && devicesDropdownValue[ 0 ] === defaultDropdownItem ) {
			selectedDevices = mapDevices;
		}
		else {
			selectedDevices = devicesDropdownValue;
		}

		let widgetProperties = [],
			{ dropdownProperties } = options;

		if( selectedDevices.length ) {
			if( options.hideUncommonProperties ) {
				dropdownProperties.forEach( ( propertyConfig ) => {
					let isSharedProperty = false; //check if all devices has that property

					selectedDevices.forEach( ( device ) => {
						let deviceContent = device.fullDevice.snapshot.content;

						for( let property in deviceContent ) {
							if( property === propertyConfig.name ) {
								isSharedProperty = true;
							}
						};
					});

					if( isSharedProperty ) {
						widgetProperties.push( propertyConfig.displayName );
					}
				});
			}
			else {
				widgetProperties = dropdownProperties.map( dropdownPropertyConfig => dropdownPropertyConfig.displayName );
			}
		}

		let heatmapPoints = [];

		if( selectedPropertyConfig ) {
			selectedDevices.forEach( ( selectedDevice, index ) => {
				let filteredDevice = mapDevices.filter( mapDevice => mapDevice.name === selectedDevice.name )[ 0 ],
					devicePropertyValue = this.getDevicePropetyValue( filteredDevice );

					if( devicePropertyValue ) {
						heatmapPoints.push( [ filteredDevice.lat, filteredDevice.lng, devicePropertyValue ] );
					}
			});
		}


		let { colorRanges } = selectedPropertyConfig;
		let gradientColors = [],
			gradientDomains = [];

		for ( let index = 0; index < colorRanges.length; index++ ) {
			let { minValue, maxValue, color } = colorRanges[ index ];

			if( index === 0 ) {
				gradientColors.push( color );
				gradientDomains.push( maxValue - 10 )
			}


			if( index !== colorRanges.length - 1 ) {
				gradientColors.push( color );
				gradientDomains.push( maxValue );
			}
			else {
				gradientColors.push( color );
				gradientColors.push( color );
				gradientDomains.push( minValue );
				gradientDomains.push( minValue + 10 );
			}
		}

		let calculateColorValue = calculateMeanColorValue;

		if( options.useAverageValues ) {
			calculateColorValue = calculateAvgColorValue;
		}

		return (
			<div className="locator-widget" >
				<div>
					<div className="widget-settings-selection">
						<Multiselect
							onChange={ this.onDevicesDropdownChange }
							data={ [ defaultDropdownItem, ...mapDevices ] }
							textField="name"
							valueField="name"
							value={ devicesDropdownValue }
							caseSensitive={false}
							minLength={1}
							filter='contains'
							id="locator-sensor-selector" />

							{
								dropdownProperties.length === 1 ? '' :
								<DropdownList
									onChange={ ( selectedProperty ) => {
										let selectedPropertyConfig = dropdownProperties.filter( property => {
											return property.displayName === selectedProperty;
										});

										selectedPropertyConfig = selectedPropertyConfig ? selectedPropertyConfig[ 0 ] : null;

										this.setState({ selectedPropertyConfig });
									} }
									data={ widgetProperties }
									textField="name"
									valueField="name"
									placeholder="Select Property"
									value={ selectedPropertyConfig.displayName }
									caseSensitive={ false }
									minLength={ 1 }
									filter="contains" />
							}
					</div>
				</div>
				<div className="map-container">
					<Map ref="locatorMap" center={ defaultMapCenter } zoom={ defaultMapZoom } animate={ true }>
						{ selectedPropertyConfig ?
							<LeafletHexbin
								data={ heatmapPoints }

								radius={ 22 }
								opacity={ 0.8 }
								duration={ 500 }

								colorScaleRange={ gradientColors }
								colorScaleDomain={ gradientDomains }

								radiusRange={ [ 22, 22 ] }

								latFunc={ ( d ) => d[ 0 ] }
								lngFunc={ ( d ) => d[ 1 ] }
								colorValueFunc={ calculateColorValue }
								radiusValueFunc={ () => 22 }
								tooltip={{ tooltipContent: ( d ) =>
									( Math.round( calculateColorValue( d ) * 100 ) / 100 ) }}
								/> : ''  }
						<TileLayer
							url={ config.tileLayer.url } />
						{ this.getLegend() }
					</Map>
				</div>
			</div>
		);
	}
}

export default stylable( HexbinWidget );
