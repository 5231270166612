import React from 'react';
import { Link } from 'react-router-dom';

const HeaderBranding = ( props ) => (
	<header
		className="maplese-header"
		style={ props.backgroundColor ? { backgroundColor: props.backgroundColor } : {} } >
		<Link
			to="/"
			title="Home" >
			<div
				className="logo-container"
				style={ props.logo ? { backgroundImage: 'url(' + props.logo + ')' } : {} } >
			</div>
		</Link>
		{ props.children }
	</header>
);

export default React.memo( HeaderBranding );