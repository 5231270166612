import React, { Component } from 'react';
import * as moment from 'moment';

import config from './../config.js';

import { DivIcon } from 'leaflet';
import { Map, TileLayer, LayerGroup, Marker, Polyline, Circle, Tooltip } from 'react-leaflet';
import './../../node_modules/leaflet/dist/leaflet.css';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import Control from 'react-leaflet-control';

import { getDefaultMarkerSvg, getDefaultClusterOptions, getPropertyValueByPath, isNully } from './../utils/devicesUtils.js';

import FullscreenMapPopup from './../popups/FullscreenMapPopup/FullscreenMapPopup.jsx';

const defaultMapCenter = [ 52.52730392656781, 10.458902895450592 ];
const defaultMapZoom = 3;

class HistoricalRouteMap extends Component {
	constructor ( props ) {
		super( props );

		this.state = {
			disableClustering: null,
			fullscreenPopupOpen: false
		}

		// storing current map viewport for the fullscreen popup
		this.currentViewport = {};
		this.onFullscreenPopupClose = this.onFullscreenPopupClose.bind( this );
	}

	componentWillReceiveProps( newProps ) {
		let { updatePopup }  = newProps;

		if( ! updatePopup || ! this.state.fullscreenPopupOpen ) {
			return;
		}

		updatePopup( FullscreenMapPopup, this.getMapFullscreenPopupOptions( newProps ) );
	}

	getDeviceIcon() {
		const markerWidth = 65;
		const markerHeight = 65;

		const defaultMarker = getDefaultMarkerSvg();
		const markerColor = this.props.color;

		return new DivIcon({
			iconSize: [ markerWidth, markerHeight ],
			html: defaultMarker,
			iconAnchor: [ markerWidth / 2, markerHeight ],
			color: markerColor,
			className: 'my-div-icon'
		});
	}

	getHistoricalRoute( history, id ) {
		if( ! history.length ) {
			return '';
		}

		let { locationPropertyPath, timeStampPropertyPath } = this.props,
			polylineOptions = {
				color: '#000000',
				dashArray: '4 6',
				weight: 2
			},
			circleOptions = {
				radius: 10,
				weight: 10,
				fillOpacity: 1,
				fillColor: '#000000',
				color: '#000000'
			},
			circles = [];

		let polylinePoints = history.map( ( historyEl, index ) => {
			let lat = getPropertyValueByPath( historyEl, locationPropertyPath.lat ),
				lng = getPropertyValueByPath( historyEl, locationPropertyPath.lng ),
				timeStamp = getPropertyValueByPath( historyEl, timeStampPropertyPath );

			if( lat && lng ) {
				circles.push( <Circle key={ index } center={ [ lat, lng ] } { ...circleOptions }>
								<Tooltip>
									<span>{ this.getTooltipMarkup( [ lat, lng ], timeStamp ) }</span>
								</Tooltip>
							</Circle> );

				return [ lat, lng ];
			}

			return null;
		}).filter( latlngs => latlngs !== null );


		circles.shift(); //remove the first one ( where the marker is )

		this.mapBounds = polylinePoints;

		return (
			<LayerGroup key={ id } >
				<Polyline positions={ polylinePoints } { ...polylineOptions } />
				{ circles.length ? circles : '' }
			</LayerGroup>
		);
	}

	// get the markup for a popup
	// containing location and current time
	getTooltipMarkup( latLngs, timestamp ) {
		return (
				<div>
					{ 'Lat: ' + latLngs[ 0 ] }
					<br/>
					{ 'Long: ' +  latLngs[ 1 ] }
					<br/>
					{ moment( timestamp ).format( 'DD-MM HH:mm:ss' ) }
				</div>
			);
	}

	getFullscreenControl( selectedDevices, options, devicesHistory ) {
		return (
			<Control position="topright" className="leaflet-bar">
				{ /* eslint-disable-next-line */ }
				<a className="map-fullscreen-icon" onClick={ () => {
					this.props.openPopup( FullscreenMapPopup, this.getMapFullscreenPopupOptions( this.props ) );

					this.setState( { fullscreenPopupOpen: true } );
				} }>
				</a>
			</Control>
			);
	}

	onFullscreenPopupClose() {
		this.setState( { fullscreenPopupOpen: false } );
	}

	getMapFullscreenPopupOptions( props ) {
		let { selectedDevices, devicesHistory, options } = props;

		return {
			selectedDevices,
			devicesHistory,
			options,
			viewport: this.currentViewport,
			disableClustering: this.shouldDisableClustering(),
			onClose: this.onFullscreenPopupClose
		}
	}

	shouldDisableClustering() {
		let { disableClustering } = this.state;

		if( isNully( disableClustering ) && !isNully( this.props.options.disableClustering ) ) {
			disableClustering = this.props.options.disableClustering;
		}

		return disableClustering;
	}

	getClusteringControl( disableClustering ) {
		let nextToggleDisableClusteringState = disableClustering ? false : true;
		return (
			<Control position="bottomleft" className="leaflet-bar">
				{ /* eslint-disable-next-line */ }
				<a className={ "map-clustering-icon" + ( nextToggleDisableClusteringState ? '' : ' disabled' ) } onClick={ () => {
					this.setState({
						disableClustering: nextToggleDisableClusteringState
					})
				} }>
				</a>
			</Control>
		);
	}

	render () {
		const { selectedDevices, devicesHistory } = this.props,
			disableClustering = this.shouldDisableClustering(),
			polylines = [];


		const markers = selectedDevices.map( ( mapDevice, index ) => {
			return (
				<Marker
					key={ index }
					position={ [ mapDevice.lat, mapDevice.lng ] }
					icon={ this.getDeviceIcon() }>
					<Tooltip>
						<span>{ this.getTooltipMarkup( [ mapDevice.lat, mapDevice.lng ], mapDevice.timeStamp ) }</span>
					</Tooltip>
				</Marker>
			);
		});

		for( let deviceId in devicesHistory ) {
			if( ! devicesHistory[ deviceId ].isFetching ) {
				polylines.push( this.getHistoricalRoute( devicesHistory[ deviceId ].history, deviceId ) );
			}
		}

		// if a realtime message is received the map is centered again
		// bounds={ this.mapBounds } boundsOptions={ { padding: [10, 10] } }

		let initialMapViewPortDefinition = {};

		if( this.props.options.mapBounds ) {
			initialMapViewPortDefinition.bounds = this.props.options.mapBounds;
		}
		else if( this.props.options.mapCenter && this.props.options.mapZoom ) {
			initialMapViewPortDefinition.center = this.props.options.mapCenter;
			initialMapViewPortDefinition.zoom = this.props.options.mapZoom;
		}

		if( Object.keys( initialMapViewPortDefinition ).length === 0 ) {
			initialMapViewPortDefinition.center = defaultMapCenter;
			initialMapViewPortDefinition.zoom = defaultMapZoom;
		}

		if( Object.keys( this.currentViewport ).length === 0 ) {
			this.currentViewport = initialMapViewPortDefinition;
		}

		return (
			<div className="map-container">
				<Map { ...initialMapViewPortDefinition } maxZoom={ 14 }
					animate={ true }
					onViewportChanged={ newViewport => {
						setTimeout( () => {
							this.currentViewport = newViewport;
						}, 300 );
					} } >

					<TileLayer
						url={ config.tileLayer.url } />

					{/* MarkerClusterGroup component is not updated if we change cluster options
						TODO: check if this is fixed and use only 1 MarkerClusterGroup layer */}

					{ disableClustering ?
						<MarkerClusterGroup { ...getDefaultClusterOptions( undefined ) } >
							{ markers }
						</MarkerClusterGroup> : '' }
					{ ! disableClustering ?
						<MarkerClusterGroup { ...getDefaultClusterOptions( 30 ) } >
							{ markers }
						</MarkerClusterGroup> : '' }


					{ polylines }

					{ this.getFullscreenControl( this.props.selectedDevices, this.props.options, this.props.devicesHistory ) }
					{ this.getClusteringControl( disableClustering ) }
				</Map>
			</div>
		);
	}
}

export default HistoricalRouteMap;
