// {
//     "type": "ValuesList",
//     "options": {
//         "stylable": {
//             "color": "#4a85b0",
//             "backgroundImage": "/icons/clock-arrow_white.svg"
//         },
//         "widgetTitle": "Temp history for device EA20016",
//         "widgetId": "temp-history-EA20016",
//         "propertyPath": [
//             "content",
//             "temp"
//         ],
//         "historyMessagesCount": 100,
//         "datasets": [
//             {
//                 "flowName": "smartcityDemo",
//                 "units": {
//                     "messages": "smartCityDemoMemoryStorage",
//                     "snapshot": "smartCityDemoMemoryStorage",
//                     "history": "smartCityDemoMemoryStorage"
//                 },
//                 "filters": [
//                     {
//                         "type": "has_snapshot_property",
//                         "options": {
//                             "propertyPath": [
//                                 "content",
//                                 "temp"
//                             ]
//                         }
//                     }
//                 ]
//             }
//         ],
//         "selection": [
//             {
//                 "type": "first_with_value",
//                 "propertyPath": [
//                     "id"
//                 ],
//                 "value": "EA20016"
//             }
//         ]
//     },
//     "position": {
//         "x": 0,
//         "y": 0,
//         "h": 10,
//         "w": 3
//     }
// }

import React, { Component } from 'react';

import * as moment from 'moment'

import stylable from './../../decorators/stylable.jsx';

import { getPropertyValueByPath } from './../../utils/devicesUtils.js';

const ListItem = ( props ) => {
	let { timeSet, value } = props;
	return (
		<div className="value-item">
			<div className="table-cell time" title={ moment( timeSet ).format( 'D-MM-YYYY H:mm:ss' ) }>
				{ moment( timeSet ).from() }
			</div>
			<div className="table-cell">{ value }</div>
		</div>
	)
}

const historyMessagesCount = 20;
class ValuesList extends Component {

	constructor ( props ) {
		super( props );

		this.state = {
			isFetching: true
		};
	}

	componentWillUpdate ( newProps, newState ) {
		this.prepareWidgetDevices( newProps );
	}

	componentDidUpdate ( prevProps, prevState ) {
		// fetch the history after the devices are received
		if ( !this.isSelectedDeviceLoaded( prevProps ) ) {
			this.fetchDeviceHistory();
		}
	}

	isSelectedDeviceLoaded ( newProps ) {
		if ( newProps.provided.selectedDevice ) {
			return true;
		}

		return false;
	}

	prepareWidgetDevices ( newProps ) {
		if ( newProps.provided ) {
			this.selectedDevice = newProps.provided.selectedDevice;
			this.isLoading = newProps.provided.isLoading;
			this.errors = newProps.provided.errors;
		}

		// update selected devices after realtime updates
		let { deviceHistory } = this.state,
			updatedDeviceHistory;

		if ( deviceHistory && this.selectedDevice ) {
			if ( deviceHistory[ 0 ].timeSet !== this.selectedDevice.snapshot.timeSet ) {
				updatedDeviceHistory = [ this.selectedDevice.snapshot, ...deviceHistory ];
				updatedDeviceHistory.pop();
				this.setState( {
					deviceHistory: updatedDeviceHistory
				} )
			}
		}
	}

	generateHistoryListMarkup () {
		let { propertyPath } = this.props.options;
		let history = this.state.deviceHistory;
		let innerMarkup = [];

		if ( history.length === 0 ) {
			return 'No history for the selected device';
		}

		history.forEach( item => {
			let propertyValue = getPropertyValueByPath( item, propertyPath );


			if ( propertyValue ) {
				innerMarkup.push(
					<ListItem key={ item.timeSet } value={ propertyValue } timeSet={ item.timeSet } />
				);
			}
		} );

		return (
			<div className="values-list-table">
				<div className="values-list-header">
					<div className="header-cell">Time Set</div>
					<div className="header-cell">Value</div>
				</div>
				<div className="values-list">
					{ innerMarkup }
				</div>
			</div>
		);
	}

	fetchDeviceHistory () {
		if ( this.isSelectedDeviceLoaded( this.props ) ) {
			let device = this.selectedDevice;
			let count = this.props.options.historyMessagesCount || historyMessagesCount;
			let historyPromise = this.props.api.fetchDevicesLastHistory( [ device ], count );

			if ( historyPromise ) {
				historyPromise.then( ( history ) => {
					// TODO: Remove history object when dublication of messages in the returned data is fixed
					let historyObj = {};
					history[ 0 ].forEach( item => {
						historyObj[ item.timeSet ] = item;
					} );
					// this.setState({
					// 	deviceHistory: [ ...history[ 0 ] ],
					// 	isFetching: false
					// });

					this.setState( {
						deviceHistory: [ ...Object.values( historyObj ) ],
						isFetching: false
					} );
				} );
			}
		}
	}

	render () {
		let deviceValuesMarkup;

		if ( !this.state.deviceHistory || this.state.isFetching === true ) {
			deviceValuesMarkup = 'Loading...';
		}
		else if ( this.errors.length ) {
			deviceValuesMarkup = this.errors;
		}
		else {
			deviceValuesMarkup = this.generateHistoryListMarkup();
		}

		return (
			<div className={ `values-widget` }>
				<div className="values-widget-text-container">
					{ deviceValuesMarkup }
				</div>
			</div>
		);
	}


}

export default stylable( ValuesList );
