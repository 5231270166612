import React, { useState, useRef } from 'react';

import addImage from './../../img/plus_white.svg';
import confirmImage from './../../img/checked_white.svg';

import './AddDashboardForm.css';

const AddDashboardForm = ( props ) => {
	const { onAdd } = props

	const [ dashboardName, setDashboardName ] = useState( '' );
	const [ isFormVisible, setIsFormVisible ] = useState( false );

	const nameInput = useRef( null );
	const onActionButtonClick = () => {
		if ( isFormVisible ) {
			if ( dashboardName ) {
				onAdd( dashboardName );
				setDashboardName( '' );
				setIsFormVisible( false );
			}
			else {
				// Cancel
				setIsFormVisible( false );
				nameInput.current.blur();
			}
		}
		else {
			setIsFormVisible( true );
			nameInput.current.focus();
		}
	}

	let iconImage;
	let iconCssClass = 'icon';
	let buttonCssClass = 'btn-nostyle';

	if ( !isFormVisible ) {
		iconImage = addImage;
		iconCssClass += ' add-icon'
	}
	else {
		if ( dashboardName ) {
			iconImage = confirmImage;
			iconCssClass += ' confirm-icon';
			buttonCssClass += ' active';
		}
		else {
			iconImage = addImage;
			iconCssClass += ' cancel-icon';
			buttonCssClass += ' problem';
		}
	}

	return (
		<>
			<div className={ `add-dashboard-name-form ${ !isFormVisible ? 'zero-width' : '' }` }>
				<input
					ref={ nameInput }
					placeholder="dashboard name"
					value={ dashboardName }
					onChange={ ( e ) => setDashboardName( e.target.value ) }
					onKeyDown={ ( e ) => {
						if ( e.key === 'Escape' && !dashboardName ) {
							onActionButtonClick()
						}
					} }
					onKeyPress={ ( e ) => {
						if ( e.key === 'Enter' && dashboardName ) {
							onActionButtonClick();
						}
					} }
					type="text" />
			</div>
			<button
				className={ buttonCssClass }
				onClick={ onActionButtonClick } >
				<img
					className={ iconCssClass }
					src={ iconImage }
					alt="add"
					title="Add new dashboard" />
			</button>
		</>
	);
}

export default AddDashboardForm;
