import React, { useState } from 'react';

import PropTypes from 'prop-types';

import { TwitterPicker as ColorPicker } from 'react-color';

import ColorIndicator from './ColorIndicator.jsx';
import IconPicker from './IconPicker.jsx';


const StylingPicker = ( props ) => {
	const { color, selectedIcon, icons, onIconChange, onColorChange } = props;

	let [ isColorPickerVisible, setIsColorPickerVisible ] = useState( false );

	return (
		<div>
			<div className="stylable-color-configuration">
				Color
					{ isColorPickerVisible ? (
					<ColorPicker
						color={ color }
						colors={ [ '#FFFFFF', '#02161D', '#0099CC', '#586BA4', '#73BF21', '#FFBC05', '#FF8A10', '#D0021B' ] }
						width={ '15rem' }
						triangle={ "hide" }
						onChangeComplete={ ( color ) => {
							onColorChange( color );
							setIsColorPickerVisible( false );
						} }
					/>
				) : (
						<ColorIndicator
							color={ color }
							onClick={ () => setIsColorPickerVisible( true ) } />
					) }
			</div>
			<div>
				Icon
					<IconPicker icons={ icons } onChange={ onIconChange } selectedIcon={ selectedIcon } />
			</div>
		</div>
	);
};

StylingPicker.propTypes = {
	color: PropTypes.string,
	selectedIcon: PropTypes.string,
	icons: PropTypes.array.isRequired,
	onIconChange: PropTypes.func.isRequired,
	onColorChange: PropTypes.func.isRequired
};

export default StylingPicker;
