import {
	ADD_USER_META_SUCCESS,
	UPDATE_USER_META_SUCCESS,
	FETCH_USER_META_STARTED,
	FETCH_USER_META_SUCCESS,
	FETCH_USER_META_FAILED
} from './../actions/userActions'

export function userReducer ( state = {
	isSyncing: false,
	error: null,
	user: {},
}, action ) {
	let user;
	switch ( action.type ) {
		case ADD_USER_META_SUCCESS:
			return {
				...state,
				user: {
					...state.user,
					meta: {
						...state.user.meta,
						[ action.key ]: action.value
					}
				}
			}
		case UPDATE_USER_META_SUCCESS:
			return {
				...state,
				user: {
					...state.user,
					meta: {
						...state.user.meta,
						[ action.key ]: action.value
					}
				}
			}
		case FETCH_USER_META_STARTED:
			return Object.assign( {}, state, {
				isSyncing: true
			} )
		case FETCH_USER_META_SUCCESS:
			user = Object.assign( {}, state.user, {
				meta: action.meta
			} );

			return Object.assign( {}, state, {
				isSyncing: false,
				error: null,
				user: user
			} )
		// TODO: move to sub-reducer
		case FETCH_USER_META_FAILED:
			user = Object.assign( {}, state.user, {
				meta: null
			} );

			return Object.assign( {}, state, {
				isSyncing: false,
				error: action.error,
				user: user
			} );
		default:
			return state
	}
}
