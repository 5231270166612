import React, { Component } from 'react';

import * as moment from 'moment';

import C3Chart from 'react-c3js';
import 'c3/c3.css';

const defaultColors = [ '#0099cc', '#cc0000', '#ffbb33', '#99cc00' ];

class MultipleTimeseriesChart extends Component {
	/**
	 * Return if we need to re-render the component
	 * This is not done automatically since we're providing new arrays with same data
	 */
	shouldComponentUpdate ( newProps, newState ) {
		let { valuesArray, isFetching } = this.props;

		let newValuesArray = newProps.valuesArray
		let newIsFetching = newProps.isFetching;

		if( isFetching !== newIsFetching ) {
			return true;
		}


		if( valuesArray.length !== newValuesArray.length ) {
			return true;
		}

		if( this.props.forceRefreshCounter !== newProps.forceRefreshCounter ) {
			return true;
		}

		let valuesArrayIndex = 0;
		for( let valuesArrayItem of valuesArray ) {
			if( !newValuesArray[ valuesArrayIndex ] ) {
				return true;
			}
			if( newValuesArray[ valuesArrayIndex ].length !== valuesArrayItem.length ) {
				return true;
			}

			let valueIndex = 0;
			for( let valueItem of valuesArray[ valuesArrayIndex ] ) {
				if( valueItem !== newValuesArray[ valuesArrayIndex ][ valueIndex ] ) {
					return true
				}
				valueIndex++;
			}

			valuesArrayIndex++;
		}

		return false;
	}

	render () {
		let { xAxisBindings, valuesArray, isFetching, valuesColors, showXAxis, showYAxis, showXGridLines, showYGridLines } = this.props;

		let chartLoading = (
			<div className="chart-data-loading">Loading data...</div>
		);

		let chartNoData = (
			<div className="no-chart-data">No data for the selected period</div>
		);

		let chartCanvasInnerContainer;
		if( isFetching ) {
			chartCanvasInnerContainer = chartLoading;
		}
		else if( !valuesArray.length ) {
			chartCanvasInnerContainer = chartNoData;
		}
		else {
			let chartColors = {};

			let colorIndex = 0;
			for( let lineName in xAxisBindings ) {
				if( valuesColors[ colorIndex ] ) {
					chartColors[ lineName ] = valuesColors[ colorIndex ];
				}
				else {
					chartColors[ lineName ] = defaultColors[ colorIndex % 4 ];
				}
				colorIndex++;
			}
			let chartProps = {
				data: {
					// there is a problem with reloading the chart after change in the date inputs
					// unload: true,
					xs: xAxisBindings,
					xFormat: '%Y-%m-%d %H:%M:%S',
					columns: valuesArray,
					colors: chartColors
				},
				axis: {
					x: {
						show: showXAxis,
						type: 'timeseries',
						tick: {
							format: '%d-%m-%Y',
							culling: {
								max: 4
							}
						}
					},
					y: {
						show: showYAxis
					}
				},
				tooltip: {
					format: {
						title: function ( d ) {
							return moment( d ).format( 'HH:mm:ss | DD-MM-YYYY' );
						}
					}
				},
				point: {
					show: false
				},
				grid: {
					x: {
						show: showXGridLines
					},
					y: {
						show: showYGridLines
					}
				},
				padding: {
					left: 50,
					right: 50
				}
			}

			chartCanvasInnerContainer = ( <C3Chart className="c3-multiple-ts-charts" { ...chartProps } /> );
		}

		return (
			<div className="chart-canvas-container">
				{ chartCanvasInnerContainer }
			</div>
		);
	}
}

export default MultipleTimeseriesChart;