import {
	FETCH_DEVICE_HISTORY_STARTED,
	FETCH_DEVICE_HISTORY_SUCCESS,
	FETCH_DEVICE_HISTORY_FAIL,
} from './../../actions/unitsActions'

import { getUniqueDeviceHistoryId } from './../../utils/devicesUtils.js'


export function devicesHistoryReducer ( state = {
	currentlySyncing: 0,
	lastError: null,
	devicesHistory: {},
}, action ) {
	switch ( action.type ) {
		case FETCH_DEVICE_HISTORY_STARTED:

			return Object.assign( {}, state, {
				currentlySyncing: state.currentlySyncing + 1,
				lastError: null,
				devicesHistory: Object.assign( {}, state.devicesHistory, {
					[ getUniqueDeviceHistoryId( action.unitId, action.deviceId, action.range ) ]: {
						history: null,
						isSyncing: true,
						error: null
					}
				} ),
			} );


		case FETCH_DEVICE_HISTORY_SUCCESS:
			return Object.assign( {}, state, {
				currentlySyncing: state.currentlySyncing - 1,
				lastError: null,
				devicesHistory: Object.assign( {}, state.devicesHistory, {
					[ getUniqueDeviceHistoryId( action.unitId, action.deviceId, action.range ) ]: {
						history: action.history,
						isSyncing: false,
						error: null
					}
				} )
			} );

		case FETCH_DEVICE_HISTORY_FAIL:
			return Object.assign( {}, state, {
				currentlySyncing: state.currentlySyncing - 1,
				lastError: action.error,
				devicesHistory: Object.assign( {}, state.devicesHistory, {
					[ getUniqueDeviceHistoryId( action.unitId, action.deviceId, action.range ) ]: {
						history: null,
						isSyncing: false,
						error: action.error
					}
				} )
			} );





		// case FETCH_USER_STARTED:
		// 	return Object.assign( {}, state, {
		// 		isFetching: true
		// 	})
		// case FETCH_USER_SUCCESS:
		// 	return Object.assign( {}, state, {
		// 		isFetching: false,
		// 		error: null,
		// 		dashboards: action.dashboards
		// 	})
		// case FETCH_USER_FAIL:
		// 	return Object.assign( {}, state, {
		// 		isFetching: false,
		// 		error: action.error,
		// 		dashboards: null
		// 	})
		default:
			return state
	}
}
