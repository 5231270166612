import React from 'react';

export default function BulkNotificationsDeleteConfirmBox ( props ) {
	return (
		<div className="confirm-bulk-delete-container">
			<span className="confirm-message">
				Delete { props.bulkCount } items?
			</span>
			<span className="confirm-bulk-delete-actions">
				<button onClick={ props.onConfirm } className="confirm-bulk-delete-button">Yes</button>
				<button onClick={ props.onAbort } >No</button>
			</span>
		</div>
	);
};
