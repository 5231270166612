import React, { Component } from 'react';

import StylingPicker from './StylingPicker.jsx';

import icons from './IconsDefinitions.js';

import './StylableConfiguration.css';

export default class StylableConfiguration extends Component {
	static getFieldConfigurationStructure ( field, value ) {
		let processedValue;

		try {
			processedValue = JSON.parse( value );
		}
		catch ( err ) {
			processedValue = value;
		}

		let fieldValue = {
			[ field.id ]: processedValue
		}

		if ( field.required && !value ) {
			fieldValue._errors = {
				[ field.id ]: [ {
					field: field.id,
					type: 'REQUIRED_EMPTY',
					message: `${ field.label } is required`
				} ]
			};
		}

		return fieldValue;
	}

	constructor ( props ) {
		super( props );

		// this.onChange = this.onChange.bind( this );
		this.onColorChange = this.onColorChange.bind( this );
		this.onIconChange = this.onIconChange.bind( this );
		this.onViewTypeChange = this.onViewTypeChange.bind( this );
	}

	render () {
		let {
			value,
			field,
			errors
		} = this.props;

		let errorsMessages = [];

		if ( errors && errors.length ) {
			errorsMessages = errors.map( ( error, index ) => ( ( <div key={ index } className="validation-error">{ error.message }</div> ) ) )
		}

		let color;
		let icon;

		if ( value.color ) {
			color = value.color;
		}
		else {
			color = '#FFFFFF';
		}

		if ( value.icon ) {
			icon = value.icon;
		}

		let { variants, viewTypes } = field;

		return (
			<div className="stylable-configuration">
				{ viewTypes ? (
					<div>
						View type:
						<select onChange={ this.onViewTypeChange }>
							<option value="">Default</option>
							{ viewTypes.map( viewType => ( <option key={ viewType.key } value={ viewType.key } >{ viewType.name }</option> ) ) }
						</select>
					</div>
				) : null }
				{ errorsMessages }
				<div className="styling-picker-container">
					{ variants ? ( <h4>Default Styling</h4> ) : null }
					<StylingPicker color={ color } icons={ icons } onIconChange={ this.onIconChange } onColorChange={ this.onColorChange } selectedIcon={ icon } />
				</div>
				{ variants ? (
					variants.map( variant => {
						let variantsValues = StylableConfiguration.getDefaultVariantsValue( value );
						let variantColor,
							variantIcon;

						if ( variantsValues && variantsValues[ variant.key ] ) {
							variantColor = variantsValues[ variant.key ].color;
							variantIcon = variantsValues[ variant.key ].icon;
						}
						return (
							<div key={ variant.name } className="styling-picker-container">
								<h4>{ variant.name }</h4>
								<StylingPicker color={ variantColor } icons={ icons } onIconChange={ ( icon ) => {
									this.onVariantIconChange( variant, icon );
								} } onColorChange={ ( color ) => {
									this.onVariantColorChange( variant, color );
								} } selectedIcon={ variantIcon } />
							</div>
						)
					} )
				) : null }
			</div>
		);
	}

	onChange ( value ) {
		this.props.onChange( this.props.field.id, value );
	}

	onColorChange ( color ) {
		let value = this.getDefaultFieldValue();

		value.color = color.hex;

		this.onChange( value );
	}

	onVariantColorChange ( variant, color ) {
		let value = this.getDefaultFieldValue();

		let variants = StylableConfiguration.getDefaultVariantsValue( value );

		if ( !variants[ variant.key ] ) {
			variants[ variant.key ] = {};
		}

		variants[ variant.key ].color = color.hex;

		value.variants = variants;

		this.onChange( value );
	}

	onIconChange ( icon ) {
		let value = this.getDefaultFieldValue();

		if ( icon ) {
			value.icon = icon.pathBase;
		}
		else {
			value.icon = null;
		}

		this.onChange( value );
	}

	onVariantIconChange ( variant, icon ) {
		let value = this.getDefaultFieldValue();

		let variants = StylableConfiguration.getDefaultVariantsValue( value );

		if ( !variants[ variant.key ] ) {
			variants[ variant.key ] = {};
		}

		variants[ variant.key ].icon = icon.pathBase;

		value.variants = variants;

		this.onChange( value );
	}

	onViewTypeChange ( e ) {
		let viewTypeValue = e.target.value;
		let value = this.getDefaultFieldValue();

		if ( viewTypeValue ) {
			value.widgetView = viewTypeValue;
		}
		else {
			value.widgetView = null;
		}

		this.onChange( value );
	}

	getDefaultFieldValue () {
		let value = this.props.value;

		if ( !value ) {
			value = {};
		}

		return value;
	}

	static getDefaultVariantsValue ( value ) {
		let variants = value.variants;

		if ( !variants ) {
			variants = {};
		}

		return variants;
	}
}

