import React, { PureComponent } from 'react';

import { decapitalizeFirstLetter } from '../../../../utils/generalUtils.js';

import DatasetsTabs from './DatasetsTabs.jsx';

import DatasetItemSelector from './DatasetItemSelector.jsx';
import DatasetConfigurationHelp from './DatasetConfigurationHelp.jsx';

import './DatasetSelectorFieldSet.css';

class DatasetsSelectorFieldSet extends PureComponent {
	static getFieldConfigurationStructure ( field, value ) {
		let errors = [];
		let fieldValues = [];

		if ( !value ) {
			errors.push( {
				field: field.id,
				type: 'REQUIRED_EMPTY',
				message: `${ field.label } are required`
			} );
		}
		else {
			value.forEach( ( dataset, datasetIndex ) => {
				if ( !dataset ) {
					return null;
				}

				let {
					flow,
					realtimeUnit,
					snapshotUnit,
					historyUnit,
					filters
				} = dataset;

				// let datasetErrors = [];

				if ( !flow ) {
					errors.push( {
						datasetIndex,
						field: 'flow',
						type: 'REQUIRED_EMPTY',
						message: `Flow is required`
					} );
				}

				if ( !snapshotUnit ) {
					errors.push( {
						datasetIndex,
						field: 'snapshotUnit',
						type: 'REQUIRED_EMPTY',
						message: `Snapshot Unit is required`
					} );
				}

				if ( filters ) {
					filters.forEach( ( filter, filterIndex ) => {
						let { options } = filter;

						if ( options ) {
							let { propertyPath } = options;

							// Sanitize PropertyPath (remove trailing null)
							if ( propertyPath && propertyPath.length ) {
								if ( propertyPath[ propertyPath.length - 1 ] === null ) {
									propertyPath.pop();
								}

								propertyPath.forEach( ( pathItem, index, array ) => {
									array[ index ] = decapitalizeFirstLetter( pathItem );
								} );
							}
							else {
								errors.push( {
									datasetIndex,
									filterIndex,
									field: 'propertyPath',
									type: 'REQUIRED_EMPTY',
									message: 'property is required for a filter'
								} );
							}
						}
					} )
				}

				fieldValues.push( {
					flowName: flow,
					units: {
						messages: realtimeUnit,
						snapshot: snapshotUnit,
						history: historyUnit
					},
					filters
				} );
			} );
		}

		let datasetFieldValue = {
			[ field.id ]: fieldValues
		};

		if ( errors.length ) {
			datasetFieldValue._errors = {
				[ field.id ]: errors
			};
		}


		return datasetFieldValue;
	}

	constructor ( props ) {
		// Props are super
		super( props );

		let datasets = this.getSanitizedFieldValue();
		let selectedDatasetTab = this.getDefaultSelectedTab( datasets );

		this.state = {
			selectedDatasetTab
		}

		this.onFieldChange = this.onFieldChange.bind( this );
		this.onDatasetItemChange = this.onDatasetItemChange.bind( this );
		this.onDatasetItemDelete = this.onDatasetItemDelete.bind( this );
		this.onAddDatasetClick = this.onAddDatasetClick.bind( this );
	}

	render () {
		let {
			field,
			errors
		} = this.props

		let isLast = false;
		let datasets = this.getSanitizedFieldValue();

		if ( datasets.filter( dataset => !!dataset ).length === 1 ) {
			isLast = true;
		}

		let topLevelErrors = [];
		let innerLevelErrors = [];

		if ( errors && errors.length ) {
			errors.forEach( error => {
				if ( error.field === field.id ) {
					topLevelErrors.push( error );
				}
				else {
					innerLevelErrors.push( error );
				}
			} );
		}

		topLevelErrors = topLevelErrors.map( ( error, index ) => ( <div key={ index } className="validation-error">{ error.message }</div> ) )

		return (
			<div>
				{ field.label }
				{ field.required ? '*' : '' }
				<DatasetConfigurationHelp />
				{ topLevelErrors }
				<div>
					<DatasetsTabs datasets={ datasets } selectedDatasetTab={ this.state.selectedDatasetTab } onSelect={ ( index ) => this.setState( { selectedDatasetTab: index } ) } onAddDatasetClick={ this.onAddDatasetClick } />
					{
						this.state.selectedDatasetTab !== null ? (
							<DatasetItemSelector
								key={ this.state.selectedDatasetTab }
								index={ this.state.selectedDatasetTab }
								dataset={ datasets[ this.state.selectedDatasetTab ] }
								onChange={ this.onDatasetItemChange }
								onDelete={ this.onDatasetItemDelete }
								flows={ this.props.flows }
								isLast={ isLast }
								errors={
									innerLevelErrors.filter( error =>
										error.datasetIndex === this.state.selectedDatasetTab )
								}
							/>
						)
							: null
					}
				</div>
			</div>

		)
	}

	getSanitizedFieldValue () {
		let value = this.props.value;

		if ( !value ) {
			value = [ {} ];
		}

		return value;
	}

	getDefaultSelectedTab ( datasets, currentIndex = 0 ) {
		let defaultTabIndex;

		// We go up to search for a suitable index, that is close to the current one
		for ( let index = currentIndex; index < datasets.length; index++ ) {
			if ( datasets[ index ] ) {
				defaultTabIndex = index;
				break;
			}
		}

		if ( !defaultTabIndex ) {
			// If we don't find such, we go downwards.
			// This will always return an item, since the last one cannot be removed
			for ( let index = currentIndex - 1; index >= 0; index-- ) {
				if ( datasets[ index ] ) {
					defaultTabIndex = index;
					break;
				}
			}
		}

		return defaultTabIndex
	}

	onAddDatasetClick () {
		let datasets = this.getSanitizedFieldValue();

		datasets = [ ...datasets, {} ];

		this.setState( {
			selectedDatasetTab: datasets.length - 1
		} );

		this.onFieldChange( datasets );
	}

	onDatasetItemChange ( datasetIndex, updatedDatasetItem ) {
		let datasets = this.getSanitizedFieldValue();

		datasets = datasets.map( ( dataset, index ) =>
			( index === datasetIndex ? updatedDatasetItem : dataset ) );

		this.onFieldChange( datasets );
	}

	onDatasetItemDelete ( datasetIndex ) {
		let datasets = this.getSanitizedFieldValue();

		datasets = datasets.map( ( dataset, index ) => ( datasetIndex !== index ? dataset : null ) );

		if ( this.state.selectedDatasetTab === datasetIndex ) {
			let selectedDatasetTab = this.getDefaultSelectedTab( datasets, datasetIndex );
			this.setState( { selectedDatasetTab } )
		}

		this.onFieldChange( datasets );
	}

	onFieldChange ( value ) {
		this.props.onChange( this.props.field.id, value );
	}
}

export default DatasetsSelectorFieldSet;