import React, { Component } from 'react';

import * as moment from 'moment';

export default class CondensedTimestamp extends Component {
	render () {
		let {
			cssClass,
			timestamp,
			outputFormats
		} = this.props;

		let now = moment(),
			timestampMoment = moment( timestamp ),
			outputFormat;

		if( !outputFormats ) {
			outputFormats = {
				day: 'HH:mm',
				year: 'DD MMM',
				default: 'DD-MM-YYYY'
			}
		}

		// There is a built-in thing like this in moment - moment().calendar
		// We don't use it, since it has built-in support for things around day/week
		// And to override it, it needs modification to the whole moment() object.

		if( now.diff( timestampMoment, 'day' ) === 0 && now.get( 'day' ) === timestampMoment.get( 'day' ) ) {
			outputFormat = outputFormats[ 'day' ]
		}
		else if( now.diff( timestampMoment, 'year' ) === 0 && now.get( 'year' ) === timestampMoment.get( 'year' ) ) {
			outputFormat = outputFormats[ 'year' ]
		}
		else {
			outputFormat = outputFormats[ 'default' ];
		}

		return (
			<div className={ cssClass } >
				{ timestampMoment.format( outputFormat ) }
			</div>
		);
	}
}
