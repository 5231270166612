import React, { Component } from 'react';

import { connect } from 'react-redux';

import DevicesOverviewSideMenu from './DevicesOverviewSideMenu.jsx';
import DevicesOverviewHeader from './DevicesOverviewHeader.jsx';
import DevicesOverviewPropertiesList from './DevicesOverviewPropertiesList.jsx';
import DevicesOverviewLocator from './DevicesOverviewLocator.jsx';
import DevicesOverviewCommunicationsList from './DevicesOverviewCommunicationsList.jsx';
import SelectedPropertyOverview from './SelectedPropertyOverview.jsx';


import * as dashboardActions from './../../actions/popupActions.js';

import { getDeviceName } from './../../utils/devicesUtils.js';

class DevicesOverviewPopup extends Component {
	constructor ( props ) {
		super( props );

		let selectedDeviceIndex = null;
		if ( this.props.devices.length === 1 ) {
			selectedDeviceIndex = 0;
		}

		this.state = {
			selectedDeviceIndex: selectedDeviceIndex,
			selectedPropertyName: null,
			isLeftColumnShown: true,
			isMapFullscreenOn: false,
		}

		this.onSidebarDeviceClicked = this.onSidebarDeviceClicked.bind( this );
		this.onPropertyClicked = this.onPropertyClicked.bind( this );
		this.onCloseButtonClicked = this.onCloseButtonClicked.bind( this );
		this.onToggleLeftColumn = this.onToggleLeftColumn.bind( this );
		this.onToggleMapFullscreen = this.onToggleMapFullscreen.bind( this );

	}

	render () {
		const { isMapFullscreenOn, isLeftColumnShown } = this.state;
		const { deviceOptions, devices, api } = this.props;

		const sideMenuDevices = devices.map( ( device, index ) => {
			return {
				id: index,
				name: getDeviceName( device, deviceOptions ),
				defaultName: device.snapshot.id
			}
		} );

		const selectedDeviceIndex = this.state.selectedDeviceIndex;
		const selectedDevice = devices[ selectedDeviceIndex ];
		const propertiesListBasePath = [ 'snapshot', 'content' ];

		let latPath = [ 'snapshot', 'content', 'lat' ];
		let lngPath = [ 'snapshot', 'content', 'lng' ];

		if ( this.props.latPath ) {
			latPath = [ 'snapshot', ...this.props.latPath ];
		}

		if ( this.props.lngPath ) {
			lngPath = [ 'snapshot', ...this.props.lngPath ];
		}

		const communicationMessagesPath = [ 'snapshot', 'content', 'messageTypes' ];

		let selectedPropertyPath;
		let selectedPropertyName = this.state.selectedPropertyName;

		if ( selectedPropertyName ) {
			selectedPropertyPath = [ ...propertiesListBasePath, selectedPropertyName ]
		}


		let getReadableNameForProperty = ( propertyName ) => {
			let [ , ...relativePropertiesListPath ] = propertiesListBasePath;

			return api.getDevicePropertyReadableName( selectedDevice, [ ...relativePropertiesListPath, propertyName ] );
		}

		let getUnitForProperty = ( propertyName ) => {
			let [ , ...relativePropertiesListPath ] = propertiesListBasePath;

			return api.getDevicePropertyUnit( selectedDevice, [ ...relativePropertiesListPath, propertyName ] );
		}

		return (
			<div className="popup-markup-container">
				<div className="close-widget-popup" onClick={ this.onCloseButtonClicked }>
					<i className="fa fa-times" aria-hidden="true"></i>
				</div>
				<DevicesOverviewSideMenu onDeviceClicked={ this.onSidebarDeviceClicked } selectedDeviceIndex={ selectedDeviceIndex } devices={ sideMenuDevices } />
				<div className="sow-sensors-popup-main-content with-sidebar">
					<DevicesOverviewHeader selectedDeviceName={ selectedDevice ? getDeviceName( selectedDevice, deviceOptions ) : null } />
					<div className={ "sow-popup-content-left-column" + ( isLeftColumnShown ? ( isMapFullscreenOn ? ' expanded' : '' ) : ' hidden' ) }>
						<DevicesOverviewPropertiesList selectedDevice={ selectedDevice } propertiesBasePath={ propertiesListBasePath } onPropertyClicked={ this.onPropertyClicked } selectedPropertyName={ this.state.selectedPropertyName } getUnitForProperty={ getUnitForProperty } getReadableNameForProperty={ getReadableNameForProperty } />
						<DevicesOverviewLocator toggleLocatorContainer={ this.onToggleMapFullscreen } selectedDevice={ selectedDevice } latPath={ latPath } lngPath={ lngPath } />
						<DevicesOverviewCommunicationsList selectedDevice={ selectedDevice } communicationMessagesPath={ communicationMessagesPath } />
					</div>
					<div className={ "sow-popup-content-right-column" + ( isLeftColumnShown ? '' : ' expanded' ) }>
						<SelectedPropertyOverview toggleChartContainer={ this.onToggleLeftColumn } chartOptions={ this.props.propChartOptions } selectedDevice={ selectedDevice } selectedPropertyPath={ selectedPropertyPath } api={ this.props.api } getUnitForProperty={ getUnitForProperty } getReadableNameForProperty={ getReadableNameForProperty } />
					</div>
				</div>
			</div>
		);
	}

	onCloseButtonClicked () {
		this.props.closePopup();
	}

	onSidebarDeviceClicked ( selectedDeviceIndex ) {
		if ( this.state.selectedDeviceIndex === selectedDeviceIndex ) {
			this.setState( {
				selectedDeviceIndex: null,
				selectedPropertyName: null,
				isLeftColumnShown: true,
				isMapFullscreenOn: false
			} )
		}
		else {
			this.setState( {
				selectedDeviceIndex,
				selectedPropertyName: null,
				isLeftColumnShown: true,
				isMapFullscreenOn: false
			} )
		}
	}

	onPropertyClicked ( selectedPropertyName ) {
		if ( this.state.selectedPropertyName === selectedPropertyName ) {
			this.setState( {
				selectedPropertyName: null
			} );
		}
		else {
			this.setState( {
				selectedPropertyName
			} )
		}
	}

	onToggleLeftColumn () {
		this.setState( {
			isLeftColumnShown: !this.state.isLeftColumnShown,
			isMapFullscreenOn: false
		} );
	}

	onToggleMapFullscreen () {
		this.setState( {
			isLeftColumnShown: true,
			isMapFullscreenOn: !this.state.isMapFullscreenOn
		} );
	}
}

function mapStateToProps ( state, ownProps ) {
	return {

	};
}

function mapDispatchToProps ( dispatch ) {
	return {
		closePopup: () => {
			dispatch( dashboardActions.closePopup() );
		}
	};
}

export default connect( mapStateToProps, mapDispatchToProps )( DevicesOverviewPopup );
