import React, { PureComponent } from 'react';

import PropTypes from 'prop-types';

import DatasetFilterItemSelector from './DatasetFilterItemSelector.jsx';

import './DatasetFiltersSelector.css';

class DatasetFiltersSelector extends PureComponent {
	constructor ( props ) {
		// Props are super
		super( props );

		this.onAddFilter = this.onAddFilter.bind( this );
		this.onChange = this.onChange.bind( this );
		this.onFilterDelete = this.onFilterDelete.bind( this );
	}

	getFilters () {
		let { filters } = this.props;

		if ( !filters ) {
			filters = [ {
				type: 'has_snapshot_property'
			} ]
		}

		return filters;
	}

	onAddFilter ( e ) {
		let filters = this.getFilters();

		let newFilters = [ ...filters, {} ];

		this.props.onChange( newFilters );
	}

	onFilterDelete ( deletedIndex ) {
		let newFilters = this.props.filters.filter(
			( _, index ) => ( deletedIndex !== index ) );

		if ( newFilters.length === 0 ) {
			this.props.onToggle();
		}

		this.props.onChange( newFilters );
	}

	onChange ( filterIndex, updatedFilterItem ) {
		let filters = this.getFilters();

		let newFilters = filters.map( ( filter, index ) =>
			( index === filterIndex ? updatedFilterItem : filter ) );

		this.props.onChange( newFilters );
	}

	render () {
		let {
			flow,
			snapshotUnit,
			showFilterView,
			onToggle,
			errors
		} = this.props;

		let filters = this.getFilters();

		return (
			<div>
				{ showFilterView ? (
					<>
						<div>
							{
								filters.map( ( filter, index ) => {
									let filterErrors = errors.filter( error => error.filterIndex === index );

									return (
										<DatasetFilterItemSelector
											flow={ flow }
											snapshotUnit={ snapshotUnit }
											key={ index }
											index={ index }
											filter={ filter }
											onChange={ this.onChange }
											onDelete={ this.onFilterDelete }
											errors={ filterErrors } />
									);
								} )
							}
						</div>
						<button onClick={ this.onAddFilter }>Add a filter</button>
					</>
				) : (
						<button className="apply-filters-icon" title="Apply filters" onClick={ onToggle }>
							Apply Filters
						</button>
					)
				}
			</div>
		);
	}
}

DatasetFiltersSelector.propTypes = {
	filters: PropTypes.array,
	flow: PropTypes.string,
	snapshotUnit: PropTypes.string,
	showFilterView: PropTypes.bool.isRequired,
	onToggle: PropTypes.func.isRequired,
	onChange: PropTypes.func.isRequired,
	errors: PropTypes.array
}

export default DatasetFiltersSelector;
