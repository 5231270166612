import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import createRootReducer from '../reducers'
import { routerMiddleware } from 'connected-react-router'


export default function configureStore ( history ) {
	const middleware = routerMiddleware( history )

	return createStore(
		createRootReducer( history ),
		applyMiddleware( middleware, thunk )
	)
}