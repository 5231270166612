// THE WIDGET IS DEPRICATED
// Please DO NOT use it
// Replaced by AccumulatedBarsWidget

// {
// 	"type": "ProrailMultipleBarriersDuration",
// 	"options": {
// 		"widgetTitle": "Barriers closed duration",
// 		"widgetId": "prorail-closed-multiple-barriers-duration-widget",
// 		"propertyPath": [
// 			"content",
// 			"barrierMessageBatch",
//			"messages"
// 		],
// 		"accumulationProperties": {
// 			"keyValue": "Down",
// 			"condition": "==="
// 		}
// 	},
// 	"position": {
// 		"x": 0,
// 		"y": 0,
// 		"h": 13,
// 		"w": 4
// 	}
// }
/**
 * extracts, processes and saves timeSets and values collection from device history
 * @param { array } history  - single device history
 * @param { object } options - widget options
 * @param { array } timeSetsCollection - empty variable in which the timeSets should be pushed to
 * @param { array } valuesCollection - empty variable in which the values should be pushed to
 */

import React, { Component } from 'react';
import request from 'superagent';

import './styles.css';
import Multiselect from '../../components/ReactWidgets/Multiselect';

import * as moment from 'moment';
import stylable from './../../decorators/stylable.jsx';

import { applyAccumulationFilter, addMissingPeriodsToResult }  from './../../utils/history/accumulationFilters.js';

import MultipleIndexedBarChart from './../../components/Charts/MultipleIndexedBarChart.jsx';
import RangeDateTimePickers from '../../components/DateTimePickers/RangeDateTimePickers';

import {
	getPropertyValueByPath,
	findDeviceExternalId
} from './../../utils/devicesUtils.js';

const ACCUMULATION_FILTER = 'per_hour';
const prorailAPI = '';

let extactAndSortObjectValues = ( data ) => {
		let keys = Object.keys( data ),
		sortedValues = [];

		keys.sort();

		keys.forEach( ( key ) => {
			sortedValues.push( data[ key ] );
		} );

		return sortedValues;
	};

class ProrailMultipleBarriersDuration extends Component {
	constructor ( props ) {
		// props are super!
		super( props );

		this.state = {
			selectedDevices: [],
			devicesHistory: {},
			areDatesDirty: false,
			isHistoryLoading: false
		}

		this.maxDatesInterval = {
			"unit": "days",
			"size": 7
		}

		this.savedWidgetState = this.props.api.getWidgetState();
	}

	componentWillMount () {
		this.fetchWidgetDataIfNeeded( this.props, this.state, null );

		this.setupDefaults( this.props );
	}

	componentWillReceiveProps ( nextProps ) {
		this.setupDefaults( nextProps );
	}

	componentWillUpdate ( newProps, newState ) {
		this.fetchWidgetDataIfNeeded( newProps, newState, this.state );
	}

	_dateConfigurationToMoment ( dateConfiguration, isToDate ) {
		let result;

		if( dateConfiguration ) {
			let { type, offset, value } = dateConfiguration;

			if( type === 'relative_to_now' ) {
				result = moment().subtract( offset.amount, offset.unit );
			}
			else {
				result = moment( value );
			}
		}
		else {
			if( isToDate ) {
				result = moment();
			}
			else {
				result = moment().subtract( 3, 'hours' );
			}
		}

		// normalize date
		result.set( 'seconds', 0 );
		result.set( 'milliseconds', 0 );

		return result;
	}

	setupDefaults( props ) {
		// let oldProps = this.props;

		// setup selectedDevices only once in the beginning
		// if ( oldProps.provided.devices.length === 0 && props.provided.devices.length > 0 ) {
		// 	this.setupSelectedDevices();
		// }

		if( !this.state.areDatesDirty ) {
			let { fromDate, toDate } = this.state;

			if( !fromDate && !toDate ) {
				let { defaultFromDate, defaultToDate } = props.options

				let newFromDate = this._dateConfigurationToMoment( defaultFromDate );
				let newToDate = this._dateConfigurationToMoment( defaultToDate, true );

				this.setState({
					fromDate: newFromDate,
					toDate: newToDate
				});
			}
		}
	}

	setupSelectedDevices () {
		let	widgetDevices = this.widgetDevices,
			selectedDevicesNames = this.savedWidgetState ? this.savedWidgetState.selectedDevicesNames : undefined,
			selectedDevices = [];

		if( selectedDevicesNames ) {
			for ( let i in selectedDevicesNames ) {
				for( let j in widgetDevices ) {
					if( widgetDevices[ j ].name === selectedDevicesNames[ i ] ) {
						selectedDevices.push( widgetDevices[ j ] );

						break;
					}
				}
			}

			this.setState({
				selectedDevices
			});
		}
		else if ( widgetDevices.length === 1 ) {
			this.setState({
				selectedDevices: widgetDevices
			});
		}
	}

	_generateDataForChart ( accumulationResults ) {
		let deviceId,
			currentData,
			currentCollection,

			devices = [],
			xTickLabels = [],
			valuesArray = [];

		for ( deviceId in accumulationResults ) {
			currentData = accumulationResults[ deviceId ];

			devices.push( deviceId );
			currentCollection = [ deviceId, ...extactAndSortObjectValues( currentData ) ];
			valuesArray.push(currentCollection );

			if ( xTickLabels.length === 0 ) {
				xTickLabels = Object.keys( currentData );
				xTickLabels.sort();
			}
		}

		return {
			devices,
			xTickLabels,
			valuesArray
		};

	}

	findProvidedDeviceWithId ( deviceId ) {
		return this.props.provided.devices.find( device => device.deviceId === deviceId );
	}

	render() {
		let { options } = this.props,
			{ selectedDevice, fromDate, toDate } = this.state,
			widgetDevices = this.props.widgetDevices,

			isFetching = false,
			deviceId,

			accumulationResults = {},
			valuesColors = [],

			chartData,
			textColor,
			multiChart,
			multiSelect;

		for( deviceId in this.state.devicesHistory ) {
			let historicalDevice = this.findProvidedDeviceWithId( deviceId ),
				externalDeviceId = findDeviceExternalId( historicalDevice ),
				singleDeviceHistory = this.state.devicesHistory[ deviceId ];

			if( singleDeviceHistory.isFetching ) {
				isFetching = true;
			}
			else if( singleDeviceHistory.history ) {
				let keyValue = options.accumulationProperties.keyValue,
					condition = options.accumulationProperties.condition,
					timeSetCollection = [],
					valuesCollection = [],
					accumulationResult = {};

				singleDeviceHistory.history.forEach( ( historyEntry ) => {
					const propertyValue = getPropertyValueByPath( historyEntry, options.propertyPath );

					if( typeof propertyValue === 'boolean' ) {
						timeSetCollection.push( moment( getPropertyValueByPath( historyEntry, [ 'timeSet' ] ) ).format( 'YYYY-MM-DD HH:mm:ss' ) );
						valuesCollection.push( propertyValue );
					}
				});

				if ( valuesCollection.length > 0 ) {
					accumulationResult = applyAccumulationFilter( ACCUMULATION_FILTER, timeSetCollection, valuesCollection, keyValue, condition );

					// add to accumulationResults if there is any value different from 0
					if ( Object.values( accumulationResult ).filter( value => value !== 0 ).length ) {
						// Fill in missing times so that the chart may display them properly
						addMissingPeriodsToResult( ACCUMULATION_FILTER, fromDate.clone(), toDate.clone(), accumulationResult );
						accumulationResults[ externalDeviceId ] = accumulationResult;
					}
				}
			}
		}
		chartData = this._generateDataForChart( accumulationResults );
		textColor = options.stylable.textColor;

		if( selectedDevice
			&& this.state.fromDate
			&& this.state.toDate ) {

			multiChart = (
				<MultipleIndexedBarChart
					devices={ chartData.devices }
					xTickLabels={ chartData.xTickLabels }
					valuesArray={ chartData.valuesArray }
					isFetching={ isFetching }
					valuesColors={ valuesColors } />
			);
		}
		else {
			multiChart = (
				<div className="no-data-selected">Select devices, properties and dates to see the chart.</div>
			)
		}

		if( widgetDevices.length !== 1 ) {
			multiSelect = (
				<Multiselect
					onChange={ ( selectedDevices ) => this.onSelectedDevicesChange( selectedDevices ) }
					data={ widgetDevices }
					textField="name"
					valueField="name"
					placeholder="Select Device"
					value={ selectedDevice }
					caseSensitive={false}
					minLength={1}
					textColor={ textColor }
					filter="contains" />
			);
		}

		return (
			<div className="comparison-widget" >
				<div className="widget-settings-selection">
					{ multiSelect }

					<RangeDateTimePickers
						dateFormat='H:i d/m/Y'

						maxDateFromCalendar={ moment().format() }
						maxDateToCalendar={ fromDate ? fromDate.clone().add( 24, 'hours' ).format() : null }

						startDate={ fromDate }
						endDate={ toDate }

						onStartDateChange={ ( fromDate ) => this.setState( { fromDate, devicesHistory: {}, areDatesDirty: true } ) }
						onEndDateChange={ ( toDate ) => this.setState( { toDate, devicesHistory: {}, areDatesDirty: true } ) } />
				</div>
				<div className="chart-container">
					{ multiChart }
				</div>
			</div>
		);
	}

	areDatasetsLoaded ( newProps ) {
		if( newProps.provided.devices && newProps.provided.devices.length ) {
			return true;
		}

		return false;
	}

	onSelectedDevicesChange( selectedDevices ) {
		// let selectedDevicesNames = selectedDevices.map( device => {
		// 	return device.name;
		// });

		this.setState({ selectedDevices, devicesHistory: {}});

		// this.props.api.saveWidgetState( { selectedDevicesNames } );
	}

	fetchBarriersHistory() {
		request.get( prorailAPI )
			.then( ( devicesHistory ) => {
				this.setState({
					isHistoryLoading: false,
					devicesHistory
				})
			})
			.catch( ( errors ) => {
				this.setState({
					isHistoryLoading: false,
					errors
				})
			} );

		this.setState({
			isHistoryLoading: true,
			devicesHistory: null,
			errors: null
		});
	}

	fetchWidgetDataIfNeeded( newProps, newState, oldState ) {
		let { selectedDevice, fromDate, toDate, controlFromDate, controlToDate } = newState;

		//if( this.areDatasetsLoaded( newProps ) ) {

			if( selectedDevice && fromDate && toDate && controlToDate && controlToDate ) {

				if( oldState ) {
					if( selectedDevice === oldState.selectedDevice
						&& fromDate === oldState.fromDate
						&& toDate === oldState.toDate
						&& controlFromDate === oldState.controlFromDate
						&& controlToDate === oldState.controlToDate ) {

						return;
					}
				}

				// Make request to fetch device history for given device with provided from-to date
				// and from-to control date
				// this.fetchBarriersHistory()
			}
		//}
	}
}


ProrailMultipleBarriersDuration.defaultProps = {
	widgetDevices: [
		{
			"name": "Test_1",
			"fullDevice": {}
		},
		{
			"name": "Test_3",
			"fullDevice": {}
		},
		{
			"name": "Test_2",
			"fullDevice": {}
		}
	]
};

export default stylable( ProrailMultipleBarriersDuration );
