import React, { Component } from 'react';

import { DivIcon } from 'leaflet';
import { Map, Marker, TileLayer } from 'react-leaflet';
import './../../../node_modules/leaflet/dist/leaflet.css';

import MarkerClusterGroup from 'react-leaflet-markercluster';
import Control from 'react-leaflet-control';

import config from './../../config.js';

import Multiselect from '../../components/ReactWidgets/Multiselect';

import { getDefaultMarkerSvg, getDefaultClusterOptions, getPropertyValueByPath, getDeviceName, isNully } from './../../utils/devicesUtils.js';

import DevicesOverviewPopup from './../../popups/DevicesOverviewPopup/DevicesOverviewPopup.jsx';
import FullscreenMapPopup from './../../popups/FullscreenMapPopup/FullscreenMapPopup.jsx';

import stylable from './../../decorators/stylable.jsx';


const defaultDropdownItem = { name: 'All' };
const defaultMapCenter = [ 52.52730392656781, 10.458902895450592 ];
const defaultMapZoom = 3;


class LocatorWidget extends Component {
	constructor ( props ) {
		// props are super!  Me too ;)
		super( props );

		this.state = {
			dropdownValue: [ defaultDropdownItem ],
			disableClustering: null,
			viewport: {},
			fullscreenPopupOpen: false
		}

		this.mapDevices = [];
		// storing current map viewport for the fullscreen popup
		this.currentViewport = {};

		this.onDeviceMarkerClick = this.onDeviceMarkerClick.bind( this );
		this.onDropdownChange = this.onDropdownChange.bind( this );
		this.onFullscreenPopupClose = this.onFullscreenPopupClose.bind( this );
	}

	componentWillReceiveProps( newProps ) {
		this.prepareDevices( newProps );
	}

	componentWillUpdate( newProps, newState ) {
		let updatePopup = newProps.api.updatePopup;

		if( ! updatePopup || ! newState.fullscreenPopupOpen ) {
			return;
		}

		updatePopup( FullscreenMapPopup, this.getMapFullscreenPopupOptions() );
	}

	// update selected devices after realtime updates
	prepareDevices ( newProps ) {
		let { dropdownValue } = this.state,
			{ options, provided } = newProps,
			mapDevices = [];

		if( !provided.isLoading
			&& provided.devices ) {
			const latPath = options.locationPropertyPath.lat;
			const lngPath = options.locationPropertyPath.lng;

			provided.devices.forEach( ( device ) => {
				const deviceName = getDeviceName( device, options.deviceOptions );
				const lat = getPropertyValueByPath( device, [ 'snapshot', ...latPath ] );
				const lng = getPropertyValueByPath( device, [ 'snapshot', ...lngPath ] );

				if( lat && lng ) {
					mapDevices.push({
						name: deviceName,
						lat,
						lng,
						fullDevice: device
					});
				}
			});
		}

		this.mapDevices = mapDevices;

		dropdownValue.forEach( selectedDevice => {
			mapDevices.forEach( device => {
				if( device.name === selectedDevice.name ) {
					selectedDevice.lat = device.lat;
					selectedDevice.lng = device.lng;
					selectedDevice.fullDevice = device.fullDevice;
				}
			});
		});

		this.setState({
			dropdownValue
		})
	}

	getFullscreenControl() {
		return (
			<Control position="topright" className="leaflet-bar">
				{ /* eslint-disable-next-line */ }
				<a className="map-fullscreen-icon" onClick={ () => {
					this.props.api.openPopup( FullscreenMapPopup, this.getMapFullscreenPopupOptions() );

					this.setState( { fullscreenPopupOpen: true } );
				} }>
				</a>
			</Control>
			);
	}

	onFullscreenPopupClose() {
		this.setState( { fullscreenPopupOpen: false } );
	}

	getMapFullscreenPopupOptions() {
		return {
			selectedDevices: this.getSelectedDevices(),
			options: this.props.options,
			viewport: this.currentViewport,
			disableClustering: this.shouldDisableClustering(),
			onClose: this.onFullscreenPopupClose
		}
	}

	shouldDisableClustering() {
		let { disableClustering } = this.state;

		if( isNully( disableClustering ) && !isNully( this.props.options.disableClustering ) ) {
			disableClustering = this.props.options.disableClustering;
		}

		return disableClustering;
	}

	getClusteringControl( disableClustering ) {
		let nextToggleDisableClusteringState = disableClustering ? false : true;
		return (
			<Control position="bottomleft" className="leaflet-bar">
				{ /* eslint-disable-next-line */ }
				<a className={ "map-clustering-icon" + ( nextToggleDisableClusteringState ? '' : ' disabled' ) } onClick={ () => {
					this.setState({
						disableClustering: nextToggleDisableClusteringState
					})
				} }>
				</a>
			</Control>
		);
	}

	/**
	 * Get the center and zoom of the currently present map that fits the given bounds
	 * @param  {L.Bounds} bounds
	 * @return { zoom: Number, center: LatLng }
	 */
	getCenterAndZoomFromBounds ( bounds ) {
		// Uses the current map to transform the bounds to zoom and center
		return this.refs.locatorMap.leafletElement._getBoundsCenterZoom( bounds );
	}

	onDropdownChange ( value ) {
		let dropdownValue = value,
			viewport = this.getInitialViewport(),
			markersBounds = [];

		if( value.length === 0 ) {
			dropdownValue = [ defaultDropdownItem ];
		}
		else if( value.length > 1 ) {
			let [ firstItem, ...restOfValue ] = value;

			if( firstItem === defaultDropdownItem ) {
				dropdownValue = restOfValue;
			}
			else if( restOfValue.indexOf( defaultDropdownItem ) !== -1 ) {
				dropdownValue = [ defaultDropdownItem ];
			}

		}

		markersBounds = dropdownValue.reduce( ( bounds, device ) => {
			if( device.name !== 'All' ) {
				bounds.push( [ device.lat, device.lng ] );
			}
			return bounds;
		}, []);

		if( markersBounds.length ) {
			viewport = this.getCenterAndZoomFromBounds( markersBounds );
		}

		// If you use the initial def (with bounds) you should convert it with center + zoom
		// Else it throws
		if( viewport.bounds ) {
			viewport = this.getCenterAndZoomFromBounds( viewport.bounds );
		}

		this.currentViewport = viewport;

		this.setState({
			dropdownValue,
			viewport
		})
	}

	onDeviceMarkerClick ( device ) {
		this.props.api.openPopup( DevicesOverviewPopup, {
			api: this.props.api,
			deviceOptions: this.props.options.deviceOptions,
			propChartOptions: this.props.options.propChartOptions,
			latPath: this.props.options.locationPropertyPath.lat,
			lngPath: this.props.options.locationPropertyPath.lng,
			devices: [ device ],
			historyUnitType: this.props.options.historyUnitType, // realtime or not
			propertyPaths: {
				displayName: [],
				location: [],
				communicationTime: []
			}
		});
	}

	getSelectedDevices() {
		const { dropdownValue } = this.state;

		if( dropdownValue.length === 1 && dropdownValue[ 0 ] === defaultDropdownItem ) {
			return this.mapDevices;
		}

		return dropdownValue;
	}

	getInitialViewport () {
		let initialMapViewPortDefinition = {};

		if( this.props.options.mapBounds ) {
			initialMapViewPortDefinition.bounds = this.props.options.mapBounds;
		}
		else if( this.props.options.mapCenter && this.props.options.mapZoom ) {
			initialMapViewPortDefinition.center = this.props.options.mapCenter;
			initialMapViewPortDefinition.zoom = this.props.options.mapZoom;
		}

		if( Object.keys( initialMapViewPortDefinition ).length === 0 ) {
			initialMapViewPortDefinition.center = defaultMapCenter;
			initialMapViewPortDefinition.zoom = defaultMapZoom;
		}

		return initialMapViewPortDefinition;
	}

	render() {
		const { options } = this.props;
		const { widgetSubtitle } = options;

		const markerWidth = 65;
		const markerHeight = 65;

		const defaultMarker = getDefaultMarkerSvg();
		const markerColor = this.props.color;

		const deviceIcon = new DivIcon({
			iconSize: [ markerWidth, markerHeight ],
			html: defaultMarker,
			iconAnchor: [ markerWidth / 2, markerHeight ],
			color: markerColor,
			className: 'my-div-icon'
		});

		let mapDevices = this.mapDevices;

		const { dropdownValue, viewport } = this.state;
		const disableClustering = this.shouldDisableClustering();

		const selectedDevices = this.getSelectedDevices();

		const markers = selectedDevices.map( ( selectedDevice, index ) => {
			let filteredDevice = mapDevices.filter( mapDevice => mapDevice.name === selectedDevice.name )[ 0 ];
			return (
				<Marker
					key={ index }
					onClick={ () => { this.onDeviceMarkerClick( filteredDevice.fullDevice ) } }
					position={ [ filteredDevice.lat, filteredDevice.lng ] }
					icon={ deviceIcon } />
			);
		});

		let subtitleMarkup = '';
		if ( widgetSubtitle ) {
			subtitleMarkup = <p className="widget-subtitle default">{ widgetSubtitle }</p>;
		}

		let initialMapViewPortDefinition = this.getInitialViewport();

		if( Object.keys( this.currentViewport ).length === 0 ) {
			this.currentViewport = initialMapViewPortDefinition;
		}

		return (
			<div className="locator-widget">
				{ subtitleMarkup }
				<div>
					<div className="widget-settings-selection">
						<Multiselect
							onChange={ this.onDropdownChange }
							data={ [ defaultDropdownItem, ...mapDevices ] }
							valueField="name"
							textField="name"
							value={ dropdownValue }
							caseSensitive={false}
							minLength={1}
							filter='contains'
							id="locator-sensor-selector" />
					</div>
				</div>
				<div className="map-container">
					<Map { ...initialMapViewPortDefinition } viewport={ viewport } animate={ true }
						ref="locatorMap"
						onViewportChanged={ newViewport => {
							setTimeout( () => {
								this.currentViewport = newViewport;
							}, 300 );
						} } >

						<TileLayer
							url={ config.tileLayer.url } />

						{/* MarkerClusterGroup component is not updated if we change cluster options
							TODO: check if this is fixed and use only 1 MarkerClusterGroup layer */}

						{ disableClustering ?
							<MarkerClusterGroup { ...getDefaultClusterOptions( undefined ) } >
								{ markers }
							</MarkerClusterGroup> : '' }
						{ ! disableClustering ?
							<MarkerClusterGroup { ...getDefaultClusterOptions( 30 ) } >
								{ markers }
							</MarkerClusterGroup> : '' }

						{ this.getFullscreenControl() }
						{ this.getClusteringControl( disableClustering ) }
					</Map>
				</div>
			</div>
		);
	}
}

export default stylable( LocatorWidget );
