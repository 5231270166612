import React, { Component } from 'react';

import LazyLoad from 'react-lazyload';
import MonitoredDeviceItem from './MonitoredDeviceItem.jsx';

import { getPropertyValueByPath, isNully } from './../../utils/devicesUtils.js';

export default class LazyMonitoredDeviceItem extends Component {
	render() {
		let height = 56;

		let {
			areFiltersEmpty,
			activeFilters,
			getValueStatus,
			warningRules,
			criticalRules,
			device
		} = this.props;

		let isHidden = false;

		if( device && ! areFiltersEmpty ) {
			for( let propertyPath of this.props.propertyPaths ) {
				let newPropertyValue = getPropertyValueByPath( device, [ 'snapshot', ...propertyPath ] );
				let newPropertyStatus;

				if( ! isNully( newPropertyValue ) ) {
					newPropertyStatus = getValueStatus( device, newPropertyValue, warningRules, criticalRules );
					if( ! activeFilters[ newPropertyStatus ] ) {
						height = 0;
						isHidden = true;
					}
					break;
				}
			}
		}


		if( isHidden ) {
			return null;
		}

		return (
			<LazyLoad
				height={ height }
				offset={ 300 }
				overflow={ true }
				scroll={ true }
				key={ this.props.device.deviceId } >
				<MonitoredDeviceItem { ...this.props } isHidden={ isHidden } />
			</LazyLoad>
		);
	}
}
