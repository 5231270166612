import {
	FETCH_DEVICES_SUCCESS,
} from './../../actions/unitsActions'

export function snapshotsToRealtimeUnitsRelationsReducer ( state = {
	snapshotsToRealtimeUnits: {}
}, action ) {
	switch ( action.type ) {
		case FETCH_DEVICES_SUCCESS: {
			let snapshotUnitName = action.unitId;
			let newRealtimeUnits = action.realtimeUnits;

			let currentSnapshotRelations = state.snapshotsToRealtimeUnits[ snapshotUnitName ];

			if ( !currentSnapshotRelations ) {
				currentSnapshotRelations = [];
			}

			// Returns an array with only the unique names
			currentSnapshotRelations = [ ...new Set( currentSnapshotRelations.concat( ...newRealtimeUnits ) ) ]

			return Object.assign( {}, state, {
				snapshotsToRealtimeUnits: {
					...state.snapshotsToRealtimeUnits,
					[ snapshotUnitName ]: currentSnapshotRelations
				}
			} );
		}
		default:
			return state;
	}
}
