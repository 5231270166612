import React, { Component } from 'react';

import { connect } from 'react-redux'

import * as moment from 'moment';


import iota from './../../middleware/iota.js';

import {
	findDeviceSnapshotUnit,
	findDeviceHistoryUnit,
	findUnitFlow,
	findDeviceExternalId
} from './../../utils/devicesUtils.js';

class DevicesOverviewCommunicationsList extends Component {
	constructor ( props ) {
		// Props are super
		super( props );

		this.state = {
			isLoading: false,
			data: null,
			error: null
		};
	}

	render () {
		const selectedDevice = this.props.selectedDevice;
		const dateFormat = 'DD-MM-YYYY HH:mm:ss';


		if ( selectedDevice ) {
			let communicationMessages = this.state.history;

			if ( communicationMessages ) {
				communicationMessages.sort( ( leftMessage, rightMessage ) => {
					let leftMessageTime = ( + moment( leftMessage.timeReceived ) );
					let rightMessageTime = ( + moment( rightMessage.timeReceived ) );

					return rightMessageTime - leftMessageTime;
				} );

				let communicationList = communicationMessages.map( ( message ) => {
					return (
						<li className="sensor-message-time-item" key={ message.timeReceived }>
							{ moment( message.timeReceived ).format( dateFormat ) }
						</li>
					);
				} );

				return (
					<div className="sow-popup-latest-messages-container">
						<h3>Communication</h3>
						<ul className="alternating-list">
							{ communicationList }
						</ul>
					</div>
				);
			}
		}

		return (
			<div className="sow-popup-latest-messages-container empty"></div>
		);
	}

	componentWillMount () {
		this.fetchLastCommunicationData( this.props )
	}

	componentWillReceiveProps ( newProps ) {
		this.fetchLastCommunicationData( newProps );
	}

	fetchLastCommunicationData ( props ) {
		let {
			flowName,
			historyUnit,
			externalDeviceId,
			selectedDevice
		} = props;

		const count = 15;

		if ( selectedDevice ) {
			this.setState( {
				isLoading: true,
				data: null,
				error: null
			} );

			iota.flows().name( flowName )
				.unit( historyUnit )
				.devices().id( externalDeviceId )
				.last( { count } )
				.then( history => this.setState( { isLoading: false, history } ) )
				.catch( error => this.setState( { isLoading: false, error } ) )
		}
	}
}


function mapStateToProps ( state, ownProps ) {
	let snapshotUnitId;
	let historyUnit;
	let flowName;
	let externalDeviceId;

	let { selectedDevice } = ownProps;

	if ( selectedDevice ) {
		snapshotUnitId = findDeviceSnapshotUnit( selectedDevice, state.entities.unitsDevicesRelations.unitsDevices );
		flowName = findUnitFlow( snapshotUnitId, state.entities.flows.flows );
		externalDeviceId = findDeviceExternalId( selectedDevice );
		historyUnit = findDeviceHistoryUnit( selectedDevice, state.entities.unitsDevicesRelations.unitsDevices, state.entities.snapshotToHistoryUnitsRelations.snapshotsToHistoryUnits )
	}

	return {
		historyUnit,
		flowName,
		externalDeviceId
	};
}

export default connect( mapStateToProps, null )( DevicesOverviewCommunicationsList );
