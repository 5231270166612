import {
	FETCH_NOTIFICATIONS_STARTED,
	FETCH_NOTIFICATIONS_SUCCESS,
	FETCH_NOTIFICATIONS_FAILED,
	MARK_NOTIFICATIONS_AS_SEEN_SUCCESS,
	REALTIME_NOTIFICATIONS_MESSAGE,
	DELETE_NOTIFICATIONS_STARTED,
	DELETE_NOTIFICATIONS_PROGRESS,
	DELETE_NOTIFICATIONS_SUCCESS,
	DELETE_NOTIFICATIONS_FAILED,
	DELETE_NOTIFICATIONS_BEFORE_SUCCESS
} from './../actions/notificationsActions.js';

import * as moment from 'moment';

export function notificationsReducer ( state = {
	isSyncing: false,
	error: null,
	notifications: null
}, action ) {
	switch ( action.type ) {
		case REALTIME_NOTIFICATIONS_MESSAGE: {
			let newNotifications = action.message.filter( newNotification => {
				const duplicatedNotification = state.notifications.find(
					notification => notification.notificationId === newNotification.notificationId );

				if ( !duplicatedNotification ) {
					return true;
				}

				return false;
			} );

			return {
				...state,
				notifications: [ ...newNotifications, ...state.notifications ]
			}
		}
		case FETCH_NOTIFICATIONS_SUCCESS:
			return {
				...state,
				notifications: action.notifications,
				isSyncing: false,
				error: null
			}
		case FETCH_NOTIFICATIONS_STARTED:
			return {
				...state,
				notifications: null,
				isSyncing: true,
				error: null
			}
		case FETCH_NOTIFICATIONS_FAILED:
			return {
				...state,
				notifications: null,
				isSyncing: false,
				error: action.error
			}
		case MARK_NOTIFICATIONS_AS_SEEN_SUCCESS: {
			let notifications = state.notifications.map( notification => {
				if ( action.notificationsIds.indexOf( notification.notificationId ) === -1 ) {
					return notification;
				}
				return { ...notification, isSeen: true };
			} );

			return {
				...state,
				notifications
			};
		}
		case DELETE_NOTIFICATIONS_STARTED: {
			return {
				...state,
				isSyncing: true
			};
		}
		case DELETE_NOTIFICATIONS_PROGRESS: {
			let notifications = state.notifications.filter( notification => {
				if ( action.notificationsIds.indexOf( notification.notificationId ) === -1 ) {
					return true;
				}

				return false;
			} );

			return {
				...state,
				notifications,
				isSyncing: true
			};
		}
		case DELETE_NOTIFICATIONS_SUCCESS: {
			let notifications = state.notifications.filter( notification => {
				if ( action.notificationsIds.indexOf( notification.notificationId ) === -1 ) {
					return true;
				}

				return false;
			} );

			return {
				...state,
				notifications,
				isSyncing: false
			};
		}
		case DELETE_NOTIFICATIONS_FAILED: {
			return {
				...state,
				isSyncing: false
			};
		}
		case DELETE_NOTIFICATIONS_BEFORE_SUCCESS: {
			let notifications = state.notifications.filter( notification => {
				if ( moment( notification.timeSet ) < moment( action.timeSet ) ) {
					return false;
				}

				return true;
			} );

			return {
				...state,
				notifications
			};
		}
		default:
			return state
	}
}