import {
	getUniqueFlowSnapshotNotifyId
} from './../../utils/devicesUtils.js';

export default class FetchWidgetsDevicesDebouncer {
	constructor ({ fetchDevices }) {
		this.cleanDebounce();
		this.fetchDevices = fetchDevices;
	}

	/**
	 * Clean the de-bounce object
	 */
	cleanDebounce () {
		this.debounce = {
			realtime: [],
			devicesByUnit: {}
		}
	}

	/**
	 * Prepare Widgets Devices
	 * 		Checks if the widgets datasets were modified and if so - fetches the new datasets.
	 * 		First time it's called (with oldProps = null) fetches the datasets initially
	 * @param  {Object} oldProps The previous props of the DashGrid
	 * @param  {Object} newProps The next props of the DashGrid
	 */
	prepareWidgetsDevices ( oldProps, newProps ) {
		let newWidgets = newProps.widgets

		if( newWidgets ) {
			newWidgets.forEach( ( widget, index ) => {
				let shouldFetch = false;

				let newOptions = widget.options;

				if( newOptions ) {
					let newDatasets = newOptions.datasets;

					if( oldProps ) {
						let oldWidget = oldProps.widgets[ index ];

						if( oldWidget ) {
							let oldDatasets = oldWidget.options.datasets;

							if( newDatasets !== oldDatasets ) {
								shouldFetch = true;
							}
						}
						else {
							shouldFetch = true;
						}
					}
					else {
						shouldFetch = true;
					}

					if( shouldFetch ) {
						this.requestDatasets( newDatasets );
					}
				}
			});
		}


		/*
		Next version idea:

		let datasetsBulks = getDistinctDatasetsBulks( this.props.widgets );

		datasetsBulks.map( bulk => {
			if( !hasBulkData( bulk ) ) {
				fetchBulkData( bulk );
			}
		} );
		 */
	}

	///////////////////////////////////////////////////////////////////////////////////
	//                                DEBOUNCE API                                   //
	// Buffering calls to the iota API in order to prevent fetching of the same data //
	///////////////////////////////////////////////////////////////////////////////////

	/**
	 * Run the de-bounced data fetch
	 * Processes the stored requests, merges them and sends the to the server.
	 */
	processDebouncedApi () {
		let fetchDevices = this.fetchDevices;
		let snapshotUnitsRelations = {};

		Object.keys( this.debounce.devicesByUnit ).forEach( ( key ) => {
			let {
				flowName,
				snapshotUnit,
				messagesUnit,
				historyUnit,
				capabilities
			} = this.debounce.devicesByUnit[ key ];

			if( Object.keys( snapshotUnitsRelations ).indexOf( snapshotUnit ) === -1 ) {
				snapshotUnitsRelations[ snapshotUnit ] = {
					flowName,
					messageUnits: [],
					capabilities: [],
					historyUnit
				};
			}

			if( snapshotUnitsRelations[ snapshotUnit ].messageUnits.indexOf( messagesUnit ) === -1 ) {
				snapshotUnitsRelations[ snapshotUnit ].messageUnits.push( messagesUnit );
			}

			if( capabilities ) {
				snapshotUnitsRelations[ snapshotUnit ].capabilities.push( capabilities );
			}

		});



		Object.keys( snapshotUnitsRelations ).forEach( ( snapshotUnit ) => {
			let {
				flowName,
				messageUnits,
				capabilities,
				historyUnit
			} = snapshotUnitsRelations[ snapshotUnit ];

			let uniqueCapabilities = [ ...new Set( capabilities ) ];

			fetchDevices( flowName, snapshotUnit, messageUnits, uniqueCapabilities, historyUnit );
		});

		this.cleanDebounce();
	}



	/**
	 * Request the datasets devices
	 * -> the requests are debounced in order to avoid duplicated API calls
	 * @param  {Array} datasets  The datasets definitions
	 */
	requestDatasets( datasets ) {
		let debounce = this.debounce;

		if( datasets ) {
			datasets.forEach( dataset => {
				let {
					flowName,
					units
				} = dataset;

				let uniqueFlowSnapshotNotifyId = getUniqueFlowSnapshotNotifyId( flowName, units.snapshot, units.messages );

				if( Object.keys( debounce.devicesByUnit ).indexOf( uniqueFlowSnapshotNotifyId ) === -1 ) {
					debounce.devicesByUnit[ uniqueFlowSnapshotNotifyId ] = {
						flowName,
						snapshotUnit: units.snapshot,
						messagesUnit: units.messages,
						historyUnit: units.history
					};
				}
				else if( units.history ) {
					debounce.devicesByUnit[ uniqueFlowSnapshotNotifyId ].historyUnit = units.history;
				}
			});
		}
	}

};