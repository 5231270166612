import React, { Component } from 'react';

import * as moment from 'moment';

import c3 from 'c3';

const defaultColors = [ '#0099cc', '#cc0000', '#ffbb33', '#99cc00' ];
// let c3 = require('c3');

class MultipleRealtimeLineChart extends Component {
	constructor ( props ) {
		// props are super!
		super( props );

		this.pointsToRemove = 1;
	}

	/**
	 * Return if we need to re-render the component
	 * This is not done automatically since we're providing new arrays with same data
	 */
	shouldComponentUpdate ( newProps, newState ) {
		let { valuesArray, isFetching } = this.props;

		let newValuesArray = newProps.valuesArray
		let newIsFetching = newProps.isFetching;

		if ( isFetching !== newIsFetching ) {
			return true;
		}


		if ( valuesArray.length !== newValuesArray.length ) {
			return true;
		}

		if ( this.props.forceRefreshCounter !== newProps.forceRefreshCounter ) {
			return true;
		}

		let valuesArrayIndex = 0;
		for ( let valuesArrayItem of valuesArray ) {
			if ( !newValuesArray[ valuesArrayIndex ] ) {
				return true;
			}
			if ( newValuesArray[ valuesArrayIndex ].length !== valuesArrayItem.length ) {
				return true;
			}

			let valueIndex = 0;
			for ( let valueItem of valuesArray[ valuesArrayIndex ] ) {
				if ( valueItem !== newValuesArray[ valuesArrayIndex ][ valueIndex ] ) {
					return true
				}
				valueIndex++;
			}

			valuesArrayIndex++;
		}

		return false;
	}

	componentWillReceiveProps ( props ) {
		// flow doesn't support adding of a new device
		// we have to reload the chart ( when isFetching = true )
		if ( this.chart && !props.isFetching ) {
			this.chart.flow( {
				columns: props.data,
				length: this.pointsToRemove
			} );

			// old points are not removed if the tab is not focused
			if ( document.hidden ) {
				this.pointsToRemove++;
			}
			else {
				this.pointsToRemove = 1;
			}
		}

	}

	render () {
		let { xAxisBindings, valuesArray, valuesColors,
			showXAxis, showYAxis, showXGridLines, showYGridLines,
			widgetId,
			axisDatetimeFormat } = this.props,
			chart,
			message = <div className="chart-data-loading">Loading data...</div>;

		if ( valuesArray.length ) {
			let chartColors = {};

			let colorIndex = 0;
			for ( let lineName in xAxisBindings ) {
				if ( valuesColors[ colorIndex ] ) {
					chartColors[ lineName ] = valuesColors[ colorIndex ];
				}
				else {
					chartColors[ lineName ] = defaultColors[ colorIndex % 4 ];
				}
				colorIndex++;
			}
			let chartProps = {
				line: {
					connectNull: true
				},
				data: {
					unload: true,
					xs: xAxisBindings,
					xFormat: '%Y-%m-%d %H:%M:%S',
					columns: valuesArray,
					colors: chartColors
				},
				axis: {
					x: {
						show: showXAxis,
						type: 'timeseries',
						tick: {
							format: function ( d ) {
								return moment( d ).format( axisDatetimeFormat ? axisDatetimeFormat : 'DD-MM HH:mm' );
							},
							culling: {
								max: 4
							},
							rotate: -50
						}
					},
					y: {
						show: showYAxis
					}
				},
				tooltip: {
					format: {
						title: function ( d ) {
							return moment( d ).format( 'HH:mm:ss | DD-MM-YYYY' );
						}
					}
				},
				point: {
					show: false
				},
				grid: {
					x: {
						show: showXGridLines
					},
					y: {
						show: showYGridLines
					}
				},
				padding: {
					left: 60,
					right: 50,
					bottom: 80
				},
				bindto: '#' + widgetId + ' .multiple-realtime-line-chart'
			}

			chart = c3.generate( chartProps );
			this.chart = chart;
		}
		else {
			message = <div className="chart-data-loading">No data</div>
		}

		return (
			<div className="chart-canvas-container">
				<div className="multiple-realtime-line-chart">{ message }</div>
			</div>
		);
	}
}

export default MultipleRealtimeLineChart;