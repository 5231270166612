import React, { Component } from 'react';

import { connect } from 'react-redux';

import { getMetaFromUserState } from './../../selectors/meta.js';

// Actions
import * as userActions from './../../actions/userActions.js';

import LoginPopup from './../../components/LoginPopup.jsx';

export default function authable ( ProvidedComponent ) {
	class AuthableWrap extends Component {
		constructor ( props ) {
			// Props are super
			super( props );

			this.handleLoginSubmit = this.handleLoginSubmit.bind( this );

			let storedCredentialsEntry = localStorage.getItem( 'storedCredentials' );
			let storedCredentials = null;

			if ( storedCredentialsEntry ) {
				storedCredentials = JSON.parse( storedCredentialsEntry );
			}

			this.state = {
				loginAttempted: false,
				storedCredentials: {
					...storedCredentials
				}
			}

		}

		componentWillMount () {
			let username;
			let password;

			if ( this.state.storedCredentials ) {
				username = this.state.storedCredentials.username;
				password = this.state.storedCredentials.password;
			}

			if ( !username ) {
				username = window[ 'config' ].username;
				password = window[ 'config' ].password;
			}


			if ( username ) {
				this.props.fetchUser( username, password );
			}
		}

		render () {
			let { error } = getMetaFromUserState( this.props.userState );

			let visualizedComponent;

			let username = this.state.storedCredentials.username;

			if ( !username ) {
				username = window[ 'config' ].username;
			}

			// TODO: Move to a decorator.
			if ( error || ( !this.state.loginAttempted && !username ) ) {
				visualizedComponent = ( <LoginPopup handleSubmit={ this.handleLoginSubmit } error={ error } /> );
			}
			else {
				visualizedComponent = ( <ProvidedComponent { ...this.props } logOut={ this.logOut } /> );
			}

			return (
				<div>
					{ visualizedComponent }
				</div>
			);
		}

		handleLoginSubmit ( { username, password, rememberMe } ) {
			this.setState( {
				loginAttempted: true
			} );

			if ( rememberMe ) {
				let storedCredentials = {
					username,
					password
				}

				localStorage.setItem( 'storedCredentials', JSON.stringify( storedCredentials ) );
			}

			this.props.fetchUser( username, password );
		}

		logOut () {
			localStorage.removeItem( 'storedCredentials' );
			window.location.reload();
		}
	}

	function mapDispatchToProps ( dispatch ) {
		return {
			fetchUser: ( username, password ) => {
				dispatch( userActions.fetchUserMeta( username, password ) );
			}
		}
	}

	function mapStateToProps ( state, ownProps ) {
		return {
			userState: state.userState
		}
	}


	return connect( mapStateToProps, mapDispatchToProps )( AuthableWrap );
}
