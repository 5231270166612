import React, { Component } from 'react';

import C3Chart from 'react-c3js';
import 'c3/c3.css';

const defaultColors = [ '#0099cc', '#cc0000', '#ffbb33', '#99cc00' ];

class CircleChart extends Component {
	render () {
		let { valuesArray, isFetching, valuesColors } = this.props,
			chartLoading = (
				<div className="chart-data-loading">Loading data...</div>
			),
			chartNoData = (
				<div className="no-chart-data">No data for the selected period</div>
			),
			chartColors = {},
			chartCanvasInnerContainer;

		if( isFetching ) {
			chartCanvasInnerContainer = chartLoading;
		}
		else if( !valuesArray.length ) {
			chartCanvasInnerContainer = chartNoData;
		}
		else {
			valuesArray.forEach( ( column, index ) => {
				let pieName = column[ 0 ];
				if ( valuesColors[ pieName ] ) {
					chartColors[ pieName ] = valuesColors[ index ];
				}
				else {
					chartColors[ pieName ] = defaultColors[ index % 4 ];
				}
			} );

			let chartProps = {
				size: {
					width: this.props.size.width,
					height: this.props.size.height
				},
				data: {
					columns: valuesArray,
					type : this.props.chartType,
					colors: chartColors
				},
				padding: {
					top: 20
				}
			}

			chartCanvasInnerContainer = ( <C3Chart className="c3-multiple-ts-charts" { ...chartProps } /> );
		}

		return (
			<div className="chart-canvas-container pie">
				{ chartCanvasInnerContainer }
			</div>
		);
	}
}

CircleChart.defaultProps = {
	size: {
		width: undefined,
		height: undefined
	},
	chartType: 'pie' // can be 'pie' or 'donut'
};

export default CircleChart;