import React, { PureComponent } from 'react';

export default class LoginPopup extends PureComponent {
	constructor ( props ) {
		// Props are super
		super( props )

		this.state = {
			username: '',
			password: '',
			rememberMe: false
		};

		this.handleInputChange = this.handleInputChange.bind( this );
		this.handleSubmit = this.handleSubmit.bind( this );
	}

	render () {
		const { error } = this.props;

		return (
			<div className="login-blanket">
				<div className="login-popup">
					{ ( error ? ( <div className="login-error">{ error.toString() }</div> ) : '' ) }
					<span className="maplese-logo"></span>
					<form onSubmit={ this.handleSubmit } >
						<label>
							Username
							<input
								type="text"
								name="username"
								value={ this.state.username }
								onChange={ this.handleInputChange } />
						</label>
						<label>
							Password
							<input
								type="password"
								name="password"
								value={ this.state.password }
								onChange={ this.handleInputChange } />
						</label>
						<label className="checkbox-label">
							<input
								type="checkbox"
								name="rememberMe"
								checked={ this.state.rememberMe }
								onChange={ this.handleInputChange } />
							Remember me
						</label>
						<div className="login-btn">
							<input type="submit" value="Log in" />
						</div>
					</form>
				</div>
			</div>
		);
	}

	handleInputChange ( event ) {
		const { target } = event;
		const value = ( target.type === 'checkbox' ? target.checked : target.value );
		const { name } = target;

		this.setState( {
			[ name ]: value
		} )
	}

	handleSubmit ( event ) {
		const { handleSubmit } = this.props;

		handleSubmit( this.state );
		event.preventDefault();
	}

}
