export function areArraysEqual ( arr1, arr2 ) {
	if ( !arr1 ) {
		return false;
	}

	if ( arr2.length !== arr1.length ) {
		return false;
	}

	for ( var i = 0, l = arr2.length; i < l; i++ ) {
		if ( arr2[ i ] instanceof Array && arr1[ i ] instanceof Array ) {
			if ( areArraysEqual( arr2, arr1 ) ) {
				return false;
			}
		}
		else if ( arr2[ i ] !== arr1[ i ] ) {
			return false;
		}
	}

	return true;
}

export function capitalizeFirstLetter ( string ) {
	return string.charAt( 0 ).toUpperCase() + string.slice( 1 );
}

export function decapitalizeFirstLetter ( string ) {
	return string.charAt( 0 ).toLowerCase() + string.slice( 1 );
}
