import React, { Component } from 'react';

import { connect } from 'react-redux';

import * as userActions from './../actions/userActions.js';
import * as activeDashboardActions from './../actions/activeDashboardActions.js';

import { getDashboardStateFromUserState } from './../selectors/dashboards.js';

import HeaderMessage from './../components/Header/HeaderMessage.jsx';

import configureImage from './../img/code-white.svg';

import AddDashboardForm from './../components/Header/AddDashboardForm.jsx';
import DashboardEditButton from './../components/Header/DashboardEditButton.jsx';


class DashboardsConfigurationForm extends Component {

	constructor ( props ) {
		super( props )

		this.state = {
			widgetsConfiguration: ''
		}

		this.onWidgetsConfigurationChange = this.onWidgetsConfigurationChange.bind( this );
		this.onToggleWidgetRearrange = this.onToggleWidgetRearrange.bind( this );
		this.onToggleWidgetConfigurationForm = this.onToggleWidgetConfigurationForm.bind( this );
		this.onAddWidgetsMessageClick = this.onAddWidgetsMessageClick.bind( this );
		this.onCloseWidgetsConfigurator = this.onCloseWidgetsConfigurator.bind( this );
		this.onDashboardAdd = this.onDashboardAdd.bind( this );
	}

	_getWidgetsConfiguration () {
		let widgetsConfiguration = this.state.widgetsConfiguration;

		if ( !widgetsConfiguration && this.props.widgetsConfiguration ) {
			widgetsConfiguration = JSON.stringify( this.props.widgetsConfiguration, null, 4 );
		}

		return widgetsConfiguration;
	}

	render () {
		let { isDndEnabled, isOpen, routingLocation } = this.props;

		let widgetsConfiguration = this._getWidgetsConfiguration();

		let isConfigurationFormVisible = isOpen;

		let dashboardsConfigurationFormClass = 'btn-nostyle';
		let configurationToggleDescription = 'Modify Dashboards Configuration';


		if ( isConfigurationFormVisible ) {
			configurationToggleDescription = 'Save Configuration Changes';
			dashboardsConfigurationFormClass += ' active';
		}

		let configurationToggleIcon = ( <img className="icon" src={ configureImage } alt={ configurationToggleDescription } title={ configurationToggleDescription } /> );

		let isCurrentPageDashboard = true;

		if ( !this.props.widgetsConfiguration.dashboards || (
			routingLocation === '/'
			// If there is only 1 dashboard configured, we show it in the '/'
			&& Object.keys( this.props.widgetsConfiguration.dashboards ).length > 1
		) ) {
			isCurrentPageDashboard = false;
		}

		return (
			<div className="dashboards-configuration-form-outer">
				<button onClick={ this.onToggleWidgetConfigurationForm } className={ dashboardsConfigurationFormClass }>
					{ configurationToggleIcon }
				</button>


				<div className={ 'dashboards-configuration-form' + ( isConfigurationFormVisible ? '' : ' hidden' ) }>
					<textarea value={ widgetsConfiguration } onChange={ ( event ) => {
						this.setState( {
							widgetsConfiguration: event.target.value
						} );
					} } />
					<button onClick={ this.onWidgetsConfigurationChange } className="btn pull-right">Save Configuration</button>
				</div>

				{
					isCurrentPageDashboard ?
						(
							<DashboardEditButton
								isActive={ isDndEnabled }
								onClick={ this.onToggleWidgetRearrange } />

						) : (
							<AddDashboardForm onAdd={ this.onDashboardAdd } />
						)
				}
				{
					isDndEnabled ?
						<HeaderMessage
							message="You can DRAG, RESIZE and ADD widgets"
							onClose={ this.onToggleWidgetRearrange }
							onAdd={ this.onAddWidgetsMessageClick } />
						: null
				}
			</div>
		);
	}

	onAddWidgetsMessageClick () {
		this.props.showWidgetsConfigurator();
	}

	onCloseWidgetsConfigurator () {
		this.props.hideWidgetsConfigurator();
	}

	onWidgetsConfigurationChange ( event ) {
		let widgetsConfiguration = this._getWidgetsConfiguration();
		let widgetsConfigurationFormatted = JSON.parse( widgetsConfiguration );
		// let dashboardsConfiguration = widgetsConfigurationFormatted;

		if ( this.props.widgetsConfiguration ) {
			this.props.updateUserMeta( 'dashboards', widgetsConfigurationFormatted );
		}
		else {
			this.props.addUserMeta( 'dashboards', JSON.stringify( widgetsConfigurationFormatted ) );
		}

		this.props.close();
	}

	onToggleWidgetRearrange ( event ) {
		this.props.toggleDashboardDragAndDrop();
	}

	onToggleWidgetConfigurationForm () {
		if ( this.props.isOpen ) {
			this.props.close();
		}
		else {
			this.props.open();
		}
	}

	onDashboardAdd ( dashboardName ) {
		let { dashboards } = getDashboardStateFromUserState( this.props.userState );
		if ( !dashboards || !dashboards.dashboards ) {
			dashboards = {
				dashboards: {},
				order: []
			}
		}

		let newDashboards = {
			...dashboards,
			dashboards: {
				...dashboards.dashboards,
				[ dashboardName ]: { name: dashboardName, widgets: [] },
			},
			order: [ ...dashboards.order, dashboardName ]
		}

		this.props.updateUserMeta( 'dashboards', newDashboards );
	}
}

function mapStateToProps ( state, ownProps ) {
	let widgets;

	let { dashboards } = getDashboardStateFromUserState( state.userState );

	if ( dashboards ) {
		let dashboardsConfigurations = dashboards.dashboards;
		let order = dashboards.order;
		let mainStyles = dashboards.mainStyles;

		// TODO: Move this logic into the lifecycles/render.
		// Each time the mapStateToProps is called the component is re-rendered
		// Due to the fact that widgets is always a different object
		widgets = {
			dashboards: dashboardsConfigurations,
			order,
			mainStyles
		}
	}

	return {
		userState: state.userState,
		widgetsConfiguration: widgets,
		isDndEnabled: state.activeDashboard.isDndEnabled,
		routingLocation: state.router.location.pathname
	};
}


function mapDispatchToProps ( dispatch ) {
	return {
		updateUserMeta: ( key, value ) => {
			dispatch( userActions.updateUserMeta( key, value ) );
		},
		addUserMeta: ( key, value ) => {
			dispatch( userActions.addUserMeta( key, value ) );
		},
		toggleDashboardDragAndDrop: () => {
			dispatch( activeDashboardActions.toggleDashboardDragAndDrop() );
		},
		showWidgetsConfigurator: () => {
			dispatch( activeDashboardActions.showWidgetsConfigurator() );
		},
		hideWidgetsConfigurator: () => {
			dispatch( activeDashboardActions.hideWidgetsConfigurator() );
		}
	}
}

export default connect( mapStateToProps, mapDispatchToProps )( DashboardsConfigurationForm );
