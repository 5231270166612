import React, { Component } from 'react';

import { DivIcon } from 'leaflet';
import { Map, Marker, TileLayer } from 'react-leaflet';
import './../../../node_modules/leaflet/dist/leaflet.css';

import MarkerClusterGroup from 'react-leaflet-markercluster';
import Control from 'react-leaflet-control';

import config from './../../config.js';

import Multiselect from '../../components/ReactWidgets/Multiselect';
import DropdownList from '../../components/ReactWidgets/DropdownList';

import { getDefaultMarkerSvg, getEmptyMarkerSvg, getDefaultMarkerTooltip, getDefaultClusterOptions, getPropertyValueByPath, getDeviceName, isNully } from './../../utils/devicesUtils.js';

import DevicesOverviewPopup from './../../popups/DevicesOverviewPopup/DevicesOverviewPopup.jsx';
import FullscreenMapPopup from './../../popups/FullscreenMapPopup/FullscreenMapPopup.jsx';

import stylable from './../../decorators/stylable.jsx';


// {
// "type": "EnhancedLocatorWidget",
// "options": {
//		"hideUncommonProperties": false,
//     "stylable": {
//         "color": "#23282D"
//     },
//     "disableClustering": true,
//     "widgetTitle": "LCU Locator",
//     "widgetId": "enhanced-locator-widget",
//     "dropdownProperties": [
//         !!! value ranges !!!
//         {
//             "name": "fCntUp",
//             "displayName": "Count up",
//             "showTooltips": true,
//             "propertyPath": [
//                 "content",
//                 "fCntUp"
//             ],
//             "rules": [
//                 {
//                     "color": "#48ae55",
//                     "maxValue": "10",
//                     "label": "ok"
//                 },
//                 {
//                     "color": "#ffc04d",
//                     "minValue": "11",
//                     "maxValue": "20",
//                     "label": "warning"
//                 },
//                 {
//                     "color": "#f63232",
//                     "minValue": "21",
//                     "label": "danger"
//                 }
//             ]
//         },
//         !!! Exact values !!!
//         {
//             "name": "alarmOn",
//             "displayName": "Alarm On",
//             "showTooltips": false,
//             "propertyPath": [
//                 "content",
//                 "alarmOn"
//             ],
//             "rules": [
//                 {
//                     "color": "green",
//                     "value": "false,
//                     "label": "Alarm Off",
//                     "icon": "/icons/closed-bridge.svg"
//                 },
//                 {
//                     "color": "red",
//                     "value": true,
//                     "label": "Alarm On",
//                     "icon": "/icons/open-bridge.svg"
//                 }
//             ]
//         }
//     ],
//     "locationPropertyPath": {
//         "lat": [
//             "content",
//             "lat"
//         ],
//         "lng": [
//             "content",
//             "lng"
//         ]
//     },
//     "datasets": [
//         {
//             "flowName": "prorail",
//             "units": {
//                 "messages": "prorailSnapshot",
//                 "snapshot": "prorailSnapshotMemory",
//                 "history": "prorailSnapshot"
//             },
//             "filters": [
//                 {
//                     "type": "has_snapshot_property",
//                     "options": {
//                         "propertyPath": [
//                             "content",
//                             "lat"
//                         ]
//                     }
//                 }
//             ]
//         },
//         {
//             "flowName": "prorail",
//             "units": {
//                 "messages": "prorailSnapshot",
//                 "snapshot": "prorailSnapshotMemory",
//                 "history": "prorailSnapshot"
//             },
//             "filters": [
//                 {
//                     "type": "has_snapshot_property",
//                     "options": {
//                         "propertyPath": [
//                             "content",
//                             "temp"
//                         ]
//                     }
//                 }
//             ]
//         }
//     ]
// },
// "position": {
//     "x": 0,
//     "y": 37,
//     "h": 11,
//     "w": 4
// }
// },


const defaultDropdownItem = { name: 'All' };
const defaultMapCenter = [ 52.52730392656781, 10.458902895450592 ];
const defaultMapZoom = 3;

class EnhancedLocatorWidget extends Component {
	constructor ( props ) {
		// props are super!
		super( props );

		let selectedPropertyConfig = this.props.options.dropdownProperties[ 0 ];

		this.state = {
			devicesDropdownValue: [ defaultDropdownItem ],
			selectedPropertyConfig,
			disableClustering: null,
			viewport: {},
			fullscreenPopupOpen: false
		}

		this.mapDevices = [];
		// storing current map viewport for the fullscreen popup
		this.currentViewport = {};

		this.onDevicesDropdownChange = this.onDevicesDropdownChange.bind( this );
		this.onDeviceMarkerClick = this.onDeviceMarkerClick.bind( this );
		this.onFullscreenPopupClose = this.onFullscreenPopupClose.bind( this );
	}

	componentWillReceiveProps( newProps ) {
		this.prepareDevices( newProps );
	}

	componentWillUpdate( newProps, newState ) {
		let updatePopup = newProps.api.updatePopup;

		if( ! updatePopup || ! newState.fullscreenPopupOpen ) {
			return;
		}

		updatePopup( FullscreenMapPopup, this.getMapFullscreenPopupOptions() );
	}

	// update selected devices after realtime updates
	prepareDevices ( newProps ) {
		let { devicesDropdownValue, selectedPropertyConfig } = this.state,
			{ options, provided } = newProps,
			newState = {},
			mapDevices = [];

		if( !provided.isLoading
			&& provided.devices ) {
			const latPath = options.locationPropertyPath.lat;
			const lngPath = options.locationPropertyPath.lng;

			provided.devices.forEach( ( device ) => {
				const deviceName = getDeviceName( device, options.deviceOptions );
				const lat = getPropertyValueByPath( device, [ 'snapshot', ...latPath ] );
				const lng = getPropertyValueByPath( device, [ 'snapshot', ...lngPath ] );

				if( lat && lng ) {
					mapDevices.push({
						name: deviceName,
						lat,
						lng,
						fullDevice: device
					});
				}
			});
		}

		this.mapDevices = mapDevices;

		devicesDropdownValue.forEach( selectedDevice => {
			mapDevices.forEach( device => {
				if( device.name === selectedDevice.name ) {
					selectedDevice.lat = device.lat;
					selectedDevice.lng = device.lng;
					selectedDevice.fullDevice = device.fullDevice;
				}
			});
		});

		newState.devicesDropdownValue = devicesDropdownValue;

		if( !selectedPropertyConfig ) {
			selectedPropertyConfig = options.dropdownProperties[ 0 ]

			newState.selectedPropertyConfig = selectedPropertyConfig;
		}

		this.setState( newState );
	}

	shouldDisableClustering() {
		let { disableClustering } = this.state;

		if( isNully( disableClustering ) && !isNully( this.props.options.disableClustering ) ) {
			disableClustering = this.props.options.disableClustering;
		}

		return disableClustering;
	}

	getClusteringControl( disableClustering ) {
		let nextToggleDisableClusteringState = disableClustering ? false : true;
		return (
			<Control position="bottomleft" className="leaflet-bar">
				{ /* eslint-disable-next-line */ }
				<a className={ "map-clustering-icon" + ( nextToggleDisableClusteringState ? '' : ' disabled' ) } onClick={ () => {
					this.setState({
						disableClustering: nextToggleDisableClusteringState
					})
				} }>
				</a>
			</Control>
		);
	}

	getFullscreenControl() {
		return (
			<Control position="topright" className="leaflet-bar">
				{ /* eslint-disable-next-line */ }
				<a className="map-fullscreen-icon" onClick={ () => {
					this.props.api.openPopup( FullscreenMapPopup, this.getMapFullscreenPopupOptions() );
					this.setState( { fullscreenPopupOpen: true } );
				} }>
				</a>
			</Control>
			);
	}

	onFullscreenPopupClose() {
		this.setState( { fullscreenPopupOpen: false } );
	}

	getMapFullscreenPopupOptions() {
		return {
			selectedDevices: this.getSelectedDevices(),
			options: this.props.options,
			viewport: this.currentViewport,
			disableClustering: this.shouldDisableClustering(),
			selectedPropertyConfig: this.state.selectedPropertyConfig,
			onClose: this.onFullscreenPopupClose
		}
	}

	getLegend() {
		let { selectedPropertyConfig } = this.state;

		if( isNully( selectedPropertyConfig ) ) {
			return '';
		}

		let rules = selectedPropertyConfig.rules;
		let legendMarkup = [];

		if( isNully( rules ) ) {
			return '';
		}

		rules.forEach( ( range, i ) => {
			legendMarkup.push( <li key={ i }>
				<div className="legend-color" style={{ backgroundColor: range.color }}></div>
				{ range.label }
			</li>)
		});

		return (
			<Control position="bottomright">
				<ul className="map-legend"> { legendMarkup } </ul>
			</Control>
		);
	}

	onDevicesDropdownChange ( value ) {
		let devicesDropdownValue = value,
			viewport = this.getInitialViewport(),
			markersBounds = [];

		if( value.length === 0 ) {
			devicesDropdownValue = [ defaultDropdownItem ];
		}
		else if( value.length > 1 ) {
			let [ firstItem, ...restOfValue ] = value;

			if( firstItem === defaultDropdownItem ) {
				devicesDropdownValue = restOfValue;
			}
			else if( restOfValue.indexOf( defaultDropdownItem ) !== -1 ) {
				devicesDropdownValue = [ defaultDropdownItem ];
			}

		}

		markersBounds = devicesDropdownValue.reduce( ( bounds, device ) => {
			if( device.name !== 'All' ) {
				bounds.push( [ device.lat, device.lng ] );
			}
			return bounds;
		}, []);

		if( markersBounds.length ) {
			viewport = this.getCenterAndZoomFromBounds( markersBounds );
		}

		// If you use the initial def (with bounds) you should convert it with center + zoom
		// Else it throws
		if( viewport.bounds ) {
			viewport = this.getCenterAndZoomFromBounds( viewport.bounds );
		}

		this.currentViewport = viewport;

		this.setState({
			devicesDropdownValue,
			viewport
		})
	}

	/**
	 * Get the center and zoom of the currently present map that fits the given bounds
	 * @param  {L.Bounds} bounds
	 * @return { zoom: Number, center: LatLng }
	 */
	getCenterAndZoomFromBounds ( bounds ) {
		// Uses the current map to transform the bounds to zoom and center
		return this.refs.locatorMap.leafletElement._getBoundsCenterZoom( bounds );
	}

	getDeviceMarkerValueColorAndIcon( device ) {
		let { selectedPropertyConfig } = this.state;
		let { propertyPath, rules } = selectedPropertyConfig;
		let propertyValue = getPropertyValueByPath( device, [ 'fullDevice', 'snapshot', ...propertyPath ] );
		let markerColor = '#000000';
		let markerIcon;

		if( isNully( propertyValue ) || isNully( rules ) ) {
			return {};
		}

		// exact value rules
		if( rules.length && !isNully( rules[ 0 ].value ) ) {
			rules.some( ( rule ) => {
				if( propertyValue === rule.value ) {

					markerColor = rule.color;
					markerIcon = rule.icon;
					return true;
				}
				return false;
			});
		}
		// value ranges
		else {
			rules.some( ( rule, i ) => {
				if( ( i < rules.length - 1 && propertyValue <= rule.maxValue )
					|| ( i === rules.length - 1 && propertyValue >= rule.minValue ) ) {

					markerColor = rule.color;
					markerIcon = rule.icon;
					return true;
				}
				return false;
			});
		}

		return {
			selectedPropertyValue: propertyValue,
			selectedPropertyColor: markerColor,
			selectedPropertyIcon: markerIcon
		}
	}

	onDeviceMarkerClick ( device ) {
		this.props.api.openPopup( DevicesOverviewPopup, {
			api: this.props.api,
			deviceOptions: this.props.options.deviceOptions,
			propChartOptions: this.props.options.propChartOptions,
			latPath: this.props.options.locationPropertyPath.lat,
			lngPath: this.props.options.locationPropertyPath.lng,
			devices: [ device ],
			historyUnitType: this.props.options.historyUnitType, // realtime or not
			propertyPaths: {
				displayName: [],
				location: [],
				communicationTime: []
			}
		});
	}

	getInitialViewport () {
		let initialMapViewPortDefinition = {};

		if( this.props.options.mapBounds ) {
			initialMapViewPortDefinition.bounds = this.props.options.mapBounds;
		}
		else if( this.props.options.mapCenter && this.props.options.mapZoom ) {
			initialMapViewPortDefinition.center = this.props.options.mapCenter;
			initialMapViewPortDefinition.zoom = this.props.options.mapZoom;
		}

		if( Object.keys( initialMapViewPortDefinition ).length === 0 ) {
			initialMapViewPortDefinition.center = defaultMapCenter;
			initialMapViewPortDefinition.zoom = defaultMapZoom;
		}

		return initialMapViewPortDefinition;
	}

	getSelectedDevices() {
		const { devicesDropdownValue } = this.state;

		if( devicesDropdownValue.length === 1 && devicesDropdownValue[ 0 ] === defaultDropdownItem ) {
			return this.mapDevices;
		}

		return devicesDropdownValue;
	}

	render() {
		const { options } = this.props;
		const { devicesDropdownValue, viewport, selectedPropertyConfig } = this.state;
		let mapDevices = this.mapDevices;

		let selectedDevices = this.getSelectedDevices();

		let widgetProperties = [],
			{ dropdownProperties } = options;

		if( selectedDevices.length ) {
			if( options.hideUncommonProperties ) {
				dropdownProperties.forEach( ( propertyConfig ) => {
					let isSharedProperty = false; //check if all devices has that property

					selectedDevices.forEach( ( device ) => {
						let deviceContent = device.fullDevice.snapshot.content;

						for( let property in deviceContent ) {
							if( property === propertyConfig.name ) {
								isSharedProperty = true;
							}
						};
					});

					if( isSharedProperty ) {
						widgetProperties.push( propertyConfig.displayName );
					}
				});
			}
			else {
				widgetProperties = dropdownProperties.map( dropdownPropertyConfig => dropdownPropertyConfig.displayName );
			}
		}

		const markerWidth = 65;
		const markerHeight = 65;

		const markers = selectedPropertyConfig ?
			selectedDevices.map( ( selectedDevice, index ) => {
				let filteredDevice = mapDevices.filter( mapDevice => mapDevice.name === selectedDevice.name )[ 0 ];

				let { selectedPropertyValue, selectedPropertyColor, selectedPropertyIcon } =  this.getDeviceMarkerValueColorAndIcon( filteredDevice );

				let tooltip = ! selectedPropertyConfig.showTooltips || isNully( selectedPropertyValue ) ? '' :
								getDefaultMarkerTooltip( selectedPropertyValue, selectedPropertyColor );

				let icon = new DivIcon({
								iconSize: [ markerWidth, markerHeight ],
								html: selectedPropertyIcon ? getEmptyMarkerSvg( selectedPropertyColor, selectedPropertyIcon ) :
									getDefaultMarkerSvg( selectedPropertyColor ),
								iconAnchor: [ markerWidth / 2, markerHeight ],
								className: 'my-div-icon'
							});

				return (
					<Marker
						key={ index }
						position={ [ filteredDevice.lat, filteredDevice.lng ] }
						onClick={ () => { this.onDeviceMarkerClick( filteredDevice.fullDevice ) } }
						icon={ icon } >

						{ tooltip }
					</Marker>
				);
			}) : '';


		const disableClustering = this.shouldDisableClustering();

		let initialMapViewPortDefinition = this.getInitialViewport();

		if( Object.keys( this.currentViewport ).length === 0 ) {
			this.currentViewport = initialMapViewPortDefinition;
		}

		return (
			<div className="locator-widget" >
				<div>
					<div className="widget-settings-selection">
						<Multiselect
							onChange={ this.onDevicesDropdownChange }
							data={ [ defaultDropdownItem, ...mapDevices ] }
							textField="name"
							valueField="name"
							value={ devicesDropdownValue }
							caseSensitive={false}
							minLength={1}
							filter='contains'
							id="locator-sensor-selector" />

							{
								dropdownProperties.length === 1 ? '' :
								<DropdownList
								onChange={ ( selectedProperty ) => {
									let selectedPropertyConfig = dropdownProperties.filter( property => {
										return property.displayName === selectedProperty;
									});

									selectedPropertyConfig = selectedPropertyConfig ? selectedPropertyConfig[ 0 ] : null;

									this.setState({ selectedPropertyConfig });
								} }
								data={ widgetProperties }
								textField="name"
								valueField="name"
								placeholder="Select Property"
								value={ selectedPropertyConfig.displayName }
								caseSensitive={ false }
								minLength={ 1 }
								filter="contains" />
							}
					</div>
				</div>
				<div className="map-container">
					<Map { ...initialMapViewPortDefinition } viewport={ viewport } animate={ true }
						ref="locatorMap"
						onViewportChanged={ newViewport => {
							setTimeout( () => {
								this.currentViewport = newViewport;
							}, 300 );
						} } >
						<TileLayer
							url={ config.tileLayer.url } />

						{/* MarkerClusterGroup component is not updated if we change cluster options
							TODO: check if this is fixed and use only 1 MarkerClusterGroup layer */}

						{ disableClustering ?
							<MarkerClusterGroup { ...getDefaultClusterOptions( undefined ) } >
								{ markers }
							</MarkerClusterGroup> : '' }
						{ ! disableClustering ?
							<MarkerClusterGroup { ...getDefaultClusterOptions( 30 ) } >
								{ markers }
							</MarkerClusterGroup> : '' }

						{ this.getClusteringControl( disableClustering ) }
						{ this.getFullscreenControl() }
						{ this.getLegend() }
					</Map>
				</div>
			</div>
		);
	}
}

export default stylable( EnhancedLocatorWidget );
