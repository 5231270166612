import React, { Component } from 'react';
import { connect } from 'react-redux';

import * as moment from 'moment';

import * as dashboardActions from './../../actions/popupActions.js';

import { DivIcon } from 'leaflet';
import { Map, TileLayer, Marker, Circle, Tooltip, LayerGroup, Polyline } from 'react-leaflet';
import './../../../node_modules/leaflet/dist/leaflet.css';

import MarkerClusterGroup from 'react-leaflet-markercluster';
import Control from 'react-leaflet-control';

import config from './../../config.js';

import { getDefaultMarkerSvg, getEmptyMarkerSvg, getDefaultMarkerTooltip, getDefaultClusterOptions, getPropertyValueByPath, isNully } from './../../utils/devicesUtils.js';

class FullscreenMapPopup extends Component {
	constructor ( props ) {
		super( props );

		this.state = {
			disableClustering: props.disableClustering
		}

		this.onCloseButtonClicked = this.onCloseButtonClicked.bind( this );
	}

	getDeviceMarkerValueColorAndIcon ( device ) {
		let { selectedPropertyConfig } = this.props;
		let { propertyPath, rules } = selectedPropertyConfig;
		let propertyValue = getPropertyValueByPath( device, [ 'fullDevice', 'snapshot', ...propertyPath ] );
		let markerColor = '#000000';
		let markerIcon;

		if ( isNully( propertyValue ) || isNully( rules ) ) {
			return {};
		}

		// exact value rules
		if ( rules.length && rules[ 0 ].value ) {
			rules.some( ( rule ) => {
				if ( propertyValue === rule.value ) {

					markerColor = rule.color;
					markerIcon = rule.icon;
					return true;
				}
				return false;
			} );
		}
		// value ranges
		else {
			rules.some( ( rule, i ) => {
				if ( ( i < rules.length - 1 && propertyValue <= rule.maxValue )
					|| ( i === rules.length - 1 && propertyValue >= rule.minValue ) ) {

					markerColor = rule.color;
					markerIcon = rule.icon;
					return true;
				}
				return false;
			} );
		}

		return {
			selectedPropertyValue: propertyValue,
			selectedPropertyColor: markerColor,
			selectedPropertyIcon: markerIcon
		}
	}

	getHistoricalRoute ( history, id ) {
		if ( !history.length ) {
			return '';
		}

		let { locationPropertyPath, timeStampPropertyPath } = this.props.options,
			polylineOptions = {
				color: '#000000',
				dashArray: '4 6',
				weight: 2
			},
			circleOptions = {
				radius: 10,
				weight: 10,
				fillOpacity: 1,
				fillColor: '#000000',
				color: '#000000'
			},
			circles = [];

		let polylinePoints = history.map( ( historyEl, index ) => {
			let lat = getPropertyValueByPath( historyEl, locationPropertyPath.lat ),
				lng = getPropertyValueByPath( historyEl, locationPropertyPath.lng ),
				timeStamp = getPropertyValueByPath( historyEl, timeStampPropertyPath );

			if ( lat && lng ) {
				circles.push( <Circle key={ index } center={ [ lat, lng ] } { ...circleOptions }>
					<Tooltip>
						<span>{ this.getTooltipMarkup( [ lat, lng ], timeStamp ) }</span>
					</Tooltip>
				</Circle> );

				return [ lat, lng ];
			}

			return null;
		} ).filter( latlngs => latlngs !== null );


		circles.shift(); //remove the first one ( where the marker is )

		this.mapBounds = polylinePoints;

		return (
			<LayerGroup key={ id } >
				<Polyline positions={ polylinePoints } { ...polylineOptions } />
				{ circles.length ? circles : '' }
			</LayerGroup>
		);
	}

	// get the markup for a popup
	// containing location and current time
	getTooltipMarkup ( latLngs, timestamp ) {
		return (
			<div>
				{ 'Lat: ' + latLngs[ 0 ] }
				<br />
				{ 'Long: ' + latLngs[ 1 ] }
				<br />
				{ moment( timestamp ).format( 'DD-MM HH:mm:ss' ) }
			</div>
		);
	}

	getFullscreenControl () {
		return (
			<Control position="topright" className="leaflet-bar">
				{ /* eslint-disable-next-line */ }
				<a className="map-fullscreen-icon popup-opened" onClick={ this.onCloseButtonClicked } > </a>
			</Control>
		);
	}

	getClusteringControl () {
		let { disableClustering } = this.state;

		disableClustering = disableClustering ? false : true;

		return (
			<Control position="bottomleft" className="leaflet-bar">
				{ /* eslint-disable-next-line */ }
				<a className={ "map-clustering-icon" + ( disableClustering ? '' : ' disabled' ) } onClick={ () => {
					this.setState( {
						disableClustering
					} )
				} }>
				</a>
			</Control>
		);
	}

	render () {
		let { selectedDevices, devicesHistory, viewport, selectedPropertyConfig } = this.props,
			{ disableClustering } = this.state,
			polylines = [];

		const markerWidth = 65;
		const markerHeight = 65;

		let markerColor = this.props.color;
		let defaultMarker = getDefaultMarkerSvg( markerColor );

		let deviceIcon = new DivIcon( {
			iconSize: [ markerWidth, markerHeight ],
			html: defaultMarker,
			iconAnchor: [ markerWidth / 2, markerHeight ],
			// color: markerColor,
			className: 'my-div-icon'
		} );

		let markers;

		// enhanced locator widget
		if ( selectedPropertyConfig ) {
			markers = selectedDevices.map( ( mapDevice, index ) => {
				let { selectedPropertyValue, selectedPropertyColor, selectedPropertyIcon } = this.getDeviceMarkerValueColorAndIcon( mapDevice );

				markerColor = selectedPropertyColor;
				defaultMarker = selectedPropertyIcon ? getEmptyMarkerSvg( selectedPropertyColor, selectedPropertyIcon ) :
					getDefaultMarkerSvg( selectedPropertyColor );

				deviceIcon = new DivIcon( {
					iconSize: [ markerWidth, markerHeight ],
					html: defaultMarker,
					iconAnchor: [ markerWidth / 2, markerHeight ],
					// color: markerColor,
					className: 'my-div-icon'
				} );

				let tooltip = !selectedPropertyConfig.showTooltips || isNully( selectedPropertyValue ) ? '' :
					getDefaultMarkerTooltip( selectedPropertyValue, selectedPropertyColor );

				return (
					<Marker
						key={ index }
						position={ [ mapDevice.lat, mapDevice.lng ] }
						icon={ deviceIcon } >

						{ tooltip }
					</Marker>
				);
			} );
		}
		else {
			markers = selectedDevices.map( ( mapDevice, index ) => {
				return (
					<Marker
						key={ index }
						position={ [ mapDevice.lat, mapDevice.lng ] }
						icon={ deviceIcon } >
					</Marker>
				);
			} );
		}

		if ( devicesHistory ) {
			for ( let deviceId in devicesHistory ) {
				if ( !devicesHistory[ deviceId ].isFetching ) {
					polylines.push( this.getHistoricalRoute( devicesHistory[ deviceId ].history, deviceId ) );
				}
			}
		}

		return (
			<div className="popup-markup-container">
				<div className="map-container">
					<Map viewport={ viewport } animate={ true }>
						<TileLayer
							url={ config.tileLayer.url } />

						{/* MarkerClusterGroup component is not updated if we change cluster options
							TODO: check if this is fixed and use only 1 MarkerClusterGroup layer */}

						{ disableClustering ?
							<MarkerClusterGroup { ...getDefaultClusterOptions( undefined ) } >
								{ markers }
							</MarkerClusterGroup> : '' }
						{ !disableClustering ?
							<MarkerClusterGroup { ...getDefaultClusterOptions( 30 ) } >
								{ markers }
							</MarkerClusterGroup> : '' }

						{ polylines }

						{ this.getClusteringControl() }
						{ this.getFullscreenControl() }
					</Map>
				</div>
			</div>
		);
	}

	onCloseButtonClicked () {
		this.props.closePopup(); // update store
		this.props.onClose(); // update widget state
	}
}

function mapDispatchToProps ( dispatch ) {
	return {
		closePopup: () => {
			dispatch( dashboardActions.closePopup() );
		}
	};
}

export default connect( undefined, mapDispatchToProps )( FullscreenMapPopup );
