import React, { Component } from 'react';

import { filterTypes } from './../../../../utils/datasets/filters.js';

import PropertyPathSelector from './PropertyPathSelector.jsx';
import FilterTypeSelector from './FilterTypeSelector.jsx';


export default class DatasetFilterItemSelector extends Component {
	constructor ( props ) {
		// Props are super
		super( props );

		this.onChange = this.onChange.bind( this );
		this.onDelete = this.onDelete.bind( this );
		this.onFilterTypeChange = this.onFilterTypeChange.bind( this );
		this.onPropertyPathFieldChange = this.onPropertyPathFieldChange.bind( this );
		this.onPropertyValueChange = this.onPropertyValueChange.bind( this );
		this.onMinValueChange = this.onMinValueChange.bind( this );
		this.onMaxValueChange = this.onMaxValueChange.bind( this );
	}

	onChange ( event ) {
		let newFilterValue = event.target.value;

		this.props.onChange( this.props.index, newFilterValue );
	}

	onDelete () {
		this.props.onDelete( this.props.index );
	}

	onFilterTypeChange ( newValue ) {
		let newFilterValue = {
			...this.props.filter,
			type: newValue ? newValue : null
		}

		this.props.onChange( this.props.index, newFilterValue );
	}

	onPropertyPathFieldChange ( propertyPath ) {
		let oldFilter = this.props.filter;

		let newFilterValue = {
			...oldFilter,
			options: {
				...oldFilter.options,
				propertyPath
			}
		}

		this.props.onChange( this.props.index, newFilterValue );
	}

	onPropertyValueChange ( event ) {
		let oldFilter = this.props.filter;
		let propertyValue = event.target.value;

		let newFilterValue = {
			...oldFilter,
			options: {
				...oldFilter.options,
				propertyValue
			}
		}

		this.props.onChange( this.props.index, newFilterValue );
	}

	onMinValueChange ( event ) {
		let oldFilter = this.props.filter;
		let minValue = event.target.value;

		let newFilterValue = {
			...oldFilter,
			options: {
				...oldFilter.options,
				minValue
			}
		}

		this.props.onChange( this.props.index, newFilterValue );
	}

	onMaxValueChange ( event ) {
		let oldFilter = this.props.filter;
		let maxValue = event.target.value;

		let newFilterValue = {
			...oldFilter,
			options: {
				...oldFilter.options,
				maxValue
			}
		}

		this.props.onChange( this.props.index, newFilterValue );
	}

	getPropertyPathSelector ( flow, snapshotUnit ) {
		let { filter } = this.props;

		let propertyPath = [];
		if ( filter && filter.options && filter.options.propertyPath ) {
			propertyPath = filter.options.propertyPath;
		}

		// TODO: provide the selected flow and unit
		return ( <PropertyPathSelector flow={ flow } snapshotUnit={ snapshotUnit } onChange={ this.onPropertyPathFieldChange } propertyPath={ propertyPath } /> );
	}

	getFilterOptionsSelector ( type, flow, snapshotUnit ) {
		let { filter } = this.props;

		let filterOptionsSelector;
		let selectedFilterOptions = [];

		let selectedFilterType = filterTypes.find( filterType => ( filterType.type === type ) );

		if ( selectedFilterType ) {
			selectedFilterOptions = selectedFilterType.options;
		}

		filterOptionsSelector = selectedFilterOptions.map( ( selectedFilterOption, index ) => {
			switch ( selectedFilterOption ) {
				case 'propertyPath': {
					// We skip the property path, since it's always there.
					return null;
				}
				case 'propertyValue': {
					let propertyValue = '';

					if ( filter && filter.options && filter.options.propertyValue ) {
						propertyValue = filter.options.propertyValue;
					}

					return (
						<label key={ index }>
							Property Value
							<input value={ propertyValue } onChange={ this.onPropertyValueChange } />
						</label>
					);
				}
				case 'minValue': {
					let minValue = '';

					if ( filter && filter.options && filter.options.minValue ) {
						minValue = filter.options.minValue;
					}

					return (
						<label key={ index }>
							Min Value
							<input value={ minValue } onChange={ this.onMinValueChange } />
						</label>
					);
				}
				case 'maxValue': {
					let maxValue = '';

					if ( filter && filter.options && filter.options.maxValue ) {
						maxValue = filter.options.maxValue;
					}

					return (
						<label key={ index }>
							Max Value
							<input value={ maxValue } onChange={ this.onMaxValueChange } />
						</label>
					);
				}
				default:
					return ( 'These filter types are not implemented' );
			}
		} );

		return filterOptionsSelector;
	}

	render () {
		let {
			filter,
			flow,
			snapshotUnit,
		} = this.props;

		let {
			type
		} = filter;

		let filterOptionsSelector = this.getFilterOptionsSelector( type, flow, snapshotUnit );

		return (
			<>
				<div style={ { paddingTop: '0.5rem' } }>
					{ this.getPropertyPathSelector( flow, snapshotUnit ) }
				</div>
				<div>
					<FilterTypeSelector onChange={ this.onFilterTypeChange } activeFilter={ type } />
				</div>
				<label>
					{ filterOptionsSelector }
					<button onClick={ this.onDelete }>Remove this filter</button>
				</label>
			</>
		);
	}
}