import React from 'react';

// The drag-and-drop grid lib
import { Responsive, WidthProvider } from 'react-grid-layout';
let ResponsiveReactGridLayout = WidthProvider( Responsive );

export default ( props ) => (
	<ResponsiveReactGridLayout
		className={ `grid-stack ${ props.isDndEnabled ? 'widgets-dnd-enabled' : '' }` }
		layouts={ props.layouts }
		breakpoints={ {
			md: 996,
			sm: 768,
			xs: 480
		} }
		cols={ {
			md: 12,
			sm: 6,
			xs: 3
		} }
		onDragStop={ props.onDashboardRearrange }
		onResizeStop={ props.onDashboardRearrange }
		rowHeight={ 30 }
		width={ 1200 } >
		{ props.children }
	</ResponsiveReactGridLayout>
);