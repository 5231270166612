// {
//     "type": "RealtimeLineChartWidget",
//     "options": {
//         "widgetTitle": "Historical Route",
//         "widgetId": "prorail-sensor-historical-route-widget",
//         "valuesColors": [
//             "#48ae55",
//             "#ffc04d",
//             "#f63232"
//         ],
//         "axisDatetimeFormat": "HH:mm:ss",
//         "propertyPath": {
//             "lat": [
//                 "content",
//                 "extTemp"
//             ]
//         },
//         "timeStampPropertyPath": [
//             "timeSet"
//         ],
//         "selection": [
//             {
//                 "type": "first_with_value",
//                 "path": [
//                     "id"
//                 ],
//                 "value": "Test_1"
//             }
//         ],
//         "historyPointsCount": 15,
//         "datasets": [
//             {
//                 "flowName": "prorail",
//                 "units": {
//                     "messages": "prorailMessageMemoryRepository",
//                     "snapshot": "prorailSnapshot"
//                 },
//                 "filters": [
//                     {
//                         "type": "has_snapshot_property",
//                         "propertyPath": [
//                             "content",
//                             "extTemp"
//                         ]
//                     }
//                 ]
//             }
//         ]
//     },
//     "position": {
//         "x": 0,
//         "y": 30,
//         "h": 6,
//         "w": 5
//     }
// },


import React, { Component } from 'react';

import { getPropertyValueByPath, isNully } from './../../utils/devicesUtils.js';
import { areArraysEqual } from './../../utils/generalUtils.js';
import MultipleRealtimeLineChart from './../../components/Charts/MultipleRealtimeLineChart.jsx';

import * as moment from 'moment';

import stylable from './../../decorators/stylable.jsx';

class RealtimeLineChartWidget extends Component {
	constructor ( props ) {
		// props are super!
		super( props );

		this.state = {
			layoutRefresh: 0,
			devicesNames: []
		}

		this.widgetDevices = [];
		this.initialData = [];
		this.data = [];
		this.devicesLastTimeStamp = {};

		this.xAxisBindings = {};
		this.valuesArray = [];
	}

	componentWillMount () {
		this.props.api.subscribeForLayoutChange( () => {
			this.setState( { layoutRefresh: this.state.layoutRefresh + 1 } );
		} );
	}

	componentWillUnmount () {
		this.props.api.unsubscribeForLayoutChange();
	}

	componentWillReceiveProps ( nextProps ) {
		this.prepareWidgetDevices( nextProps );
	}

	componentWillUpdate ( nextProps, nextState ) {
		if ( !areArraysEqual( this.state.devicesNames, nextState.devicesNames ) ) {
			this.isFetching = true;
			this.fetchWidgetHistoryData( nextProps );
		}
	}

	prepareWidgetDevices ( newProps ) {
		const { options } = this.props;
		let monitoredDevices,
			widgetDevices = [],
			devicesNames = [];

		// selected device !!!
		if ( newProps.provided.selectedDevice ) {
			monitoredDevices = [ newProps.provided.selectedDevice ];
		}
		else if ( newProps.provided.devices ) {
			monitoredDevices = newProps.provided.devices;
		}

		if ( monitoredDevices ) {
			monitoredDevices.forEach( ( device ) => {
				const deviceName = device.snapshot.id;
				const property = getPropertyValueByPath( device, [ 'snapshot', ...options.propertyPath ] );
				const timeStamp = moment( getPropertyValueByPath( device, [ 'snapshot', ...options.timeStampPropertyPath ] ) ).format( 'YYYY-MM-DD HH:mm:ss' );

				devicesNames.push( deviceName );

				if ( !isNully( property ) ) {
					widgetDevices.push( {
						name: deviceName,
						property,
						timeStamp,
						fullDevice: device
					} );
				}
			} );
		}

		this.setState( {
			devicesNames
		} );

		this.widgetDevices = widgetDevices;

		let data = [],
			devicesLastTimeStamp = this.devicesLastTimeStamp;

		//get the last device values
		widgetDevices.forEach( device => {
			if ( !devicesLastTimeStamp[ device.name ] ||
				devicesLastTimeStamp[ device.name ] !== device.timeStamp ) {

				devicesLastTimeStamp[ device.name ] = device.timeStamp;

				let xAxis = [ 'x_' + device.name ];
				let values = [ device.name ];

				if ( !isNully( device.property ) ) {
					xAxis.push( device.timeStamp );
					values.push( device.property );
				}

				data.push( xAxis );
				data.push( values );
			}
		} );

		this.data = data;
		this.devicesLastTimeStamp = devicesLastTimeStamp;
	}

	prepareChartData ( devicesHistory ) {
		const { options } = this.props;

		let widgetDevices = this.widgetDevices,
			xAxisBindings = {},
			valuesArray = [];

		for ( let deviceId in devicesHistory ) {
			let historicalDevice =
				widgetDevices.find( ( device ) => {
					return ( device.fullDevice.deviceId === deviceId )
				} );

			let deviceName = historicalDevice.name;

			let singleDeviceHistory = devicesHistory[ deviceId ];

			if ( singleDeviceHistory.isFetching ) {
				this.isFetching = true;
			}
			else if ( singleDeviceHistory.history ) {
				let xAxis = [ 'x_' + deviceName ];
				let values = [ deviceName ];

				singleDeviceHistory.history.forEach( ( historyEntry ) => {
					const propertyValue = getPropertyValueByPath( historyEntry, options.propertyPath );

					if ( propertyValue !== undefined && propertyValue !== null ) {
						xAxis.push( moment( getPropertyValueByPath( historyEntry, options.timeStampPropertyPath ) ).format( 'YYYY-MM-DD HH:mm:ss' ) );
						values.push( propertyValue );
					}
				} );

				xAxisBindings[ deviceName ] = 'x_' + deviceName;
				valuesArray.push( xAxis );
				valuesArray.push( values );
			}
		}

		this.xAxisBindings = xAxisBindings;
		this.valuesArray = valuesArray;
	}

	areDatasetsLoaded ( newProps ) {
		if ( ( newProps.provided.devices && newProps.provided.devices.length ) ||
			( newProps.provided.selectedDevice ) ) {
			return true;
		}

		return false;
	}

	fetchWidgetHistoryData ( newProps ) {
		if ( this.areDatasetsLoaded( newProps ) ) {
			let selectedDevices = this.widgetDevices;

			let fullSelectedDevices = selectedDevices.map( device => device.fullDevice );

			let historyPromise = newProps.api.fetchDevicesLastHistory( fullSelectedDevices, newProps.options.historyPointsCount );

			if ( historyPromise ) {
				historyPromise.then( ( history ) => {
					let devicesHistory = {};

					fullSelectedDevices.forEach( ( device, index ) => {
						devicesHistory[ device.deviceId ] = {
							history: history[ index ]
						}
					} );

					this.prepareChartData( devicesHistory );
					this.setState( {
						devicesHistory,
						layoutRefresh: this.state.layoutRefresh + 1 // we must refresh the chart
					} );

					this.isFetching = false;

				} );
			}
		}
	}

	render () {
		const { options } = this.props;

		let multiChart = (
			<MultipleRealtimeLineChart
				xAxisBindings={ this.xAxisBindings }
				valuesArray={ this.valuesArray }
				valuesColors={ options.valuesColors ? options.valuesColors : [] }
				axisDatetimeFormat={ options.axisDatetimeFormat }
				isFetching={ this.isFetching }
				forceRefreshCounter={ this.state.layoutRefresh }

				showXAxis={ true }
				showYAxis={ true }
				showXGridLines={ false }
				showYGridLines={ true }

				data={ this.data }
				widgetId={ options.widgetId }
			/>
		);

		return (
			<div className="chart-widget" id={ options.widgetId }>
				<div className="chart-container">
					{ multiChart }
				</div>
			</div>
		);
	}
}

export default stylable( RealtimeLineChartWidget );
