import React, { Component } from 'react';

import DevicesOverviewHistoryChart from './DevicesOverviewHistoryChart.jsx';
import StringPropertyHistoryOverview from './StringPropertyHistoryOverview.jsx';

import { getPropertyValueByPath } from './../../utils/devicesUtils.js';

import JSONTree from 'react-json-tree';

const jsonTreeTheme = {
	scheme: 'google',
	author: 'seth wright (http://sethawright.com)',
	base00: '#1d1f21',
	base01: '#282a2e',
	base02: '#373b41',
	base03: '#969896',
	base04: '#b4b7b4',
	base05: '#c5c8c6',
	base06: '#e0e0e0',
	base07: '#ffffff',
	base08: '#CC342B',
	base09: '#F96A38',
	base0A: '#FBA922',
	base0B: '#198844',
	base0C: '#0099CC',
	base0D: '#0099CC',
	base0E: '#A36AC7',
	base0F: '#0099CC'
};

export default class SelectedPropertyOverview extends Component {
	render () {
		let {
			selectedDevice,
			selectedPropertyPath,
			chartOptions,
			api
		} = this.props;

		let propertyValue;
		if ( selectedPropertyPath ) {
			propertyValue = getPropertyValueByPath( selectedDevice, selectedPropertyPath );
		}


		let selectedPropertyName;
		let propertyValueUnit;
		let readablePropertyName;


		if ( selectedPropertyPath ) {
			selectedPropertyName = selectedPropertyPath[ selectedPropertyPath.length - 1 ];
			propertyValueUnit = this.props.getUnitForProperty( selectedPropertyName );
			readablePropertyName = this.props.getReadableNameForProperty( selectedPropertyName );
		}

		if ( propertyValue !== null && propertyValue !== undefined ) {
			if ( !isNaN( propertyValue ) ) {
				return (
					<DevicesOverviewHistoryChart
						toggleChartContainer={ this.props.toggleChartContainer }
						chartOptions={ chartOptions }
						selectedDevice={ selectedDevice }
						selectedPropertyPath={ selectedPropertyPath }
						propertyValueUnit={ propertyValueUnit }
						readablePropertyName={ readablePropertyName }
						api={ api } />
				);
			}

			if ( typeof propertyValue === 'object' ) {
				return (
					<div className="sow-complex-property-overview">
						<h3>
							{ readablePropertyName }
						</h3>
						<div className="sow-complex-property-json-tree">
							<JSONTree
								data={ propertyValue }
								theme={ jsonTreeTheme }
								hideRoot={ true }
								shouldExpandNode={ () => true }
							/>
						</div>
					</div>
				);
			}

			return (
				<StringPropertyHistoryOverview
					toggleChartContainer={ this.props.toggleChartContainer }
					selectedDevice={ selectedDevice }
					selectedPropertyPath={ selectedPropertyPath }
					api={ api } />
			);

		}

		return (
			<div className="sow-popup-property-history-container">
				<p>
					{ 'Select a property to see its overview' }
				</p>
			</div>
		);
	}
}
