import React from 'react';

import logoutImage from './../img/logout.svg';

const LogoutButton = ( props ) => (
	<button
		className="btn-nostyle logout-btn"
		title="Log out"
		onClick={ props.logOut }>
		<img className="icon" src={ logoutImage } alt="Log out" />
	</button>
);

export default LogoutButton;
