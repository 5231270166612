// {
//     "type": "RealtimeRouteWidget",
//     "options": {
//         "widgetTitle": "Historical Route",
//         "widgetId": "prorail-sensor-historical-route-widget",
//         "disableClustering": true,
//         "locationPropertyPath": {
//             "lat": [
//                 "content",
//                 "lat"
//             ],
//             "lng": [
//                 "content",
//                 "lng"
//             ]
//         },
//         "timeStampPropertyPath": [
//             "timeSet"
//         ],
//         "historyPointsCount": 15,
//         "datasets": [
//             {
//                 "flowName": "prorail",
//                 "units": {
//                     "messages": "prorailMessageMemoryRepository",
//                     "snapshot": "prorailSnapshot"
//                 },
//                 "filters": [
//                     {
//                         "type": "has_snapshot_property",
//                         "propertyPath": [
//                             "content",
//                             "lat"
//                         ]
//                     }
//                 ]
//             }
//         ]
//     },
//     "position": {
//         "x": 6,
//         "y": 15,
//         "h": 11,
//         "w": 5
//     }
// },


import React, { Component } from 'react';

import Multiselect from '../../components/ReactWidgets/Multiselect';

import { getPropertyValueByPath } from './../../utils/devicesUtils.js';

import HistoricalRouteMap from '../../components/HistoricalRouteMap';

import stylable from './../../decorators/stylable.jsx';

const defaultDropdownItem = { name: 'All' };

class RealtimeRouteWidget extends Component {
	constructor ( props ) {
		// props are super!
		super( props );

		this.state = {
			dropdownValue: [],
			selectedDevices: []
		}

		this.widgetDevices = [];

		this.onDropdownChange = this.onDropdownChange.bind( this );
	}

	componentWillUpdate ( newProps, newState ) {
		if( newState.dropdownValue !== this.state.dropdownValue ) {
			this.fetchWidgetHistoryData( newState );
		}
	}

	componentDidUpdate ( prevProps, prevState ) {
		// fetch the history after the devices are received
		if( ! this.areDatasetsLoaded( prevProps ) ) {
			this.fetchWidgetHistoryData( this.state );
		}
	}

	componentWillReceiveProps ( nextProps ) {
		this.prepareWidgetDevices( nextProps );

		// only once
		this.setupDefaults( nextProps );
	}

	prepareWidgetDevices ( newProps ) {
		let monitoredDevices;
		let widgetDevices = [];
		const { options } = this.props;

		if( newProps.provided.devices ) {
			monitoredDevices = newProps.provided.devices;
		}

		if( monitoredDevices ) {
			const latPath = options.locationPropertyPath.lat;
			const lngPath = options.locationPropertyPath.lng;

			monitoredDevices.forEach( ( device ) => {
				const deviceName = device.snapshot.id;
				const lat = getPropertyValueByPath( device, [ 'snapshot', ...latPath ] );
				const lng = getPropertyValueByPath( device, [ 'snapshot', ...lngPath ] );
				const timeStamp = getPropertyValueByPath( device, [ 'snapshot', ...options.timeStampPropertyPath ] );

				if( lat && lng ) {
					widgetDevices.push({
						name: deviceName,
						lat,
						lng,
						timeStamp,
						fullDevice: device
					});
				}
			});
		}

		this.widgetDevices = widgetDevices;

		// update selected devices after realtime updates
		let { selectedDevices, devicesHistory } = this.state,
			deviceHistory;

		for ( let i in selectedDevices ) {
			for( let j in widgetDevices ) {
				if( widgetDevices[ j ].name === selectedDevices[ i ].name ) {
					selectedDevices[ i ] = widgetDevices[ j ];

					deviceHistory = devicesHistory[ selectedDevices[ i ].fullDevice.deviceId ].history;

					if( deviceHistory[ 0 ].timeSet !== selectedDevices[ i ].fullDevice.snapshot.timeSet ) {
						deviceHistory.unshift( selectedDevices[ i ].fullDevice.snapshot );
						deviceHistory.pop();
					}

					break;
				}
			}
		}
	}

	setupDefaults( props ) {
		let savedWidgetState = this.props.api.getWidgetState(),
			selectedDevicesNames = savedWidgetState? savedWidgetState.selectedDevicesNames : null,
			{ dropdownValue } = this.state,
			widgetDevices = this.widgetDevices;

		// we use this function only once, when we don't have a dropdown value
		if( dropdownValue.length ) {
			return;
		}

		if( ! selectedDevicesNames || ( selectedDevicesNames && selectedDevicesNames[ 0 ] === 'All' ) ) {
			dropdownValue = [ defaultDropdownItem ];
		}
		else if( widgetDevices.length ) {
			for ( let i in selectedDevicesNames ) {
				for( let j in widgetDevices ) {
					if( widgetDevices[ j ].name === selectedDevicesNames[ i ] ) {
						dropdownValue.push( widgetDevices[ j ] );

						break;
					}
				}
			}
		}

		this.setState({
			dropdownValue
		});
	}

	areDatasetsLoaded ( newProps ) {
		if( newProps.provided.devices && newProps.provided.devices.length ) {
			return true;
		}

		return false;
	}

	fetchWidgetHistoryData( newState ) {
		let { selectedDevices, dropdownValue } = newState;

		if( this.areDatasetsLoaded( this.props ) ) {
			if( dropdownValue.length ) {

				if( dropdownValue.length === 1 && dropdownValue[ 0 ] === defaultDropdownItem ) {
					selectedDevices = this.widgetDevices;
				}
				else {
					selectedDevices = dropdownValue;
				}

				let fullSelectedDevices = selectedDevices.map( device => device.fullDevice );

				let historyPromise = this.props.api.fetchDevicesLastHistory( fullSelectedDevices, this.props.options.historyPointsCount );

				if( historyPromise ) {
					historyPromise.then( ( history ) => {
						let devicesHistory = {};

						fullSelectedDevices.forEach( ( device, index ) => {
							devicesHistory[ device.deviceId ] = {
								history: history[ index ]
							}
						});

						this.setState({
							selectedDevices,
							devicesHistory,
							isHistoryLoading: false
						});
					});
				}
			}
		}
	}

	onDropdownChange ( value ) {
		let dropdownValue = value;

		if( value.length === 0 ) {
			dropdownValue = [ defaultDropdownItem ]
		}
		else if( value.length > 1 ) {
			let [ firstItem, ...restOfValue ] = value;

			if( firstItem === defaultDropdownItem ) {
				dropdownValue = restOfValue;
			}
			else if( restOfValue.indexOf( defaultDropdownItem ) !== -1 ) {
				dropdownValue = [ defaultDropdownItem ]
			}
		}

		this.setState({
			dropdownValue
		});

		let selectedDevicesNames = dropdownValue.map( device => {
			return device.name;
		});

		this.props.api.saveWidgetState( { selectedDevicesNames } ); // or 'All'
	}

	render() {
		const { options } = this.props,
			{ widgetSubtitle } = options,
			{ dropdownValue, selectedDevices, devicesHistory } = this.state;

		let	widgetDevices = this.widgetDevices;

		let subtitleMarkup = '';
		if ( widgetSubtitle ) {
			subtitleMarkup = <p className="widget-subtitle default">{ widgetSubtitle }</p>;
		}

		return (
			<div className="locator-widget">
				{ subtitleMarkup }
				<div>
					<div className="widget-settings-selection">
						<Multiselect
							onChange={ this.onDropdownChange }
							data={ [ defaultDropdownItem, ...widgetDevices ] }
							valueField="name"
							textField="name"
							value={ dropdownValue }
							caseSensitive={ false }
							minLength={ 1 }
							filter='contains'
							id="locator-sensor-selector" />
					</div>
				</div>
				<HistoricalRouteMap
					selectedDevices={ selectedDevices }
					devicesHistory= { devicesHistory }
					locationPropertyPath={ options.locationPropertyPath }
					timeStampPropertyPath={ options.timeStampPropertyPath }
					options={ options }
					openPopup={ this.props.api.openPopup }
					updatePopup={ this.props.api.updatePopup } />
			</div>
		);
	}
}

export default stylable( RealtimeRouteWidget );
