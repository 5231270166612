import { getPropertyValueByPath } from './../../utils/devicesUtils.js';

import * as moment from 'moment';



export function getFilteredDevices ( datasets, unitsDevices, allDevices ) {
	let datasetsDevices = [];

	// Build up the dataset devices
	if ( datasets ) {
		datasetsDevices = datasets.map( ( { flowName, units, filters } ) => {
			let currentDevices;
			let snapshotUnit = units.snapshot;

			if ( unitsDevices[ snapshotUnit ] && unitsDevices[ snapshotUnit ].devices ) {
				currentDevices = unitsDevices[ snapshotUnit ].devices;
			}
			else {
				currentDevices = [];
			}

			currentDevices = currentDevices.map( deviceName => allDevices[ deviceName ] );

			if ( filters ) {
				currentDevices = applyFilters( currentDevices, filters );
			}

			return currentDevices;
		} )
	}


	// Merge the devices (unique only)
	let mergedDevices = [];

	datasetsDevices.forEach( ( datasetDevices ) => {
		datasetDevices.forEach( device => {
			if ( mergedDevices.indexOf( device ) === -1 ) {
				mergedDevices.push( device );
			}
		} )
	} );

	return mergedDevices;
}

export default function applyFilters ( devices, filters ) {
	let filteredDevices = devices;

	filters.forEach( ( filter ) => {
		filteredDevices = applySingleFilter( filteredDevices, filter );
	} );

	return filteredDevices;
};

export function applySingleFilter ( devices, filter ) {
	let filteredDevices = devices,
		filterType,
		filterOptions;

	if ( filter ) {
		filterType = filter.type;
		filterOptions = filter.options;

		if ( typeof widgetFilters[ filterType ] === 'function' ) {

			// In the old notation everything was in the filter.
			// In the new one we use a dedicated filter options property.
			if ( !filterOptions ) {
				console.warn( 'DEPRECATED: You are using the old notation of the filter options.' );
				console.warn( 'DEPRECATED: Update to the new one - with options property in the filter' );
				console.warn( 'DEPRECATED: Future versions will drop support of the old notation ' );

				filterOptions = filter;
			}

			filteredDevices = filteredDevices.filter(
				device => widgetFilters[ filterType ]( device, filterOptions ) );
		}
	}

	return filteredDevices
};

export const filterTypes = [
	{
		type: 'has_snapshot_property',
		options: [
			'propertyPath'
		]
	},
	{
		type: 'has_snapshot_property_with_value',
		options: [
			'propertyPath',
			'propertyValue'
		]
	},
	{
		type: 'has_snapshot_property_within_limits',
		options: [
			'propertyPath',
			'minValue',
			'maxValue'
		]
	},
	{
		type: 'has_snapshot_date_property_within_limits',
		options: [
			'propertyPath',
			'minDateValue',
			'maxDateValue'
		]
	},
];

var widgetFilters = {
	has_snapshot_property: function ( device, filterOptions ) {
		let propertyPath = filterOptions.propertyPath;
		let propertyValue = getPropertyValueByPath( device, [ 'snapshot', ...propertyPath ] );

		if ( propertyValue !== undefined && propertyValue !== null ) {
			return true;
		}

		return false;
	},
	has_snapshot_property_with_value: function ( device, filterOptions ) {
		let propertyPath = filterOptions.propertyPath;
		let desiredPropertyValue = filterOptions.propertyValue;
		let propertyValue = getPropertyValueByPath( device, [ 'snapshot', ...propertyPath ] );

		// Weak check because user can configure a string for a number also
		// eslint-disable-next-line
		if ( propertyValue == desiredPropertyValue ) {
			return true;
		}

		return false;
	},
	has_snapshot_property_within_limits: function ( device, filterOptions ) {
		let propertyPath = filterOptions.propertyPath;

		let minValue = parseFloat( filterOptions.minValue );
		let maxValue = parseFloat( filterOptions.maxValue );

		let propertyValue = parseFloat(
			getPropertyValueByPath( device, [ 'snapshot', ...propertyPath ] ) );


		if ( isNaN( propertyValue ) ) {
			return false;
		}

		if ( ( !isNaN( minValue ) ) && propertyValue < minValue ) {
			return false;
		}

		if ( ( !isNaN( maxValue ) ) && propertyValue > maxValue ) {
			return false;
		}

		return true;
	},
	has_snapshot_date_property_within_limits: function ( device, filterOptions ) {
		// return true;
		let propertyPath = filterOptions.propertyPath;
		let propertyValue = getPropertyValueByPath( device, [ 'snapshot', ...propertyPath ] );

		let propertyDateValue;
		let minValue = filterOptions.minValue;
		let maxValue = filterOptions.maxValue;
		let exactMinValue;
		let exactMaxValue;

		if ( propertyValue ) {
			propertyDateValue = moment( propertyValue );
		}

		if ( minValue ) {
			if ( minValue.type === 'relative_to_now' ) {
				exactMinValue = moment().subtract( minValue.size, minValue.unit );
			}
			else {
				exactMinValue = moment( minValue.value );
			}
		}

		if ( maxValue && maxValue.type ) {
			if ( maxValue.type === 'relative_to_now' ) {
				exactMaxValue = moment().subtract( maxValue.size, maxValue.unit );
			}
			else {
				exactMaxValue = moment( maxValue.value );
			}
		}

		if ( !propertyDateValue || !propertyDateValue.isValid() ) {
			return false;
		}

		if ( exactMinValue && exactMinValue.isValid() && propertyDateValue < exactMinValue ) {
			return false;
		}

		if ( exactMaxValue && exactMaxValue.isValid() && propertyDateValue > exactMaxValue ) {
			return false;
		}

		return true;
	}
};

export function calculateNextFiltersRefreshTime ( devices, filters ) {
	let nextRefreshTimes = [];

	filters.forEach( ( filter ) => {
		let nextSingleFilterRefreshTime = calculateNextSingleFilterRefreshTime( devices, filter );

		if ( nextSingleFilterRefreshTime ) {
			nextRefreshTimes.push( nextSingleFilterRefreshTime );
		}
	} );

	if ( nextRefreshTimes.length === 0 ) {
		return null;
	}

	let nextRefreshTime = Math.min( ...nextRefreshTimes );


	return nextRefreshTime;
};

export function calculateNextSingleFilterRefreshTime ( devices, filter ) {
	let filterRefreshTime = [],
		filterType,
		filterOptions;

	if ( filter ) {
		filterType = filter.type;
		filterOptions = filter.options;

		if ( typeof widgetFiltersTimeouts[ filterType ] === 'function' ) {

			// In the old notation everything was in the filter.
			// In the new one we use a dedicated filter options property.
			if ( !filterOptions ) {
				console.warn( 'DEPRECATED: You are using the old notation of the filter options.' );
				console.warn( 'DEPRECATED: Update to the new one - with options property in the filter' );
				console.warn( 'DEPRECATED: Future versions will drop support of the old notation ' );

				filterOptions = filter;
			}

			devices.forEach( ( device ) => {
				let deviceRefreshTime = widgetFiltersTimeouts[ filterType ]( device, filterOptions );

				if ( deviceRefreshTime ) {
					filterRefreshTime.push( deviceRefreshTime );
				}
			} );
		}
	}

	if ( filterRefreshTime.length === 0 ) {
		return null;
	}

	return Math.min( ...filterRefreshTime );
};

var widgetFiltersTimeouts = {
	has_snapshot_date_property_within_limits: function ( device, filterOptions ) {
		let propertyPath = filterOptions.propertyPath,
			propertyValue = getPropertyValueByPath( device, [ 'snapshot', ...propertyPath ] ),
			propertyDateValue,
			minValue = filterOptions.minValue,
			maxValue = filterOptions.maxValue,
			exactMinValue,
			exactMaxValue,
			refreshAfter = [];

		if ( propertyValue ) {
			propertyDateValue = moment( propertyValue );
		}

		if ( minValue && minValue.type ) {
			if ( minValue.type === 'relative_to_now' ) {
				exactMinValue = moment().subtract( minValue.size, minValue.unit );
			}
			else {
				exactMinValue = moment( minValue.value );
			}
		}


		if ( maxValue && maxValue.type ) {
			if ( maxValue.type === 'relative_to_now' ) {
				exactMaxValue = moment().subtract( maxValue.size, maxValue.unit );
			}
			else {
				exactMaxValue = moment( maxValue.value );
			}
		}

		if ( !propertyDateValue || !propertyDateValue.isValid() ) {
			return null;
		}

		if ( exactMinValue && exactMinValue.isValid() ) {
			if ( ( ( + propertyDateValue ) - ( + exactMinValue ) ) > 0 ) {
				refreshAfter.push( ( + propertyDateValue ) - ( + exactMinValue ) );
			}
		}

		if ( exactMaxValue && exactMaxValue.isValid() ) {
			if ( ( ( + propertyDateValue ) - ( + exactMaxValue ) ) > 0 ) {
				refreshAfter.push( ( + propertyDateValue ) - ( + exactMaxValue ) );
			}
		}

		if ( refreshAfter.length === 0 ) {
			return null;
		}

		return Math.min( ...refreshAfter );
	}
}
