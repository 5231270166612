import React, { Component } from 'react';

import { getPropertyValueByPath, isNully } from './../../utils/devicesUtils.js';

import stylable from './../../decorators/stylable.jsx';

// import image from './image.png';

import DevicesOverviewPopup from './../../popups/DevicesOverviewPopup/DevicesOverviewPopup.jsx';

// import MonitoredDeviceItem from './MonitoredDeviceItem.jsx';
import LazyMonitoredDeviceItem from './LazyMonitoredDeviceItem.jsx';

import { forceCheck } from 'react-lazyload';
// import LazyLoad from 'react-lazyload';

class StateMonitoringWidget extends Component {
	// static getWidgetConfiguration() {
	// 	return {
	// 		name: 'Sensors Overview',
	// 		image: image,
	// 		description: 'Use this image to show many devices',
	// 		defaultSize: [ 2, 4 ],
	// 		fields: [{
	// 			id: 'widgetSubtitle',
	// 			type: 'textarea',
	// 			label: 'Subtitle'
	// 		}]
	// 	};
	// }
	constructor ( props ) {
		// Props are super!
		super( props );

		this.state = {
			activeFilters: {}
		};

		this.toggleCriticalFilter = this.toggleCriticalFilter.bind( this );
		this.toggleWarningFilter = this.toggleWarningFilter.bind( this );
		this.toggleOkayFilter = this.toggleOkayFilter.bind( this );

		this.onDeviceClick = this.onDeviceClick.bind( this );

		this.getValueStatus = this.getValueStatus.bind( this );
	}

	componentDidUpdate( prevProps, prevState ) {
		forceCheck();
	}

	render() {
		let {
			options,
		} = this.props;

		let devicesRows = [];

		let activeFilters = this.state.activeFilters;
		let areFiltersEmpty = this._areFiltersEmpty( activeFilters );


		/* Coloring is a setting in the widget  */
		let colorCodes = {};
		if( options.colorCodes ) {
			colorCodes = { ...options.colorCodes };
		}

		if( !colorCodes.criticalColor ) {
			colorCodes.criticalColor = '#cb2026';
		}
		if( !colorCodes.warningColor ) {
			colorCodes.warningColor = '#fdba32';
		}
		if( !colorCodes.okayColor ) {
			colorCodes.okayColor = '#669900';
		}


		let isLoadingRelatedData = false;

		if( this.props.provided ) {
			isLoadingRelatedData = this.props.provided.isLoading;
		}

		let monitoringTableCssClass = 'monitoring-widget-table-container';

		if( isLoadingRelatedData ) {
			monitoringTableCssClass += ' loading';
		}

		if( this.props.provided.devices ) {
			this.props.provided.devices.forEach( ( device ) => {
				devicesRows.push(
					<LazyMonitoredDeviceItem
						key={ device.deviceId }
						device={ device }

						propertyPaths={ this.props.options.propertyPaths }
						colorCodes={ colorCodes }

						areFiltersEmpty={ areFiltersEmpty }
						activeFilters={ activeFilters }

						warningRules={ this.props.options.warningRules }
						criticalRules={ this.props.options.criticalRules }

						api={ this.props.api }
						onDeviceClick={ () => { this.onDeviceClick( device ) } }
						getValueStatus={ this.getValueStatus }
						/>
				);
			});
		}

		let criticalClass = '';
		let warningClass = '';
		let okayClass = '';

		if( areFiltersEmpty ) {
			criticalClass = warningClass = okayClass = ' selected';
		}
		else {
			if( activeFilters.critical ) {
				criticalClass = ' selected with-checkmark';
			}

			if( activeFilters.warning ) {
				warningClass = ' selected with-checkmark';
			}

			if( activeFilters.okay ) {
				okayClass = ' selected with-checkmark';
			}
		}

		let devicesLabel = this.props.options.devicesLabel;
		if( !devicesLabel ) {
			devicesLabel = 'Track';
		}

		let newLabel = this.props.options.newLabel;
		if( !newLabel ) {
			newLabel = 'Temp new';
		}

		let oldLabel = this.props.options.oldLabel;
		if( !oldLabel ) {
			oldLabel = 'Temp old';
		}

		return (
			<div className="state-monitoring-widget">
				<div className="monitoring-filters">
					<div className="monitoring-filters-title"> Filter </div>
					<ul className="monitoring-filters-list">
						<li onClick={ this.toggleCriticalFilter } className={ `monitoring-filter-item ${ criticalClass }` } style={{ 'background': colorCodes.criticalColor }}></li>
						<li onClick={ this.toggleWarningFilter } className={ `monitoring-filter-item ${ warningClass }` } style={{ 'background': colorCodes.warningColor }}></li>
						<li onClick={ this.toggleOkayFilter } className={ `monitoring-filter-item ${ okayClass }` } style={{ 'background': colorCodes.okayColor }}></li>
					</ul>
				</div>
				<div className={ monitoringTableCssClass }>
					<div className="monitoring-widget-table">
						<div className="monitoring-list-header">
							<div className="header-cell name-cell">{ devicesLabel }</div>
							<div className="header-cell">{ oldLabel }</div>
							<div className="header-cell">{ newLabel }</div>
						</div>
						<div>
							{ devicesRows }
						</div>
					</div>
				</div>
			</div>
		);
	}

	_areFiltersEmpty ( activeFilters ) {
		if( !activeFilters.critical
			&& !activeFilters.warning
			&& !activeFilters.okay ) {

			return true;
		}

		return false;
	}

	_getSanitizeFilters ( activeFilters ) {
		if( activeFilters.critical
			&& activeFilters.warning
			&& activeFilters.okay ) {

			return {};
		}

		return activeFilters;
	}

	toggleCriticalFilter () {
		let activeFilters = this.state.activeFilters;
		activeFilters.critical = !activeFilters.critical;

		this.setState({
			activeFilters: this._getSanitizeFilters( activeFilters )
		});
	}

	toggleWarningFilter () {
		let activeFilters = this.state.activeFilters;
		activeFilters.warning = !activeFilters.warning;

		this.setState({
			activeFilters: this._getSanitizeFilters( activeFilters )
		});
	}

	toggleOkayFilter () {
		let activeFilters = this.state.activeFilters;
		activeFilters.okay = !activeFilters.okay;

		this.setState({
			activeFilters: this._getSanitizeFilters( activeFilters )
		});
	}

	onDeviceClick ( device ) {
		this.props.api.openPopup( DevicesOverviewPopup, {
			api: this.props.api,
			deviceOptions: this.props.options.deviceOptions,
			propChartOptions: this.props.options.propChartOptions,
			latPath: this.props.options.latPath,
			lngPath: this.props.options.lngPath,
			devices: [ device ],
			historyUnitType: this.props.options.historyUnitType, // realtime or not
			propertyPaths: {
				displayName: [],
				location: [],
				communicationTime: []
			}
		});
	}

	checkDeviceRule( device, rules ) {
		if( rules ) {
			for( let singleRule of rules ) {
				let { propertyPaths, values } = singleRule;

				let pathIndex = 0;
				for( let propertyPath of propertyPaths ) {
					let propertyValue = getPropertyValueByPath( device, [ 'snapshot', ...propertyPath ] );

					if( propertyValue === values[ pathIndex ] )  {
						return true;
					}
				}
			}
		}

		return false;
	}

	getValueStatus( device, propertyValue, warningRules, criticalRules ) {
		if( !isNully( propertyValue ) ) {
			let isCritical = this.checkDeviceRule( device, criticalRules );

			if( isCritical ) {
				return 'critical';
			}
			else {
				let isWarning = this.checkDeviceRule( device, warningRules );

				if( isWarning )  {
					return 'warning';
				}
			}
		}
		else {
			return 'unknown';
		}

		return 'okay';
	}
}

export default stylable( StateMonitoringWidget );
