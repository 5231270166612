import api from './../middleware/api/index.js'


export const FETCH_NOTIFICATIONS_STARTED = 'FETCH_NOTIFICATIONS_STARTED'
export function fetchNotificationsStarted () {
	return {
		type: FETCH_NOTIFICATIONS_STARTED
	}
}

export const FETCH_NOTIFICATIONS_SUCCESS = 'FETCH_NOTIFICATIONS_SUCCESS'
export function fetchNotificationsSuccess ( notifications ) {
	return {
		type: FETCH_NOTIFICATIONS_SUCCESS,
		notifications
	}
}

export const FETCH_NOTIFICATIONS_FAILED = 'FETCH_NOTIFICATIONS_FAILED'
export function fetchNotificationsFailed ( error ) {
	return {
		type: FETCH_NOTIFICATIONS_FAILED,
		error
	}
}

export function fetchNotifications() {
	return function ( dispatch ) {
		dispatch( fetchNotificationsStarted() );

		return api.notifications().get()
			.then( notifications => {
				dispatch( fetchNotificationsSuccess( notifications ) );
				dispatch( enableRealtimeNotifications() );
			})
			.catch( error => {
				dispatch( fetchNotificationsFailed( error ) );
			});
	}
}

export const REALTIME_NOTIFICATIONS_ENABLED = 'REALTIME_NOTIFICATIONS_ENABLED';
export function realtimeNotificationsEnabled () {
	return {
		type: REALTIME_NOTIFICATIONS_ENABLED
	}

}
export const REALTIME_NOTIFICATIONS_MESSAGE = 'REALTIME_NOTIFICATIONS_MESSAGE';
export function realtimeNotificationsMessage ( message ) {
	return {
		type: REALTIME_NOTIFICATIONS_MESSAGE,
		message
	}

}
export const REALTIME_NOTIFICATIONS_ERROR = 'REALTIME_NOTIFICATIONS_ERROR';
export function realtimeNotificationsError ( error ) {
	return {
		type: REALTIME_NOTIFICATIONS_ERROR,
		error
	}

}
export const REALTIME_NOTIFICATIONS_CLOSED = 'REALTIME_NOTIFICATIONS_CLOSED';
export function realtimeNotificationsClosed () {
	return {
		type: REALTIME_NOTIFICATIONS_CLOSED
	}

}

export function enableRealtimeNotifications() {
	return function ( dispatch ) {
		dispatch( realtimeNotificationsEnabled() );

		return api.notifications().subscribe({
			onmessage: ( message ) => {
				dispatch( realtimeNotificationsMessage( JSON.parse( message.toString() ) ) );
			},
			onerror: ( error ) => {
				dispatch( realtimeNotificationsError( error ) );
			},
			onclose: () => {
				dispatch( realtimeNotificationsClosed() );
			}
		});
	}
}


export const MARK_NOTIFICATIONS_AS_SEEN_STARTED = 'MARK_NOTIFICATIONS_AS_SEEN_STARTED';
export function markNotificationsAsSeenStarted ( notificationsIds ) {
	return {
		type: MARK_NOTIFICATIONS_AS_SEEN_STARTED,
		notificationsIds
	}
}
export const MARK_NOTIFICATIONS_AS_SEEN_SUCCESS = 'MARK_NOTIFICATIONS_AS_SEEN_SUCCESS';
export function markNotificationsAsSeenSuccess ( notificationsIds ) {
	return {
		type: MARK_NOTIFICATIONS_AS_SEEN_SUCCESS,
		notificationsIds
	}
}
export const MARK_NOTIFICATIONS_AS_SEEN_FAILED = 'MARK_NOTIFICATIONS_AS_SEEN_FAILED';
export function markNotificationsAsSeenFailed ( error ) {
	return {
		type: MARK_NOTIFICATIONS_AS_SEEN_FAILED,
		error
	}
}

export function markNotificationsAsSeen( notificationsIds ) {
	return function ( dispatch ) {
		dispatch( markNotificationsAsSeenStarted( notificationsIds ) );

		return api.notifications().seen( notificationsIds )
			.then( () => {
				dispatch( markNotificationsAsSeenSuccess( notificationsIds ) )
			})
			.catch( ( error ) => {
				dispatch( markNotificationsAsSeenFailed( error ) )
			});
	}
}


export const DELETE_NOTIFICATIONS_STARTED = 'DELETE_NOTIFICATIONS_STARTED';
export function deleteNotificationsStarted ( notificationsIds ) {
	return {
		type: DELETE_NOTIFICATIONS_STARTED,
		notificationsIds
	}
}
export const DELETE_NOTIFICATIONS_PROGRESS = 'DELETE_NOTIFICATIONS_PROGRESS';
export function deleteNotificationsProgress ( deletedNotificationsIds ) {
	return {
		type: DELETE_NOTIFICATIONS_PROGRESS,
		notificationsIds: deletedNotificationsIds
	}
}
export const DELETE_NOTIFICATIONS_SUCCESS = 'DELETE_NOTIFICATIONS_SUCCESS';
export function deleteNotificationsSuccess ( notificationsIds ) {
	return {
		type: DELETE_NOTIFICATIONS_SUCCESS,
		notificationsIds
	}
}
export const DELETE_NOTIFICATIONS_FAILED = 'DELETE_NOTIFICATIONS_FAILED';
export function deleteNotificationsFailed ( error ) {
	return {
		type: DELETE_NOTIFICATIONS_FAILED,
		error
	}
}

// request uri size is restricted,
// that's why we send delete requests in bulks
export function deleteNotifications( notificationsIds ) {
	return function ( dispatch ) {
		let bulkSize = 50,
			bulk = [];

		dispatch( deleteNotificationsStarted( notificationsIds ) );

		bulk = notificationsIds.slice( 0, bulkSize );
		notificationsIds = notificationsIds.slice( bulkSize, notificationsIds.length );

		return api.notifications().delete( bulk )
			.then( () => {
				if( notificationsIds.length ) {
					dispatch( deleteNotificationsProgress( bulk ) );

					dispatch( deleteNotifications( notificationsIds ) );
				}
				else {
					dispatch( deleteNotificationsSuccess( bulk ) );
				}
			})
			.catch( ( error ) => {
				dispatch( deleteNotificationsFailed( error ) );
			})
	}
}

export const DELETE_NOTIFICATIONS_BEFORE_STARTED = 'DELETE_NOTIFICATIONS_BEFORE_STARTED';
export function deleteNotificationsBeforeStarted ( timeSet ) {
	return {
		type: DELETE_NOTIFICATIONS_BEFORE_STARTED,
		timeSet
	}
}
export const DELETE_NOTIFICATIONS_BEFORE_SUCCESS = 'DELETE_NOTIFICATIONS_BEFORE_SUCCESS';
export function deleteNotificationsBeforeSuccess ( timeSet ) {
	return {
		type: DELETE_NOTIFICATIONS_BEFORE_SUCCESS,
		timeSet
	}
}
export const DELETE_NOTIFICATIONS_BEFORE_FAILED = 'DELETE_NOTIFICATIONS_BEFORE_FAILED';
export function deleteNotificationsBeforeFailed ( error ) {
	return {
		type: DELETE_NOTIFICATIONS_BEFORE_FAILED,
		error
	}
}

export function deleteNotificationsBefore ( timeSet ) {
	return function ( dispatch ) {
		dispatch( deleteNotificationsBeforeStarted( timeSet ) );

		return api.notifications().deleteBefore( timeSet )
			.then( () => {
				dispatch( deleteNotificationsBeforeSuccess( timeSet ) );
			})
			.catch( ( error ) => {
				dispatch( deleteNotificationsBeforeFailed( error ) );
			})
	}
}
