import requireContext from 'require-context.macro';

let widgetTypes = null;

const DynamicWidgetsLoader = {
	widgetsContext: requireContext( './../widgets', true, /index\.jsx$/ ),
	clearWidgetTypes: () => {
		widgetTypes = null;
	},
	getWidgetTypes: () => {

		// The first call will cache the widget types
		if ( !widgetTypes ) {
			widgetTypes = {};

			DynamicWidgetsLoader.widgetsContext.keys().forEach( ( fileKey ) => {

				// The widget key is the name of the widget folder
				// We cannot use the context.default since the widget can be decorated with redux connect
				let widgetKey = fileKey
					.replace( '/index.jsx', '' )
					.replace( './', '' );

				if ( Object.keys( widgetTypes ).indexOf( widgetKey ) !== -1 ) {
					throw Error( `Widget with name ${ widgetKey } already exists` );
				}

				widgetTypes[ widgetKey ] = DynamicWidgetsLoader.widgetsContext( fileKey ).default;
			} );
		}

		return widgetTypes;
	}
}

export default DynamicWidgetsLoader;
