import React, { Component } from 'react';
import request from 'superagent';

import stylable from './../../decorators/stylable.jsx';

// {
//     "type": "CsvUploadWidget",
//     "options": {
//         "stylable": {
//             "backgroundImage": "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a1/Circle-icons-upload.svg/2000px-Circle-icons-upload.svg.png"
//         },
//         "widgetTitle": "CSV Upload",
//         "widgetId": "csv-upload",
//         "uploadMethod": "put",
//         "uploadUrl": "http://localhost:5050/api/flows/clientId/key?LrnDevEui={{deviceId}}&MessageType=meta",
//         "acceptedFields": [
//             "deviceId",
//             "geolocation",
//             "street",
//             "city",
//             "description",
//             "correctionTimeOffset"
//         ],
//         "maxBulkRequestsSize": 100,
//         "maxUploadMessageLength": 60,
//         "datasets": [
//             {
//                 "flowName": "prorail",
//                 "units": {
//                     "messages": "prorailMessagesMemoryRepository",
//                     "snapshot": "prorailSnapshot"
//                 }
//             }
//         ]
//     },
//     "position": {
//         "x": 2,
//         "y": 15,
//         "h": 4,
//         "w": 2
//     }
// },

class CsvUploadWidget extends Component {
	constructor ( props ) {
		// Props are super
		super( props );

		this.state = {
			uploadMessage: ''
		}

		this.uploadedRowsCount = 0;
		this.failedRowsIndexes = [];

		this.uploadRow = this.uploadRow.bind( this );
	}

	componentWillMount () {
		let { uploadMethod, uploadUrl, widgetSubtitle } = this.props.options;

		if ( !uploadMethod || !uploadUrl || ( uploadMethod !== 'put' && uploadMethod !== 'post' ) ) {
			this.setState( {
				uploadMessage: 'Configuration problem!'
			} );
		}
		else {
			this.setState( {
				uploadMessage: widgetSubtitle ? widgetSubtitle : 'Click to upload'
			} )
		}
	}

	// check if accepted fields ( configuration ) contain the fields from the csv
	areHeaderFieldsAccepted ( headerFields ) {
		let { acceptedFields } = this.props.options;

		for ( let i in acceptedFields ) {
			if ( headerFields.indexOf( acceptedFields[ i ] ) < 0 ) {
				return false;
			}
		}

		return true;
	}

	handleFiles ( e ) {
		let file = e.target.files[ 0 ],
			{ acceptedFields, maxBulkRequestsSize } = this.props.options,
			areHeaderFieldsAccepted = this.areHeaderFieldsAccepted.bind( this ),
			setState = this.setState.bind( this ),
			uploadRows = this.uploadRows.bind( this ),
			rowsBulks = [],
			bulksCounter = 0;

		this.uploadedRowsCount = 0;
		this.failedRowsIndexes = [];

		let reader = new FileReader();

		reader.onload = ( function () {
			return function ( e ) {
				let result = e.target.result,
					rows = result.split( /\n/ );

				setState( {
					uploadMessage: 'Loading...'
				} );

				if ( !rows.length ) {
					setState( {
						uploadMessage: 'Error in the csv file!'
					} );

					return;
				}

				rows = rows.filter( row => {
					// remove the empty rows
					return row.replace( /\s/g, '' ) !== '';
				} )
					.map( row => {
						return row.trim().split( /[,;]\s?/ );
					} );

				let headerRow = rows[ 0 ];

				if ( !areHeaderFieldsAccepted( headerRow ) ) {
					setState( {
						uploadMessage: 'Header cells are not from the accepted fields!'
					} );

					return;
				}

				rows.shift();

				rowsBulks[ bulksCounter ] = [];

				// prepare rows bulks
				rows.forEach( ( row, index ) => {
					let rowJson = {};

					headerRow.forEach( ( el, i ) => {

						// send the accepted fields only
						if ( acceptedFields.indexOf( el ) > -1 ) {
							rowJson[ el ] = row[ i ];
						}
					} );

					// we want to send the data in bulks
					rowsBulks[ bulksCounter ].push( rowJson );

					if ( index === maxBulkRequestsSize * ( bulksCounter + 1 ) ) {
						bulksCounter++;
						rowsBulks[ bulksCounter ] = [];
					}
				} );

				uploadRows( rowsBulks );
			}
		} )();

		if ( file ) {
			reader.readAsText( file );
		}
	}

	// send 1 bulk of requests, wait for them to finish, then send another
	uploadRows ( rowsBulks ) {
		let currentBulkPromises = [],
			{ uploadRow } = this,
			updateUploadMessage = this.updateUploadMessage.bind( this ),
			bulksCounter = 0;

		if ( !rowsBulks.length ) {
			return;
		}

		sendBulks( bulksCounter );

		function sendBulks ( bulksCounter ) {
			currentBulkPromises = [];

			if ( !rowsBulks[ bulksCounter ] ) {
				updateUploadMessage( false );
				return;
			}

			rowsBulks[ bulksCounter ].forEach( ( row, index ) => {
				currentBulkPromises.push( uploadRow( row, index ) );
			} );

			Promise.all( currentBulkPromises ).then( values => {
				updateUploadMessage( true );
				bulksCounter++;
				sendBulks( bulksCounter );
			} );
		}
	}

	uploadRow ( row, index ) {
		let { uploadUrl, uploadMethod } = this.props.options;

		uploadUrl = uploadUrl.replace( '{{deviceId}}', row.deviceId );

		return new Promise( ( resolve, reject ) => {
			request[ uploadMethod.toLowerCase() ]( uploadUrl )
				.send( row )
				.then( ( response ) => {
					this.uploadedRowsCount++;

					resolve();
				} )
				.catch( ( errors ) => {
					this.failedRowsIndexes.push( index + 2 );

					// NB! Don't use reject if u want to display all failed rows
					// Promise.all() rejects when the first promise rejects,
					// it doesn't wait for all the promises to finish
					resolve();
				} );
		} );

	}

	updateUploadMessage ( addLoading ) {
		this.setState( {
			uploadMessage: ( addLoading ? 'Loading... ' : '' ) + this.uploadedRowsCount + ' uploaded rows!' +
				( this.failedRowsIndexes.length ? ' Failed ' + this.failedRowsIndexes.join( ', ' ) : '' )
		} );
	}

	render () {
		let uploadMessage = this.state.uploadMessage,
			{ maxUploadMessageLength } = this.props.options,
			uploadMessageElement = null;

		// if the upload message is too long, we want to display it in a tooltip
		if ( uploadMessage.length > maxUploadMessageLength ) {
			uploadMessageElement =
				<div className="tooltip">{ uploadMessage.substr( 0, maxUploadMessageLength ) + '...' }
					<span className="tooltip-text">{ uploadMessage.substr( maxUploadMessageLength, uploadMessage.length ) }</span>
				</div>;
		}
		else {
			uploadMessageElement = ( <span>{ uploadMessage }</span> )
		}

		return (
			<label className="upload-widget">
				<div className="csv-upload-message widget-subtitle default">{ uploadMessageElement }</div>
				<input className="upload-csv-input" type="file" accept=".csv" onChange={ ( e ) => this.handleFiles( e ) } />
			</label>
		);
	}

}

export default stylable( CsvUploadWidget );
