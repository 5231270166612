import React, { useRef } from 'react';

import PropTypes from 'prop-types';


import './DateTimeRangePicker.css';

import RangeDateItem from './RangeDateItem.jsx';

import moment from 'moment';

/**
 * This is the replacement of the old RangeDateTimePicker that used flatpickr
 *
 * @param {Object} props
 *
 * @param {Date} props.start
 * @param {Date} props.end
 *
 * @param {Function} props.onChange
 *
 * @param {Object} [props.maxInterval]
 * @param {Number} [props.maxInterval.size]
 * @param {String} [props.maxInterval.unit]
 *
 * @param {Date} [props.minDate]
 * @param {Date} [props.maxDate]
 *
 * @param {Function} [props.onBlur]
 * @param {Function} [props.onFocus]
 *
 * @param {String} [props.dateFormat]
 *
 */
const DateTimeRangePicker = ( { start, end, onChange, maxInterval, minDate, maxDate, dateFormat } ) => {
	const startCalendar = useRef( null );
	const endCalendar = useRef( null );

	let startDayClassNameFunction;
	let endDayClassNameFunction;

	if ( maxInterval ) {
		startDayClassNameFunction = ( date ) => {
			// We mark a date as invalid when:
			// - It is greater than the currently selected end DateTime
			// OR
			// - The difference between the end of the day* and the selected end date is greater than the maxInterval
			// * - We add 1 day to the date, since the dates are represented as a timestamp of the beginning of the date.
			// We need to use the end (date+1 day), since in the edge case where the max (valid) interval happens to be on this date, we still want to indicate the date as a valid choice (since there are potential hours, that this might be true).

			// @ts-ignore we don't want to depend on the Diff type def of moment
			if ( end < date || moment( end ).diff( moment( date ).add( 1, 'day' ), maxInterval.unit, true ) > maxInterval.size ) {
				return 'calendar-day-currently-invalid';
			}

			return 'calendar-day';
		}

		endDayClassNameFunction = ( date ) => {
			// @ts-ignore: We don't want to depend on the Diff type def of moment
			if ( date < start || moment( date ).diff( moment( start ), maxInterval.unit, true ) > maxInterval.size ) {
				return 'calendar-day-currently-invalid';
			}

			return 'calendar-day';
		}
	}

	const openPicker = ( pickerRef ) => {
		pickerRef.current.setFocus( true );
		pickerRef.current.setOpen( true );
	}

	return (
		<>
			<div className="react-datespicker-container react-rangepickers-container">
				<RangeDateItem
					ref={ startCalendar }
					value={ start }
					minDate={ minDate }
					maxDate={ maxDate }
					dateFormat={ dateFormat }
					// open={ isStartPickerOpen }
					dayClassName={ startDayClassNameFunction }
					onBlur={ () => {
						// If the selected end is before the selected start - open the other picker
						if ( end < start ) {
							openPicker( endCalendar );
						}
						// If max interval is defined, but the selected range exceeds that  - open the other picker
						if ( maxInterval ) {
							// @ts-ignore we don't want to depend on the Diff type def of moment
							if ( moment( end ).diff( moment( start ), maxInterval.unit, true ) > maxInterval.size ) {
								endCalendar.current.setFocus( true );
								endCalendar.current.setOpen( true );
								// @ts-ignore we don't want to depend on the Diff type def of moment
								onChange( { end: moment( start ).add( maxInterval.size, maxInterval.unit ).toDate(), start } )
							}
						}
					} }
					onChange={ ( date ) => {
						onChange( { start: date, end } );
					} } />
				<RangeDateItem
					ref={ endCalendar }
					value={ end }
					minDate={ minDate }
					maxDate={ maxDate }
					dateFormat={ dateFormat }
					dayClassName={ endDayClassNameFunction }
					onBlur={ () => {
						// if the range is not valid - open the other picker
						if ( maxInterval ) {
							// @ts-ignore we don't want to depend on the Diff type def of moment
							if ( end < start || moment( end ).diff( moment( start ), maxInterval.unit, true ) > maxInterval.size ) {
								startCalendar.current.setFocus( true );
								startCalendar.current.setOpen( true );
								// @ts-ignore we don't want to depend on the Diff type def of moment
								onChange( { start: moment( end ).subtract( maxInterval.size, maxInterval.unit ).toDate(), end } )
							}
						}
					} }
					onChange={ ( date ) => {
						onChange( { end: date, start } );
					} } />
			</div>
			{
				maxInterval ? (
					<div>
						* You can select up to { maxInterval.size } { maxInterval.unit }
					</div>
				) : null
			}
		</>
	);
}

DateTimeRangePicker.propTypes = {
	start: PropTypes.instanceOf( Date ).isRequired,
	end: PropTypes.instanceOf( Date ).isRequired,

	onChange: PropTypes.func.isRequired,

	maxInterval: PropTypes.shape( {
		size: PropTypes.number,
		unit: PropTypes.string
	} ),

	minDate: PropTypes.instanceOf( Date ),
	maxDate: PropTypes.instanceOf( Date ),

	dateFormat: PropTypes.string
};

export default DateTimeRangePicker;
