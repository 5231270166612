import React, { Component } from 'react';

import CondensedTimestamp from './../../components/CondensedTimestamp.jsx';

const outputFormats = {
	day: 'HH:mm',
	year: 'HH:mm DD MMM',
	default: 'HH:mm DD-MM-YYYY'
};

export default class TimeseriesList extends Component {
	render () {
		const {
			xAxis,
			values,
			isFetching,
			valuesLabel
		} = this.props;

		let newToOldAxis = xAxis.reverse();
		let newToOldValues = values.reverse();

		let listItems = newToOldValues.map( ( value, index ) => {
			return (
				<tr key={ index }>
					<td>
						<CondensedTimestamp outputFormats={ outputFormats } timestamp={ newToOldAxis[ index ] } />
					</td>
					<td>{ value }</td>
				</tr>
			);
		} )

		let cssClass = 'timeseries-list';
		let tableMarkup;

		if ( isFetching ) {
			cssClass += ' loading';
			tableMarkup = 'Loading...';
		}
		else {
			tableMarkup = (
				<table>
					<tbody>
						<tr>
							<th>{ 'TimeSet' }</th>
							<th>{ valuesLabel }</th>
						</tr>
						{ listItems }
					</tbody>
				</table>
			);
		}

		return (
			<div className={ cssClass }>
				{ tableMarkup }
			</div>
		);
	}
}
