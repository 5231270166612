import React from 'react';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// The step for incrementing the minutes (in the flatpickr minute input)
const MINUTE_INCREMENT = 15;

/**
 *
 * @param {Object} props
 * @param {Date} [props.value]
 * @param {String} [props.dateFormat]
 * @param {Date} [props.minDate]
 * @param {Date} [props.maxDate]
 * @param {Function} [props.onChange]
 * @param {Function} [props.onFocus]
 * @param {Function} [props.onBlur]
 * @param {Function} [props.dayClassName]
 * @param {*} ref
 */
const RangeDateItem = ( { value, dateFormat, minDate, maxDate, onChange, onFocus, onBlur, dayClassName }, ref ) => {
	return (
		<DatePicker
			ref={ ref }
			/* We "disabledKeyboardNavigation" to true. If it is enabled, it leaves a highlight to the old date when opening the calendar via code */
			disabledKeyboardNavigation={ true }

			dayClassName={ dayClassName }
			onFocus={ onFocus }
			onBlur={ onBlur }
			onClickOutside={ onBlur }

			// preventOpenOnFocus={ true }


			selected={ value }


			onChange={ onChange }
			showTimeSelect
			timeFormat="HH:mm"
			timeIntervals={ MINUTE_INCREMENT }
			dateFormat={ dateFormat }
			timeCaption="time"
			minDate={ minDate }
			maxDate={ maxDate }
		/>
	)
};
/* Forward Ref does not support propTypes
RangeDateItem.propTypes = {
	value: PropTypes.instanceOf( Date ),
	dateFormat: PropTypes.string,
	minDate: PropTypes.instanceOf( Date ),
	maxDate: PropTypes.instanceOf( Date ),
	onChange: PropTypes.func,
	onFocus: PropTypes.func,
	onBlur: PropTypes.func,
	dayClassName: PropTypes.func
}; */

export default React.forwardRef( RangeDateItem );
