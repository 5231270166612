import React, { Component } from 'react';

import * as moment from 'moment';

import C3Chart from 'react-c3js';
import 'c3/c3.css';

class TimeseriesChart extends Component {
	render () {
		const {
			xAxis,
			values,
			isFetching,
			valuesLabel,
			valuesUnit,
			chartOptions,
			legend
		} = this.props;

		let chartLoading = (
			<div className="chart-data-loading">Loading data...</div>
		);

		let chartNoData = (
			<div className="no-chart-data">No data for the selected period</div>
		);

		let chartCanvasInnerContainer;
		if( isFetching ) {
			chartCanvasInnerContainer = chartLoading;
		}
		else if( !xAxis.length ) {
			chartCanvasInnerContainer = chartNoData;
		}
		else {
			let legendObj = legend ? legend : {};
			let maxValue = Math.max( ...values );
			let countOfYticks =  maxValue === 0 ? 3 : ( maxValue <= 6 ? Math.round( maxValue ) + 1 : undefined );
			let currentChartOptions = {};

			if ( chartOptions && chartOptions[ valuesLabel ] ) {
				currentChartOptions = chartOptions[ valuesLabel ];
			}

			let chartProps = {
				data: {
					unload: true,
					x: 'x',
					xFormat: '%Y-%m-%d %H:%M:%S',
					columns: [
						[ 'x', ...xAxis ],
						[ valuesLabel, ...values ]
					],
					colors: {
						[ valuesLabel ]: '#0099cc'
					}
				},
				axis: {
					x: {
						type: 'timeseries',
						tick: {
							format: '%Y-%m-%d',
							culling: {
								max: 4
							}
						}
					},
					y: {
						// min: 0,
						padding: { top: 0, bottom: 0 },
						tick: {
							format: ( value ) => {
								let tickFormat = currentChartOptions.yTickFormat;
								if ( tickFormat ) {
									return value + tickFormat;
								}
								if( valuesUnit ) {
									return value + ' ' + valuesUnit.substr( 0, 5 );
								}

								return value;
							},
							// If values is available, count is ignored
							values: currentChartOptions.yTickValues,
							count: countOfYticks
						}
					}
				},
				tooltip: {
					format: {
						title: function ( d ) {
							return moment( d ).format( 'HH:mm:ss' );
						}
					}
				},
				point: {
					show: false
				},
				grid: {
					x: {
						show: false
					},
					y: {
						show: true
					}
				},
				legend: legendObj,
				padding: {
					left: currentChartOptions.paddingLeft ? currentChartOptions.paddingLeft : 50,
					right: 50
				}
			}

			chartCanvasInnerContainer = ( <C3Chart className="c3-timeseries-chart" { ...chartProps } /> );
		}

		return (
			<div className="chart-canvas-container">
				{ chartCanvasInnerContainer }
			</div>
		);
	}
}

export default TimeseriesChart;