import request from 'superagent';

import config from './../config';

import IotaMqtt from './api/iota.mqtt.js';

import * as moment from 'moment';

import md5 from 'md5';

const NOTIFICATIONS_TOPIC = 'iota__notifications';

let {
	iotaUrl,
	username,
	password,
	delayApi
} = config;

let iotaApiUrl = iotaUrl + '/api';

let mqtt;

let requestsCache = {};

let cache = {
	flushCache: () => {
		if ( Object.keys( requestsCache ).length ) {
			requestsCache = {};
		}
	},
	getCache: () => {
		return requestsCache;
	}
}

let setUser = ( newUser, newPass ) => {
	username = newUser;
	password = newPass;
};

/**
 *
 * @param {Object} options
 * @param {String} options.url
 * @param {String} options.verb
 * @param {Object} [options.body]
 * @param {function ( Object ): void } [options.processResponse]
 * @param {Boolean} [options.ignoreCache]
 *
 */
let requestPromise = ( { url, verb, body, processResponse, ignoreCache } ) => {
	if ( !verb ) {
		verb = 'get';
	}

	let authHeaderValue;

	if ( username && password ) {
		let base64Key = btoa( username + ':' + password );
		authHeaderValue = 'Basic ' + base64Key;
	}


	let reqObject = {
		url,
		verb,
		body
	}
	let requestUniqueKey = md5( JSON.stringify( reqObject ) );

	if ( ignoreCache || !requestsCache[ requestUniqueKey ] ) {
		// return requestsCache[ requestUniqueKey ];
		requestsCache[ requestUniqueKey ] = new Promise( ( resolve, reject ) => {
			let req = request[ verb ]( url )
				.set( 'Content-Type', 'application/json' );

			if ( authHeaderValue ) {
				req.set( 'Authorization', authHeaderValue )
			}
			else {
				req.set( 'Authorization', 'Basic xxxx' )
			}

			if ( body ) {
				req.send( body );
			}

			req.then( ( response ) => {
				if ( delayApi ) {
					setTimeout( () => {
						if ( processResponse ) {
							response = processResponse( response )
						}
						resolve( response )

					}, delayApi );
				}
				else {
					if ( processResponse ) {
						response = processResponse( response )
					}
					resolve( response )
				}
			} )
				.catch( error => {
					reject( error );
					// setTimeout( () => {
					// 	requestsCache[ requestUniqueKey ] = null;
					// }, 1000 )
				} );
		} );
	}

	return requestsCache[ requestUniqueKey ];
}

let meta = () => {
	let url = iotaApiUrl + '/instanceMeta';

	return {
		get: () => {
			return requestPromise( {
				url,
				verb: 'get',
				processResponse: ( response ) => {
					let rawMeta = response.body;
					let metadata = {};

					if ( rawMeta ) {
						rawMeta.forEach( ( rawMetaItem ) => {
							if ( rawMetaItem.key === 'dashboards' ) {
								metadata[ rawMetaItem.key ] = JSON.parse( rawMetaItem.value );
							}
							else {
								metadata[ rawMetaItem.key ] = rawMetaItem.value;
							}
						} )
					}

					return metadata;
				}
			} )
		},
		add: ( key, value ) => {
			return requestPromise( {
				url,
				verb: 'post',
				body: {
					key,
					value
				}
			} );
		},

		/**
		 * Find meta by key
		 * @param  {String} key			The key to be found
		 * @return {Object}	The actions that are allowed
		 */
		key: ( key ) => {
			let keyUrl = url + '/key/' + key;

			return {
				get: () => {
					return requestPromise( {
						url: keyUrl,
						verb: 'get'
					} );
				},
				update: ( value ) => {
					let stringifiedValue = value

					return requestPromise( {
						url: keyUrl,
						verb: 'put',
						body: stringifiedValue
					} );
				},
				delete: () => {
					return requestPromise( {
						url: keyUrl,
						verb: 'del'
					} );
				}
			}
		}
	}
}

let user = () => {
	return {
		meta
	};
}

let notifications = () => {
	let url = iotaApiUrl + '/notifications';

	return {
		get: ( flowName ) => {
			if ( !flowName ) {
				return requestPromise( {
					url,
					verb: 'get',
					processResponse: ( response ) => {
						return response.body;
					}
				} )
			}

			return requestPromise( {
				url: url + '/flow/' + flowName,
				verb: 'get',
				processResponse: ( response ) => {
					return response.body;
				}
			} )
		},
		seen: ( notificationIds ) => {
			return requestPromise( {
				url: url + '/seen',
				verb: 'patch',
				body: {
					notificationIds
				},
				processResponse: ( response ) => {
					return response;
				}
			} )
		},
		delete: ( notificationIds ) => {
			if ( notificationIds && notificationIds.length ) {
				let bulkUrl = url + '/notificationIds?notificationIds=';

				return requestPromise( {
					url: bulkUrl + notificationIds.join( '&notificationIds=' ),
					verb: 'delete'
				} )
			}

			return requestPromise( {
				url: url + '/notificationId/' + notificationIds,
				verb: 'delete'
			} )
		},
		deleteBefore: ( beforeDate ) => {
			return requestPromise( {
				url: url + '/before/' + encodeURIComponent( moment( beforeDate ).utc().format() ),
				verb: 'delete'
			} )
		},
		subscribe: ( { onmessage, onerror, onclose } ) => {
			if ( !mqtt ) {
				mqtt = new IotaMqtt( iotaUrl );

				mqtt.subscribe( {
					notificationId: NOTIFICATIONS_TOPIC,
					onmessage,
					onerror,
					onclose
				} );
			}
		}
	}
}

let unit = ( unitId ) => {
	return {
		subscribe: ( { onmessage, onerror, onclose } ) => {
			if ( !mqtt ) {
				mqtt = new IotaMqtt( iotaUrl );
			}

			mqtt.subscribe( {
				notificationId: unitId,
				onmessage,
				onerror,
				onclose
			} );
		}
	};
}

let flows = () => {
	let url = iotaApiUrl + '/flows';

	return {
		get: () => {
			return requestPromise( {
				url: url,
				verb: 'get',
				processResponse: ( response ) => {
					let rawFlows = response.body;
					let flows = {};

					if ( rawFlows ) {
						rawFlows.forEach( ( flow ) => {
							flows[ flow.name ] = flow;
						} );
					}

					return flows;
				}
			} );
		},
		add: ( { name, address, clientId, type, source } ) => {
			return requestPromise( {
				url: url,
				verb: 'post',
				body: {
					name,
					address,
					clientId,
					type,
					source
				}
			} );
		},


		/**
		 * Find flow by name
		 * @param  {String} name    The flow name
		 * @return {Object}         Allowed actions
		 */
		name: ( name ) => {
			let nameUrl = url + '/name/' + encodeURIComponent( name );

			return {
				get: () => {
					return requestPromise( {
						url: nameUrl,
						verb: 'get'
					} );
				},
				delete: () => {
					return requestPromise( {
						url: nameUrl,
						verb: 'del'
					} );
				},
				update: ( { name, address, clientId, type, source } ) => {
					return requestPromise( {
						url: nameUrl,
						verb: 'put',
						body: {
							name,
							address,
							clientId,
							type,
							source
						},
						processResponse: ( response ) => {
							let flow = response.body;

							return flow;
						}
					} );
				},
				unit: ( unitName ) => {
					let unitUrl = nameUrl + '/readableUnit/' + encodeURIComponent( unitName );

					return {
						schema: () => {
							return requestPromise( {
								url: unitUrl + '/schema',
								verb: 'get',
								processResponse: ( response ) => {
									let unitSchema = response.body;

									return unitSchema;
								}
							} );
						},
						devices: () => {
							return {
								get: ( includeLastData = false ) => {
									return requestPromise( {
										url: unitUrl + '/devices/includeLastData/' + includeLastData,
										verb: 'get',
										processResponse: ( response ) => {
											let unitSchema = response.body;

											return unitSchema;
										}
									} );
								},
								id: ( deviceId ) => {
									let deviceUrl = unitUrl + '/device/' + encodeURIComponent( deviceId );

									return {
										last: ( { count } ) => {
											let lastUrl =
												deviceUrl + '/last' + count + '/history';

											return requestPromise( {
												url: lastUrl,
												verb: 'get',
												processResponse: ( response ) => {
													let history = response.body;

													if ( !history ) {
														history = [];
													}

													return history;
												},
												ignoreCache: true
											} )
										},
										history: ( { start, end } ) => {
											let startUtc = moment( start ).utc().format();
											let endUtc = moment( end ).utc().format();

											let historyUrl =
												deviceUrl
												+ '/startTime/' + encodeURIComponent( startUtc )
												+ '/endTime/' + encodeURIComponent( endUtc )
												+ '/history';

											return requestPromise( {
												url: historyUrl,
												verb: 'get',
												processResponse: ( response ) => {
													let history = response.body;

													if ( !history ) {
														history = [];
													}

													return history;
												}
											} );
										}
									};
								}
							};
						}
					};
				}
			};
		},

		// Invoke flow with key
		invoke: ( clientId ) => {
			let clientIdUrl = url + '/clientId/' + clientId;

			return {
				get: ( { urlQuery } ) => {
					let queryUrl = clientIdUrl + ( urlQuery ? '?' + urlQuery : '' );

					return requestPromise( {
						url: queryUrl,
						verb: 'get'
					} );
				},
				add: ( { urlQuery, body } ) => {
					let queryUrl = clientIdUrl + ( urlQuery ? '?' + urlQuery : '' );

					return requestPromise( {
						url: queryUrl,
						verb: 'post',
						body: body
					} );
				},
				delete: ( { urlQuery, body } ) => {
					let queryUrl = clientIdUrl + ( urlQuery ? '?' + urlQuery : '' );

					return requestPromise( {
						url: queryUrl,
						verb: 'del',
						body: body
					} );
				},
				update: ( { urlQuery, body } ) => {
					let queryUrl = clientIdUrl + ( urlQuery ? '?' + urlQuery : '' );

					return requestPromise( {
						url: queryUrl,
						verb: 'put',
						body: body
					} );
				},
			}
		},
	}
}

export default {
	meta,
	user,
	notifications,
	unit,
	flows,
	setUser,
	cache
};