import React from 'react';
import PropTypes from 'prop-types';

import DatasetTabSelector from './DatasetTabSelector.jsx';

const DatasetsTabs = ( { datasets, onSelect, selectedDatasetTab, onAddDatasetClick } ) => {
	return (
		<div className="datasets-tabs">
			{
				datasets.map( ( dataset, index ) => {
					if ( !dataset ) {
						return null;
					}


					return (
						<DatasetTabSelector
							key={ index }
							index={ index }
							isSelected={ index === selectedDatasetTab }
							onClick={ () => onSelect( index ) } />
					)
				} )
			}
			<button className="dataset-tab add-new-tab" onClick={ onAddDatasetClick }>+</button>
		</div>
	);
}

DatasetsTabs.propTypes = {
	datasets: PropTypes.array.isRequired,
	selectedDatasetTab: PropTypes.number.isRequired,
	onSelect: PropTypes.func.isRequired,
	onAddDatasetClick: PropTypes.func.isRequired
};

export default DatasetsTabs;
