import React, { Component } from 'react';

import { calculateNextFiltersRefreshTime } from './../utils/datasets/filters.js';

// SetTimeout-ed function will be invoked immediately if the delay is more than INT32_MAX
// https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/setTimeout#Maximum_delay_value
let INT32_MAX = 2147483647;

export default function timedGridRerender ( ProvidedComponent ) {
	return class TimedDatasetsRefresh extends Component {
		constructor ( props ) {
			// Props are super
			super( props );

			this.state = {
				timeOfRefresh: null
			}
		}

		componentDidMount () {
			this._setupNextFilterUpdate();
		}

		componentDidUpdate () {
			this._setupNextFilterUpdate();
		}

		render () {
			return (
				<ProvidedComponent { ...this.props } timeOfRefresh={ this.state.timeOfRefresh } />
			)
		}

		///////////////////////////////////////////////////////////////////
		// TIMER                                                         //
		// Managing automatic refiltering of the dataset devices         //
		// Used when the filters are based on the current time           //
		///////////////////////////////////////////////////////////////////

		/**
		 * Setup a timer to refresh the  filtered devices due to invalidity
		 * (When the filtered is based on relative time - timeReceived < 30 minutes ago)
		 */
		_setupNextFilterUpdate () {
			let nextTime = this._calculateNextRefilterTime( this.props.widgets );

			if ( this.refreshTimer ) {
				clearTimeout( this.refreshTimer );
			}

			if ( nextTime ) {
				if ( nextTime > INT32_MAX ) {
					nextTime = INT32_MAX;
				}

				this.refreshTimer = setTimeout( () => {
					this.setState( {
						timeOfRefresh: Date.now()
					} );

					this._setupNextFilterUpdate();
				}, nextTime );
			}
		}

		/**
		 * Calculate when the filtered widgets datasets are not valid anymore
		 * (When the filtered is based on relative time - timeReceived < 30 minutes ago)
		 * @param  {Array} widgets  The widgets configurations of the current dashboard
		 * @return {Number}         The next refresh time (In milliseconds) Limited to INT_32 (See row: 40)
		 */
		_calculateNextRefilterTime ( widgets ) {
			if ( !widgets ) {
				return;
			}

			let _this = this;
			let refilterTimes = widgets.map( ( widget ) => _this._calculateNextWidgetRefilterTime( widget ) );

			let soonestRefilterTime = refilterTimes.reduce( ( smallestTime, currentTime ) => {
				if ( currentTime ) {
					return Math.min( smallestTime, currentTime );
				}

				return smallestTime;
			}, INT32_MAX );

			return soonestRefilterTime;
		}

		/**
		 * Calculate next re-filter time for the specific widget
		 * @param  {Object} widget  The widget configuration
		 * @return {Number}         The next re-filter time in milliseconds.
		 */
		_calculateNextWidgetRefilterTime ( widget ) {
			let unitsDevices = this.props.unitsDevices;
			let allDevices = this.props.allDevices;
			let datasets = widget.options.datasets;

			let nextRefreshTime = null;

			if ( datasets ) {
				datasets.forEach( ( { flowName, units, filters } ) => {
					let currentDevices;
					let snapshotUnit = units.snapshot;
					let datasetRefreshTime;

					if ( unitsDevices
						&& unitsDevices[ snapshotUnit ]
						&& unitsDevices[ snapshotUnit ].devices ) {
						currentDevices = unitsDevices[ snapshotUnit ].devices
					}
					else {
						currentDevices = [];
					}

					if ( filters ) {
						currentDevices = currentDevices.map( deviceName => allDevices[ deviceName ] );
						datasetRefreshTime = calculateNextFiltersRefreshTime( currentDevices, filters );

						if ( datasetRefreshTime ) {
							if ( !nextRefreshTime ) {
								nextRefreshTime = datasetRefreshTime;
							}
							else if ( nextRefreshTime > datasetRefreshTime ) {
								nextRefreshTime = datasetRefreshTime;
							}
						}
					}
				} )
			}

			if ( nextRefreshTime && nextRefreshTime < 1000 ) {
				nextRefreshTime = 1000;
			}

			return nextRefreshTime;
		}

		//////////////////
		// /END OF TIMER //
		//////////////////
	}
}


