import React from 'react';

import { filterTypes } from './../../../../utils/datasets/filters.js';

import PropTypes from 'prop-types';

import './FilterTypeSelector.css';

const filterTypesLabels = {
	has_snapshot_property: 'Any',
	has_snapshot_property_with_value: 'Specific Value',
	has_snapshot_property_within_limits: 'Value Range',
	has_snapshot_date_property_within_limits: 'Date/Time Range'
};

const FilterTypeSelector = ( { activeFilter, onChange } ) => (
	<div>
		<div className="filter-type-label">Value:</div>
		<div className="filter-type-selector" >
			{ filterTypes.map( ( filterType ) => {
				let itemCssClass = `filter-type-item ${ activeFilter === filterType.type ? 'active' : '' }`;
				let title = filterTypesLabels[ filterType.type ];
				let onClick = () => { onChange( filterType.type ) };

				// We disable this filter, since it's not implemented
				if ( filterType.type === 'has_snapshot_date_property_within_limits' ) {
					itemCssClass += ' disabled';
					title = 'This filter type is coming soon';
					onClick = null;
				}

				return (
					<div key={ filterType.type }
						className={ itemCssClass }
						onClick={ onClick } >
						<span title={ title } >
							{ filterTypesLabels[ filterType.type ] }
						</span>
					</div>
				)
			} ) }
		</div>
	</div>
);

FilterTypeSelector.propTypes = {
	onChange: PropTypes.func.isRequired,
	activeFilter: PropTypes.string,
}

export default FilterTypeSelector;
