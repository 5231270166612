import React, { Component } from 'react';
import { connect } from 'react-redux';

import * as dashboardActions from './../../actions/popupActions.js';

import { Map, TileLayer } from 'react-leaflet';
import HeatmapLayer from 'react-leaflet-heatmap-layer';
import Control from 'react-leaflet-control';
import './../../../node_modules/leaflet/dist/leaflet.css';

import { getPropertyValueByPath, getDeviceName } from './../../utils/devicesUtils.js';

import config from './../../config.js';

class FullscreenHeatMapPopup extends Component {
	constructor ( props ) {
		super( props );

		this.mapDevices = props.selectedDevices;

		this.getLegend = this.getLegend.bind( this );
		this.onCloseButtonClicked = this.onCloseButtonClicked.bind( this );
	}

	componentWillReceiveProps ( newProps ) {
		this.prepareDevices( newProps );
	}

	// handle realtime updates
	prepareDevices ( newProps ) {
		let { allDevices, options, selectedDevices } = newProps;
		let mapDevices = selectedDevices;

		if ( allDevices ) {
			const latPath = options.locationPropertyPath.lat;
			const lngPath = options.locationPropertyPath.lng;

			mapDevices.forEach( mapDevice => {
				for ( let i in allDevices ) {
					const device = allDevices[ i ];
					const deviceName = getDeviceName( device, options.deviceOptions );
					const lat = getPropertyValueByPath( device, [ 'snapshot', ...latPath ] );
					const lng = getPropertyValueByPath( device, [ 'snapshot', ...lngPath ] );

					if ( deviceName === mapDevice.name ) {
						mapDevice.lat = lat;
						mapDevice.lng = lng;
					}
				}
			} );
		}

		this.mapDevices = mapDevices;
	}

	getLegend () {
		let { selectedPropertyConfig } = this.props;

		if ( !selectedPropertyConfig || !selectedPropertyConfig.gradient ) {
			return '';
		}

		let gradient = selectedPropertyConfig.gradient;
		let legendMarkup = [];

		for ( let key in gradient ) {
			legendMarkup.push( <li key={ key }>
				<div className="legend-color" style={ { backgroundColor: gradient[ key ].color } }></div>
				{ gradient[ key ].label }
			</li> )
		}

		return (
			<Control position="bottomright">
				<ul className="map-legend"> { legendMarkup } </ul>
			</Control>
		);
	}

	getFullscreenControl () {
		return (
			<Control position="topright" className="leaflet-bar">
				{ /* eslint-disable-next-line */ }
				<a className="map-fullscreen-icon popup-opened" onClick={ this.onCloseButtonClicked } > </a>
			</Control>
		);
	}

	getDevicePropertyValue ( device ) {
		let { selectedPropertyConfig } = this.props;
		let { propertyPath } = selectedPropertyConfig;
		let propertyValue = getPropertyValueByPath( device, [ 'fullDevice', 'snapshot', ...propertyPath ] );

		return propertyValue;
	}

	render () {
		const { selectedPropertyConfig, viewport } = this.props;
		const mapDevices = this.mapDevices;
		let gradient = {};

		if ( selectedPropertyConfig && selectedPropertyConfig.gradient ) {
			for ( let key in selectedPropertyConfig.gradient ) {
				gradient[ key ] = selectedPropertyConfig.gradient[ key ].color;
			}
		}
		else {
			gradient = { 0.4: 'green', 0.8: 'yellow', 1.0: 'red' };
		}

		let heatmapPoints = [];
		if ( selectedPropertyConfig ) {
			mapDevices.forEach( ( mapDevice, index ) => {
				let devicePropertyValue = this.getDevicePropertyValue( mapDevice );

				if ( devicePropertyValue ) {
					heatmapPoints.push( [ mapDevice.lat, mapDevice.lng, devicePropertyValue ] );
				}
			} )
		}

		// TESTING

		// heatmapPoints = [
		// 	[-37.8869090667, 175.3657417333, "10000"],
		// 	[-37.8894207167, 175.4015351167, "20"],
		// 	[-37.8927369333, 175.4087452333, "30"],
		// 	[-37.90585105, 175.4453463833, "100"],
		// 	[-37.9064188833, 175.4441556833, "100"],
		// 	[-37.90584715, 175.4463564333, "100"],
		// 	[-37.9033391333, 175.4244005667, "100"],
		// 	[-37.9061991333, 175.4492620333, "100000000"],
		// 	[-37.9058955167, 175.4445613167, "100000000"]
		// ];

		return (
			<div className="popup-markup-container" >
				<div className="map-container">
					<Map viewport={ viewport } animate={ true }>
						{ selectedPropertyConfig ?
							// @ts-ignore
							<HeatmapLayer
								points={ heatmapPoints }
								gradient={ gradient }
								radius={ selectedPropertyConfig.radius ? parseFloat( selectedPropertyConfig.radius ) : 40 }
								longitudeExtractor={ m => m[ 1 ] }
								latitudeExtractor={ m => m[ 0 ] }
								intensityExtractor={ m => parseFloat( m[ 2 ] ) } /> : '' }

						<TileLayer url={ config.tileLayer.url } />

						{ this.getLegend() }
						{ this.getFullscreenControl() }
					</Map>
				</div>
			</div>
		);
	}

	onCloseButtonClicked () {
		this.props.closePopup(); // update store
		this.props.onClose(); // update widget state
	}
}

function mapDispatchToProps ( dispatch ) {
	return {
		closePopup: () => {
			dispatch( dashboardActions.closePopup() );
		}
	};
}

export default connect( undefined, mapDispatchToProps )( FullscreenHeatMapPopup );
