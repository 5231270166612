export default [ {
	name: 'Sensors',
	pathBase: 'sensors'
}, {
	name: 'Bad Network',
	pathBase: 'bad-network'
}, {
	name: 'Battery',
	pathBase: 'battery'
}, {
	name: 'Clock with arrow',
	pathBase: 'clock-arrow'
}, {
	name: 'Ruler',
	pathBase: 'distance-line'
}, {
	name: 'Device CPU',
	pathBase: 'device-chip'
}, {
	name: 'Download',
	pathBase: 'export'
}, {
	name: 'Factory',
	pathBase: 'factory'
}, {
	name: 'Fire',
	pathBase: 'fire'
}, {
	name: 'Humidity',
	pathBase: 'humidity'
}, {
	name: 'Upload',
	pathBase: 'import'
}, {
	name: 'Lamp',
	pathBase: 'light'
}, {
	name: 'Mountain',
	pathBase: 'mountain'
}, {
	name: 'Network',
	pathBase: 'network'
}, {
	name: 'Pin',
	pathBase: 'pin'
}, {
	name: 'Plug',
	pathBase: 'plug-in'
}, {
	name: 'Railroad Crossing',
	pathBase: 'railroad-crossing'
}, {
	name: 'Router',
	pathBase: 'router'
}, {
	name: 'Satellite',
	pathBase: 'satellite'
}, {
	name: 'Secure WiFi',
	pathBase: 'secure-wifi'
}, {
	name: 'Snowflake',
	pathBase: 'snowflake'
}, {
	name: 'Sound',
	pathBase: 'sound'
}, {
	name: 'Speed',
	pathBase: 'speed'
}, {
	name: 'Street Lamp',
	pathBase: 'street-lamp'
}, {
	name: 'Temperature',
	pathBase: 'temperature'
}, {
	name: 'Wind',
	pathBase: 'wind'
}, {
	name: 'Opened Bridge',
	pathBase: 'open-bridge'
}, {
	name: 'Closed Bridge',
	pathBase: 'closed-bridge'
} ];
