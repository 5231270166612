import React, { PureComponent } from 'react';

import DatasetFiltersSelector from './DatasetFiltersSelector.jsx';
import ConfigurationFieldSelectorList from './ConfigurationFieldSelectorList.jsx';

import deleteImage from './../../../../img/delete-white.svg';

class DatasetItemSelector extends PureComponent {
	constructor ( props ) {
		// Props are super
		super( props );

		this.onRealtimeUnitChange = this.onRealtimeUnitChange.bind( this );
		this.onSnapshotUnitChange = this.onSnapshotUnitChange.bind( this );
		this.onHistoryUnitChange = this.onHistoryUnitChange.bind( this );
		this.onFiltersChange = this.onFiltersChange.bind( this );
		this.onDeleteDataset = this.onDeleteDataset.bind( this );


		this.state = {
			// If the fields are not selected, we are going to expand the pickers
			enableFlowEditing: props.dataset.flow ? false : true,
			enableSnapshotUnitEditing: props.dataset.snapshotUnit ? false : true,
			enableRealtimeUnitEditing: props.dataset.realtimeUnit ? false : true,
			enableHistoryUnitEditing: props.dataset.historyUnit ? false : true,

			filterView: false,
		}
	}

	render () {
		let {
			dataset,
			errors
		} = this.props

		let { isFetching, flows } = this.props.flows;

		if ( !dataset ) {
			return null;
		}

		let selectedFlow = dataset.flow;

		let selectedRealtimeUnit = dataset.realtimeUnit;
		let selectedSnapshotUnit = dataset.snapshotUnit;
		let selectedHistoryUnit = dataset.historyUnit;
		let filters = dataset.filters;

		let selectedFlowUnits = [];


		let areUnitsDisabled = false;

		if ( !selectedFlow || isFetching ) {
			areUnitsDisabled = true;
		}
		else {
			selectedFlowUnits = flows[ selectedFlow ].units;
		}

		let otherErrors = [];
		let filtersErrors = [];
		let flowErrors = [];
		let snapshotUnitErrors = [];

		errors.forEach( error => {
			if ( error.field === 'flow' ) {
				flowErrors.push( error );
			}
			else if ( error.field === 'snapshotUnit' ) {
				snapshotUnitErrors.push( error );
			}
			else if ( error.filterIndex ) {
				filtersErrors.push( error );
			}
			else {
				otherErrors.push( error )
			}
		} );

		otherErrors = otherErrors.map( ( error, index ) => ( <div key={ index } className="validation-error">{ error.message }</div> ) )
		flowErrors = flowErrors.map( ( error, index ) => ( <div key={ index } className="validation-error">{ error.message }</div> ) )
		snapshotUnitErrors = snapshotUnitErrors.map( ( error, index ) => ( <div key={ index } className="validation-error">{ error.message }</div> ) )

		let flowListItems = Object.values( flows ).map( item => item.name );

		let unitsConfigurationPart;

		if ( !areUnitsDisabled ) {
			unitsConfigurationPart = (
				(
					<>
						<label
							title="Select unit to get initial data from"
							onClick={ () => {
								if ( selectedSnapshotUnit ) {
									this.setState( {
										enableSnapshotUnitEditing: !this.state.enableSnapshotUnitEditing
									} );
								}
							} }>
							Initial Data*
								{ snapshotUnitErrors }
							<ConfigurationFieldSelectorList
								onSelect={ this.onSnapshotUnitChange }
								value={ selectedSnapshotUnit }
								items={ selectedFlowUnits }
								enableEditing={ this.state.enableSnapshotUnitEditing } />
						</label>
						{
							selectedSnapshotUnit ?
								(
									<>
										<label
											title="Select unit to get realtime updates from"
											onClick={ () => {
												if ( selectedRealtimeUnit ) {
													this.setState( {
														enableRealtimeUnitEditing: !this.state.enableRealtimeUnitEditing
													} );
												}
											} }>
											Realtime Data
											<ConfigurationFieldSelectorList
												onSelect={ this.onRealtimeUnitChange }
												value={ selectedRealtimeUnit }
												items={ selectedFlowUnits }
												enableEditing={ this.state.enableRealtimeUnitEditing } />
										</label>
										<label
											title="Select unit to fetch history from"
											onClick={ () => {
												if ( selectedHistoryUnit ) {
													this.setState( {
														enableHistoryUnitEditing: !this.state.enableHistoryUnitEditing
													} );
												}
											} }>
											History Data
											<ConfigurationFieldSelectorList onSelect={ this.onHistoryUnitChange } value={ selectedHistoryUnit } items={ selectedFlowUnits } enableEditing={ this.state.enableHistoryUnitEditing } />
										</label>
									</>
								) : null
						}
					</>
				)
			);
		}

		return (
			<div className="dataset-item-container">
				<h4>
					Dataset { this.props.index + 1 }{ this.state.filterView ? ' | Filters' : null }
				</h4>
				{ !this.state.filterView ? (
					<>
						<button
							className={ `btn-nostyle dataset-remove-icon ${ this.props.isLast ? 'disabled' : '' }` }
							disabled={ this.props.isLast }
							title={ this.props.isLast ? 'A dataset is required' : 'Remove this dataset' }
							onClick={ this.onDeleteDataset }>
							<img src={ deleteImage } alt="Remove" title="Remove this dataset" />
						</button>
						{ otherErrors }
						<label onClick={ () => {
							if ( selectedFlow ) {
								this.setState( { enableFlowEditing: !this.state.enableFlowEditing } );
							}
						} }>
							Flow*
							{ flowErrors }
							<ConfigurationFieldSelectorList items={ flowListItems } onSelect={ this.onFlowSelect } value={ selectedFlow } enableEditing={ this.state.enableFlowEditing } />
						</label>
						{ unitsConfigurationPart }
					</>
				) : null }
				{
					selectedSnapshotUnit ? (
						<DatasetFiltersSelector
							showFilterView={ this.state.filterView }
							flow={ selectedFlow }
							snapshotUnit={ selectedSnapshotUnit }
							onChange={ this.onFiltersChange }
							onToggle={ () => this.setState( { filterView: !this.state.filterView } ) }
							filters={ filters }
							errors={ filtersErrors } />
					) : null }
			</div>
		)
	}

	onFlowSelect = ( flowKey ) => {
		if ( this.props.dataset.flow !== flowKey ) {
			let newDataset = {
				...this.props.dataset,
				flow: flowKey
			};

			this.props.onChange( this.props.index, newDataset );
			this.setState( {
				enableFlowEditing: false
			} )
		}
		else {
			this.setState( {
				enableFlowEditing: !this.state.enableFlowEditing
			} )
		}
	}

	onSnapshotUnitChange ( unitName ) {
		if ( unitName !== this.props.dataset.snapshotUnit ) {

			let newDataset = {
				...this.props.dataset,
				snapshotUnit: unitName
			};

			if ( !this.props.dataset.realtimeUnit ) {
				newDataset.realtimeUnit = unitName;
				this.setState( {
					enableRealtimeUnitEditing: false
				} )
			}

			if ( !this.props.dataset.historyUnit ) {
				newDataset.historyUnit = unitName;
				this.setState( {
					enableHistoryUnitEditing: false
				} )
			}

			this.props.onChange( this.props.index, newDataset );

			this.setState( {
				enableSnapshotUnitEditing: false
			} )
		}
		else {
			this.setState( {
				enableSnapshotUnitEditing: !this.state.enableSnapshotUnitEditing
			} )
		}
	}

	onRealtimeUnitChange ( unitName ) {
		if ( unitName !== this.props.dataset.realtimeUnit ) {

			let newDataset = {
				...this.props.dataset,
				realtimeUnit: unitName
			};

			this.props.onChange( this.props.index, newDataset );

			this.setState( {
				enableRealtimeUnitEditing: false
			} )
		}
		else {
			this.setState( {
				enableRealtimeUnitEditing: !this.state.enableRealtimeUnitEditing
			} )
		}
	}


	onHistoryUnitChange ( unitName ) {
		if ( unitName !== this.props.dataset.historyUnit ) {

			let newDataset = {
				...this.props.dataset,
				historyUnit: unitName
			};

			this.props.onChange( this.props.index, newDataset );

			this.setState( {
				enableHistoryUnitEditing: false
			} )
		}
		else {
			this.setState( {
				enableHistoryUnitEditing: !this.state.enableHistoryUnitEditing
			} )
		}
	}

	onFiltersChange ( filters ) {
		let newDataset = {
			...this.props.dataset,
			filters
		};

		this.props.onChange( this.props.index, newDataset );
	}

	onDeleteDataset ( e ) {
		this.props.onDelete( this.props.index, this.props.dataset );
	}
}

export default DatasetItemSelector;
