import {
	FETCH_DEVICES_STARTED,
	FETCH_DEVICES_SUCCESS,
	FETCH_DEVICES_FAILED,
	REALTIME_ROUTED_MESSAGE,
	FETCH_DEVICE_HISTORY_STARTED,
	FETCH_DEVICE_HISTORY_SUCCESS,
	FETCH_DEVICE_HISTORY_FAIL,
} from './../../actions/unitsActions'

import * as moment from 'moment';

import { getUniqueDeviceId } from './../../utils/devicesUtils.js';

export function unitsDevicesRelationsReducer ( state = {
	unitsDevices: {}
}, action ) {
	switch ( action.type ) {
		case REALTIME_ROUTED_MESSAGE:
			let newUnitDevices = [];
			let snapshotUnitDevices = state.unitsDevices[ action.snapshotUnit ];

			action.message.forEach( ( deviceMessage ) => {
				let uniqueDeviceId = getUniqueDeviceId( action.snapshotUnit, deviceMessage.id );

				if ( !snapshotUnitDevices.devices || snapshotUnitDevices.devices.indexOf( uniqueDeviceId ) === -1 ) {
					newUnitDevices.push( uniqueDeviceId );
				}
			} );

			// TODO: decrease the nesting -
			// Make [ snapshotUnit ]: [ deviceId1, deviceId2 ]
			// Now its: [ snapshotUnit ]: { devices: [ deviceId1, deviceId2 ] }
			return {
				...state,
				unitsDevices: {
					...state.unitsDevices,
					[ action.snapshotUnit ]: {
						...snapshotUnitDevices,
						devices: ( snapshotUnitDevices.devices ? [ ...newUnitDevices, ...snapshotUnitDevices.devices ] : [ ...newUnitDevices ] )
					}
				}
			};

		case FETCH_DEVICES_STARTED:
			return {
				...state,
				unitsDevices: {
					...state.unitsDevices,
					[ action.unitId ]: {
						devices: null,
						isSyncing: true,
						error: null
					}
				}
			};
		case FETCH_DEVICES_SUCCESS: {
			let actionDevices = action.devices.sort( ( leftDevice, rightDevice ) =>
				( + moment( rightDevice.lastData.timeReceived ) ) - ( + moment( leftDevice.lastData.timeReceived ) ) );

			return {
				...state,
				unitsDevices: {
					...state.unitsDevices,
					[ action.unitId ]: {
						devices: actionDevices.map( ( device ) => {
							return getUniqueDeviceId( action.unitId, device.query.id.value )
						} ),
						isSyncing: false,
						error: null
					}
				}
			};
		}

		case FETCH_DEVICES_FAILED:
			return {
				...state,
				unitsDevices: {
					...state.unitsDevices,
					[ action.unitId ]: {
						devices: null,
						isSyncing: false,
						error: action.error
					}
				}
			};

		case FETCH_DEVICE_HISTORY_STARTED:
			return state;
		case FETCH_DEVICE_HISTORY_SUCCESS:
			return state;
		case FETCH_DEVICE_HISTORY_FAIL:
			return state;
		default:
			return state;
	}
}
