import api from './../middleware/api/'

import iota from './../middleware/iota'

let websocketRetryTime = 1000;

/* Unit Fetching */
export const FETCH_UNIT_STARTED = 'FETCH_UNIT_STARTED'
export function fetchUnitStarted ( unitId ) {
	return {
		type: FETCH_UNIT_STARTED,
		unitId
	}
}

export const FETCH_UNIT_SUCCESS = 'FETCH_UNIT_SUCCESS'
export function fetchUnitSuccess ( unitId, unit ) {
	return {
		type: FETCH_UNIT_SUCCESS,
		unitId,
		unit
	}
}

export const FETCH_UNIT_FAIL = 'FETCH_UNIT_FAIL'
export function fetchUnitFailed ( unitId, error ) {
	return {
		type: FETCH_UNIT_FAIL,
		error
	}
}

export function fetchUnit( unitId ) {
	return function ( dispatch ) {
		dispatch( fetchUnitStarted( unitId ) );

		return api.unit( unitId ).get()
			.then( unit => {
				dispatch( fetchUnitSuccess( unitId, unit ) );
			})
			.catch( error => {
				dispatch( fetchUnitFailed( unitId, error ) );
			});
	}
}

/* Realtime */
export const REALTIME_ROUTE_ENABLED = 'REALTIME_ROUTE_ENABLED';
export function realtimeRouteEnabled ( realtimeUnit, snapshotUnitId ) {
	return {
		type: REALTIME_ROUTE_ENABLED,
		realtimeUnit,
		snapshotUnitId
	};
}

export const REALTIME_ROUTE_OPENED = 'REALTIME_ROUTE_OPENED';
export function realtimeRouteOpened ( realtimeUnit, snapshotUnit, event ) {
	return {
		type: REALTIME_ROUTE_OPENED,
		realtimeUnit,
		snapshotUnit,
		event
	};
};

export const REALTIME_ROUTED_MESSAGE = 'REALTIME_ROUTED_MESSAGE';
export function realtimeMessageRouted ( realtimeUnit, snapshotUnit, message ) {
	return {
		type: REALTIME_ROUTED_MESSAGE,
		realtimeUnit,
		snapshotUnit,
		message
	};
}

export const REALTIME_ROUTE_ERROR = 'REALTIME_ROUTE_ERROR';
export function realtimeRouteFailed ( realtimeUnit, snapshotUnit, error ) {
	return {
		type: REALTIME_ROUTE_ERROR,
		realtimeUnit,
		snapshotUnit,
		error
	};
}

export const REALTIME_ROUTE_CLOSE = 'REALTIME_ROUTE_CLOSE';
export function realtimeRouteClosed ( realtimeUnit, snapshotUnit, error ) {
	return {
		type: REALTIME_ROUTE_CLOSE,
		realtimeUnit,
		snapshotUnit,
		error
	};
}

export const REALTIME_ROUTE_OPEN_RETRY = 'REALTIME_ROUTE_OPEN_RETRY';
export function websocketConnectRetry( flowName, realtimeUnitId, snapshotUnitId, websocketRetryTime ) {
	return {
		type: REALTIME_ROUTE_OPEN_RETRY,
		flowName,
		realtimeUnitId,
		snapshotUnitId,
		websocketRetryTime
	};
};

const retryWebSocketConnect = ( dispatch, flowName, realtimeUnitId, snapshotUnitId ) => {
	dispatch( websocketConnectRetry( flowName, realtimeUnitId, snapshotUnitId, websocketRetryTime ) );

	setTimeout( () => {
		dispatch( setupRealtimeRouting( flowName, realtimeUnitId, snapshotUnitId ) );
	}, websocketRetryTime );

	websocketRetryTime *= 2;
};

export function setupRealtimeRouting( flowName, realtimeUnitId, snapshotUnitId ) {
	return function ( dispatch ) {
		dispatch( realtimeRouteEnabled( realtimeUnitId, snapshotUnitId ) );
		websocketRetryTime = 1000;

		return iota.unit( realtimeUnitId ).subscribe({
			onopen: ( event ) => {
				dispatch( realtimeRouteOpened( realtimeUnitId, snapshotUnitId, event ) );
			},
			onmessage: ( message ) => {
				dispatch( realtimeMessageRouted( realtimeUnitId, snapshotUnitId, JSON.parse( message.toString() ) ) );
				iota.cache.flushCache();
			},
			onerror: ( error ) => {
				dispatch( realtimeRouteFailed( realtimeUnitId, snapshotUnitId, error ) );
			},
			onclose: () => {
				dispatch( realtimeRouteClosed( realtimeUnitId, snapshotUnitId ) );

				retryWebSocketConnect( dispatch, flowName, realtimeUnitId, snapshotUnitId );
			}
		});

	}
}


export const FETCH_UNIT_SCHEMA_STARTED = 'FETCH_UNIT_SCHEMA_STARTED';
export function fetchUnitSchemaStarted ( flowName, unitId ) {
	return {
		type: FETCH_UNIT_SCHEMA_STARTED,
		flowName,
		unitId
	}
}

export const FETCH_UNIT_SCHEMA_SUCCESS = 'FETCH_UNIT_SCHEMA_SUCCESS';
export function fetchUnitSchemaSuccess ( flowName, unitId, schema ) {
	return {
		type: FETCH_UNIT_SCHEMA_SUCCESS,
		flowName,
		unitId,
		schema
	}
}

export const FETCH_UNIT_SCHEMA_FAILED = 'FETCH_UNIT_SCHEMA_FAILED';
export function fetchUnitSchemaFailed ( flowName, unitId, error ) {
	return {
		type: FETCH_UNIT_SCHEMA_FAILED,
		flowName,
		unitId,
		error
	}
}

export function fetchUnitSchema ( flowName, snapshotUnitId ) {
	return function ( dispatch ) {
		dispatch( fetchUnitSchemaStarted( flowName, snapshotUnitId ) );

		return iota.flows().name( flowName ).unit( snapshotUnitId ).schema()
			.then( schema => {
				dispatch( fetchUnitSchemaSuccess( flowName, snapshotUnitId, schema ) );
			})
			.catch( error => {
				dispatch( fetchUnitSchemaFailed( flowName, snapshotUnitId, error ) );
			});
	}
}


export const FETCH_DEVICES_STARTED = 'FETCH_DEVICES_STARTED'
export function fetchDevicesStarted ( flowName, unitId, capabilities, realtimeUnits, historyUnit ) {
	return {
		type: FETCH_DEVICES_STARTED,
		flowName,
		unitId,
		capabilities,
		realtimeUnits,
		historyUnit
	};
}

export const FETCH_DEVICES_SUCCESS = 'FETCH_DEVICES_SUCCESS'
export function fetchDevicesSuccess ( flowName, unitId, devices, capabilities, realtimeUnits, historyUnit ) {
	return {
		type: FETCH_DEVICES_SUCCESS,
		flowName,
		unitId,
		devices,
		capabilities,
		realtimeUnits,
		historyUnit
	};
}

export const FETCH_DEVICES_FAILED = 'FETCH_DEVICES_FAILED'
export function fetchDevicesFailed ( flowName, unitId, capabilities, error ) {
	return {
		type: FETCH_DEVICES_FAILED,
		flowName,
		unitId,
		capabilities,
		error
	}
}

/**
 * Fetches the devices from the snapshot
 * in addition wires the relation from realtimeUnits to snapshotUnit and enables realtime
 * @param  {Strung} flowName             the name of the flow
 * @param  {String} snapshotUnitId       the name of the snapshot unit
 * @param  {String} realtimeUnits        array with the realtime units from the realtime msg
 * @param  {Array(String)} capabilities  the actions that are going to be undertaken on the devices
 *                                       (in order to request only what is needed)
 * @return {AsyncAction}
 */
export function fetchDevices ( flowName, snapshotUnitId, realtimeUnits, capabilities, historyUnit ) {
	return function ( dispatch ) {
		dispatch( fetchDevicesStarted( flowName, snapshotUnitId, capabilities, realtimeUnits, historyUnit ) );


		let preloadLastValue = false;

		// TODO: export capabilities to a module.
		if( capabilities.indexOf( 'LAST_VALUES' ) ) {
			preloadLastValue = true;
		}

		dispatch( fetchUnitSchema( flowName, snapshotUnitId ) );

		// TODO: preloadCurrentState
		return iota.flows().name( flowName ).unit( snapshotUnitId ).devices()
			.get( preloadLastValue )
			.then( devices => {
				dispatch( fetchDevicesSuccess( flowName, snapshotUnitId, devices, capabilities, realtimeUnits, historyUnit ) );
				realtimeUnits.forEach( ( realtimeUnit ) => {
					// Messages from the realtime unit will modify the snapshot devices
					dispatch( setupRealtimeRouting( flowName, realtimeUnit, snapshotUnitId ) );
				})
			})
			.catch( error => {
				dispatch( fetchDevicesFailed( flowName, snapshotUnitId, capabilities, error, historyUnit ) );
			});
	};
}

export const FETCH_DEVICE_HISTORY_STARTED = 'FETCH_DEVICE_HISTORY_STARTED'
export function fetchDeviceHistoryStarted ( flowName, unitId, deviceId, range, internalDeviceId ) {
	return {
		type: FETCH_DEVICE_HISTORY_STARTED,
		flowName,
		unitId,
		deviceId,
		range,
		internalDeviceId
	};
}

export const FETCH_DEVICE_HISTORY_SUCCESS = 'FETCH_DEVICE_HISTORY_SUCCESS'
export function fetchDeviceHistorySuccess ( flowName, unitId, deviceId, range, internalDeviceId, history ) {
	return {
		type: FETCH_DEVICE_HISTORY_SUCCESS,
		flowName,
		unitId,
		deviceId,
		range,
		internalDeviceId,
		history
	};
}

export const FETCH_DEVICE_HISTORY_FAIL = 'FETCH_DEVICE_HISTORY_FAIL'
export function fetchDeviceHistoryFailed ( flowName, unitId, deviceId, range, internalDeviceId, error ) {
	return {
		type: FETCH_DEVICE_HISTORY_FAIL,
		flowName,
		unitId,
		deviceId,
		range,
		internalDeviceId,
		error
	}
}

export function fetchDeviceHistory ( flowName, unitId, externalDeviceId, range, internalDeviceId ) {
	return function ( dispatch ) {

		dispatch( fetchDeviceHistoryStarted( flowName, unitId, externalDeviceId, range, internalDeviceId ) );

		return iota.flows().name( flowName ).unit( unitId ).devices().id( externalDeviceId ).history({
				start: range.from,
				end: range.to
			})
			.then( history => {
				dispatch( fetchDeviceHistorySuccess( flowName, unitId, externalDeviceId, range, internalDeviceId, history ) );
			})
			.catch( error => {
				dispatch( fetchDeviceHistoryFailed( flowName, unitId, externalDeviceId, range, internalDeviceId, error ) );
			})
	}
}


export const FETCH_DEVICE_LAST_HISTORY_STARTED = 'FETCH_DEVICE_LAST_HISTORY_STARTED'
export function fetchDeviceLastHistoryStarted ( flowName, unitId, deviceId, count, internalDeviceId ) {
	return {
		type: FETCH_DEVICE_LAST_HISTORY_STARTED,
		flowName,
		unitId,
		deviceId,
		count,
		internalDeviceId
	};
}

export const FETCH_DEVICE_LAST_HISTORY_SUCCESS = 'FETCH_DEVICE_LAST_HISTORY_SUCCESS'
export function fetchDeviceLastHistorySuccess ( flowName, unitId, deviceId, count, internalDeviceId, history ) {
	return {
		type: FETCH_DEVICE_LAST_HISTORY_SUCCESS,
		flowName,
		unitId,
		deviceId,
		count,
		internalDeviceId,
		history
	};
}

export const FETCH_DEVICE_LAST_HISTORY_FAIL = 'FETCH_DEVICE_LAST_HISTORY_FAIL'
export function fetchDeviceLastHistoryFailed ( flowName, unitId, deviceId, count, internalDeviceId, error ) {
	return {
		type: FETCH_DEVICE_LAST_HISTORY_FAIL,
		flowName,
		unitId,
		deviceId,
		count,
		internalDeviceId,
		error
	}
}

export function fetchDeviceLastHistory ( flowName, unitId, externalDeviceId, count, internalDeviceId ) {
	return function ( dispatch ) {
		dispatch( fetchDeviceLastHistoryStarted( flowName, unitId, externalDeviceId, count, internalDeviceId ) );

		return iota.flows().name( flowName ).unit( unitId ).devices().id( externalDeviceId ).last({
				count
			})
			.then( history => {
				dispatch( fetchDeviceLastHistorySuccess( flowName, unitId, externalDeviceId, count, internalDeviceId, history ) );
			})
			.catch( error => {
				dispatch( fetchDeviceLastHistoryFailed( flowName, unitId, externalDeviceId, count, internalDeviceId, error ) );
			})
	}
}
