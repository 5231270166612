import React, { Component } from 'react';

import { connect } from 'react-redux';

import * as popupActions from './../actions/popupActions.js';

class PopupCanvas extends Component {
	constructor( props ) {
		super( props );

		this.closePopup = this.closePopup.bind( this );
	}

	closePopup () {
		this.props.closePopup();
		if ( this.props.popupState.popupProps.onClose ) {
			this.props.popupState.popupProps.onClose();
		}
	}

	render () {
		if( this.props.popupState.popup ) {
			let popupElement = React.createElement( this.props.popupState.popup, this.props.popupState.popupProps );


			return (
				<div className="widget-popup-outer-container">
					<div id="widget-popup-blanket" className="widget-popup-blanket" onClick={ this.closePopup }></div>
					<div id="widget-popup" className="widget-popup">
						{ popupElement }
					</div>
				</div>
			);
		}


		return (
			<div className="widget-popup-outer-container">
			</div>
		);
	}
}


function mapStateToProps ( state, ownProps ) {
	return {
		popupState: state.popupState
	};
}

function mapDispatchToProps( dispatch ) {
	return {
		closePopup: () => {
			dispatch( popupActions.closePopup() )
		}
	};
}

export default connect( mapStateToProps, mapDispatchToProps )( PopupCanvas );


