// Libs
import React, { Component } from 'react';

import { connect } from 'react-redux';

import { Route } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';

import * as activeDashboardActions from './../../actions/activeDashboardActions.js';

// Components
import Header from './../../components/Header/Header.jsx';
import Home from './../Home.jsx';
import NewDashboard from './../NewDashboard.jsx';

import Dashboard from './../Dashboard.jsx';

// Decorators
import authable from './../../decorators/authable/authable.jsx';

import { calculateContrastedColor } from './../../utils/devicesUtils.js';

import { getDashboardStateFromUserState } from './../../selectors/dashboards.js';

// Styling
import './App.css';

import './../../css/style.css';

class App extends Component {
	constructor ( props ) {
		// Props are super!
		super( props );

		this.state = {
			selectedMenu: null
		}

		this.closeAllMenus = this.closeAllMenus.bind( this );
		this.openMenu = this.openMenu.bind( this );
	}

	static getDerivedStateFromProps ( newProps ) {
		if ( newProps.isDndEnabled ) {
			return {
				selectedMenu: null
			}
		}

		return null;
	}

	closeAllMenus () {
		this.setState( { selectedMenu: null } );
	}

	openMenu ( menuId ) {
		this.setState( { selectedMenu: menuId } );

		if ( this.props.isDndEnabled ) {
			this.props.toggleDashboardDragAndDrop();
		}
	}

	render () {
		let { history, userState, logOut, isWidgetsPaletteVisible, widgetConfigurationForm } = this.props;

		let { dashboards } = getDashboardStateFromUserState( userState );

		let { mainStyles, order } = dashboards;

		if ( !mainStyles ) {
			mainStyles = {};
		}

		let { backgroundImage, backgroundColor, headerBackgroundColor, headerLogo } = mainStyles;

		let appInnerStyles = {};
		let backgroundStyles = {};

		if ( backgroundImage ) {
			backgroundStyles.backgroundImage = `url(${ backgroundImage })`;
		}

		if ( backgroundColor ) {
			backgroundStyles.backgroundColor = backgroundColor;
			appInnerStyles.color = calculateContrastedColor( backgroundColor );
		}

		if ( isWidgetsPaletteVisible || widgetConfigurationForm ) {
			appInnerStyles.overflow = 'hidden';
		}

		return (
			<div className="app">
				<ConnectedRouter history={ history }>
					<div className="app-inner" style={ appInnerStyles }>
						<Header
							activeMenu={ this.state.selectedMenu }
							openMenu={ this.openMenu }
							closeAllMenus={ this.closeAllMenus }
							headerBackgroundColor={ headerBackgroundColor }
							headerLogo={ headerLogo }
							logOut={ logOut } />

						{ /* if there is only one dashboard, we want to display it directly
							instead of displaying the dashboards menu page */ }
						<Route
							exact
							path="/"
							component={ ( order
								&& order.length === 1 ?
								Dashboard : Home )
							} />
						<Route
							exact
							path="/new-dashboard"
							component={ NewDashboard } />
						<Route
							path="/dashboards/:dashboardId"
							component={ Dashboard } />
						{
							this.state.selectedMenu ?
								<div className="background-disabled" onClick={ this.closeAllMenus }></div> : null
						}
						<div className="app-inner-background" style={ backgroundStyles }></div>
					</div>
				</ConnectedRouter>
			</div>
		);
	}
}

function mapStateToProps ( state, ownProps ) {
	return {
		userState: state.userState,
		isWidgetsPaletteVisible: state.activeDashboard.isWidgetsPaletteVisible,
		widgetConfigurationForm: state.activeDashboard.widgetConfigurationForm,
		isDndEnabled: state.activeDashboard.isDndEnabled
	};
}

function mapDispatchToProps ( dispatch ) {
	return {
		toggleDashboardDragAndDrop: () => {
			dispatch( activeDashboardActions.toggleDashboardDragAndDrop() );
		}
	};
}

export default authable( connect( mapStateToProps, mapDispatchToProps )( App ) );
