import React, { Component } from 'react';

class DevicesOverviewSideMenu extends Component {
	constructor ( props ) {
		super( props );

		this.state = {
			searchFilter: ''
		}

		this.onSearchFiledChanged = this.onSearchFiledChanged.bind( this );
	}

	render () {
		let devices = this.props.devices;
		let selectedDeviceIndex = this.props.selectedDeviceIndex;

		let searchFilter = this.state.searchFilter;

		let sensorsListInnerMarkup = devices.map( device => {
			let cssClass = 'popup-sensor-item ';
			let onDeviceClicked = this.props.onDeviceClicked;
			let matchByName = searchFilter ? device.name.toLowerCase().match( searchFilter.toLowerCase() ) : null;
			let matchByDefaultName = searchFilter ? device.defaultName.toLowerCase().match( searchFilter.toLowerCase() ) : null;
			let filterHasMatch = matchByName || matchByDefaultName;

			if ( device.id === selectedDeviceIndex ) {
				cssClass += 'active';
			}

			if ( searchFilter !== '' && !filterHasMatch ) {
				return null;
			}

			return (
				<li className={ cssClass }
					key={ device.id }
					onClick={ () => onDeviceClicked( device.id ) }
					title={ device.defaultName }>
					{ device.name }
				</li>
			);
		} )

		let sensorsListMarkup = (
			<ul>
				{ sensorsListInnerMarkup }
			</ul>
		);

		return (
			<div className="sow-widget-popup-search-sidebar">
				<div className="sow-filter-input">
					<i className="fa fa-search popup-filter-search-icon" aria-hidden="true"></i>
					<input placeholder="Search" type="search" onChange={ this.onSearchFiledChanged } />
				</div>
				<div className="sow-sensors-list">
					{ sensorsListMarkup }
				</div>
			</div>
		)
	}

	onSearchFiledChanged ( event ) {
		this.setState( {
			searchFilter: event.target.value.trim()
		} )
	}
}

export default DevicesOverviewSideMenu;
