// {
// 	"type": "AccumulatedBarsWidget",
// 	"options": {
// 		"widgetTitle": "Accumulated Bars Widget",
// 		"widgetId": "prorail-sensor-bars-widget",
// 		"propertyPath": [
// 			"content",
// 			"loLoTemp"
// 		],
//		"maxSelectedDevices": 3,
//		"periodProperties": {
//			"per_day": { "minDuration": 32, "maxDuration": 168, "unit": "hours" },
//			"per_hour": { "minDuration": 3, "maxDuration": 32, "unit": "hours" },
// 			"per_10_min": { "minDuration": 10, "maxDuration": 180, "unit": "minutes" },
// 			"per_min": { "minDuration": 0, "maxDuration": 10, "unit": "minutes" }
// 		},
// 		"accumulationProperties": {
// 			"keyValue": true,
// 			"condition": "==="
// 		},
// 		"datasets": [
// 			{
// 				"flowName": "prorail",
// 				"units": {
// 					"messages": "prorailMessagesMemoryRepository",
// 					"snapshot": "prorailSnapshot"
// 				},
// 				"filters": [
// 					{
// 						"type": "has_snapshot_property",
// 						"options": {
//							"propertyPath": [
// 								"content",
// 								"loLoTemp"
// 							]
//						}
// 					}
// 				]
// 			}
// 		]
// 	},
// 	"position": {
// 		"x": 0,
// 		"y": 3,
// 		"h": 17,
// 		"w": 4
// 	}
// }
/**
 * extracts, processes and saves timeSets and values collection from device history
 * @param { array } history  - single device history
 * @param { object } options - widget options
 * @param { array } timeSetsCollection - empty variable in which the timeSets should be pushed to
 * @param { array } valuesCollection - empty variable in which the values should be pushed to
 */

import React, { Component } from 'react';

import Multiselect from '../../components/ReactWidgets/Multiselect';
import * as moment from 'moment';
import stylable from './../../decorators/stylable.jsx';

import { applyAccumulationFilter, addMissingPeriodsToResult } from './../../utils/history/accumulationFilters.js';

import MultipleIndexedBarChart from './../../components/Charts/MultipleIndexedBarChart.jsx';
import RangeDateTimePickers from '../../components/DateTimePickers/RangeDateTimePickers';

import {
	getPropertyValueByPath,
	findDeviceExternalId
} from './../../utils/devicesUtils.js';

let accumulationPeriodEnum = {
	PER_DAY: 1,
	PER_HOUR: 2,
	PER_10_MIN: 3,
	PER_MIN: 4,
	properties: {
		1: { minDuration: 32, unit: 'hours', maxDuration: 168, filter: 'per_day' },
		2: { minDuration: 3, unit: 'hours', maxDuration: 32, filter: 'per_hour' },
		3: { minDuration: 10, unit: 'minutes', maxDuration: 180, filter: 'per_10_minutes' },
		4: { minDuration: 0, unit: 'minutes', maxDuration: 10, filter: 'per_minute' }
	}
},
	mergePeriodProperties = ( userProps ) => {
		let period,
			currentPeriodNumber;

		for ( period in userProps ) {
			currentPeriodNumber = accumulationPeriodEnum[ period.toUpperCase() ];
			accumulationPeriodEnum.properties[ currentPeriodNumber ].minDuration = userProps[ period ].minDuration;
			accumulationPeriodEnum.properties[ currentPeriodNumber ].maxDuration = userProps[ period ].maxDuration;
			accumulationPeriodEnum.properties[ currentPeriodNumber ].unit = userProps[ period ].unit;
		}
	},
	convertDurationToSeconds = () => {
		let accumulationProps = accumulationPeriodEnum.properties,
			key, minDuration, maxDuration;
		for ( key in accumulationProps ) {
			minDuration = accumulationProps[ key ][ 'minDuration' ];
			maxDuration = accumulationProps[ key ][ 'maxDuration' ];

			switch ( accumulationProps[ key ][ 'unit' ] ) {
				case 'hours':
					accumulationProps[ key ][ 'minDuration' ] = minDuration * 3600;
					accumulationProps[ key ][ 'maxDuration' ] = maxDuration * 3600; break;
				case 'minutes':
					accumulationProps[ key ][ 'minDuration' ] = minDuration * 60;
					accumulationProps[ key ][ 'maxDuration' ] = maxDuration * 60; break;
				default:
			}

			accumulationProps[ key ][ 'unit' ] = 'seconds';
		}
	},
	extractAndSortObjectValues = ( data ) => {
		let keys = Object.keys( data ),
			sortedValues = [];

		keys.sort();

		keys.forEach( ( key ) => {
			sortedValues.push( data[ key ] );
		} );

		return sortedValues;
	};

class AccumulatedBarsWidget extends Component {
	constructor ( props ) {
		// props are super!
		super( props );

		this.state = {
			selectedDevices: [],
			devicesHistory: {},
			areDatesDirty: false,
			isHistoryLoading: false
		}

		if ( props.options.periodProperties ) {
			mergePeriodProperties( props.options.periodProperties );
		}
		// convert accumulationPeriodEnum durations to seconds and update units
		convertDurationToSeconds();

		// maxDatesInterval corresponds to maxDuration of per_day period
		this.maxDatesInterval = {
			size: accumulationPeriodEnum.properties[ 1 ].maxDuration,
			unit: accumulationPeriodEnum.properties[ 1 ].unit
		};
		// minuteIncrement corresponds to maxDuration of per_minute period
		this.minuteIncrement = moment.duration( ( accumulationPeriodEnum.properties[ 4 ].maxDuration * 1000 ) ).get( 'minutes' );

		this.savedWidgetState = this.props.api.getWidgetState();
	}

	componentWillMount () {
		this.prepareWidgetDevices( this.props );

		this.fetchWidgetDataIfNeeded( this.props, this.state, null );

		this.setupDefaults( this.props );
	}

	componentWillReceiveProps ( nextProps ) {
		this.prepareWidgetDevices( nextProps );

		this.setupDefaults( nextProps );
	}

	componentWillUpdate ( newProps, newState ) {
		this.fetchWidgetDataIfNeeded( newProps, newState, this.state );
	}

	componentDidUpdate ( prevProps, prevState ) {
		if ( prevState.fromDate !== this.state.fromDate || prevState.toDate !== this.state.toDate ) {
			if ( this.state.fromDate && this.state.toDate ) {
				this.changeAccumulationPeriod( this.state.fromDate, this.state.toDate );
			}
		}
	}

	_dateConfigurationToMoment ( dateConfiguration, isToDate ) {
		let result;

		if ( dateConfiguration ) {
			let { type, offset, value } = dateConfiguration;

			if ( type === 'relative_to_now' ) {
				result = moment().subtract( offset.amount, offset.unit );
			}
			else {
				result = moment( value );
			}
		}
		else {
			if ( isToDate ) {
				result = moment();
			}
			else {
				result = moment().subtract( 3, 'hours' );
			}
		}

		return result;
	}

	changeAccumulationPeriod ( fromDate, toDate ) {
		let accumulationPeriod = toDate.diff( fromDate, 'seconds' ),
			accumulationPeriodProps = accumulationPeriodEnum.properties;

		if ( accumulationPeriodProps[ accumulationPeriodEnum.PER_DAY ].minDuration < accumulationPeriod ) {
			//&& accumulationPeriod <= accumulationPeriodProps[ accumulationPeriodEnum.PER_DAY ].maxDuration  ) {
			this.currentAccumulationPeriod = accumulationPeriodEnum.PER_DAY;
		}
		else if ( accumulationPeriodProps[ accumulationPeriodEnum.PER_HOUR ].minDuration < accumulationPeriod
			&& accumulationPeriod <= accumulationPeriodProps[ accumulationPeriodEnum.PER_HOUR ].maxDuration ) {
			this.currentAccumulationPeriod = accumulationPeriodEnum.PER_HOUR;
		}
		else if ( accumulationPeriodProps[ accumulationPeriodEnum.PER_10_MIN ].minDuration < accumulationPeriod
			&& accumulationPeriod <= accumulationPeriodProps[ accumulationPeriodEnum.PER_10_MIN ].maxDuration ) {
			this.currentAccumulationPeriod = accumulationPeriodEnum.PER_10_MIN;
		}
		else if ( accumulationPeriodProps[ accumulationPeriodEnum.PER_MIN ].minDuration < accumulationPeriod
			&& accumulationPeriod <= accumulationPeriodProps[ accumulationPeriodEnum.PER_MIN ].maxDuration ) {
			this.currentAccumulationPeriod = accumulationPeriodEnum.PER_MIN;
		}
	}

	setupDefaults ( props ) {
		let oldProps = this.props;

		// setup selectedDevices only once in the beginning
		if ( oldProps.provided.devices.length === 0 && props.provided.devices.length > 0 ) {
			this.setupSelectedDevices();
		}

		if ( !this.state.areDatesDirty ) {
			let { fromDate, toDate } = this.state;

			if ( !fromDate && !toDate ) {
				let { defaultFromDate, defaultToDate } = props.options

				let newFromDate = this._dateConfigurationToMoment( defaultFromDate );
				let newToDate = this._dateConfigurationToMoment( defaultToDate, true );

				this.setState( {
					fromDate: newFromDate,
					toDate: newToDate
				} );

				this.changeAccumulationPeriod( newFromDate, newToDate );
			}
		}
	}

	setupSelectedDevices () {
		let widgetDevices = this.widgetDevices,
			selectedDevicesNames = this.savedWidgetState ? this.savedWidgetState.selectedDevicesNames : undefined,
			selectedDevices = [];

		if ( selectedDevicesNames ) {
			for ( let i in selectedDevicesNames ) {
				for ( let j in widgetDevices ) {
					if ( widgetDevices[ j ].name === selectedDevicesNames[ i ] ) {
						selectedDevices.push( widgetDevices[ j ] );

						break;
					}
				}
			}

			this.setState( {
				selectedDevices
			} );
		}
		else if ( widgetDevices.length === 1 ) {
			this.setState( {
				selectedDevices: widgetDevices
			} );
		}
	}

	prepareWidgetDevices ( newProps ) {
		let monitoredDevices,
			widgetDevices = [];

		if ( newProps.provided.devices ) {
			monitoredDevices = newProps.provided.devices;
		}

		if ( monitoredDevices ) {
			monitoredDevices.forEach( ( device ) => {
				widgetDevices.push( {
					name: device.snapshot.id,
					fullDevice: device
				} );
			} );
		}

		this.widgetDevices = widgetDevices;
	}

	_generateDataForChart ( accumulationResults ) {
		let deviceId,
			currentData,
			currentCollection,

			devices = [],
			xTickLabels = [],
			valuesArray = [];

		for ( deviceId in accumulationResults ) {
			currentData = accumulationResults[ deviceId ];

			devices.push( deviceId );
			currentCollection = [ deviceId, ...extractAndSortObjectValues( currentData ) ];
			valuesArray.push( currentCollection );

			if ( xTickLabels.length === 0 ) {
				xTickLabels = Object.keys( currentData );
				xTickLabels.sort();
			}
		}

		return {
			devices,
			xTickLabels,
			valuesArray
		};

	}

	findProvidedDeviceWithId ( deviceId ) {
		return this.props.provided.devices.find( device => device.deviceId === deviceId );
	}

	render () {
		let { options } = this.props,
			{ selectedDevices, fromDate, toDate } = this.state,
			widgetDevices = this.widgetDevices,

			deviceId,

			accumulationResults = {},
			valuesColors = [],

			chartData,
			textColor,
			multiChart,
			multiselect;

		for ( deviceId in this.state.devicesHistory ) {
			let singleDeviceHistory = this.state.devicesHistory[ deviceId ];

			if ( !this.props.provided.isLoading && singleDeviceHistory.history ) {
				let accumulationFilterProps = accumulationPeriodEnum.properties[ this.currentAccumulationPeriod ],
					keyValue = options.accumulationProperties.keyValue,
					condition = options.accumulationProperties.condition,
					timeSetCollection = [],
					valuesCollection = [],
					accumulationResult = {};

				singleDeviceHistory.history.forEach( ( historyEntry ) => {
					let propertyValue = getPropertyValueByPath( historyEntry, options.propertyPath );

					timeSetCollection.push( moment( getPropertyValueByPath( historyEntry, [ 'timeSet' ] ) ).format( 'YYYY-MM-DD HH:mm:ss' ) );
					valuesCollection.push( propertyValue );
				} );

				if ( valuesCollection.length > 0 ) {
					accumulationResult = applyAccumulationFilter( accumulationFilterProps.filter, timeSetCollection, valuesCollection, keyValue, condition );

					// add to accumulationResults if there is any value different from 0
					if ( Object.values( accumulationResult ).filter( value => value !== 0 ).length ) {
						// Fill in missing times so that the chart may display them properly
						addMissingPeriodsToResult( accumulationFilterProps.filter, fromDate.clone(), toDate.clone(), accumulationResult );

						let historicalDevice = this.findProvidedDeviceWithId( deviceId );
						let externalDeviceId = findDeviceExternalId( historicalDevice );
						accumulationResults[ externalDeviceId ] = accumulationResult;
					}
				}
			}
		}

		chartData = this._generateDataForChart( accumulationResults );
		textColor = options.stylable.textColor;

		if ( selectedDevices.length
			&& this.state.fromDate
			&& this.state.toDate ) {

			multiChart = (
				<MultipleIndexedBarChart
					devices={ chartData.devices }
					xTickLabels={ chartData.xTickLabels }
					valuesArray={ chartData.valuesArray }
					isFetching={ this.state.isHistoryLoading }
					valuesColors={ valuesColors } />
			);
		}
		else {
			multiChart = (
				<div className="no-data-selected">Select devices, properties and dates to see the chart.</div>
			)
		}

		if ( widgetDevices.length !== 1 ) {
			multiselect = (
				<Multiselect
					onChange={ ( selectedDevices ) => {
						if ( selectedDevices.length <= options.maxSelectedDevices ) {
							this.onSelectedDevicesChange( selectedDevices );
						}
					} }
					data={ widgetDevices }
					textField="name"
					valueField="name"
					placeholder="Select Devices to Compare"
					value={ selectedDevices }
					caseSensitive={ false }
					minLength={ 1 }
					textColor={ textColor }
					filter="contains" />
			);
		}

		return (
			<div className="comparison-widget" >
				<div className="widget-settings-selection">
					{ multiselect }
					<RangeDateTimePickers
						dateFormat='H:i d/m/Y'

						maxDatesInterval={ this.maxDatesInterval ? this.maxDatesInterval : null }

						maxDateFromCalendar={ toDate ? toDate.clone().subtract( 1, 'minutes' ).format() : moment().subtract( 1, 'minutes' ).format() }
						maxDateToCalendar={ moment().format() }

						minuteIncrement={ this.minuteIncrement }

						startDate={ this.state.fromDate }
						endDate={ this.state.toDate }

						onStartDateChange={ ( fromDate ) => this.setState( { fromDate, devicesHistory: {}, areDatesDirty: true, isHistoryLoading: true } ) }
						onEndDateChange={ ( toDate ) => this.setState( { toDate, devicesHistory: {}, areDatesDirty: true, isHistoryLoading: true } ) } />
				</div>
				<div className="chart-container">
					{ multiChart }
				</div>
			</div>
		);
	}

	areDatasetsLoaded ( newProps ) {
		if ( newProps.provided.devices && newProps.provided.devices.length ) {
			return true;
		}

		return false;
	}

	onSelectedDevicesChange ( selectedDevices ) {
		let selectedDevicesNames = selectedDevices.map( device => {
			return device.name;
		} );

		this.setState( { selectedDevices, devicesHistory: {}, isHistoryLoading: true } );

		this.props.api.saveWidgetState( { selectedDevicesNames } );
	}

	fetchWidgetDataIfNeeded ( newProps, newState, oldState ) {
		let { selectedDevices, fromDate, toDate, devicesHistory } = newState;

		if ( this.areDatasetsLoaded( newProps ) ) {

			if ( selectedDevices.length && fromDate && toDate ) {

				if ( oldState ) {
					if ( selectedDevices === oldState.selectedDevices
						&& fromDate === oldState.fromDate
						&& toDate === oldState.toDate ) {

						return;
					}
				}

				const fromDateFormatted = fromDate.clone().set( { 'second': 0 } ).format();
				const toDateFormatted = toDate.clone().set( { 'second': 0 } ).format();

				let fullSelectedDevices = selectedDevices.map( device => device.fullDevice );

				let historyPromise = newProps.api.fetchDevicesHistory( fullSelectedDevices, fromDateFormatted, toDateFormatted );

				if ( historyPromise ) {
					historyPromise.then( ( history ) => {
						devicesHistory = {};

						fullSelectedDevices.forEach( ( device, index ) => {
							devicesHistory[ device.deviceId ] = {
								history: history[ index ]
							}
						} );

						this.setState( {
							devicesHistory,
							isHistoryLoading: false
						} );
					} );
				}
			}
		}
	}
}



export default stylable( AccumulatedBarsWidget );
