import { getPropertyValueByPath } from './../devicesUtils.js';

import * as moment from 'moment';

export default function selectDevice ( selections, devices ) {
	for ( let selection of selections ) {
		if ( typeof selectionTypes[ selection.type ] === 'function' ) {
			let {
				propertyPath,
				value
			} = selection;

			if ( !propertyPath ) {
				console.warn( 'DEPRECATED: You are using the old version of the selection notation.' );
				console.warn( 'DEPRECATED: Update to the new one - with "propertyPath" instead of "path"' );
				console.warn( 'DEPRECATED: Future versions will drop support of the old notation.' );

				propertyPath = selection.path;
			}

			let selected = selectionTypes[ selection.type ]( devices, [ 'snapshot', ...propertyPath ], value );

			if ( selected ) {
				return selected;
			}
			// Else we continue searching
		}
	}

	return null;
}

const selectionTypes = {
	min_number: ( devicesArray, propertyPath ) => {
		let properties = devicesArray.map( device => getPropertyValueByPath( device, propertyPath ) );

		let selectedIndex = properties.reduce( ( indexMin, property, index, arr ) => {
			if ( property < arr[ indexMin ] ) {
				return index;
			}
			return indexMin;
		}, 0 );

		return devicesArray[ selectedIndex ];
	},
	max_number: ( devicesArray, propertyPath ) => {
		let properties = devicesArray.map( device => getPropertyValueByPath( device, propertyPath ) );

		let selectedIndex = properties.reduce( ( indexMax, property, index, arr ) => {
			if ( property > arr[ indexMax ] ) {
				return index;
			}
			return indexMax;
		}, 0 );

		return devicesArray[ selectedIndex ];
	},
	min_date: ( devicesArray, propertyPath ) => {
		let properties = devicesArray.map( device => moment( getPropertyValueByPath( device, propertyPath ) ) );

		let selectedIndex = properties.reduce( ( indexMin, property, index, arr ) => {
			if ( moment( property ) < moment( arr[ indexMin ] ) ) {
				return index;
			}
			return indexMin;
		}, 0 );

		return devicesArray[ selectedIndex ];
	},
	max_date: ( devicesArray, propertyPath ) => {
		let properties = devicesArray.map( device => moment( getPropertyValueByPath( device, propertyPath ) ) );

		let selectedIndex = properties.reduce( ( indexMax, property, index, arr ) => {
			if ( moment( property ) > moment( arr[ indexMax ] ) ) {
				return index;
			}
			return indexMax;
		}, 0 );

		return devicesArray[ selectedIndex ];
	},
	first_with_value: ( devicesArray, propertyPath, needle ) => {
		for ( let index = 0; index < devicesArray.length; index++ ) {
			if ( getPropertyValueByPath( devicesArray[ index ], propertyPath ) === needle ) {
				return devicesArray[ index ]
			}
		}

		return null;
	},
	last_with_value: ( devicesArray, propertyPath, needle ) => {
		for ( let index = devicesArray.length - 1; index >= 0; index-- ) {
			if ( getPropertyValueByPath( devicesArray[ index ], propertyPath ) === needle ) {
				return devicesArray[ index ]
			}
		}

		return null;
	}
}