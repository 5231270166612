import React, { PureComponent } from 'react';

import CondensedTimestamp from './../CondensedTimestamp.jsx';

export default class HeaderNotificationsList extends PureComponent {
	constructor ( props ) {
		// Props are super
		super( props );

		this.state = {
			showConfirm: false
		};
	}

	onDeleteNotificationClick( event ) {
		event.stopPropagation();

		this.setState({
			showConfirm: true
		});
	}

	static getDerivedStateFromProps( nextProps, prevState ) {
		if( !nextProps.expanded && prevState.showConfirm ) {
			return {
				showConfirm: false
			}
		}

		return null;
	}

	onConfirmDeleteNotificationClick ( event ) {
		event.stopPropagation();

		this.props.onDeleteNotificationClick( this.props.notification );
	}
	onAbortDeleteNotificationClick ( event ) {
		event.stopPropagation();

		this.setState({
			showConfirm: false
		});
	}

	render () {
		let { notification, selected, expanded } = this.props;

		let messageRows = notification.message.split( '</br>' );
		let [ firstRow, ...restRows ] = messageRows;
		let outputFormats;


		let cssClass = 'header-notification-item';
		if( !notification.isSeen ) {
			cssClass += ' unseen';
		}
		if( selected ) {
			cssClass += ' selected';
		}
		if( expanded ) {
			cssClass += ' expanded';
			outputFormats = {
				day: 'HH:mm',
				year: 'HH:mm DD MMM',
				default: 'HH:mm DD-MM-YYYY'
			};
		}

		let deleteNotification = (
			<div className="delete-notification" onClick={ ( e ) => this.onDeleteNotificationClick( e ) } title="Delete"></div>
		);
		let confirmDeleteText = (
			<div className="confirm-delete">
				<div className="confirm-delete-notification-question">Delete notification?</div>
			</div>
		);

		const confirmDeleteActions = (
			<div className="confirm-delete-actions">
				<button className="confirm-delete-notification" onClick={ ( e ) => this.onConfirmDeleteNotificationClick( e ) }>Yes</button>
				<button className="abort-delete-notification" onClick={ ( e ) => this.onAbortDeleteNotificationClick( e ) }>No</button>
			</div>
		);

		const notificationTextInnerContent = (
			<div className="notification-text-content">
				<div className="main-row">{ firstRow }</div>
				<div className="other-rows">
					{ restRows.map( ( messageRow, index ) => ( <div key={ index } >{ messageRow }</div> ) ) }
				</div>
			</div>
		);

		return (
			<div className={ cssClass }>
				<div className="notification-checkbox-wrapper">
					<span className="notification-checkbox" onClick={ () => this.props.onNotificationItemSelect( notification.notificationId ) }></span>
				</div>
				<div className="notification-item-wrapper" onClick={ () => this.props.onNotificationItemClick( notification.notificationId ) } >
					{ /*this.state.showConfirm? null :*/ notificationTextInnerContent }
					<div className="notification-misc-container">
						{
							this.state.showConfirm? confirmDeleteText : (
									<CondensedTimestamp
										cssClass="notification-timestamp"
										outputFormats={ outputFormats }
										timestamp={ notification.timeSet } />
								)
						}

						{ expanded ? ( this.state.showConfirm? confirmDeleteActions : deleteNotification ) : null }
					</div>
				</div>
				<div className="notification-arrow-wrapper" onClick={ () => this.props.onNotificationItemClick( notification.notificationId ) }>
					<span className="arrow"></span>
				</div>
			</div>
		);
	}
};
