import React, { Component } from 'react';

// Widgets Loader
import DynamicWidgetsLoader from './../../../middleware/dynamic-widgets-loader.js';

import PaletteWidgetItem from './../../../components/PaletteWidgetItem.jsx';

import './WidgetsPalette.css';

class WidgetsPalette extends Component {
	constructor ( props ) {
		// Props are super
		super( props );

		this.state = {
			configuringWidget: null
		};

		this.onAddWidgetClick = this.onAddWidgetClick.bind( this );
		this.widgetTypes = DynamicWidgetsLoader.getWidgetTypes();
	}

	render () {
		let { onClose } = this.props;

		let widgetsInPalette = [];

		for ( let widgetTypeName in this.widgetTypes ) {
			let widgetType = this.widgetTypes[ widgetTypeName ];
			if ( widgetType && typeof widgetType.getWidgetConfiguration === 'function' ) {
				let widgetsConfiguration = widgetType.getWidgetConfiguration();

				if ( widgetsConfiguration ) {
					widgetsInPalette.push( {
						type: widgetTypeName,
						configuration: widgetsConfiguration
					} );
				}
			}
		}


		let widgetsInPaletteMarkup = widgetsInPalette.map( ( widgetInPalette ) => {
			let {
				name,
				image,
				description,
				defaultSize,
			} = widgetInPalette.configuration;

			return (
				<PaletteWidgetItem
					key={ widgetInPalette.type }
					type={ widgetInPalette.type }
					name={ name }
					description={ description }
					image={ image }
					defaultSize={ defaultSize }
					onAddWidgetClick={ this.onAddWidgetClick }
				/>
			);
		} );


		return (
			<div className="widgets-palette-outer-container">
				<h3 className="widgets-palette-title">Add widgets</h3>
				{ widgetsInPaletteMarkup }
				<button className="btn" onClick={ onClose }>
					Done
				</button>
			</div>
		)
	}

	onAddWidgetClick ( widgetType ) {
		this.props.onWidgetTypeSelected( widgetType );
	}
}

export default WidgetsPalette;
