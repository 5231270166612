import {
	ENABLE_DASHBOARD_DND,
	DISABLE_DASHBOARD_DND,
	TOGGLE_DASHBOARD_DND,
	SHOW_WIDGETS_CONFIGURATOR,
	HIDE_WIDGETS_CONFIGURATOR,
} from './../actions/activeDashboardActions.js'

export function activeDashboardReducer ( state = {
	isDndEnabled: false,
	isWidgetsConfiguratorVisible: false,
}, action ) {
	switch ( action.type ) {
		case ENABLE_DASHBOARD_DND:
			return {
				...state,
				isDndEnabled: true
			};
		case DISABLE_DASHBOARD_DND:
			return {
				...state,
				isDndEnabled: false,
				isWidgetsConfiguratorVisible: false
			};
		case TOGGLE_DASHBOARD_DND:
			return {
				...state,
				isDndEnabled: !state.isDndEnabled,
				isWidgetsConfiguratorVisible: false
			};
		case SHOW_WIDGETS_CONFIGURATOR:
			return {
				...state,
				isWidgetsConfiguratorVisible: true
			}
		case HIDE_WIDGETS_CONFIGURATOR:
			return {
				...state,
				isWidgetsConfiguratorVisible: false
			}
		default:
			return state;
	}
}
